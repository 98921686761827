import React, { useMemo, useRef, useState, useCallback, useEffect, useContext } from 'react';
import { t } from '@lingui/macro';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import useSearchParams from '../../../util/useSearchParams';
import { determineUniquePropName } from './actions';

import EditorContainer from '../../../components/FormBuilder/EditorContainer';
import SidebarPanel from '../../../components/FormBuilder/SidebarPanel';
import WorkflowDetailsSidepanel from './WorkflowDetailsSidepanel';
import WorkflowObjectSidepanel from './WorkflowObjectSidepanel';
import WorkflowRolesSidepanel from './WorkflowRolesSidepanel';
import WorkflowParamSidepanel from './WorkflowParamSidepanel';
import WorkflowAI from "./WorkflowAI";
import { ButtonBarContext, useButtonGroup } from '../../../components/ui/ButtonBar';

import CustomConnectionLine from './edge/CustomConnectingLine';
import UWENode from './node/UWENode';
import UWEEdge from './edge/UWEEdge';
import UWEBackground from './background/UWEBackground';

import { ReactFlow, Controls, MarkerType, Background, useReactFlow, ReactFlowProvider } from '@xyflow/react';
import '@xyflow/react/dist/style.css';

const TABS_SIDEBAR = {
  details: { title: 'Details', component: WorkflowDetailsSidepanel },
  object: { title: 'Steps', component: WorkflowObjectSidepanel },
  roles: { title: 'Roles', component: WorkflowRolesSidepanel },
  paramSchema: { title: 'Param Schema', component: WorkflowParamSidepanel },
  ai: { title: 'AI', component: WorkflowAI  },
};

const DEFAULT_TAB = Object.keys(TABS_SIDEBAR)[0];

const NODE_TYPES = {
  uwe: UWENode,
};

const EDGE_TYPES = {
  uwe: UWEEdge,
};

function WorkflowDiagram(props) {
  return (
    <ReactFlowProvider>
      <IntegratedWorkflowDiagram {...props} />
    </ReactFlowProvider>
  );
}

function IntegratedWorkflowDiagram({ state, dispatchAction }) {
  const { initialized, layout, steps, arrows, roles, height, selection, paramSchema } = state;
  // console.log("STATE: ", state);

  const selectedStepObject = steps[selection];

  // React flow instance for imperative manipulation of uncontrolled component
  const reactFlowInstance = useReactFlow();

  // --------------
  // - Helpers
  // --------------
  const getRoleFromVerticalPosition = y => {
    const index = Math.floor((y || 0) / (layout.swimmingLane.height || 1));
    console.log(roles[index]);

    return roles[index] || '';
  };

  const stepDefToNode = useCallback(
    stepdef => {
      return {
        id: stepdef.name, // step.idx.toString(),
        position: {
          x: stepdef.x ?? 0,
          y: stepdef.y ?? 0,
        },
        selected: selection === stepdef.name,
        width: stepdef.size?.width ?? 90,
        height: stepdef.size?.height ?? 40,
        data: {
          label: stepdef.title ?? stepdef.name,
          stepdef,
          layout,
        },
        type: 'uwe', // step.type
      };
    },
    [selection]
  );

  const arrowToEdge = useCallback((a, i) => {
    return {
      id: i.toString(),
      source: a.from,
      target: a.to,
      label: a.text,
    };
  }, []);

  const propagateTransitionChangesToEdges = (name, stepDef) => {
    // Update the names of the edges corresponding to transitions
    const transitionTargets = new Map();
    stepDef.transitions?.forEach(t => {
      transitionTargets.set(t.next, t.name);
    });

    // Get the outgoing edges from edited node
    const outEdges = reactFlowInstance.getEdges().filter(e => e.source === name);

    // For each edge with a relevant target:
    // - Update visible qualities
    // Else:
    // - Flag old edge to delete
    const visitedTargets = new Set();
    const edgesToDelete = [];
    outEdges.forEach(e => {
      if (transitionTargets.has(e.target)) {
        const label = transitionTargets.get(e.target);
        reactFlowInstance.updateEdge(e.id, { label });
        visitedTargets.add(e.target);
      } else {
        edgesToDelete.push(e);
      }
    });

    // Create edge for any transition targets that were not updated (did not have corresponding edge)
    const newEdges = [];
    transitionTargets.forEach((label, target) => {
      if (visitedTargets.has(target)) {
        return;
      }

      newEdges.push({
        id: name + target,
        source: name,
        target,
        label,
      });
    });
    reactFlowInstance.addEdges(newEdges);

    // Delete edges
    reactFlowInstance.deleteElements({ edges: edgesToDelete });
  };

  const setStep = (name, stepDef) => {
    // Update the node corresponding to this step
    const updatedNode = stepDefToNode(stepDef);
    reactFlowInstance.updateNode(selection, updatedNode);

    // Propagate any changes to transitions to representative edges
    propagateTransitionChangesToEdges(name, stepDef);

    // Update the step
    dispatchAction.setStepAttrs({ name, data: stepDef });
  };

  const deleteStep = name => {
    reactFlowInstance.deleteElements({
      nodes: [{ id: name }],
    });

    dispatchAction.removeStep(name);
  };

  const clearStepSelection = () => {
    reactFlowInstance.updateNode(selection, { selected: false });
    dispatchAction.setSelection({});
  };

  const addStep = () => {
    const newStep = {
      title: 'NEW STEP',
      name: getUniqueStepName('newStep'),
    };

    reactFlowInstance.addNodes(stepDefToNode(newStep));

    dispatchAction.addStep(newStep);
  };

  const duplicateStep = name => {
    // Get the original step using name
    const originalStep = steps[name];

    // Use the modified addStep function instead of the duplicate one for better control
    // Lets us synchronize the step propagated and the step displayed
    const newStep = {
      ...originalStep,
      name: getUniqueStepName(name),
      title: `${originalStep.title || name}_duplicate`,
      y: (originalStep.y || 1) + 50,
      x: (originalStep.x || 1) + 100,
    };

    // Create new node
    const newNode = stepDefToNode(newStep);
    reactFlowInstance.addNodes(newNode);

    // Create duplicated edges
    const newEdges = reactFlowInstance
      .getEdges()
      .filter(e => e.source !== e.target && e.source === name)
      .map(e => ({ ...e, id: `${e.id}_${newStep.name}`, source: newStep.name }));
    reactFlowInstance.addEdges(newEdges);

    dispatchAction.addStep(newStep);
  };

  const selectStep = name => {
    dispatchAction.setSelection({
      selection: name,
    });

    if (!/object|roles/.test(tab2)) {
      setTab2('object');
    }
  };

  const renameSelectedStep = newName => {
    // Get a unique name
    const uniqueName = getUniqueStepName(newName);

    // Rename the corresponding node (replace with node with new id, rename in stepdef data)
    const updatedNode = reactFlowInstance.getNode(selection);
    updatedNode.id = uniqueName;
    updatedNode.data.stepdef.name = uniqueName;
    reactFlowInstance.updateNode(selection, updatedNode, { replace: true });

    // Rename the selected step
    dispatchAction.setSelectedStepName(uniqueName);
  };

  const setSelectedStepRole = role => {
    dispatchAction.setRol({ rolName: role, active: true });
  };

  const setStepPosition = (name, x, y) => {
    dispatchAction.setStepPosition({
      name: name,
      position: { x, y },
    });
  };

  const addStepTransition = (name, to) => {
    dispatchAction.addTransitionToStep({
      key: name,
      next: to,
      name: '',
      action: {},
    });
  };

  const getUniqueStepName = name => {
    // Using the node ids in the flow diagram since state prop isnt updating correctly...
    const currentNodeIds = {};
    reactFlowInstance.getNodes().forEach(n => {
      currentNodeIds[n.id] = null;
    });

    // Get a unique name
    return determineUniquePropName(name, currentNodeIds);
  };

  // ----------------------------
  // - Tabs and panels callbacks
  // -----------------------------
  const [[tab2, setTab2]] = useSearchParams({
    tab2: DEFAULT_TAB,
  });

  const renameSelectedStepObject = renameSelectedStep;

  const selectedStepObjectOnChange = useCallback(newObject => setStep(selection, newObject), [steps, selection]);

  const PanelComponent = TABS_SIDEBAR[tab2]?.component;

  // -------------------
  // - Button group
  // -------------------
  const bbc = useContext(ButtonBarContext);
  useButtonGroup(
    bbc,
    () => ({
      name: 'diagram buttons',
      align: 'left',
      order: 10,
      buttons: [
        <Button key={1} color="warning" size="sm" onClick={addStep}>
          <i className="fa fa-plus" />
        </Button>,
      ],
    }),
    [dispatchAction]
  );

  useButtonGroup(
    bbc,
    () => {
      if (!selectedStepObject) return null;

      return {
        name: 'object buttons',
        align: 'left',
        order: 20,
        buttons: [
          <Button key={1} variant="success" size="sm" onClick={() => duplicateStep(selectedStepObject.name)} title={t`Duplicate`}>
            <i className="fa fa-copy" />
          </Button>,
          <Button key={2} variant="destructive" size="sm" onClick={() => deleteStep(selectedStepObject.name)} title={t`Delete`}>
            <i className="fa fa-trash" />
          </Button>,
          null,
          <Button key={3} variant="secondary" size="sm" onClick={clearStepSelection} title={t`Clear Selection`}>
            <i className="fa fa-expand" />
          </Button>,
        ],
      };
    },
    [dispatchAction, selectedStepObject]
  );

  // -------------------------------------------
  // - Convert steps to nodes & arrows to edges
  // -------------------------------------------
  const nodes = Object.values(steps).map(stepDefToNode);
  const edges = arrows.map(arrowToEdge);

  // -------------
  // - Callbacks
  // -------------
  const onNodeClick = (event, node) => selectStep(node.id);
  const onNodeDragStart = (event, node) => selectStep(node.id);

  const onNodeDragStop = (event, node) => {
    setStepPosition(node.id, node.position.x, node.position.y);
    // const role = getRoleFromVerticalPosition(node.position.y);
    // setSelectedStepRole(role);
  };

  const onConnect = ({ source, target }) => addStepTransition(source, target);

  // -------------
  // - Rendering
  // -------------
  return initialized ? (
    <>
      <EditorContainer noScroll>
        <ReactFlow
          defaultNodes={nodes}
          defaultEdges={edges}
          defaultEdgeOptions={{
            type: 'uwe',
            markerEnd: {
              type: MarkerType.Arrow,
              width: 25,
              height: 25,
              color: 'black',
            },
            style: {
              strokeWidth: 1,
              stroke: 'black',
            },
          }}
          nodeTypes={NODE_TYPES}
          edgeTypes={EDGE_TYPES}
          connectionLineComponent={CustomConnectionLine}
          connectionLineStyle={{
            strokeWidth: 1,
            stroke: 'black',
          }}
          onNodeClick={onNodeClick}
          onNodeDragStart={onNodeDragStart}
          onNodeDragStop={onNodeDragStop}
          onConnect={onConnect}
        >
          {/* <Controls /> */}
          <UWEBackground roles={roles} layout={layout} height={height} dispatchAction={dispatchAction} gap={12} size={1} />
        </ReactFlow>

        <SidebarPanel title="Workflow Diagram" collapsable adjustable>
          <div className="_formEditor _workflowEditor">
            <Nav tabs>
              {Object.entries(TABS_SIDEBAR).map(([key, { title }]) => (
                <NavItem key={key} onClick={() => setTab2(key)}>
                  <NavLink active={tab2 === key}>{title}</NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={tab2}>
              {PanelComponent && (
                <TabPane tabId={tab2}>
                  <PanelComponent
                    dispatchAction={dispatchAction}
                    object={selectedStepObject}
                    objectOnChange={selectedStepObjectOnChange}
                    setPathForSelectedObject={renameSelectedStepObject}
                    selectedObject={selectedStepObject}
                    onChange={selectedStepObjectOnChange}
                    paramSchema={paramSchema}
                    setPath={renameSelectedStepObject}
                    state={state}
                  />
                </TabPane>
              )}
            </TabContent>
          </div>
        </SidebarPanel>
      </EditorContainer>
    </>
  ) : null;
}

function WorkflowSidebarPanel({ state, dispatchAction }) {}

WorkflowSidebarPanel.defaultView = SidebarPanel.defaultView;

export default WorkflowDiagram;
