import BooleanField from './BooleanField';
import StringField from './StringField';
import SelectField from './SelectField';
import MultiSelectField from './MultiSelectField';
import ArrayField from './ArrayField';
import ObjectField from './ObjectField';
import OE_FIELD_MAP from './types';
import ObjectEditorField from './ObjectEditorField';
import JsonField from './JsonField';
import JnxField from './JnxField';
import ButtonField from './ButtonField';

[
  BooleanField,
  StringField,
  SelectField,
  MultiSelectField,
  ArrayField,
  ObjectField,
  JsonField,
  JnxField,
  ButtonField,
].forEach((Component) => {
  OE_FIELD_MAP.push({
    pattern: Component.pattern,
    Component
  });
});


export default ObjectEditorField;