import fetchApi from '../util/fetchApi';
import checkErrors from './checkErrors';

const baseUrl = 'manage/themes/';

export const ThemeSchemaApi = {
  async delete(id) {
    return checkErrors(await fetchApi.api.delete(`${baseUrl}${id}`));
  },
  async duplicate(id) {
    return checkErrors(await fetchApi.api.post(`${baseUrl}duplicate/${id}`));
  },
  async getTheme(id) {
    return checkErrors(await fetchApi.api.get(`${baseUrl}${id}`));
  },
  async getDefaultTheme() {
    return checkErrors(await fetchApi.api.get(`${baseUrl}default`));
  },
  async getThemes() {
    return checkErrors(await fetchApi.api.get(`${baseUrl}all`));
  },
  async create(theme) {
    return checkErrors(await fetchApi.api.post(`${baseUrl}new`, { data: theme }));
  },
  async update(theme) {
    return checkErrors(await fetchApi.api.post(`${baseUrl}${theme.id}`, { data: theme }));
  },
  async setDefaultTheme(id) {
    return checkErrors(await fetchApi.api.post(`${baseUrl}default/${id}`));
  },
};

export default ThemeSchemaApi;
