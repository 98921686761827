import { useMemo, useReducer } from 'react';

function useActionReducer(actions, initialState, traceTag){
  const reducer = useMemo(() => (state, {action, payload}) => {
    let newState;

    if (actions[action]) {
      if (traceTag) console.info(`ActionReducer[${traceTag}].${action}`, {payload, state});
      newState = actions[action](payload, state);
    } else {
      console.warn(`unknown action sent to reducer ${traceTag}: ${action}`);
    }

    if (!newState ||
            newState === state ||
            Object.entries(newState).every(([k, v]) => v === state[k])
    ) {
      return state;
    }

    return {...state, ...newState};
  }, [actions]);

  const [state, dispatch] = useReducer(reducer, initialState, actions.initialize ? (x) => actions.initialize(x, {}) : undefined);

  const dispatchAction = useMemo(() => Object.keys(actions).reduce((_, action) => {
    _[action] = (payload) => dispatch({action, payload});

    return _;
  }, {}), [actions]);

  return [state, dispatchAction];
}


export default useActionReducer;