import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ExternalLayout from '../components/layouts/ExternalLayout';
import { AuthContext } from '../context/AuthContext';

export function Home() {
  const auth = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    history.push(auth?.user ? ('Agencia Gerente Transporte' in auth?.user.roles ? 'workflow/workpage-gt/tray' : 'workflow/home/tray') : 'login');
  }, []);

  return <ExternalLayout />;
}

export default Home;
