import { useCallback, useMemo, useState } from 'react';
import AppContext from '../context/AppContext';
import FormModal from './FormModal';

function ModalContainer({
  formContext,
  currentFormData,
  getCurrentFormObject,
  scope,
}) {

  const [modal, setModal] = useState();

  const openModal = useCallback((modal, ...args) => {
    let resolve = null;
    let reject = null;

    // init catalog clean
    window.localStorage.removeItem('contract');
    window.localStorage.removeItem('products');

    const promise = new Promise((_resolve, _reject) => {
      resolve = (value) => {
        setModal();
        _resolve(value);
      };

      reject = () => {
        setModal();
        _reject();
      };
    });
    setModal({
      modal,
      args,
      promise,
      resolve,
      reject
    });

    return promise;
  }, []);

  const handle = useMemo(() => ({
    openModal
  }), []);

  return (
    <>
      <AppContext.Prop prop="modalContainer" value={handle} />
      {modal ? <FormModal
        modalDef={modal}
        formContext={formContext}
        formData={currentFormData.current}
        getCurrentFormObject={getCurrentFormObject}
        scope={scope}
      /> : null}
    </>
  );

}

export default ModalContainer;