import React, { useState, useEffect, useCallback } from 'react';
import { FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap';
import { useDebouncedEffect } from '../../hooks/useDebounceEffect';
import { useFormatter } from '../../util/applyFormatting';


export default function TextInput(props) {
  const {
    id,
    required,
    disabled,
    readonly,
    autofocus,
    uiSchema: { 'ui:staticText': staticText, 'ui:classNames': classNames, 'ui:prefix': prefix, 'ui:sufix': sufix, 'ui:placeHolder': placeholder },
    schema: { minLength, maxLength },
  } = props;

  const { text, onChange } = useTextInputHooks(props);

  return (
    <FormGroup>
      {staticText ? (
        <div className={classNames || ''}>{text}</div>
      ) : (
        wrapInput(
          prefix,
          <Input
            disabled={disabled}
            type="text"
            placeholder={`${placeholder || ''}`}
            className={`custom ${classNames || ''}`}
            value={text}
            minLength={minLength || ''}
            maxLength={maxLength || ''}
            required={required}
            onChange={onChange}
            readOnly={readonly}
            autoFocus={autofocus}
            id={id}
          />,
          sufix
        )
      )}
    </FormGroup>
  );
}

function wrapInput(prefix, input, sufix) {
  if (!prefix && !sufix) return input;

  return (
    <InputGroup>
      {prefix ? <InputGroupText>{prefix}</InputGroupText> : null}
      {input}
      {sufix ? <InputGroupText>{sufix}</InputGroupText> : null}
    </InputGroup>
  );
}


function useTextInputHooks({
  schema: { format },
  value,
  onChange: onChangeForm,
}) {

  const [current, setCurrent] = useState();
  const formatter = useFormatter(format);

  useDebouncedEffect(
    () => {
      if (current && current.value !== value) {
        onChangeForm(current.value);
      }
    },
    [current?.value],
    450
  );

  const onChange = useCallback(
    ({ target: { value: text } }) =>
      setCurrent({
        value: formatter.parse(text),
        text,
      }),
    [formatter]
  );

  useEffect(() => {
    setCurrent({
      value,
      text: formatter.apply(value || ''),
    });
  }, [value]);

  return { text: current?.text || '', onChange };
}
