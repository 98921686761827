import { t, Trans } from '@lingui/macro';
import React, { useCallback, useMemo, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Button } from 'reactstrap';

import LookupApi from '../../../api/LookupApi';
import RoleApi from '../../../api/RoleApi';
import DropZoneTargets from '../../../components/DropZoneTargets';
import Loader from '../../../components/Loader';
import useResourceLoader from '../../../util/useResourceLoader';
import Switch from '../../../components/Switch';
import ViewRolesModal from './ViewRolesModal';
import RolePermissionSwitch from './RolePermissionSwitch';
import { stepCanHavePermissions } from './utils';

function WorkflowRolesSidepanel({ dispatchAction, state }) {
  const [selectRolValue, setSelectRolValue] = useState('');

  const onChangeRole = (role, active = true) => {
    dispatchAction.setRol({ rolName: role, active });
    setSelectRolValue('');
  };

  const [openViewRolesModal, setOpenViewRolesModal] = useState();

  const [loadingCreateRole, setLoadingCreateRole] = useState(false);

  const onCreateRole = async role => {
    setLoadingCreateRole(true);
    const formData = { name: role };

    try {
      const result = await RoleApi.create(formData);
      setLoadingCreateRole(false);
      dispatchAction.setRol({ rolName: result.name, active: true });
      setSelectRolValue('');
    } catch (ex) {
      setLoadingCreateRole(false);
    }
  };

  const [allRoles, loadingRoles] = useResourceLoader(
    () => LookupApi.getRoles({ ignoreCache: true }).then(x => x.map(obj => ({ value: obj.id, label: obj.name }))),
    [],
    []
  );

  const { roles, selection, steps } = state;
  const selectedStep = steps[selection];
  const { role: stepRole, transitions, permissions } = selectedStep || {};
  const actions = useMemo(
    () =>
      stepCanHavePermissions(selectedStep)
        ? [...[{ title: t`View`, value: 'view' }], ...(transitions || []).map(tx => ({ title: tx.name, value: tx.name }))]
        : [],
    [selectedStep, transitions]
  );

  const roleMap = useMemo(
    () =>
      (roles || []).reduce((_, obj) => {
        _[obj] = 1;

        return _;
      }, {}),
    [roles]
  );

  const onChangePermission = useCallback(
    (role, permission, value) => {
      dispatchAction.setStepRoleActionPermission({
        step: selection,
        role,
        permission,
        value,
      });
    },
    [selection, dispatchAction]
  );

  const [dragItem, setDragItem] = useState();
  const dndProps = useMemo(
    () => ({
      dragItem,
      setDragItem,
      onDrop({ path: targetPath, side }) {
        const roleIdx = dragItem | 0;
        const targetIdx = (targetPath | 0) + (side === 'down' ? 1 : 0);
        dispatchAction.moveRole({ roleIdx, targetIdx });
        setDragItem();
      },
    }),
    [dragItem, setDragItem, dispatchAction]
  );

  return loadingRoles ? null : (
    <div className="container">
      <table className="table table-sm">
        <thead>
          <tr>
            <th>Name</th>
            {actions.map(({ title }, idx) => (
              <th key={idx} className="text-center">
                {title}
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {state.roles.length ? (
            state.roles.map((role, idx) => (
              <RoleListItem
                key={idx}
                path={`${idx}`}
                permissions={permissions?.[role]}
                isStepRole={stepRole === role}
                actions={actions}
                dndProps={dndProps}
                role={role}
                onChangePermission={onChangePermission}
                onChangeRole={onChangeRole}
              />
            ))
          ) : (
            <tr>
              <td className="text-center">add roles to work</td>
            </tr>
          )}
        </tbody>
      </table>
      {loadingCreateRole ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          <label className="title">Add Role</label>
          <CreatableSelect
            onChange={a => onChangeRole(a.label)}
            value={selectRolValue}
            onCreateOption={e => onCreateRole(e)}
            isClearable
            options={allRoles.filter(x => !roleMap[x.label])}
          />
        </>
      )}
      <hr />
      <Button onClick={() => setOpenViewRolesModal(true)}>
        <Trans>View Roles For All Steps</Trans>
      </Button>
      {openViewRolesModal ? <ViewRolesModal state={state} dispatchAction={dispatchAction} onClose={setOpenViewRolesModal} /> : null}
    </div>
  );
}

function RoleListItem({ path, dndProps, isStepRole, actions, permissions, role, onChangePermission, onChangeRole }) {
  const { dragging, activeClass, dragStart, onZoneEnter, onZoneLeave, onZoneDrop } = useDragAndDropForTreeItems({ path, dndProps });

  return (
    <tr>
      <td style={{ width: '90%' }} className={`draggable-list-item ${activeClass || ''}`} onDragStart={dragStart} draggable={!!dndProps}>
        <i className="fa fa-grip-vertical" />
        {role}
        {isStepRole ? '*' : ''}
        {dragging ? <DropZoneTargets top bottom onZoneEnter={onZoneEnter} onZoneLeave={onZoneLeave} onZoneDrop={onZoneDrop} /> : null}
      </td>
      {actions.map(({ value: action, title: actionName }, idx) => (
        <td key={idx} className="text-center">
          <RolePermissionSwitch
            isStepRole={isStepRole}
            actionName={actionName}
            role={role}
            value={!!permissions?.[action]}
            onChange={checked => onChangePermission(role, action, checked)}
          />
        </td>
      ))}
      <td>
        <button className="btn btn-sm" title={t`Remove Role`} onClick={() => onChangeRole(role, false)}>
          <i className="fa fa-trash" />
        </button>
      </td>
    </tr>
  );
}

function useDragAndDropForTreeItems({ path, dndProps }) {
  const { dragItem, setDragItem, onDrop } = dndProps;
  const [activeClass, setActiveClass] = useState();

  const dragStart = useCallback(
    e => {
      e.stopPropagation();
      e.dataTransfer.effectAllowed = 'move';
      setDragItem(path);
    },
    [path]
  );

  const onZoneEnter = useCallback(side => setActiveClass(side === 'top' ? 'droptarget up' : 'droptarget down'), []);
  const onZoneLeave = useCallback(() => setActiveClass(), []);
  const onZoneDrop = useCallback(
    side =>
      onDrop({
        path,
        side: side === 'top' ? 'up' : 'down',
      }),
    [onDrop]
  );

  const dragging = useMemo(() => dragItem && path && dragItem !== path.substring(0, dragItem.length), [dragItem, path]);

  return dndProps
    ? {
        dragging,
        activeClass: dragging && activeClass,
        dragStart,
        onZoneEnter,
        onZoneLeave,
        onZoneDrop,
      }
    : {};
}

export default WorkflowRolesSidepanel;
