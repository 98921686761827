import React, { useMemo, useState, useCallback } from 'react';
import Tree from 'react-d3-tree';

export const Diagram = (props) => {

  const { data, history, dataDiagram } = props;

  const tree = useMemo(() => {
    const rootNodes = [];

    const nodeMap =
      data?.reduce((_, data) => {
        _[data.id] = {
          name: data.id,
          data,
        };

        return _;
      }, {}) || {};

    Object.values(nodeMap).forEach(node => {
      const parent = nodeMap[node.data.parentId];

      if (parent) {
        if (!parent.children) parent.children = [];
        parent.children.push(node);
      } else {
        rootNodes.push(node);
      }
    });

    return rootNodes;
  }, [data]);

  const renderForeignObjectNode = ({
    nodeDatum: {
      data: { id, type, name },
      children,
      __rd3t,
    },
    toggleNode,
    foreignObjectProps,
  }) => (
    <g className="organizational-unit-tree-node">
      <foreignObject {...foreignObjectProps}>
        <div>
          <div className="type">{dataDiagram?.const_types[type]}</div>
          <div className="body">
            <h3>{name}</h3>
          </div>
          <div className="buttons">
            {children && (
              <button className="btn btn-secondary" onClick={toggleNode}>
                {__rd3t.collapsed ? <i className="fa fa-caret-right" /> : <i className="fa fa-caret-down" />}
              </button>
            )}
            <button className="btn btn-primary" onClick={() => history.push(`../organizationalunits/${id}`)}>
              Editar
            </button>
          </div>
        </div>
      </foreignObject>
    </g>
  );

  const [containerRef] = useCenteredTree();
  const nodeSize = { x: 200, y: 200 };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -100 };

  const containerStyles = {
    width: '90vw',
    height: '100vh',
  };

  return tree.length ? (
    <div className="organizational-unit-tree" style={containerStyles} ref={containerRef}>
      <Tree
        data={tree}
        translate={{ x: 900, y: 37 }}
        nodeSize={nodeSize}
        separation={{ siblings: 1.5, nonSiblings: 2 }}
        zoom={1}
            renderCustomNodeElement={(rd3tProps) =>
              renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
            }
        orientation="vertical"
      />
    </div>
  ) : null;
};

export default Diagram;

export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const [dimensions, setDimensions] = useState();
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setDimensions({ width, height });
      setTranslate({ x: width / 2, y: height / 2 });
    }
  }, []);

  return [dimensions, translate, containerRef];
};
