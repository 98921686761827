import { t } from '@lingui/macro';
import Workpage from '../../../components/Workpage/index';

const ListDocuments = Workpage.HOC({
  schema: {
    title: t`Document`,
    type: 'document',
    elements: [
      {
        title: t`Documents Pending Generation`,
        type: 'worktray',
        ':className': 'col-lg-12',
        resource: 'manage/documents/pending',
        tableActions: [{ type: 'reload' }],
        columns: [
          { title: t`Id`, sortkey: 'id', 'map:field': 'id' },
          { title: t`Event`, sortkey: 'event', 'map:field': 'event' },
          { title: t`Status`, sortkey: 'generationStatus', 'map:field': 'generationStatus' },
          { title: t`Last Update`, sortkey: 'lastUpdate', 'map:field': 'lastUpdate', format: 'date' },
        ],
      },
    ],
  },
});

export default ListDocuments;
