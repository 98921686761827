import { t } from '@lingui/macro';
import { useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import useActionReducer from '../util/useActionReducer';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfViewer({ url }) {
  const [state, dispatch] = useActionReducer(pdfviewerActions, {
    page: 0,
    width: 100,
    document: null,
  });

  const {
    page,
    width,
  } = state;

  function onDocumentLoadError(error) {
    console.error('onDocumentLoadError');
    console.error(error);
  }

  const ref = useRef();
  useEffect(() => {
    const interval = setInterval(() => {
      if (!ref.current) return;

      const { width: rectWidth } = ref.current.getBoundingClientRect();
      const newWidth = rectWidth - 20;

      if (newWidth !== width) dispatch.setWidth(newWidth);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [ref, width]);

  return (
    <div className="pdf-viewer" ref={ref} role="document">
      <div className="document-container">
        <Document
          file={url}
          onLoadSuccess={document => dispatch.setDocument(document)}
          onLoadError={onDocumentLoadError}
          loading={t`Loading PDF...`}
          noData={t`No PDF file specified.`}
          error={t`Failed to load PDF file.`}
          externalLinkTarget="_blank"
        >
          <Page
            pageIndex={page}
          width={width}
          />
        </Document>
      </div>
      <ControlsPanel state={state} dispatch={dispatch} />
    </div>
  );
}

function ControlsPanel({ state, dispatch }) {
  const {
    page,
    document
  } = state;

  return document ? (
    <div className="controls">
      <div className="control">
        <label>Page:</label>
        <select value={page} onChange={({ target: { value } }) => dispatch.setPage(value | 0)}>
          {Array.from(new Array(document.numPages | 0), (_, idx) => (
            <option key={idx} value={idx}>
              {idx + 1}
            </option>
          ))}
        </select>
      </div>
    </div>
  ) : null;
}

const pdfviewerActions = {
  setDocument: document => ({ document }),
  setPage: page => ({ page }),
  setScale: scale => ({ scale }),
  setWidth: width => ({ width }),
};

export default PdfViewer;
