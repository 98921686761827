import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { useDebouncedEffect } from '../../hooks/useDebounceEffect';
import { lookupOptions } from './hooks/useOptionsLookup';
import { useSideChannelSubscription } from '../../util/useSideChannel';
import getPathFromId from '../../util/getPathFromId';
import { useJnx } from '../../util/jnx';


function Autocomplete(props) {
  const {
    schema: {
      title,
      lookup,
    },
    formContext: {
      sideChannel
    },
    value,
    formData,
    onChange,
    disabled,
    required,
    idSchema: { $id } = {},
    uiSchema: {
      'ui:readonly': uiReadonly
    },
    readonly: propReadonly,
  } = props;

  const {
    onSelectExpr,
    id,
    label: labelExpr,
    searchParam,
  } = lookup || {};

  const promiseRef = useRef();

  const rootFormData = useSideChannelSubscription(sideChannel, 0);

  const path = useMemo(() => getPathFromId($id), [$id]);

  const labelJnx = useJnx(labelExpr);

  const onSelectJnx = useJnx(onSelectExpr);
  const label = useMemo(
    () => onSelectJnx?.eval(
      rootFormData || {},
      path,
      {
        formData, rootFormData
      }
    ),
    [onSelectJnx, formData, rootFormData, path]
  );

  const [current, setCurrent] = useState(value);
  useDebouncedEffect(
    () => {
      if (current !== value) {
        onChange(current.value);
      }
    },
    [current],
    450
  );

  useEffect(() => setCurrent(value), [value]);

  const readonly = uiReadonly || propReadonly;

  const promiseOptions = useCallback(
    (inputValue, isQR = false) => {
      const searchValue = inputValue.trim();

      if (searchValue.length > 2) {
        const search = { searchParam };
        search.promise = new Promise((resolve) => {
          search.resolve = resolve;
        });
        promiseRef.current = search.promise;
        setTimeout(async () => {
          if (promiseRef.current === search.promise) {
            const options = await lookupOptions({ ...lookup, params: { ...lookup.params, [searchParam]: searchValue } }, rootFormData);

            if (isQR) {
              const obj = options?.[0];
              setCurrent({ value: obj[id], label: labelJnx.eval(obj) });
              search.resolve([{
                value: obj[id],
                label: labelJnx.eval(obj),
              }]);
            } else {
              const dataParse = (options || []).map((obj) => {
                return {
                  value: obj[id],
                  label: labelJnx.eval(obj),
                };
              });
              search.resolve(dataParse);
            }
          }
        }, 300);

        return search.promise;
      }

      return [];
    },
    [rootFormData, searchParam, lookupOptions, id, labelJnx]
  );

  useEffect(() => {
    if (label) {
      promiseOptions(label, true);
    }
  }, [label, onSelectJnx, promiseOptions]);

  return (
    <div>
      {title.trim() ? (
        <label className="control-label">
          {title}
          {required ? <span className="required">*</span> : null}
        </label>
      ) : null}
      <AsyncSelect
        required={required}
        isDisabled={readonly || disabled}
        cacheOptions
        defaultOptions
        value={current}
        loadOptions={promiseOptions}
        onChange={(value) => setCurrent(value)}
      />
    </div>
  );
}

export default Autocomplete;
