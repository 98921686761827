import { Input } from 'reactstrap';

const RelactionSection = ({ relationData, isBackWardRelation }) => {
  console.log('relationData', relationData);
    return (
    <>
      {relationData.map((relation, idx) => (
        <div className="d-flex" key={`${relation.path}-${relation.relation}`}>
          <Input
            type="text"
            className={`bg-secondary form-control w-40 mr-1 ${
              isBackWardRelation ? 'order-2' : ''
            }`}
            style={{ flex: '0 0 45%' }}
            defaultValue={relation.relationNameField}
          />
          <Input
            type="text"
            className="mr-2"
            style={{ flex: '0 0 45%' }}
            disabled
            defaultValue={relation.sourceName}
          />
        </div>
      ))}
    </>
  );
};

export default RelactionSection;
