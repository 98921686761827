import React, { useState, useMemo, useContext, Fragment } from 'react';
import dayjs from 'dayjs';

import { 
  Button,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import { useParams } from 'react-router-dom';

import useResourceLoader from '../../util/useResourceLoader';
import UWEEntityApi from '../../api/UWEEntityApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import { DATETIME_FORMAT } from '../../constants';
import RequestReloadContext from '../../context/RequestReloadContext';
import InternalLayout from '../../components/layouts/InternalLayout';


export function UweEntityLogView() {
  const { id } = useParams();
  const [updateCt, setUpateCt] = useState(0);
  const [currentUWEEntity, loadingUWEEntity, errorLoadingUWEEntity] = useResourceLoader(() => UWEEntityApi.getUWEEntityLog({ id }), [updateCt], null);
  const [selectedIdx, setSelectedIdx] = useState();

  function select(idx) {
    setSelectedIdx(idx);
  }

  const [statusMap, loadingStates, errorLoadingStates] = useResourceLoader(() => UWEEntityApi.getWorkflowStatusMap({ id }), [], {});

  const workflowStep = useMemo(
    () => (
      currentUWEEntity ? (
        JSON.parse(currentUWEEntity.workflowStep)
      ) : null
    ),
    [(currentUWEEntity || {}).workflowStep]
  );

  const loading = loadingUWEEntity || loadingStates;
  const errors = errorLoadingUWEEntity || errorLoadingStates;

  const log = useMemo(
    () =>
      ((currentUWEEntity || {}).log || [])
        .map((entry, index) => ({
          index,
          timestamp: entry.timestamp ?? entry.createdAt,
          ...entry,
        }))
        .reverse(),
    [currentUWEEntity]
  );

  const requestReload = useContext(RequestReloadContext);

  async function teleportTo(idx) {
    await UWEEntityApi.teleportUWEEntityToLogEntry({ id, stepIdx: idx });
    setUpateCt(updateCt + 1);
    requestReload();
  }

  return (
    <InternalLayout>
      {loading ? (
        <Loader fullscreen />
      ) : errors ? (
        <Notification error={errors} />
      ) : !currentUWEEntity ? (
        <Notification color="danger">Entity Not Found (This must be an error, please contact the administration).</Notification>
      ) : (
        <div>
          <h3>Current Status</h3>
          <Card className="log-entry">
            <CardHeader>
              <span className="workflowStep">
                {(workflowStep || []).map((st, idx) => (
                  <div key={idx}>{statusMap[st]}</div>
                ))}
              </span>
            </CardHeader>
            <CardBody>
              <div className="state">{JSON.stringify(sortKeys(JSON.parse(currentUWEEntity.state)), null, 2)}</div>
            </CardBody>
          </Card>
          <h3>Log</h3>
          <table className='log-table'>
            <thead>
              <tr>
                <th className='idx'>#</th>
                <th>Step</th>
                <th>Action</th>
                <th>Timestamp</th>
                <th className='w-m' />
              </tr>
            </thead>
            <tbody>
              {log.map(entry => (
                <LogEntry
                  key={entry.index}
                  selected={selectedIdx === entry.index}
                  onSelect={() => select(entry.index)}
                  onTeleport={() => teleportTo(entry.index)}
                  entry={entry}
                  statusMap={statusMap}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </InternalLayout>
  );
}


function sortKeys(obj, fn) {
  return Object.keys(obj)
    .sort(fn)
    .reduce((_, k) => {
      _[k] = obj[k];

      return _;
    }, {});
}


function LogEntry({
  entry: {timestamp, action, workflowStep, state, user, index},
  statusMap,
  selected, onSelect,
  onTeleport,
  isLast
}) {
  return (
    <Fragment key={index}>
      <tr className={`${selected ? 'selected' : ''}`} onClick={onSelect}>
        <td className='idx'>{index + 1}</td>
        <td>{statusMap[workflowStep]}</td>
        <td>{dayjs(timestamp).format(DATETIME_FORMAT)}</td>
        <td>{action}</td>
        <td>{(selected && !isLast) ? (
          <Button onClick={onTeleport}>Teleport Here</Button>
        ) : null}</td>
      </tr>
      {selected ? (
        <>
          <tr className="selected-expanded">
            <td colSpan={5}>
              <div>User: {user?.name}</div>
            </td>
          </tr>
          <tr className="selected-expanded last">
            <td colSpan={5}>
              <div className='state'>{JSON.stringify(JSON.parse(state), null, 2)}</div>
            </td>
          </tr>
        </>
      ) : null}
    </Fragment>
  );
}


export default UweEntityLogView;
