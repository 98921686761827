import React from 'react';
import { Redirect } from 'react-router';
import InvoiceItemApi from '../../../api/InvoiceItemApi';
import FormComponent from '../../../components/FormComponent';
import InternalLayout from '../../../components/layouts/InternalLayout';
import { EditItemForm } from './EditItem';

const CreateItem = FormComponent({
  displayName: 'New Item',
  schema: {
    ...EditItemForm.formDefinition.schema,
    title: 'New Item',
  },
  uiSchema: EditItemForm.formDefinition.uiSchema,
  validate: EditItemForm.formDefinition.validate,
  submitButtons: {
    submit: {text: 'Create Item', className: 'btn-primary'},
  },
  async onSubmit({ formData }) {
    await InvoiceItemApi.createItem(formData);
  },
  renderFormSubmitted: () => (
    <Redirect to="/system-settings/invoice" />
  )
});

export default function() {
  return (
    <InternalLayout>
      <CreateItem />
    </InternalLayout>
  );
}

