import React, { useMemo, useContext } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { getObject } from '../util/mapObject';


function RouteSwitch({ routes }) {
  const auth = useContext(AuthContext);

  const allowedRoutes = useMemo(() => Object.entries(routes).filter(
    entry => checkRequirements(auth, entry[1].requires)
  ), [auth]);

  const defaultRoute = useMemo(() => (
    allowedRoutes.filter(entry => entry[1].default).sort(
      (a, b) => b[1].default - a[1].default
    )[0]
  ), [allowedRoutes]);

  return (
    <Switch>
      {allowedRoutes.map(([path, { ...route }], idx) => (
        <Route key={idx} path={path} {...route} />
      ))}
      {defaultRoute ? <Redirect to={defaultRoute[0]} /> : null}
    </Switch>
  );
}

function checkRequirements({ user }, requires) {
  if (requires === 'login') {
    return !!user;
  } else if (typeof requires === 'string') {
    return !!getObject(user, requires);
  }

  return true;
}

export default RouteSwitch;
