import { getStraightPath, useInternalNode } from '@xyflow/react';

import { getEdgeParams } from './utils.js';

const getCircleDirection = () => {
  return 'tr';
};

const getCircleCenterOffset = (direction) => {
  const isLeft = direction[1] === 'l';
  const isTop  = direction[0] === 't';

  const offsetX = isLeft ? -20 : 20;
  const offsetY = isTop  ? -20 : 20;

  return [offsetX, offsetY];
};

const getLabelPoint = (cx, cy, direction) => {
  const [offsetX, offsetY] = getCircleCenterOffset(direction);
  const lx = cx + 1.1*offsetX;
  const ly = cy + 1.1*offsetY;

  return [lx, ly];
};

const getCenterPoint = (sx, sy, direction) => {
  const [offsetX, offsetY] = getCircleCenterOffset(direction);
  const cx = sx + offsetX;
  const cy = sy + offsetY;

  return [cx, cy];
};

const getIntersectionPoint = (sourceNode, direction) => {
  const { x, y } = sourceNode.position; // Located at top left of node
  const { width, height } = sourceNode;

  const [offsetX, offsetY] = getCircleCenterOffset(direction);

  const isLeft = direction[1] === 'l';
  const isTop  = direction[0] === 't';

  const mx = isLeft ? x - offsetX : x + width - offsetX;
  const my = isTop  ? y - offsetY : y + height - offsetY;

  return [mx, my];
};

function UWEEdge(props) {
  const { 
    id, 
    source, 
    target, 
    markerEnd, 
    label,
    style
  } = props;

  const sourceNode = useInternalNode(source);
  const targetNode = useInternalNode(target);

  if (!sourceNode || !targetNode) {
    return null;
  }

  if (source === target) {
    const direction = getCircleDirection();
    const [sx, sy] = getIntersectionPoint(sourceNode, direction);
    const [cx, cy] = getCenterPoint(sx, sy, direction);
    const [lx, ly] = getLabelPoint(cx, cy, direction);

    const r = Math.sqrt((sx - cx)**2 + (sy - cy)**2);

    return (
      <>
        <circle cx={cx} cy={cy} r={r} stroke="black" strokeWidth="1" fill="none" />
        <text x={lx} y={ly} fill="black">{label}</text>
      </>
    );
  }

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  const [edgePath] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
  });

  const mx = (sx + tx) / 2;
  const my = (sy + ty) / 2;

  return (
    <>
      <path
        id={id}
        d={edgePath}
        markerEnd={markerEnd}
        style={style}
      />
      <text x={mx} y={my} fill="black">{label}</text>
    </>
  );
}

export default UWEEdge;
