import React, { useMemo } from 'react';
import parseFormDefinition from '../util/parseFormDefinition';
import UWEEntityFormComponent from './UWEEntityFormComponent';


function UWEFormComponent({
  slug,
  form,
  readonly,
  currentStatus,
  currentEntity,
  renderFormSubmitted,
  overrideOnSubmit,
  ...props
}) {
  const formArgs = useMemo(() => parseFormDefinition(form), [form]);
  const FormComponent = useMemo(
    () =>
      formArgs &&
      UWEEntityFormComponent({
        ...formArgs,
        renderFormSubmitted,
        overrideOnSubmit,
      }),
    [formArgs, overrideOnSubmit]
  );

  return FormComponent ? (
    <FormComponent currentEntity={currentEntity} currentStatus={currentStatus} readonly={readonly} slug={slug} {...props} />
  ) : null;
}


export default UWEFormComponent;
