/* eslint-disable indent */
import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router';
import OrganizationalUnitApi from '../../../api/OrganizationalUnitApi';
import FormComponent from '../../../components/FormComponent';
import InternalLayout from '../../../components/layouts/InternalLayout';
import { FORM_UPDATE_SUBMIT_BUTTON } from '../../../constants';

export const EditOrganizationalUnitForm = FormComponent({
  beforeRenderHook({ setFormDefinition, initialFormObject }) {
    useEffect(() => {
      setFormDefinition({
        title: 'Agencia',
        type: 'object',
        properties: {
          name: { title: 'Nombre', type: 'string' },
          type: {
            'type': 'string',
            'title': 'Tipo',
            'enum': ['Agency', 'Bureau', 'WorkDivision'],
            'enumNames': ['Agencia', 'Entidad Peticionaria', 'División de Trabajo'],
          },
          code: { title: 'Código de Negociado', type: 'string' },
          address1: { title: 'Dirección Linea 1', type: ['string', 'null'] },
          address2: { title: 'Dirección Linea 2', type: ['string', 'null'] },
          postalCode: { title: 'Código Postal', type: ['string', 'null'] },
          parentId: {
            'title': 'Unidad Organizacional Padre',
            'type': ['number', 'null'],
            'lookup': {
              'resource': 'Unidades Organizacionales',
              'api': 'organizationalunits',
              'params': {
                'all': true,
              },
             'options': {
                'useCache': true
              },
              'parentId': 'parentId',
              'collapsable': true,
              'collapseLevel': 2,
              'id': 'id',
              'label': 'name',
            },
          },
          city: { title: 'Ciudad', type: ['string', 'null'] },
          state: { title: 'Estado', type: ['string', 'null'] },
          numericCode: { title: 'Código de Agencia', type: ['number', 'null'] },
          dependencyFigure: { title: 'Cifra de Dependencia', type: ['number', 'null'] },
          ...(initialFormObject?.workflow?.paramSchema
            ? {
                workflowParams: {
                  title: 'Manejo de Módulos',
                  properties: initialFormObject.workflow.paramSchema,
                },
              }
            : null),
        },
      });
    }, [initialFormObject]);
  },
  displayName: 'EditOrganizationalUnit',
  withoutNavigation: true,
  schema: {},
  uiSchema: {
    classNames: 'smallTwoRows',
    workflowParams: { classNames: 'from-col-1 to-col-3' },
    parentId: {
      ':classNames': 'grid-3-columns',
      'ui:field': 'LookupFormField',
    },
  },
  validate(prop, errors) {
    return errors;
  },
  parseProps: () => ({
    params: useParams(),
  }),
  loadData: ({ params: { id } }) => OrganizationalUnitApi.getOrganizationUnit({ id }),
  async onSubmit(
    { formData },
    {
      props: {
        params: { id },
      },
    }
  ) {
    await OrganizationalUnitApi.editgetOrganizationUnit({ id, ...formData });
  },
  submitButtons: FORM_UPDATE_SUBMIT_BUTTON,
  renderFormSubmitted: () => (
    <Redirect to="../system-settings/organizationalunits" />
  )
});

export default function() {
  return (
    <InternalLayout>
      <EditOrganizationalUnitForm />
    </InternalLayout>
  );
}

