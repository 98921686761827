import { useCallback } from 'react';
import { Button } from 'reactstrap';

const PATTERN = { type: 'button' };

function ButtonField({ path, fieldProps, scope, object, onChange }) {
  const { label, icon, onClick } = fieldProps;

  const handleClick = useCallback(() => {
    if (!onClick) return;

    onClick({
      path,
      fieldProps,
      scope,
      object,
      onChange,
    });
  }, [path, fieldProps, scope, object, onChange]);

  return (
    <>
      <Button onClick={handleClick}>
        {icon ? (
          <>
            <i className={icon} />{' '}
          </>
        ) : null}
        {label}
      </Button>
    </>
  );
}

ButtonField.pattern = PATTERN;

export default ButtonField;
