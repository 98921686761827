import dfsSearch from './dfsSearch';


function parseObjectMapFromSchema(schema, initialObjectMap, options) {
  const objectMap = [];
  const {
    removeNullMappedFields
  } = options || {};

  if (initialObjectMap) {
    if (Array.isArray(initialObjectMap)) {
      objectMap.push(...initialObjectMap);
    } else {
      objectMap.push(...Object.entries(initialObjectMap));
    }
  }

  dfsSearch([['', schema, '', null]], ([path, current, prop, parent]) => {
    const pathPrefix = path.length ? `${path}.` : '';

    const {
      type,
      title,
      'map:field': mapField,
      'map:array': mapArray,
    } = current;

    if (path && (mapField || mapField === '')) {
      objectMap.push([mapField, path]);

      if (prop && parent && type === 'null' && title === ' ' && removeNullMappedFields) {
        delete parent[prop];
      }
    }

    switch (current.type){
      case 'object': 
        return Object.entries(current.properties).map(
          ([prop, propSchema]) => [`${pathPrefix}${prop}`, propSchema, prop, current.properties]
        );
      case 'array':
        if (mapArray) {
          const arraySubMap = parseObjectMapFromSchema(current.items, null, options);

          if (arraySubMap.length === 0) {
            arraySubMap.push(['', '']);
          }

          objectMap.push([mapArray, {array: path, map: arraySubMap}]);
        }

        break;
    }
  });

  return objectMap;
}

export default parseObjectMapFromSchema;