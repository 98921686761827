import React, { useCallback, useMemo} from 'react';
import useSearchParams from '../../../util/useSearchParams';
import EditForm from './EditForm';
import ListForms from './ListForms';
import CompareForms from './CompareForms';


function Forms(){
  const [
    [form, setFormId],
    [_compare, _setCompare]
  ] = useSearchParams({
    form: undefined,
    compare: undefined,
  });

  const compare = useMemo(
    () =>
      (_compare || '')
        .split('-')
        .map(x => x && x | 0)
        .filter(x => !!x),
    [_compare]
  );
  const setCompare = useCallback(value => 
    _setCompare(
      value?.length
      ? value.join('-')
      : undefined
    ),
    [_setCompare]
  );

  return compare.length ? (
    <CompareForms formIds={compare} setFormIds={setCompare} />
  ) : form ? (
    <EditForm formId={form} setFormId={setFormId} />
  ) : (
    <ListForms setForm={setFormId} />
  );
}

export default Forms;
