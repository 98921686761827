import React, { useContext, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';

import useResourceLoader from '../../util/useResourceLoader';
import UWEEntityApi from '../../api/UWEEntityApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';

import RequestReloadContext from '../../context/RequestReloadContext';

import { AuthContext } from '../../context/AuthContext';
import canViewStatus from '../../util/canViewStatus';
import CurrentUWEEntityForm from '../../blc/CurrentUWEEntityForm';
import InternalLayout from '../../components/layouts/InternalLayout';
import useTitle from '../../util/useTitle';
import AppContext from '../../context/AppContext';


export function DetailsVehicleRegistration() {
  const { id, entityType: slug } = useParams();

  const [reloadCount, setReloadCount] = useState(0);

  const [currentUWEEntity, loadingCurrentUWEEntity, errorLoadingCurrentUWEEntity] = useResourceLoader(
    () => {
      return UWEEntityApi.getUWEEntity({ entityId: id });
    },
    [reloadCount],
    null
  );

  const [workflowInfo, loadingWorkflowInfo, errorLoadingWorkflowInfo] = useResourceLoader(
    () => (currentUWEEntity?.workflowId ? (
      UWEEntityApi.getWorkflowInfoForId({ id: currentUWEEntity?.workflowId })
    ) : null),
    [currentUWEEntity?.workflowId]
  );

  const loading = loadingCurrentUWEEntity || loadingWorkflowInfo;
  const errorLoading = errorLoadingCurrentUWEEntity || errorLoadingWorkflowInfo;

  const isClosed = (currentUWEEntity || {}).isOpen === false;
  const readonly = isClosed;
  const [statusIdx, setStatusIdx] = useState(0);

  const auth = useContext(AuthContext);
  const statuses = useMemo(() => currentUWEEntity && currentUWEEntity.status.filter(status => canViewStatus(status, auth)), [currentUWEEntity]);

  const currentStatus = useMemo(() => {
    if (!statuses) return null;

    return (
      Number.isFinite(statusIdx) ? (
        statuses[statusIdx]
      ) : statuses.length === 1 ? (
        statuses[0]
      ) : null
    );
  }, [statuses, statusIdx]);

  const rrcState = RequestReloadContext.useHook(() => {
    console.log('rrcState hook', reloadCount);
    setReloadCount(reloadCount + 1);
  }, [reloadCount]);

  const [showDebugControls, setShowDebugControls] = useState();

  const { awaitDocuments } = currentStatus || {};
  const showControls = !awaitDocuments;

  useTitle(currentUWEEntity?.metadata?.number || 'Entidad');

  return (
    <InternalLayout>
      <AppContext.SetSidebarTab value="home" />
      {loading ? (
        <Loader fullscreen />
      ) : errorLoading ? (
        <Notification color="danger">{JSON.stringify(errorLoading)}</Notification>
      ) : (!currentUWEEntity || !workflowInfo) ? (
        <Notification color="danger">Entidad no encontrada. Esto debe ser un error, por favor contacte a la administraci&oacute;n).</Notification>
      ) : (
        <div>
          <RequestReloadContext.Provider value={rrcState}>
            <button
              style={{
                position: 'absolute',
                top: 60,
                right: 0,
                opacity: '1%',
              }}
              onClick={() => setShowDebugControls(!showDebugControls)}
            >
              {showDebugControls ? 'hide' : 'show'} debug
            </button>

            {showControls && showDebugControls ? (
              <DebugControls
                id={id}
                slug={slug}
                readonly={readonly}
                currentUWEEntity={currentUWEEntity}
                statuses={statuses}
                currentStatus={currentStatus}
                statusIdx={statusIdx}
                setStatusIdx={setStatusIdx}
              />
            ) : null}

            <CurrentUWEEntityForm
              hide={false}
              slug={slug}
              viewForm={workflowInfo.viewForm}
              currentEntity={currentUWEEntity}
              currentStatus={currentStatus}
            />
          </RequestReloadContext.Provider>
        </div>
      )}
    </InternalLayout>
  );
}


function DebugControls({
  id, currentUWEEntity,
  slug,
  statuses,
  currentStatus,
  statusIdx, setStatusIdx,
}) {
  const history = useHistory();
  const { page, role, canSendBack } = currentStatus || {};
  const [action, setAction] = useState('submit');

  async function simulateFormSubmit(e) {
    e.preventDefault();
    await UWEEntityApi.updateUWEEntity({ id, page, formData: { ...currentUWEEntity, action } });
    history.push(slug ? `/workflow/${slug}/tray` : '');
  }

  async function simulateFormSendBack() {
    await UWEEntityApi.updateUWEEntity({ id, page, formData: { ...currentUWEEntity, action: 'sendBack', sentBack: 1 } });
    history.push(slug ? `/workflow/${slug}/tray` : '');
  }

  return (
    <>
      {statuses.length > 1 ? (
        <>
          View Form
          <select value={statusIdx} onChange={({ target: { value } }) => setStatusIdx(value | 0)}>
            {statuses.map(({ page, role }, index) => (
              <option key={index} value={index}>
                {page} ({role})
              </option>
            ))}
          </select>
        </>
      ) : null}
      <div>
        Current Form page {JSON.stringify(page)} (role: {JSON.stringify(role)})
      </div>
      <form onSubmit={simulateFormSubmit}>
        <Button color="primary" onClick={simulateFormSubmit}>
          Simulate Form Submit
        </Button>
        <label>
          Action:
          <input name="action" value={action} onChange={({ target: { value } }) => setAction(value)} />
        </label>
      </form>
      {canSendBack ? (
        <Button color="primary" onClick={simulateFormSendBack}>
          Simulate Form Send Back
        </Button>
      ) : null}
      <hr />
    </>
  );
}


export default DetailsVehicleRegistration;
