

async function checkErrors(response) {
  const data = await ((response instanceof Response) ? response.json() : response);
  const { status, title, error } = data;

  if (status && title){
    throw new Error(title);
  } else if (error){
    throw new Error(error);
  } else {
    return response;
  }
}


export default checkErrors;