import fetchApi from '../util/fetchApi';
import checkErrors from './checkErrors';


const JobApi = {
  async getScheduledTasks() {
    return checkErrors(await fetchApi.api.get('manage/jobs/scheduled-tasks'));
  },
  async getBackgroundJobs() {
    return checkErrors(await fetchApi.api.get('manage/jobs/background-jobs'));
  },
  async rerunJob(params) {
    return checkErrors(await fetchApi.api.post(`manage/jobs/${params.jobId}/rerun`));
  },
  async hideJob(params) {
    return checkErrors(await fetchApi.api.post(`manage/jobs/${params.jobId}/hide`));
  },
};


export default JobApi;
