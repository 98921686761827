import React, { useEffect, useMemo } from 'react';
import { Button } from 'reactstrap';
import getPathFromId from '../../../util/getPathFromId';

import ArrayObjectRow from './ArrayObjectRow';
import ArrayPanelRow from './ArrayPanelRow';
import { useOpenModal } from '../../FormModal';
import AdditionalButton from './AdditionalButton';

function MakeArrayItem({
  $id,
  props,
  onAddClick,
  layout,
  rowIdx,
  rowCount,
  rootFormData,
  formContext,
  filterRowJnx,
  readonlyRowIfJnx,
  itemsAreObjectType,
  objectItemsSchema,
  panels,
  itemsUiSchema,
  showColumnsIf,
  rowClassNames,
  rowClassNamesIf,
  additionalButtons,
}) {
  const btnStyle = { flex: '0 0 32px', paddingLeft: 6, paddingRight: 6, fontWeight: 'bold' };
  const objectProps = { ...props?.children?.props };
  const { formData } = objectProps;
  const {
    'ui:orderable': orderable,
    'ui:removable': removable,
    'ui:removable-label': removableLabel,
    'ui:removableConfirm': removableConfirm,
    'ui:removableConfirmText': removableConfirmText,
    'classNames': uiClassNames,
  } = itemsUiSchema || {};

  const showRow = useMemo(
    () => (
      !filterRowJnx ||
      filterRowJnx.eval(formData || {}, '', {
        root: rootFormData,
        formContext,
      })
    ),
    [formData, rootFormData, formContext]
  );
  const isReadOnly = useMemo(
    () => (
      readonlyRowIfJnx && readonlyRowIfJnx.eval(formData || {}, '', {
        root: rootFormData,
        formContext,
      })
    ),
    [formData, rootFormData, formContext]
  );
  const errors = props?.children?.props?.errorSchema?.__errors;

  const hasMoveBtns = (orderable !== false) && (props.hasMoveUp || props.hasMoveDown) && !isReadOnly;
  const hasRemoveBtn = removable !== false && props.hasRemove && !isReadOnly;
  const hasToolbar = props.hasToolbar && (hasMoveBtns || hasRemoveBtn || additionalButtons);
  const columnCount = Object.keys(objectItemsSchema.properties || {}).length + (hasToolbar ? 1 : 0);

  const openModal = useOpenModal();

  const perRowClassNames = useMemo(() => (
    rowClassNamesIf ? (
      Object.entries(rowClassNamesIf).filter(
        ([_, conditional]) => conditional.eval(formData)
      ).map(
        ([className]) => className
      ).join(' ')
    ) :
    ''
  ), [rowClassNamesIf, formData]);

  rowClassNames = `${rowClassNames || ''} ${perRowClassNames || ''} ${uiClassNames || ''}`;

  const rowId = useMemo(() => `${$id}_${rowIdx}`, [$id]);
  const path = useMemo(() => getPathFromId(rowId), [rowId]);

  useEffect(() => {
    const visibility = formContext?.formFields?.current;

    if (visibility && path) {
      visibility[path] = true;

      return () => {
        visibility[path] = false;
      };
    }
  }, [rowId, formContext, rowIdx, path]);

  if (!showRow) {
    return null;
  }

  const CONFIRM_REMOVE_ITEM = () => [
    'ConfirmationModal',
    '',
    removableConfirmText || 'Confirmar que quiere borrar información entrada',
    [
      { text: 'Si', color: 'primary', value: true },
      { text: 'No', color: 'secondary', value: false },
    ]
  ];

  const mainRow = layout.wrapItem(
    rowId,
    props,
    onAddClick,
    rowIdx,
    rowCount,
    itemsAreObjectType ? (
      <ArrayObjectRow
        {...objectProps}
        isReadOnly={isReadOnly}
        schema={objectItemsSchema}
        rowIdx={rowIdx}
        showColumnsIf={showColumnsIf}
        layout={layout}
      />
    ) : (
      layout.makeSimpleItem(props)
    ),
    hasToolbar && (
      <>
        {additionalButtons ? (
          Object.entries(additionalButtons).map(([, value], idx) => (
            <AdditionalButton
              key={idx}
              formContext={formContext}
              path={path}
              value={value}
              rootFormData={rootFormData}
            />
          ))
        ) : null}
        {hasMoveBtns && (
          <Button
            aria-label="Move up"
            className="array-item-move-up"
            tabIndex="-1"
            style={btnStyle}
            disabled={props.disabled || props.readonly || !props.hasMoveUp}
            onClick={props.onReorderClick(props.index, props.index - 1)}
          >
            <i className="fa fa-arrow-up" />
          </Button>
        )}
        {hasMoveBtns && (
          <Button
            className="array-item-move-down"
            aria-label="Move down"
            tabIndex="-1"
            style={btnStyle}
            disabled={
              props.disabled || props.readonly || !props.hasMoveDown
            }
            onClick={props.onReorderClick(props.index, props.index + 1)}
          >
            <i className="fa fa-arrow-down" />
          </Button>
        )}
        {hasRemoveBtn && (
          <Button
            color="primary"
            aria-label="Remove"
            className="array-item-remove"
            tabIndex="-1"
            style={btnStyle}
            disabled={props.disabled || props.readonly}
            onClick={
              removableConfirm ? (
                async () => {
                  if (await openModal(...CONFIRM_REMOVE_ITEM())) {
                    await props.onDropIndexClick(props.index);
                  }
                }
              ) : (
                async () => {
                  await props.onDropIndexClick(props.index);
                }
              )
            }
          >
            {removableLabel || 'X'}
          </Button>
        )}
      </>
    ),
    rowClassNames
  );

  const panelRows = itemsAreObjectType ? panels.map(({ field, schema }, idx) => (
    <ArrayPanelRow
      key={idx}
      {...objectProps}
      layout={layout}
      rowClassNames={rowClassNames}
      field={field}
      schema={schema}
      columnCount={columnCount}
      showColumnsIf={showColumnsIf}
      rowIdx={rowIdx}
    />
  )) : [];

  return (
    <>
      {mainRow}
      {panelRows.length ? panelRows : null}
      {errors ? layout.wrapItemErrorList(<ErrorList errors={errors} />) : null}
    </>
  );
}


function ErrorList({ errors }) {
  return (
    <ul className="error-detail bs-callout bs-callout-info">
      {(errors || []).map((err, idx) => (
        <li key={idx} className="text-danger">
          {err.replace(/^:/, '')}
        </li>
      ))}
    </ul>
  );
}

export default MakeArrayItem;
