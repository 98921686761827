import React, { useEffect, useContext } from 'react';
import UWEEntityApi from '../../api/UWEEntityApi';
import { WaitingForDocumentState } from '../../constants';
import RequestReloadContext from '../../context/RequestReloadContext';
import usePolledResource from '../../util/usePolledResource';
import Loader from './Loader';

const WaitForUWEEntityFiles = ({ currentEntity, page }) => {
  const rrc = useContext(RequestReloadContext) || window.location;
  const [{ status }] = usePolledResource({
    resourceFn: () => UWEEntityApi.awaitDocuments({ id: currentEntity.id, page }),
    dependencies: [currentEntity.id, page],
    interval: 5000,
    initialValue: {},
  });

  useEffect(() => {
    if (status === WaitingForDocumentState.AVAILABLE) {
      rrc.reload();
    }
  }, [status, rrc]);

  return (
    <div className="d-flex justify-content-center flex-column text-center">
      <Loader big />
      <h4>Generando Documentos</h4>
    </div>
  );
};

export default WaitForUWEEntityFiles;
