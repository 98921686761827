import { getObject } from '../../util/mapObject';
import clone from '../../util/clone';

function makeDefaultSchema() {
  return { type: 'string' };
}


class JsonSchemaTreeEditorActionDispatcher {
  constructor(schemaRef, onChange) {
    this.__ref = schemaRef;
    this.__onChange = onChange;
  }

  get rootSchema() {
    return this.__ref.current;
  }

  getObjectAt(path) {
    return path ? getObject(this.rootSchema, path) : this.rootSchema;
  }

  setObjectAt(path, newSchema) {
    const newRootSchema = path ? clone.set(this.rootSchema, path, newSchema) : newSchema;
    this.__onChange(newRootSchema);
  }

  setObjectAttributes(path, newAttributes) {
    const object = this.getObjectAt(path);
    const {
      type,
      properties,
      items: itemsSchema,
    } = object;

    const newObject = {
      type,
      properties,
      items: itemsSchema,
      ...newAttributes,
    };

    this.setObjectAt(path, newObject);
  }

  deleteObjectAt(path) {
    if (!path) return;
    const newRootSchema = clone.set(this.rootSchema, path, clone.DELETE_VALUE);
    this.__onChange(newRootSchema);
  }

  changeType(path, newType) {
    const currentSchema = this.getObjectAt(path);
    if (!currentSchema || currentSchema.type === newType) return;

    const newCurrentSchema = { ...currentSchema, type: newType };

    if (newType === 'object') {
      newCurrentSchema.properties = {};
    }

    if (newType === 'array') {
      newCurrentSchema.items = makeDefaultSchema();
    }

    if (newType !== 'object') {
      delete newCurrentSchema.properties;
    }

    if (newType !== 'array') {
      delete newCurrentSchema.items;
    }

    this.setObjectAt(path, newCurrentSchema);
  }

  addPropertyToObjectSchema(path) {
    const currentSchema = this.getObjectAt(path);
    if (!currentSchema || currentSchema.type !== 'object') return;
    const newCurrentSchema = { ...currentSchema };

    if (!newCurrentSchema.properties) {
      newCurrentSchema.properties = {};
    }

    const newName = determineUniquePropName('prop', newCurrentSchema.properties);
    newCurrentSchema.properties[newName] = makeDefaultSchema();

    this.setObjectAt(path, newCurrentSchema);
  }

  renameObjectSchemaProperty(path, newName) {
    console.log(path, newName);
    if (!path) return;

    if (!newName) {
      newName = 'prop';
    }

    const pathComps = path.split('.');
    const propertyName = pathComps.pop();

    if (propertyName === newName) return;
    if (pathComps[pathComps.length - 1] !== 'properties') return;

    const parentPropsPath = pathComps.join('.');
    const parentProps = this.getObjectAt(parentPropsPath);

    const newUniqueName = determineUniquePropName(newName, parentProps);

    const newParentProps = Object.entries(parentProps).reduce((_, [key, value]) => {
      if (key === propertyName) {
        key = newUniqueName;
      }

      _[key] = value;

      return _;
    }, {});

    this.setObjectAt(parentPropsPath, newParentProps);
  }
}

function determineUniquePropName(name, object) {
  let candidate = name;
  let i = 1;

  while (candidate in object) {
    candidate = `${name}_${i}`;
    i += 1;
  }

  return candidate;
}


export default JsonSchemaTreeEditorActionDispatcher;
