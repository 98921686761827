import React, { useMemo, useState } from 'react';

const UWEFormContext = React.createContext();


export function UWEFormContextProvider(props) {
  const { children } = props;
  const { value } = useUWEFormContextComponentHooks(props);

  return (
    <UWEFormContext.Provider value={value}>
      {children}
    </UWEFormContext.Provider>
  );
}

function useUWEFormContextComponentHooks() {
  const [pendingNotes, setPendingNotes] = useState();

  const value = useMemo(
    () => ({
      pendingNotes,
      setPendingNotes,
    }),
    [pendingNotes, setPendingNotes]
  );

  return {
    value,
  };
}

export default UWEFormContext;
