import React, { useMemo } from 'react';
import Buttons from './_buttons';


const PATTERN = { type: 'select', options: { $notEmpty: true }, multiple: { $isEmpty: true } };


function SelectField({ fieldProps: prop, value, onChange, scope, object, path }) {
  const options = useMemo(() => (
    prop.optionsFromScope ?
    (scope && scope[prop.optionsFromScope]) :
    prop.options
  ) || {}, [scope, prop]);

  const { showValueIfNotInOptions, valueTextFn, leftButtons, rightButtons } = prop;
  const hasValue = useMemo(() => options?.some?.(item => item.value === value), [options, value]);
  const valueText = useMemo(() => (
        valueTextFn ? valueTextFn(value) : `${value}`
  ), [value, valueTextFn]);

  return (
    <>
      {prop.label ? <label className="title">{prop.label}</label> : null}
      <div className="d-flex">
        {leftButtons ? <Buttons buttons={leftButtons} prop={prop} value={value} object={object} path={path} scope={scope} /> : null}
        <select
          name={prop.property}
          value={value || ''}
          title={valueText}
          className="form-control"
          onChange={({ target: { value } }) => onChange(value)}
        >
          {!hasValue && showValueIfNotInOptions ? <option value={value}>{valueText}</option> : null}
          <option value="">----</option>
          {Object.values(options).map((item, idx) => (
            <option key={idx} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        {rightButtons ? <Buttons buttons={rightButtons} prop={prop} value={value} object={object} path={path} scope={scope} /> : null}
      </div>
    </>
  );
}

SelectField.pattern = PATTERN;

export default SelectField;
