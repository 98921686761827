function binarySearch(value, sortedList){
  let a = 0;
  let b = sortedList.length - 1;

  while (a <= b) {
    const c = ((a + b) / 2) | 0;
    const cVal = sortedList[c];

    if (cVal < value) {
      a = c + 1;
    } else if (cVal > value) {
      b = c - 1;
    } else {
      return c; 
    }

  }

  return a;
}


export default binarySearch;