import React from 'react';
import { FormGroup, Input } from 'reactstrap';


function StaticField(props) {
  const {
    formData,
    disabled,
    schema: {
      title,
    },
    uiSchema: {
      'ui:readonly': readonly
    },
    required,
  } = props;

  return (
    <FormGroup disabled={readonly || disabled}>
      {title ? (
        <>
          <label className="control-label" htmlFor="root_preferredBranchId">
            {title}
            {required ? <span className="required">*</span> : null}
          </label>
        </>
      ) : null}
      <Input plaintext value={formData} />
    </FormGroup>
  );
}

export default StaticField;
