import React, { useState, useMemo, useRef, useCallback } from 'react';
import {
  Button,
  FormGroup,
} from 'reactstrap';
import Jnx from '../../util/jnx';
import { TitleField } from './ObjectFieldTemplate';


function ToggleObjectField(props) {
  const {
    formContext,
    idPrefix,
    idSeparator,
    registry,
    disabled,
    readonly,
    hideError,
    formData,
    onBlur,
    onFocus,
    onChange,
    uiSchema,
    errorSchema,
    idSchema,
    schema,
  } = props;

  const { title, properties } = schema;

  const {
    fields: { SchemaField },
  } = registry;

  const { $id } = idSchema;

  const {
    'ui:className': className,
    'ui:newSection': uiNewSection,
    'ui:sectionType': uiSectionType,
    'ui:expandable': expandable,
    'ui:expandedDefault': expandedDefault = true,
  } = uiSchema;

  const formDataRef = useRef();
  formDataRef.current = formData;

  const {
    onKeyChange,
    onDropPropertyClick,
    wasPropertyKeyModified,
    onPropertyChange
  } = useObjectFieldHooks(props);

  const [expanded, setExpanded] = useState(expandedDefault);

  function toggleExpand(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setExpanded(!expanded);
  }

  const children = useMemo(
    () =>
      Object.entries(properties || {}).map(([name, childSchema]) => {
        const { title } = childSchema;
        const childUISchema = uiSchema[name] || {};

        return {
          name,
          title: title || name,
          schema: {
            ...childSchema,
            title: ' ',
          },
          uiSchema: {
            ...childUISchema,
            'ui:hideLabel': true,
          },
        };
      }),
    [properties, uiSchema]
  );

  const [addedChildren, setAddedChildren] = useState();
  const addChild = useCallback(
    name =>
      setAddedChildren(addedChildren => ({
        ...(addedChildren || {}),
        [name]: 1,
      })),
    []
  );
  const removeChild = useCallback(
    name => {
      setAddedChildren(addedChildren => {
        if (!addedChildren) return addedChildren;
        addedChildren = { ...addedChildren };
        delete addedChildren[name];

        return addedChildren;
      });

      if (!formData) return;
      const newFormData = { ...formData };
      delete newFormData[name];
      onChange(newFormData);
    },
    [formData, setAddedChildren, onChange]
  );

  const [childrenInData, missingChildren] = useMemo(
    () =>
      children.reduce(
        (_, child) => {
          const { name } = child;
          const hasData = !(formData[name] === null || formData[name] === undefined);
          const isAdded = addedChildren && addedChildren[name];
          const idx = hasData || isAdded ? 0 : 1;
          _[idx].push(child);

          return _;
        },
        [[], []]
      ),
    [children, formData, addedChildren]
  );

  return (
    <FormGroup className={className}>
      <TitleField
        id={$id}
        isSection={uiNewSection}
        sectionType={uiSectionType}
        title={title}
        expandable={expandable}
        expanded={expanded}
        toggleExpand={toggleExpand}
      />
      {expanded || !expandable ? (
        <table className="prop-fields">
          <tbody>
            {childrenInData.map(({ name, title, schema, uiSchema: childUiSchema }) => (
              <tr key={name}>
                <td>{title}</td>
                <td>
                  <SchemaField
                    key={name}
                    name={name}
                    displayLabel={false}
                    required={isRequired(name, schema, false, uiSchema)}
                    schema={schema}
                    uiSchema={childUiSchema}
                    errorSchema={errorSchema[name]}
                    idSchema={idSchema[name]}
                    idPrefix={idPrefix}
                    formContext={formContext}
                    idSeparator={idSeparator}
                    formData={(formData || {})[name]}
                    wasPropertyKeyModified={wasPropertyKeyModified}
                    onKeyChange={(value, errorSchema) => onKeyChange(name, value, errorSchema)}
                    onChange={(value, errorSchema) => onPropertyChange(name, value, errorSchema)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    registry={registry}
                    disabled={disabled}
                    readonly={readonly}
                    hideError={hideError}
                    onDropPropertyClick={onDropPropertyClick}
                  />
                </td>
                <td>
                  <Button
                    onClick={e => {
                      e.stopPropagation();
                      removeChild(name);
                    }}
                  >
                    X
                  </Button>
                </td>
              </tr>
            ))}
            {missingChildren.length ? (
              <tr>
                <td>
                  <select className="form-control" value="" onChange={({ target: { value } }) => addChild(value)}>
                    <option value="">---</option>
                    {missingChildren.map(({ name, title }) => (
                      <option key={name} value={name}>
                        {title}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      ) : null}
    </FormGroup>
  );
}

function useObjectFieldHooks(props){
  const {
    formData,
    onChange,
    errorSchema,
  } = props;
  const formDataRef = useRef();
  formDataRef.current = formData;
  const [wasPropertyKeyModified, setWasPropertyKeyModified] = useState();

  function onKeyChange(oldValue, value, errorSchema) {
    if (oldValue === value) {
      return;
    }

    value = getAvailableKey(value, formData);
    const _formData = formDataRef.current || {};
    const newKeys = { [oldValue]: value };
    const renamed = Object.keys(_formData).reduce((_, key) => {
      _[newKeys[key] || key] = _formData[key];

      return _;
    }, {});

    setWasPropertyKeyModified(true);

    onChange(
      renamed,
      (errorSchema && props.errorSchema) &&
      { ...props.errorSchema, [value]: errorSchema }
    );
  }

  function onDropPropertyClick(key) {
    return (event) => {
      event.preventDefault();
      const copiedFormData = { ...formDataRef.current };
      delete copiedFormData[key];
      onChange(copiedFormData);
    };
  }

  function onPropertyChange(name, value, propErrorSchema) {
    const newFormData = { ...formDataRef.current };
    newFormData[name] = value;
    onChange(
      newFormData,
      (propErrorSchema && errorSchema) &&
            { ...errorSchema, [name]: propErrorSchema }
    );
  }

  return {
    onKeyChange,
    onDropPropertyClick,
    wasPropertyKeyModified,
    onPropertyChange,
  };
}


export function compileShowIf(objectShowIf) {
  return (objectShowIf instanceof Jnx) ? objectShowIf : new Jnx(objectShowIf);
}

export function evalShowIf(formData, route, objectShowIf, bindings) {
  try {
    return compileShowIf(objectShowIf).eval(formData, route, bindings);
  } catch {
    return false;
  }
}


export function getAvailableKey(preferredKey, formData) {
  let index = 0;
  let newKey = preferredKey;

  while (Object.prototype.hasOwnProperty.call(formData, newKey)) {
    index += 1;
    newKey = ''.concat(preferredKey, '-').concat(index);
  }

  return newKey;
}


export function isRequired(name, schema, hideField, uiSchema) {
  const childUISchema = uiSchema[name] || {};

  return (Array.isArray(schema.required) && schema.required.indexOf(name) !== -1 && !hideField) || (childUISchema['akc:required'] && !hideField);
}


export default ToggleObjectField;
