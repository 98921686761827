import { Trans } from '@lingui/macro';
import React, { useState, useEffect } from 'react';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import WorkpageApi from '../../../api/WorkpageApi';
import Loader from '../../../components/Loader';
import Notification from '../../../components/Notification';
import useResourceLoader from '../../../util/useResourceLoader';
import useLoader from '../../../util/useLoader';
import JsonTreeEditor from '../../../components/JsonTreeEditor';
import useSearchParams from '../../../util/useSearchParams';
import WorkpageDetailsEditor from './WorkpageDetailsEditor';

import JsonEditor from '../../../components/JsonEditor';

const SHOW_TABS = {
  workpage: 'Workpage',
  tree: 'Tree',
  json: 'Json',
};

function EditWorkpage({ workpageId, setWorkpage: setWorkpageId }) {
  const [workpage, loadingWorkpage, errorLoadingWorkpage, setWorkpage] = useResourceLoader(
    () =>
      workpageId === 'new'
        ? {
            order: 0,
            slug: 'new-workpage',
            name: 'New Workpage',
            image: {
              icon: 'fa fa-file',
            },
            definition: {
              type: 'workpage',
              elements: [],
            },
          }
        : WorkpageApi.get({ id: workpageId }),
    []
  );

  const [[show, setShow]] = useSearchParams({
    show: 'workpage',
  });

  const [loadingSetWorkpage, errorLoadingSetWorkpage, loadFn] = useLoader();
  const loading = loadingWorkpage || loadingSetWorkpage;
  const error = errorLoadingWorkpage || errorLoadingSetWorkpage;
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const [dirtyWorkpage, setDirtyWorkpage] = useState();

  const currentWorkpage = dirtyWorkpage || workpage;

  function save() {
    return loadFn(async () => {
      if (workpageId === 'new') {
        const saveOS = await WorkpageApi.create(dirtyWorkpage);
        setWorkpage(saveOS);
        setWorkpageId(saveOS.id);
      } else {
        const savedOS = await WorkpageApi.update(workpageId, dirtyWorkpage);
        setWorkpage(savedOS);
      }
    });
  }

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return loading ? (
    <Loader />
  ) : (
    <>
      {error ? <Notification error={error} /> : null}
      {workpage ? (
        <div>
          <h3>
            {workpageId === 'new' ? (
              <>
                <Trans>Creating Workpage: {workpage.name}</Trans>
              </>
            ) : (
              <>
                <Trans>Editing Workpage: {workpage.name}</Trans>
              </>
            )}
          </h3>
          <UncontrolledDropdown>
            <DropdownToggle caret>{SHOW_TABS[show]}</DropdownToggle>
            <DropdownMenu>
              {Object.entries(SHOW_TABS).map(([key, title]) => (
                <DropdownItem key={key} onClick={() => setShow(key)}>
                  {title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
          {show === 'tree' ? <JsonTreeEditor mode="object" value={currentWorkpage} onChange={setDirtyWorkpage} /> : null}
          {show === 'json' ? <JsonEditor value={currentWorkpage} onChange={setDirtyWorkpage} onSaveForm={save} /> : null}
          {show === 'workpage' ? <WorkpageDetailsEditor workpage={currentWorkpage} onChange={setDirtyWorkpage} /> : null}
          <Button color="success" disabled={!dirtyWorkpage} onClick={save}>
            <Trans>Save</Trans>
          </Button>
        </div>
      ) : null}
    </>
  );
}

export default EditWorkpage;
