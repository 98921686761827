import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import AppContext from '../../context/AppContext';
import { AuthContext } from '../../context/AuthContext';
import useSearchParams from '../../util/useSearchParams';


function ProcessLogin() {
  const [
    [token]
  ] = useSearchParams({ token: null });
  const {
    setWelcomeShown
  } = useContext(AppContext);

  const history = useHistory();
  const auth = useContext(AuthContext);
  const [error, setError] = useState();
  const [callingLogIn, setCallingLogIn] = useState();
  const [calledLogIn, setCalledLogIn] = useState();

  useEffect(() => {
    if (auth?.user) {
      setWelcomeShown(true);
    }

    if (!token) {
      setError(new Error('Login token is missing.'));
    } else if (calledLogIn && auth?.user) {
      history.replace('/');
    } else if (!callingLogIn) {
      setCallingLogIn(true);
      auth
        .loginWithToken({ token })
        .then(() => setCalledLogIn(true))
        .catch(setError);
    }
  }, [
    history,
    token,
    auth,
    callingLogIn,
    calledLogIn,
  ]);

  return error ? (
    <Notification error={error} />
  ) : (
    <Loader fullscreen />
  );
}


export default ProcessLogin;

