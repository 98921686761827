import fetchApi from '../util/fetchApi';
import checkErrors from './checkErrors';


export const SettingsManagementApi = {
  async setSLASetting(data) {
    return checkErrors(await fetchApi.api.post('manage/settings/sla', { data }));
  },
};


export default SettingsManagementApi;
