import React from 'react';
import UserApi from '../../api/UserApi';
import FormComponent from '../../components/FormComponent';
import useSearchParams from '../../util/useSearchParams';
import { bindEvent } from '../../util/useEventHandler';
import SetBodyClassName from '../../components/SetBodyClassName';
import { setObject } from '../../util/mapObject';
import OrganizationalUnitApi from '../../api/OrganizationalUnitApi';

const sendMessage = (message) => {
  window.parent.postMessage(message, '*');
};


const makeUserSchemaToJedi = ({
  onlyEditUserData = false
} = {}) => ({
  type: 'object',
  properties: {
    firstName: { title: 'Nombre', type: 'string' },
    lastName: { title: 'Apellido', type: 'string' },
    organizationalUnitId: {
      title: 'Organización',
      type: 'number',
      'ui:field': 'LookupFormField',
      'ui:readonly': onlyEditUserData,
      lookup: {
        api: 'organizationalunits',
        resource: 'Organizaciones',
        id: 'id',
        label: 'name',
        setObjectAs: 'organizationalUnit',
        params: {
          agencyCode: { field: 'extras.agencyCode' },
        },
      },
    },
    position: {
      title: 'Puesto',
      type: ['string', 'null'],
      'akc:validate': ' "Position" in extras.isRequired and $isFalsy(position) ? "es requerido." : null',
    },
    accessProfileId: {
      title: 'Tipo de Usuario',
      type: 'number',
      lookup: {
        api: 'profiles',
        resource: 'Tipos de Usuarios',
        id: 'id',
        label: 'displayName',
      },
      'ui:field': 'LookupFormField',
      'ui:readonly': onlyEditUserData,
    },
    email: {
      title: 'Email',
      type: 'string',
      'ui:readonly': onlyEditUserData,
      format: 'email',
    },
    phone: {
      title: 'Teléfono',
      type: ['string', 'null'],
      'akc:validate': ' "Phone" in extras.isRequired and $isFalsy(phone) ? "es requerido." : null',
    },
    extension: { title: 'Extensión', type: ['string', 'null'] },
    isOnLeave: {
      title: 'Fuera de Oficina',
      type: 'boolean',
      'ui:field': 'BoolYesNoField',
      ':classNames': 'from-col-1',
    },
    roles: {
      title: ' ',
      description: 'Roles',
      ':classNames': 'from-col-1 to-col-3',
      type: 'array',
      'ui:readonly': onlyEditUserData,
      items: {
        type: 'object',
        'ui:addable': !onlyEditUserData,
        'ui:orderable': !onlyEditUserData,
        'ui:removable': !onlyEditUserData,
        properties: {
          roleId: {
            title: 'Rol',
            lookup: {
              api: 'roles',
              resource: 'Roles',
              id: 'id',
              label: 'name',
            },
            items: { type: 'number' },
            'ui:field': 'LookupFormField',
            'ui:readonly': onlyEditUserData,
          },
          canWorkAs: {
            'title': 'Puede Trabajar',
            'ui:cellClassName': 'text-center align-middle',
            'type': 'boolean',
          },
          canBeAssigned: {
            'title': 'Puede ser asignado',
            'ui:cellClassName': 'text-center align-middle',
            'type': 'boolean',
          },
          canAssign: {
            'title': 'Puede asignar',
            'ui:cellClassName': 'text-center align-middle',
            'type': 'boolean',
          },
          mainRole: {
            'title': 'Rol Principal',
            'ui:cellClassName': 'text-center align-middle',
            'type': 'boolean',
          },
        },
      },
    },
  },
  required: [
    'firstName',
    'lastName',
    'email',
    'organizationalUnitId',
    'accessProfileId',
  ]
});


const JEDI_FIELD_MAP = {
  email: 'email',
};


export const IntegrationUserForm = FormComponent({
  displayName: 'IntegrationUser',
  withoutNavigation: true,
  schema: makeUserSchemaToJedi(),
  uiSchema: {},
  parseProps: () => ({
    token: useSearchParams({ token: null })[0][0],
  }),
  loadData: ({ token }) => UserApi.getUserAdHoc({ token }).then(user => user || {}),
  async onSubmit({ formData }, { props: { token } }) {
    await UserApi.setUserAdHoc({ token, ...formData });
    sendMessage({ action: 'submitted' });
  },
  submitButtons: {
    submit: {
      text: 'Enviar',
      className: 'btn-primary',
      'onBeforeSubmit': (submitEvent) =>
        new Promise(async (resolve) => {
          console.log('submitEvent', submitEvent);
          const organizationalUnit = await OrganizationalUnitApi.getOrganizationUnitStub({
            id: submitEvent.formData.organizationalUnitId
          });

          const unbind = bindEvent(window, 'message', (e) => {
            e.preventDefault();

            if (typeof e.data === 'object' && e.data.action) {
              if (e.data.action === 'submitOk') {
                resolve();
                unbind();
              } else if (e.data.action === 'validationFailed') {
                const errors = (e?.data?.errors || []).reduce((_, err) => {
                  const path = JEDI_FIELD_MAP[err.field.toLowerCase()];
                  if (path) setObject(_, `${path}.__errors`, [err.error]);

                  return _;
                }, {});
                submitEvent.setExtraErrors(errors);
                submitEvent.preventDefault();
                resolve();
                unbind();
              } else if (e.data.action === 'submitNotOk') {
                submitEvent.preventDefault();
                resolve();
                unbind();
              }
            }
          });

          sendMessage({
            action: 'submit',
            userData: {
              ...submitEvent.formData,
              dependencyFigure: String(organizationalUnit.dependencyFigure || '0').padStart(4, '0'),
              agencyCode: String(organizationalUnit.numericCode || '0').padStart(3, '0'),
            },
          });
        }),
    },
    cancel: {
      text: 'Regresar',
      className: 'btn-secondary',
      'onBeforeSubmit': (submitEvent) => {
        submitEvent.preventDefault();
        sendMessage({ action: 'cancel' });
      },
      ignoreValidation: true,
    },
  },
});

export default function() {
  return (
    <>
      <SetBodyClassName className="bg-white" />
      <div className="jedi-form">
        <IntegrationUserForm />
      </div>
    </>
  );
}

