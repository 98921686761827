import { t } from '@lingui/macro';
import Workpage from '../../../components/Workpage/index';

const ListWorkpage = Workpage.HOC({
  slug: '?workpage=',
  schema: {
    title: t`Workpages`,
    type: 'workpage',
    elements: [
      {
        title: t`Workpages`,
        type: 'worktray',
        ':className': 'col-lg-12',
        resource: 'workpages',
        tableActions: [
          {
            label: t`Create Workpage`,
            route: '${workpage.slug}new',
          },
        ],
        filters: [
          { title: t`Name`, key: 'name', type: 'string' },
          { title: t`Slug`, key: 'slug', type: 'string' },
        ],
        columns: [
          { title: t`Order`, sortkey: 'order', 'map:field': 'order', style: { textAlign: 'center' } },
          { title: t`Name`, sortkey: 'name', 'map:field': 'name', 'map:image': 'image', iconSize: '1.4em' },
          { title: t`Slug`, sortkey: 'slug', 'map:field': 'slug' },
          { title: t`Version`, sortkey: 'version', 'map:field': 'version' },
          { title: t`Last Updated`, sortkey: 'lastUpdated', 'map:field': 'lastUpdated', format: 'date' },
          {
            title: t`Actions`,
            actions: [
              {
                label: t`Edit`,
                route: '${workpage.slug}$id',
              },
            ],
          },
        ],
      },
    ],
  },
});

export default ListWorkpage;
