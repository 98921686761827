import React, { useMemo } from 'react';
import _ from 'lodash';
import { FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap';
import leftPad from '../../util/leftPad';


export default function TimeInputField({
  schema: {
    title
  },
  uiSchema: {
    'akc:requiredIfVisible': akcRequiredIfVisible,
    'ui:readonly': uiReadonly,
  },
  formData: value,
  disabled,
  readonly: propReadonly,
  required: requiredProp,
  onChange,
}) {
  const readonly = propReadonly || uiReadonly;
  const required = requiredProp || akcRequiredIfVisible;

  const [valid, hour, minute, ampm] = useMemo(() => {
    const m = /^(\d\d?):(\d\d?)\s*(am|pm)$/.exec((value || '').toLowerCase());

    if (m) {
      return [
        true,
        Math.min(Math.max(m[1] | 0, 0), 12) || 12,
        Math.min(Math.max(m[2] | 0, 0), 59),
        m[3]
      ];
    }

    return [false, null, null, null];
  }, [value]);

  const setTime = useMemo(
    () =>
      _.debounce((hour, minute, ampm) => {
        hour = Math.min(Math.max(hour, 0), 12) || 12;
        minute = Math.min(Math.max(minute, 0), 59);
        const value = `${leftPad(hour || 12, 2, '0')}:${leftPad(minute || 0, 2, '0')}${ampm ?? 'am'}`;
        console.log('new value', value);

        return onChange(value);
      }, 100),
    [onChange]
  );

  const [
    onHourChange,
    onMinuteChange,
    onAMPMChange,
  ] = useMemo(() => {
    return [
      ({target: {value}}) => setTime(value | 0, minute, ampm),
      ({target: {value}}) => setTime(hour, value | 0, ampm),
      ({target: {value}}) => setTime(hour, minute, value),
    ];
  }, [setTime, hour, minute, ampm]);

  const readOnlyIf = false;
  const isDisabled = readonly || readOnlyIf || disabled;
  const allowNoValue = false;

  return (
    <FormGroup disabled={readonly || readOnlyIf || disabled}>
      {title !== ' ' ? (
        <label className="control-label" htmlFor="root_preferredBranchId">
          {title}
          {required ? <span className="required">*</span> : null}
        </label>
      ) : null}
      <InputGroup style={{ width: '16em' }}>
        <Input placeholder="--" value={valid ? leftPad(hour, 2, '0') : ''} disabled={isDisabled} required={required} onChange={onHourChange} />
        <InputGroupText>:</InputGroupText>
        <Input placeholder="--" value={valid ? leftPad(minute, 2, '0') : ''} disabled={isDisabled} required={required} onChange={onMinuteChange} />
        <Input type="select" disabled={isDisabled} required={required} value={ampm ?? ''} onChange={onAMPMChange}>
          {allowNoValue || !valid ? <option value="">---</option> : null}
          <option value="am">am</option>
          <option value="pm">pm</option>
        </Input>
      </InputGroup>
    </FormGroup>
  );
}
