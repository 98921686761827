import { Label, Input, Row, Col } from 'reactstrap';
import dayjs from 'dayjs';

const EntityInfoLayout = ({ info = {}, onObjectSchemaChange }) => {
  return (
    <>
      <h3>
        Object Schema
      </h3>
      <Row>
        <Col xs="4">
          <Label for="name">
            Name
          </Label>
          <Input className="form-control" id="name" value={info.name || ''} onChange={e => onObjectSchemaChange({ name: e.target.value })} />
        </Col>
        <Col xs="4">
          <Label for="version">
            Version
          </Label>
          <Input readOnly value={info.version || '--'} id="version" />
        </Col>
        <Col xs="4">
          <Label for="lastUpdated">
            Last updated
          </Label>
          <Input readOnly id="lastUpdated" value={dayjs(info.lastUpdated).format('MM/DD/YY')} />
        </Col>
      </Row>
    </>
  );
};

export default EntityInfoLayout;
