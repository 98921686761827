import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import UWEEntityApi from '../api/UWEEntityApi';

import customFormats from '../util/customFormats';
import FormComponent from '../components/FormComponent';
import { getObject, interpolateMap, mapObject, setObject } from '../util/mapObject';
import { useSideChannel } from '../util/useSideChannel';
import formValidation from '../util/formValidation';

import Effect from '../components/Effect';
import HandleUWEEntityUpdate from '../components/HandleUWEEntityUpdate';
import FormNavigationPanel from './FormNavigationPanel';

import { FORM_SUBMIT_BUTTONS, FORM_ONLY_SUBMIT_BUTTON, FORM_BUTTONS_GENERATE_DOCS } from '../constants';
import { AuthContext } from '../context/AuthContext';
import IntegrationsApi from '../api/IntegrationsApi';
import AppContext from '../context/AppContext';
import UWEFormContext from '../context/UWEFormContext';


function UWEEntityFormComponent({
  slug,
  entityType,
  page,
  buildFormContext,
  secondaryClientForm,
  generateDocuments,
  renderFormSubmitted: propRenderFormSubmitted,
  noSubmit,
  canAddClients,
  hasSaveProgressButton = true,
  commentFieldsMap,
  overrideOnSubmit,
  submitButtons: propSubmitButtons,
  displayName,
  ...props
}) {
  const submitButtons = propSubmitButtons || (noSubmit ? (
    {}
  ) : (hasSaveProgressButton ?
    FORM_SUBMIT_BUTTONS :
    FORM_ONLY_SUBMIT_BUTTON
  ));

  const hasSecondaryClients = !!secondaryClientForm;

  return FormComponent({
    noHtml5Validate: true,
    parseProps: ({ readonly, currentEntity, onlySave }) => {
      const buttons = useMemo(
        () => ({
          ...(onlySave ? (submitButtons.saveOnly ? { saveOnly: submitButtons.saveOnly } : {}) : submitButtons),
          ...(generateDocuments && onlySave ? FORM_BUTTONS_GENERATE_DOCS : {}),
        }),
        [onlySave]
      );

      const auth = useContext(AuthContext);
      const uweFormContext = useContext(UWEFormContext);
      const appContext = useContext(AppContext);

      return ({
        readonly: readonly || Object.keys(buttons).length === 0,
        currentEntity,
        appContext,
        uweFormContext,
        auth,
        entityType,
        submitButtons: buttons,
        generateDocuments,
      });
    },
    loadData: ({ currentEntity }) => Promise.resolve(currentEntity),
    buildFormContext: (...args) => {
      const { props } = args[0];
      const curUWEEntity = props.currentEntity || {};
      const { user: auth = {} } = props.auth || {};
      const { user = {} } = auth;

      return ({
        auth,
        user,
        integration: {
          url: IntegrationsApi.GetOperationUrl,
          call: IntegrationsApi.CallOperationUrl,
        },
        draftId: curUWEEntity?.draftId,
        status: props.currentStatus,
        fileField: { postArgs: { entityId: (curUWEEntity || {}).id || '' } },
        clientEmail: getObject(curUWEEntity, 'clients[0].retailClientBasicInfo.email'),
        clientFirstName: getObject(curUWEEntity, 'clients[0].retailClientBasicInfo.firstName'),
        clientLastName: getObject(curUWEEntity, 'clients[0].retailClientBasicInfo.lastName'),
        ...(buildFormContext ? buildFormContext(...args) : {}),
      });
    },
    beforeRenderHook({
      currentFormData,
      saveCurrentFormObject,
      setFormDefinition,
      getCurrentFormObject,
      setExtraErrors,
      setFormObject,
      submitButtons,
      navRef,
      onSubmitRef,
    }) {
      const [clientIdx, _setClientIdx] = useState(null);
      const [clientErrors, setClientErrors] = useState([]);
      const setClientIdx = useCallback(
        (idx) => {
          saveCurrentFormObject().then(() => {
            console.log('setting clientIdx to', idx);
            _setClientIdx(idx | 0);
          });
        },
        [_setClientIdx, saveCurrentFormObject]
      );

      useEffect(() => {
        if (clientIdx === null) return;
        console.log('clientIdx changed', clientIdx);

        if (!hasSecondaryClients || clientIdx === 0) {
          setFormDefinition();
          setExtraErrors();
        } else {
          const { schema, uiSchema, objectMap, commentFieldsMap } = secondaryClientForm;
          setFormDefinition(
            schema,
            uiSchema,
            interpolateMap(objectMap, { idx: clientIdx }),
            commentFieldsMap,
            clientIdx
          );
          setExtraErrors();
        }
      }, [clientIdx]);

      const addNewClient = useCallback(() => {
        const uweEntityObj = getCurrentFormObject();
        const clients = (uweEntityObj.clients || []).length === 0 ? [{}] : (uweEntityObj.clients || []);
        const idx = clients.length;

        return saveCurrentFormObject({
          ...uweEntityObj,
          clients: [...clients, {}],
        }).then(() => _setClientIdx(idx));
      }, [getCurrentFormObject, saveCurrentFormObject]);

      const validateCurrentClient = useCallback((object) => {
        const errorSchema = validateClient(
          object === undefined ? getCurrentFormObject() : object,
          clientIdx | 0,
          props, secondaryClientForm
        );
          const hasErrors = !!Object.keys(errorSchema).length;
          console.log('validate current client : ', errorSchema);

          const newClientErrors = [...clientErrors];
          newClientErrors[clientIdx | 0] = hasErrors;

          if (hasErrors) {
            setExtraErrors(errorSchema);
          } else {
            setExtraErrors();
          }

          setClientErrors(newClientErrors);

          return !hasErrors;
        },
        [clientIdx, getCurrentFormObject]
      );

      const validateAllClients = useCallback(
        object => {
          object = object === undefined ? getCurrentFormObject() : object;

          // Short circuit validation on Send Back
          if (object.IsSentBack) {
            return true;
          }

        const validations = object.clients.map((cl, idx) => validateClient(
          object,
          idx,
          props, secondaryClientForm
        ));
          const curIdx = clientIdx | 0;

          console.log('validations', validations);
          const clientErrors = validations.map(v => !!Object.keys(v).length);
          const hasErrors = clientErrors.some(v => v);

          if (clientErrors[curIdx]) {
            setExtraErrors(validations[curIdx]);
          } else {
            setExtraErrors();
          }

          setClientErrors(clientErrors);

          return !hasErrors;
        },
        [clientIdx, getCurrentFormObject]
      );

      const cfSideChannel = useSideChannel();
      useEffect(() => {
        cfSideChannel.publish({
          clientIdx,
          currentFormData,
          addNewClient,
          setClientIdx,
          saveCurrentFormObject,
          validateCurrentClient,
          validateAllClients,
          canAddClients,
          navRef,
          onSubmitRef,
        });
      }, [
        clientIdx,
        currentFormData,
        setClientIdx,
        addNewClient,
        saveCurrentFormObject,
        validateCurrentClient,
        validateAllClients,
        canAddClients,
        navRef,
        onSubmitRef,
      ]);

      if (submitButtons.submit && submitButtons.submit.btnProps) {
        submitButtons.submit.btnProps.cfSideChannel = cfSideChannel;
      }

      return {
        addNewClient,
        clientIdx: clientIdx || 0,
        clientErrors,
        setClientIdx,
      };
    },
    renderNavigation({ title, navRef, formRef, hasUnsavedChanges }) {
      return !props.withoutNavigation ? (
        <FormNavigationPanel
          formTitle={title}
          ref={navRef}
          formElement={formRef && formRef.current}
          hasUnsavedChanges={hasUnsavedChanges}
        />
      ) : null;
    },
    submitButtons,
    async onSubmit(
      { object, commentFieldsMap: curFieldMap },
      scope
    ) {
      const { currentEntity, entityType, slug, auth, appContext, uweFormContext } = scope.props;
      const commentFM = curFieldMap || commentFieldsMap;

      if (commentFM) {
        Object.entries(commentFM).forEach(([destPath, srcPath]) => {
          const currentVal = mapObject(
            object,
            {
              value: srcPath,
            },
            {},
            {}
          ).value;

          setObject(object, destPath, currentVal);
        });
      }

      if (uweFormContext.pendingNotes) {
        object.comments = object.comments ?? [];
        uweFormContext.pendingNotes.forEach(({ message }) => object.comments.push(message));
        uweFormContext.setPendingNotes();
      }

      if (currentEntity?.id) {
        const payload = {
          id: currentEntity.id,
          page,
          formData: {
            action: object.action,
            isSentBack: object.isSentBack,
            isPreview: object.isPreview,
            sendBackReason: object.sendBackReason,
            sendBackTo: object.sendBackTo,
            flags: object.flags,
            generateDocs: object.generateDocs,
            closeReason: object.closeReason,
            comments: object.comments,
            data: object.data,
          },
        };

        return overrideOnSubmit ? overrideOnSubmit({
          action: 'updateUWEEntity',
          payload,
        }) : (appContext.isAnonymous ? (
          UWEEntityApi.updateUWEEntityAsAnonymous({payload, slug, tenant: appContext.tenant})
        ) : (
          UWEEntityApi.updateUWEEntity(payload)
        ));
      } else {
        const payload = {
          page,
          formData: {
            entityTypeId: entityType.id,
            ...object,
          },
        };

        return overrideOnSubmit ? overrideOnSubmit({
          action: 'createUWEEntity',
          payload,
        }) :  (appContext.isAnonymous ? (
          UWEEntityApi.createUWEEntityAsAnonymous({payload, slug, tenant: appContext.tenant})
        ) : (
          UWEEntityApi.createUWEEntity(payload)
        ));
      }
    },
    renderFormSubmitted: propRenderFormSubmitted  || (propRenderFormSubmitted === false ? null : ((props) => (
      <HandleUWEEntityUpdate {...props} slug={slug} entityType={entityType} />
    ))),
    customFormats,
    displayName: `UWEEntityForm${displayName}`,
    ...props,
  });
}

function validateClient(formObject, idx, props, secondaryClientForm) {
  idx = idx | 0;
  let schema = null;
  let objectMap = null;

  if (!secondaryClientForm || idx === 0) {
    schema = props.schema;
    objectMap = props.objectMap;
  } else {
    schema = secondaryClientForm.schema;
    objectMap = interpolateMap(secondaryClientForm.objectMap, { idx });
  }

  return formValidation(formObject, schema, objectMap, customFormats);
}

export default UWEEntityFormComponent;

