import fetchApi from '../util/fetchApi';
import checkErrors from './checkErrors';


export const UweApi = {

  async post({ api, data }) {
    return checkErrors(await fetchApi.api.post(api, { data }));
  },

  async get({ api, data }) {
    return checkErrors(await fetchApi.api.get(api, { data }));
  },

  async put({ api, data }) {
    return checkErrors(await fetchApi.api.put(api, { data }));
  },
    
  async delete({ api, data }) {
    return checkErrors(await fetchApi.api.delete(api, { data }));
  },
    
};

export default UweApi;
