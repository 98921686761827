import { useMemo } from 'react';
import useResourceLoader from '../../../util/useResourceLoader';
import fetchApi from '../../../util/fetchApi';
import checkErrors from '../../../api/checkErrors';
import { getObject } from '../../../util/mapObject';
import Jnx, { useJnx, useMultipleJnx } from '../../../util/jnx';

function useOptionsLookup({ lookup, rootFormData, path }) {
  const { api: propApi, entity, jnx: apiResponseJnxExpr, params, options: fetchOptions, filter: filterJnxExpr } = lookup || {};

  const apiResponseJnx = useJnx(apiResponseJnxExpr);

  const paramsJnx = useMultipleJnx(params);
  const filterJnx = useJnx(filterJnxExpr);

  const paramsString = useMemo(() => computeParamString(rootFormData, params, paramsJnx), [rootFormData, params, paramsJnx]);
  const requiresFetch = !!entity || !!propApi;

  const [_options, loadingOptions, errorLoadingOptions] = useResourceLoader(
    async () => loadOptionsData(propApi, apiResponseJnx, paramsString, fetchOptions, entity, path),
    [propApi, apiResponseJnx, paramsString, fetchOptions, entity, path]
  );

  const options = useMemo(() => applyFilterToOptions(_options, filterJnx, rootFormData, requiresFetch, apiResponseJnx, path), [
    _options,
    filterJnx,
    rootFormData,
    requiresFetch,
    apiResponseJnx,
    path,
  ]);

  return {
    options,
    loadingOptions,
    errorLoadingOptions,
  };
}

export function lookupOptions(lookup, rootFormData, path) {
  const { api: propApi, entity, jnx: apiResponseJnxExpr, params, options: fetchOptions, filter: filterJnxExpr } = lookup || {};

  const apiResponseJnx = apiResponseJnxExpr ? new Jnx(apiResponseJnxExpr) : null;
  const filterJnx = filterJnxExpr ? new Jnx(filterJnxExpr) : null;

  const paramsString = computeParamString(rootFormData, params);
  const requiresFetch = !!entity || !!propApi;

  const _options = loadOptionsData(propApi, apiResponseJnx, paramsString, fetchOptions, entity, path);

  return applyFilterToOptions(_options, filterJnx, rootFormData, requiresFetch, apiResponseJnx, path);
}

function computeApiUrl(api, paramsString) {
  let module = null;
  let listFn = null;

  let m = /^(api|entity):(.+)$/.exec(api);

  if (m) {
    if (m[1] === 'entity') {
      listFn = x => x.items;
    }

    module = 'api';
    api = m[1] === 'entity' ? `uwe-entities/${m[2]}/list` : m[2];
  } else {
    m = /^https?:\/\/(.+)$/.exec(api);

    if (m) {
      module = 'external';
    } else {
      module = 'lookup';
    }
  }

  const delimiter = api.indexOf('?') > -1 ? '&' : '?';
  const url = `${api}${paramsString ? `${delimiter}${paramsString}` : ''}`;

  return [module, url, listFn];
}

function computeParamString(rootFormData, params, paramsJnx) {
  if (!params || typeof params !== 'object') {
    return '';
  }

  if (paramsJnx) {
    params = Object.entries(params).reduce((_, [k, v]) => {
      if (k.toString().startsWith('expr:')) {
        const key = k.replace(/^expr:/, '');

        if (paramsJnx[key]) {
          const jnx = paramsJnx[key].eval(rootFormData || {}, '', {
            root: rootFormData,
          });
          _[key] = jnx;
        }
      } else {
        _[k] = v;
      }

      return _;
    }, {});
  }

  return rootFormData && params
    ? Object.entries(params)
        .map(([k, v]) => {
          if (rootFormData && v && v.field) v = getObject(rootFormData, v.field);

          return v ? `${k}=${encodeURIComponent(v)}` : null;
        })
        .filter(s => !!s)
        .join('&')
    : '';
}

async function loadOptionsData(propApi, apiResponseJnx, paramsString, fetchOptions, entity, path) {
  const api = entity ? `entity:${entity}` : propApi;
  if (!api) return null;
  const [module, url, listFn] = computeApiUrl(api, paramsString);
  let data = await checkErrors(await fetchApi[module].get(url, fetchOptions));

  if (listFn) {
    data = listFn(data);
  }

  if (apiResponseJnx) {
    data = apiResponseJnx.eval(data, '', { dataPath: path, data });
  }

  return data ? (Array.isArray(data) ? data : [data]) : null;
}

function applyFilterToOptions(options, filterJnx, rootFormData, requiresFetch, apiResponseJnx, path) {
  if (!requiresFetch && apiResponseJnx) {
    options = apiResponseJnx.eval(options, '', { dataPath: path, data: options, rootFormData });
  }

  if (!filterJnx) return options;
  if (!options) return;

  return options.filter(option => filterJnx.eval(option, '', { rootFormData }));
}

export default useOptionsLookup;
