/**
 * Search function callback to be called with each object.
 *
 * @callback SearchFn
 * @param {object} current - the current object.
 * @param {object} scope - the search's variable scope.
 * @param {array} scope.stack - the stack used to perform the dfs.
 *
 * @returns {array} array of objects to add to the stack.
 */


/** Conducts a search on a given list of objects and any subsequent candidate sub objects, in a last in first out (depth first) order.
 * @param {object[]} boundary - the list of object to explore initially.
 * @param {SearchFn} searchFn - the function to call on each object. Should return nothing or a list of subobjects to append to the search boundary.
 */
function dfsSearch(boundary, searchFn){
  const scope = {
    stack: [...boundary || []],
  };

  while (scope.stack && scope.stack.length) {
    const current = scope.stack.shift();
    const fringe = searchFn(current, scope);

    if (fringe) {
      scope.stack?.unshift(...fringe);
    }
  }
}


export default dfsSearch;