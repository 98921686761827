import { useState, useEffect } from 'react';
import ExternalLayout from '../components/layouts/ExternalLayout';
import MessageView from '../components/MessageView';
import UWEEntityApi from '../api/UWEEntityApi';


function ConfirmationEmailForm(props) {
  const {
    match: {
      params: { token },
    },
  } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!token) {
      setError(new Error('token is missing.'));
    } else {
      UWEEntityApi.submissionFromEmail({ token })
        .then(() => {
          setLoading(false);
        })
        .catch(x => setError(x));
    }
  }, [token, setError, setLoading, UWEEntityApi]);

  return (
    <ExternalLayout>
      {error && error.message ? (
        error.message
      ) : loading ? (
        'Cargando...'
      ) : (
        <MessageView title="Respuesta Envíada">
          <div>Su respuesta ha sido sometida.</div>
          <br />
          Gracias
        </MessageView>
      )}
    </ExternalLayout>
  );
}


export default ConfirmationEmailForm;

