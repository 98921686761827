import {
  useEffect,
  useCallback,
} from 'react';


function useScrollHandler(scrollParent, handler, dependencies){
  const cb = useCallback(handler, [...dependencies]);

  useEffect(() => {
    if (!scrollParent) return undefined;

    const element = (scrollParent === document.body) ? window : scrollParent;
    const listener = element === window ? (
            () => cb(element.scrollY)
        ) : (
            () => cb(element.scrollTop)
        );

    element.addEventListener('scroll', listener, {passive: true});

    return () => {
      element.removeEventListener('scroll', listener);
    };
  }, [scrollParent, cb]);

}


export default useScrollHandler;