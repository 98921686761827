import { useMemo } from 'react';

import ObjectEditor from '../_formEditor/ObjectEditor';
import { PROPERTIES } from '../_formEditor/Constants';
import ObjectSchemaApi from '../../api/ObjectSchemaApi';
import useResourceLoader from '../../util/useResourceLoader';
import dfsSearch from '../../util/dfsSearch';
import jsonSchemas from '../../util/jsonSchemas';
import customFields from '../customFields';

const FIELD_MAP = [
  {
    structure: 'Radio',
    pattern: { type: 'string', enum: { $type: 'array' }, 'ui:widget': 'radio' },
    initObject(object) {
      object.enum = object.enum ?? [];
      object['ui:widget'] = 'radio';
    },
    cleanObject(object) {
      delete object.enum;
      delete object['ui:widget'];
    },
  },
  {
    structure: 'Dropdown',
    pattern: { type: 'string', enum: { $type: 'array' } },
    initObject(object) {
      object.enum = object.enum ?? [];
    },
    cleanObject(object) {
      delete object.enum;
    },
  },
  {
    structure: 'Button',
    pattern: { type: 'string', 'ui:field': 'Button' },
    initObject(object) {
      object['ui:field'] = 'Button';
    },
    cleanObject(object) {
      delete object['ui:field'];
      delete object['ui:onClick'];
    },
  },
  {
    structure: 'Text',
    pattern: { type: 'string' },
    initObject(object) {
      object.type = 'string';
    },
    cleanObject() {},
  },
  {
    structure: 'Object',
    pattern: { type: 'object' },
    initObject(object) {
      object.properties = {};
    },
    cleanObject(object) {
      delete object.properties;
    },
  },
  {
    structure: 'Boolean',
    pattern: { type: 'boolean' },
    initObject(object) {
      object.type = 'boolean';
    },
    cleanObject() {},
  },
  {
    structure: 'Array',
    pattern: { type: 'array' },
    initObject(object) {
      object.items = { type: 'object', properties: [] };
    },
    cleanObject(object) {
      delete object.items;
    },
  },
  {
    structure: 'File',
    pattern: { 'ui:field': 'FileField' },
    initObject(object) {
      object['ui:field'] = 'FileField';
    },
    cleanObject(object) {
      delete object['ui:field'];
    },
  },
  {
    structure: 'Number',
    pattern: { type: 'number' },
    initObject() {},
    cleanObject() {},
  },
  {
    structure: 'Signature',
    pattern: { type: ['string', 'null'], 'ui:field': 'SignatureField' },
    initObject(object) {
      object['ui:field'] = 'SignatureField';
    },
    cleanObject(object) {
      delete object['ui:field'];
    },
  },
  {
    structure: 'QrRead',
    pattern: { type: 'string', 'ui:field': 'QrRead' },
    initObject(object) {
      object['ui:field'] = 'QrRead';
    },
    cleanObject(object) {
      delete object['ui:field'];
    },
  },
];

FIELD_MAP.forEach(fieldEntry => {
  fieldEntry.properties = Object.entries(PROPERTIES[fieldEntry.structure]).map(([k, v]) => ({
    ...v,
    property: k,
  }));
});

const FormFieldsEditor = ObjectEditor.HOC({
  name: 'FormFieldsEditor',
  fieldMap: FIELD_MAP,
  buildScope(props) {
    const { form, path, object } = props;
    const { objectSchemaId } = form;

    const schemaChain = useMemo(() => jsonSchemas.collectSchemaChainFor(
      path,
      {type:'object', properties: form.schema},
      {schemaJsonPath: true}
    ).slice(1), [path, form.schema]);

    console.log('schemaChain', path, schemaChain);

    const objectSchemaFields = useObjectSchemaFields(
      objectSchemaId, form, path,
      schemaChain
    );

    const scope = useMemo(
      () => ({
        path,
        isArrayItems: schemaChain.length > 2 && schemaChain[schemaChain.length - 2].type === 'array',
        formSchema: form.schema,
        schemaChain,
        arrayItemIsRemovable: object?.type === 'array' ? object?.items?.['ui:removable'] : null,
        arrayItemIsAddable: object?.type === 'array' ? object?.items?.['ui:addable'] : null,
        objectSchemaFields,
        objectSchemaArrayFields: objectSchemaFields.filter(x => x.schema.type === 'array'),
        objectCustomFields: customFields ? Object.keys(customFields).map(name => ({ label: name, key: name })) : [],
      }),
      [path, objectSchemaFields, schemaChain]
    );

    return scope;
  },
});

function useObjectSchemaFields(
  objectSchemaId, form, path,
  schemaChain
){
  const [objectSchema, loadingObjectSchema, errorLoadingObjectSchema] = useResourceLoader(() => 
    ObjectSchemaApi.getSchema({ objectSchemaId }),
  [objectSchemaId]);

  const objectSchemaFields = useMemo(() => {
    if (!objectSchema) return [];

    let components = null;

    if (path) {
      components = path.split('.');
      components.pop();
    }

    const refSchema = schemaChain.slice(0, -1).reduce((_, chainedFieldSchema) => {
      if (chainedFieldSchema.type === 'array' && chainedFieldSchema['map:array']) {
        const refPath = chainedFieldSchema['map:array'];
        const refSchema = jsonSchemas.getSubSchemaForField(refPath, _);
        const isArray = refSchema.type === 'array';

        return isArray ? refSchema.items : refSchema;
      }

      return _;
    }, objectSchema);

    const fields = [];
    dfsSearch(Object.entries(refSchema.properties || {}).map(([childName, childMap]) => 
      [0, childName, childName, childMap]
    ), ([depth, nodePath, nodeName, nodeSchema]) => {
        fields.push({
          value: nodePath,
          label: `${'  |  '.repeat(depth)}${nodeName}${nodeSchema.type === 'array' ? '[]' : ''}`,
          schema: nodeSchema,
        });

      if (nodeSchema.type === 'object') {
        return Object.entries(nodeSchema.properties).map(([childName, childMap]) => 
          [depth + 1, `${nodePath}.${childName}`, childName, childMap]
        );
      }

    });

    console.log(fields);

    return fields;
  }, [objectSchema, path, schemaChain]);

  return objectSchemaFields;
}

export default FormFieldsEditor;
