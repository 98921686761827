import fetchApi from '../util/fetchApi';
import checkErrors from './checkErrors';


export const LookupApi = {
  async getInstitutions() {
    return checkErrors(await fetchApi.lookup.get('institutions'));
  },
  async getProducts({ institution }) {
    return checkErrors(await fetchApi.lookup.get(`products/${institution}`));
  },
  async getClientTypes() {
    return checkErrors(await fetchApi.lookup.get('client-types'));
  },
  async getRoles() {
    return checkErrors(await fetchApi.lookup.get('roles'));
  },
  async getRoleUsers({roleId}) {
    return (await checkErrors(await fetchApi.lookup.get(`role/${roleId}/users`))).data;
  },
  async getSLASetting({ignoreCache} = {}) {
    return checkErrors(await fetchApi.lookup.get('sla', {
      resetCache: ignoreCache
    }));
  },
  async getMetadataAttributeEnum(attribute) {
    return (await checkErrors(await fetchApi.lookup.get(`metadata/enum/${attribute}`))).data;
  },
  async getUsers({
    roles,
    inOffice,
    canOwn
  }) {
    return checkErrors(await fetchApi.lookup.get('users', {
      useCache: false,
      query: {
        roles: roles ? roles.join(',') : undefined,
        inOffice,
        canOwn
      }
    }));
  },
  async getOrganizationalUnits(data) {
    return checkErrors(await fetchApi.lookup.get('organizationalunits', {
      useCache: false,
      query: data
    }));
  }

};

export default LookupApi;
