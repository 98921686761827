// https://learn.microsoft.com/en-us/dotnet/standard/base-types/composite-formatting

import { formatNumber } from './format/format';

const MONTH_IDX = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'].reduce((_, m, idx) => {
  _[m] = idx;

  return _;
}, {});

const elements = {
  index: ({index}) => index,
  year({ date, args }) {
    args = parseElementArgs(args, ['start', 'offset', 'digits']);
    const startMonth = MONTH_IDX[(args.start || '').toLowerCase()] ?? 0;
    const isAboveSM = startMonth && (date.getMonth() >= startMonth);
    const offset = (args.offset | 0);
    const year = date.getFullYear() + (isAboveSM ? 1 : 0) + offset;

    return args.digits === '2' ? year % 100 : year;
  },
  month({ date, args }) {
    args = parseElementArgs(args, ['start']);
    const startMonth = MONTH_IDX[(args.start || '').toLowerCase()] ?? 0;

    return ((date.getMonth() + 12 - startMonth) % 12) + 1;
  },
  day: ({ date }) => date.getDate(),
  hour: ({ date }) => date.getHours(),
  minute: ({ date }) => date.getMinutes(),
  second: ({ date }) => date.getSeconds(),
  millisecond: ({ date }) => date.getMilliseconds(),
  default: ({ elementType }) => elementType,
};


function parseElementArgs(args, names) {
  if (!args) return {};
  const usedNames = names.reduce((_, name) => {
    _[name] = 0;

    return _;
  }, {});
  const positionals = [];
  const parsed = args.split(',').reduce((_, arg) => {
    const m = /\s*((\w*)\s*:)?(.*)/.exec(arg);

    if (m[2]) {
      _[m[2]] = m[3];
      usedNames[m[2]] = 1;
    } else {
      positionals.push(m[3]);
    }

    return _;
  }, {});

  let posIdx = 0;
  Object.entries(usedNames).forEach(([name, isUsed]) => {
    if (!isUsed) {
      parsed[name] = positionals[posIdx];
      posIdx += 1;
    }
  });

  return parsed;
}

function sequenceFormatting(index, format){
  const context = { 
    index: index ?? 0,
    date: new Date()
  };

  const number = format.replace(
    /{(\w+)(\(([^)]+)\))?(,(-?\d+))?(:([^}]*))?}/g,
    (...m) => {
      let value = 0;
      const elementType = (m[1] || 'sequence');
      const args = m[3];
      const alignment = (m[5] || '0') | 0;
      const format = m[7] || '';

      const fn = elements[elementType] ?? elements.default;
      value = fn({ ...context, args, elementType });

      let text = valueFormatting(value, format);

      if (text.length < Math.abs(alignment)) {
        const pad = ' '.repeat(Math.abs(alignment) - text.length);

        if (alignment > 0) {
          text = `${pad}${text}`;
        } else {
          text = `${text}${pad}`;
        }
      }

      return text;
    }
  );

  return number;
}


function valueFormatting(value, format) {
  if (value && value.applyFormat) { return value.applyFormat(format); }

  return formatNumber(value, format);
}




export default sequenceFormatting;