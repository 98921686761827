import React, { useMemo, useRef, useState } from 'react';
import { isRequired, getAvailableKey } from './util';


function ArrayPanelRow(props) {
  const {
    layout,
    formContext,
    idPrefix,
    idSeparator,
    registry,
    disabled,
    readonly,
    hideError,
    formData,
    onBlur,
    onFocus,
    onChange,
    uiSchema,
    errorSchema,
    idSchema,
    schema,
    columnCount,
    field: name,
    schema: fieldDef,
    showColumnsIf,
  } = props;

  const {
    fields: { SchemaField },
  } = registry;

  const show = useMemo(() => {
    const showIf = showColumnsIf[name];

    return !showIf || showIf.eval(formData, name);
  }, [showColumnsIf, name, formData]);

  const [wasPropertyKeyModified, setWasPropertyKeyModified] = useState();

  const formDataRef = useRef();
  formDataRef.current = formData;

  function onKeyChange(oldValue, value, errorSchema) {
    if (oldValue === value) {
      return;
    }

    value = getAvailableKey(value, formData);
    const _formData = formDataRef.current || {};
    const newKeys = { [oldValue]: value };
    const renamed = Object.keys(_formData).reduce((_, key) => {
      _[newKeys[key] || key] = _formData[key];

      return _;
    }, {});

    setWasPropertyKeyModified(true);

    onChange(
      renamed,
      (errorSchema && props.errorSchema) &&
      { ...props.errorSchema, [value]: errorSchema }
    );
  }

  function onDropPropertyClick(key) {
    return (event) => {
      event.preventDefault();
      const copiedFormData = { ...formDataRef.current };
      delete copiedFormData[key];
      onChange(copiedFormData);
    };
  }

  function onPropertyChange(name, value, propErrorSchema) {
    const newFormData = { ...formDataRef.current };
    newFormData[name] = value;
    onChange(
      newFormData,
      (propErrorSchema && errorSchema) &&
      { ...errorSchema, [name]: propErrorSchema }
    );
  }

  return show ? layout.wrapItemPanelRow(
    {
      columnCount,
    }, <SchemaField
      name={name}
      required={isRequired(name, schema)}
      schema={fieldDef}
      uiSchema={uiSchema[name]}
      errorSchema={errorSchema[name]}
      idSchema={idSchema[name]}
      idPrefix={idPrefix}
      formContext={formContext}
      idSeparator={idSeparator}
      formData={(formData || {})[name]}
      wasPropertyKeyModified={wasPropertyKeyModified}
      onKeyChange={(value, errorSchema) => onKeyChange(name, value, errorSchema)}
      onChange={(value, errorSchema) => onPropertyChange(name, value, errorSchema)}
      onBlur={onBlur}
      onFocus={onFocus}
      registry={registry}
      disabled={disabled}
      readonly={readonly}
      hideError={hideError}
      onDropPropertyClick={onDropPropertyClick}
    />
  ) : null;
}

export default ArrayPanelRow;
