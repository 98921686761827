import ApiDefinition from './ApiDefinition';


const RoleApi = new ApiDefinition({
  create: {
    url: 'manage/roles/',
    method: 'post',
    body: data => data
  },
  list: {
    url: 'manage/roles/',
  },
  get: {
    url: ({id}) => `manage/roles/${id}`,
  },
  update: {
    url: ({id}) => `manage/roles/${id}`,
    method: 'post',
    body: data => data
  },
});

export default RoleApi;
