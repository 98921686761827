import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap';

const PARAMS = ['formData'];

function SendBackModal({
  formData,
  formContext,
  onConfirm: onClose,
  isOpen,
}) {
  const {
    setFormDataValues,
  } = formContext;

  const [rejectReason, setRejectReason] = useState('');
  const [error, setError] = useState('');

  function onChange({ target: { value } }) {
    setError('');
    setRejectReason(value);
  }

  function onAccept(e) {
    e.stopPropagation();

    if (!rejectReason) {
      setError('Debe ingresar un motivo de devolución');
    } else {
      formData.sendBackReason = rejectReason;
      onClose(true);
    }
  }

  return (
    <Modal isOpen={isOpen}
      toggle={() => onClose()}
      className="modal-primary"
      size='xl'
      backdrop
    >
      <ModalHeader
        toggle={() => onClose()}
      >
                Devolver Requisición
      </ModalHeader>
      <ModalBody>
        <>
          <div className="row">
            <div className="col-lg-12">
              <span className="text-danger">{error}</span>
              <div className="form-group">
                <label className="title">Motivo*</label>
                <textarea rows={4} value={rejectReason || ''} className="form-control" name="description" onChange={e => onChange(e)} />
              </div>
            </div>
          </div>
        </>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onAccept} color="primary">
          Confirmar
        </Button>
        <Button onClick={() => onClose()} color="secondary">
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

SendBackModal.PARAMS = PARAMS;

export default SendBackModal;
