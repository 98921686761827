import React from 'react';
import { FormGroup, Button } from 'reactstrap';
import UWEEntityApi from '../../api/UWEEntityApi';
import useResourceLoader from '../../util/useResourceLoader';
import { useSideChannelSubscription } from '../../util/useSideChannel';


function UWEEntityZipDownloadField(props) {
  const {
    formContext,
    schema: {
      title,
      filter
    },
    uiSchema: {
      'ui:readonly': readonly
    },
  } = props;
  const { sideChannel } = formContext;

  const formObject = useSideChannelSubscription(sideChannel, 1) || [{}];

  const [url] = useResourceLoader(() => {
    if (!formObject && !formObject?.id) {
      return null;
    }

    return UWEEntityApi.getUWEEntityZipDownloadUrl({
      id: formObject.id,
      filter,
    });
  }, [(formObject || {}).id, filter]);

  return (
    <FormGroup className="file-field" disabled={readonly}>
      <Button className="btn-block" href={url} target="_blank">
        {title}
      </Button>
    </FormGroup>
  );
}

export default UWEEntityZipDownloadField;
