import { useCallback, useEffect, useMemo } from 'react';
import ConfigPanel from './ConfigPanel';
import useActionReducer from '../../../../util/useActionReducer';
import compareFormActions from './actions';
import FormApi from '../../../../api/FormApi';
import ComparisonTable from './ComparisonTable';

function CompareForms(props) {
  const {
    state,
    formIds,
    onAddFormId, onRemoveFormId,
    dispatchAction,
  } = useCompareFormHooks(props);

  return <div className="form-comparison-container">
    <ComparisonTable
      formIds={formIds}
      onRemoveFormId={onRemoveFormId}
      state={state}
      dispatchAction={dispatchAction}
    />
    <ConfigPanel
      onAddFormId={onAddFormId}
      state={state}
      dispatchAction={dispatchAction}
    />
  </div>;
}

function useCompareFormHooks({
  formIds: propFormIds,
  setFormIds,
}){
  const [state, dispatchAction] = useActionReducer(compareFormActions, {});

  const formIds = useMemo(
    () =>
      Object.keys(
        (propFormIds || []).reduce((_, x) => {
          _[x] = x;

          return _;
        }, {})
      )
        .map(x => x | 0)
        .filter(x => !!x),
    [propFormIds]
  );

  const {
    fieldsList,
    loading,
    errorLoading,
    forms,
    unrolledForms,
  } = state;

  const onAddFormId = useCallback(
    formId => {
      if (formIds.indexOf(formId) !== -1) return;
      setFormIds([...formIds, formId]);
    },
    [setFormIds, formIds]
  );

  const onRemoveFormId = useCallback(
    formId => {
      if (formIds.indexOf(formId) === -1) return;
      setFormIds(formIds.filter(x => x !== formId));
    },
    [setFormIds, formIds]
  );

  useEffect(() => {
    const { formIds: stateFormIds = [], forms = {}, loading = {}, errorLoading = {} } = state;
    const newForms = (formIds || []).filter(x => !(forms[x] || loading[x] || errorLoading[x]));
    const removedForms = stateFormIds.filter(x => formIds.indexOf(x) === -1);

    newForms.forEach(formId => {
      dispatchAction.setFormLoadingStatus({ formId, loading: true });
      FormApi.get({ formId })
        .then(form => {
          dispatchAction.setFormLoadingStatus({ formId, loading: false });
          dispatchAction.addForm({ form });
        })
        .catch(error => {
          dispatchAction.setFormLoadingStatus({ formId, error });
        });
    });

    removedForms.forEach(formId => {
      dispatchAction.removeForm({ formId });
    });
  }, [state, formIds, dispatchAction]);


  return {
    state,
    formIds: formIds || [],
    loading: loading || {},
    errorLoading: errorLoading || {},
    forms: forms || {},
    unrolledForms: unrolledForms || {},
    fieldsList: fieldsList || [],
    onAddFormId, onRemoveFormId,
    dispatchAction,
  };
}


export default CompareForms;
