import React from 'react';
import {
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

const PATTERN = { type: 'boolean' };

function BooleanField({ path, fieldProps: prop, value, onChange }) {
  return (
    <FormGroup check>
      <Input
        id={`${path}`}
        type="checkbox"
        name={prop.property}
        checked={value || false}
        onChange={({ target: { checked } }) => onChange(!!checked)}
      />
      <Label check for={`${path}`}>
        {prop.label} {prop.toChild}
      </Label>
    </FormGroup>
  );
}

BooleanField.pattern = PATTERN;

export default BooleanField;
