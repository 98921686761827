import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import AppContext from '../context/AppContext';
import RequestReloadContext from '../context/RequestReloadContext';

const HandleUWEEntityUpdate = ({
  scope: {
    props: { slug },
  },
  formSubmitResult,
}) => {
  const { isAnonymous } = useContext(AppContext);
  const history = useHistory();
  const rrc = useContext(RequestReloadContext);
  const {
    data: { id, status, isOpen },
  } = formSubmitResult;
  const isNew = history.location.pathname.endsWith('new');
  const canWork = status?.some(x => x.canWork);
  const path = slug ? (isNew && canWork && isOpen ? `/workflow/${slug}/${id}/` : `/workflow/${slug}/tray`) : '';

  useEffect(() => {
    if (isAnonymous) {
      history.replace('/thankyou');
    } else if (!isNew && canWork && isOpen) {
      // Can continue the flow of the case
      if (rrc) {
        rrc.reload();
      } else {
        window.location.reload();
      }
    } else {
      history.replace(path);
    }
  }, []);

  return <div>redirect to {path}</div>;
};

export default HandleUWEEntityUpdate;
