import fetchApi from '../util/fetchApi';
import checkErrors from './checkErrors';


export const UserApi = {
  async createUser(data) {
    return checkErrors(await fetchApi.api.post('manage/users/', { data }));
  },

  async getUsers() {
    return checkErrors(await fetchApi.api.get('form-lookup/users'));
  },

  async searchUsers({ offset, size, sortBy, ...filters }) {
    return checkErrors(await fetchApi.api.get('manage/users', {
      query: { offset, size, sortBy, ...filters }
    }));
  },

  async getUser({ id }) {
    return (await checkErrors(await fetchApi.api.get(`manage/users/${id}`))).data;
  },

  async getUserAdHoc({ token }) {
    return (await checkErrors(await fetchApi.get('manage/users/adhoc', {
      auth: { type: 'Bearer', token },
      json: true,
    })))?.data;
  },

  async setUserAdHoc({ token, ...data }) {
    return checkErrors(await fetchApi.post('manage/users/adhoc', {
      auth: { type: 'Bearer', token },
      data,
      json: true,
    }));
  },

  async editUser({ id, ...data }) {
    return checkErrors(await fetchApi.api.post(`manage/users/${id}`, { data }));
  },

};


export default UserApi;
