function getReactFibersForDomNode(domNode) {
  return Object.entries(domNode)
    .filter(([k]) => k.startsWith('__reactFiber$'))
    .map(e => e[1])[0];
}

function getFiberNodeHierarchy(fiberNode) {
  const hierarchy = [];

  while (fiberNode) {
    hierarchy.unshift(fiberNode);
    fiberNode = fiberNode.return;
  }

  return hierarchy;
}

function reactTraverse(domNode) {
  const fiber = getReactFibersForDomNode(domNode);

  return fiber ? getFiberNodeHierarchy(fiber) : [];
}

function reactTraverse_findAncestor(fiberNode, ancestorElementType) {
  while (fiberNode && fiberNode.elementType !== ancestorElementType) {
    fiberNode = fiberNode.return;
  }

  return fiberNode;
}

reactTraverse.findAncestor = reactTraverse_findAncestor;

export default reactTraverse;
