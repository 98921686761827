import { useContext, useMemo } from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import { AuthContext } from '../../context/AuthContext';
import WorkpageApi from '../../api/WorkpageApi';
import Workpage from '../../components/Workpage/index';
import InternalLayout from '../../components/layouts/InternalLayout';
import Loader from '../../components/Loader';
import UWEContext from '../../context/UWEContext';
import useResourceLoader from '../../util/useResourceLoader';

function TrayView() {
  const auth = useContext(AuthContext);

  const {
    params: { slug },
  } = useRouteMatch();

  const { workpages } = useContext(UWEContext);

  const { workpageId, redirectTo } = useMemo(() => {
    let workpageId = workpages?.filter(db => db.slug === slug).pop()?.id;
    let redirectTo;

    if (workpageId === undefined) {
      if (slug === 'home') {
        workpageId = workpages[0]?.id;
      } else {
        redirectTo = workpages[0]?.slug;
      }
    }

    return { workpageId, redirectTo };
  }, [workpages, slug]);

  const [workpage, loadingWorkpage] = useResourceLoader(async () => (workpageId ? WorkpageApi.get({ id: workpageId }) : null), [workpageId]);

  const TrayComponent = useMemo(() => {
    if (!workpage) return null;

    const schema = {
      wrapperType: 'internal',
      ...workpage,
      schema: workpage.definition,
    };

    return Workpage.HOC(schema);
  }, [auth, workpage]);

  const isLoading = loadingWorkpage;

  return isLoading ? (
    <InternalLayout>
      <Loader />
    </InternalLayout>
  ) : redirectTo ? (
    <Redirect to={`/workflow/${redirectTo}/tray`} />
  ) : TrayComponent ? (
    <TrayComponent />
  ) : (
    <ErrorTray />
  );
}

const ErrorTray = Workpage.HOC({
  wrapperType: 'internal',
  schema: {
    type: 'workpage',
    elements: [
      {
        type: 'text',
        label: 'Tray not Found',
      },
    ],
  },
});

export default TrayView;
