import React from 'react';
import { Button, CardBody, Card, ListGroup, ListGroupItem } from 'reactstrap';
import { useHistory } from 'react-router';
import InternalLayout from '../components/layouts/InternalLayout';
import useTitle from '../util/useTitle';
import Notification from '../components/Notification';


const DOCUMENTS = [
];


const VIDEOS = [
];

export function Help() {
  const history = useHistory();
  useTitle('Ayuda');

  return (
    <InternalLayout>
      <div>
        {DOCUMENTS.length || VIDEOS.length ? (
          <>
            <h1>Documentaci&oacute;n</h1>
            <br />
            <p>Aquí puedes encontrar los documentos de apoyo para UWE </p>
            {DOCUMENTS.length ? (
              <>
                <h3>Documentos</h3>
                <ListGroup>
                  {DOCUMENTS.map(({ title, url }, idx) => (
                    <ListGroupItem key={idx}>
                      <a href={url} target="_blank" rel="noreferrer">
                        {title}
                      </a>
                    </ListGroupItem>
                  ))}
                </ListGroup>
                <br />
              </>
            ) : null}
            <h3>Videos de Entrenamiento</h3>
            <div className="d-flex flex-wrap flex-row">
              {VIDEOS.map(({ title, url }, idx) => (
                <Card className="mb-4" key={idx}>
                  <CardBody>
                    <h4 className="text-default">{title}</h4>
                    <iframe
                      width="560"
                      height="315"
                      src={url}
                      title={title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </CardBody>
                </Card>
              ))}
              {!VIDEOS?.length ? (
                <Notification color="warning">Al momento no se encuentra material en esta sección. Verifique de nuevo pronto.</Notification>
              ) : (
                ''
              )}
            </div>
            <br />
          </>
        ) : null}
        <h3>Crear un Ticket</h3>
        <p>¿Necesitas ayuda directo al equipo? Crea un ticket.</p>
        <Button
          onClick={() => {
            history.push('/create-ticket');
          }}
          color="primary"
        >
          Crear Ticket
        </Button>
      </div>
    </InternalLayout>
  );
}

export default Help;

