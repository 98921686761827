import { useEffect, useState } from 'react';


export function useZohoDeskWidget(){
  useEffect(()=> {
    const script = document.createElement('script');
    script.src = 'https://desk.zoho.com/portal/api/feedbackwidget/376105000008877007?orgId=686283233&displayType=popout';
    script.async = true;
    document.head.appendChild(script);
    let interval = null;
    window.zdwPromise = new Promise((resolve) => {
      interval = setInterval(() => {
        if (window.zsLoadAutoSuggestions) {
          clearInterval(interval);
          resolve(window.zsLoadAutoSuggestions);
        }
      }, 1000);
    });
    
    return () => {
      if (interval) clearInterval(interval);
      document.head.removeChild(script);
    };
  }, []);
}


export function useZohoDeskWidgetCallFunction(){
  const [[zdwcf], setZdwcf] = useState([]);

  useEffect(() => {
    if(window.zdwPromise) window.zdwPromise.then(zdwcf => setZdwcf([zdwcf]));
  }, [window.zdwPromise]);

  return zdwcf;
}