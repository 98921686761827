import useSearchParams from '../../../util/useSearchParams';
import EditTheme from './EditTheme';
import SelectTheme from './SelectTheme';

function ThemeEditor() {
  const [[themeId, setThemeId]] = useSearchParams({
    themeId: undefined,
  });

  return themeId ? <EditTheme themeId={themeId} /> : <SelectTheme setThemeId={setThemeId} />;
}

export default ThemeEditor;
