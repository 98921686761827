import React from 'react';

function ElementIcon({
  element: {
    icons, icon, image,
    size: elSize,
    fontSize: elFontSize
  }, size: propSize, fontSize: propFontSize
}){
  if (icons) {
    const size = propFontSize || elFontSize || icons.size;
    const style = size ? { fontSize: size } : {};

    return (
      <span className={icons.base} style={style}>
        {icons.paths.map((p, idx) => (
          <i key={idx} className={p} />
        ))}
      </span>
    );
  } else if (icon) {
    const size = propFontSize || elFontSize;
    const style = size ? { fontSize: size } : {};

    return <i className={icon} style={style} />;
  } else {
    const size = propSize || elSize;
    const style = size
      ? {
        width: size[0],
        height: size[1],
      }
      : {};

    return <img alt={image} src={image} style={style} className="img-responsive" />;
  }
}

export default ElementIcon;
