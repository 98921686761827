import React from 'react';
import Notification from '../components/Notification';
import WaitForFiles from '../components/WaitForFiles';
import WaitForExternalProcess from '../components/WaitForExternalProcess';
import WaitForEmailForm from '../components/WaitForEmailForm';
import Loader from '../components/Loader';
import useResourceLoader from '../util/useResourceLoader';
import FormApi from '../api/FormApi';
import UWEEntityUWEFormComponent from './UWEEntityUWEFormComponent';


function CurrentUWEEntityForm({
  hide,
  slug,
  viewForm,
  currentEntity,
  currentStatus,
}) {
  const { page: _formName, type, awaitDocuments, isOpen } = currentStatus || {};
  const formName = (isOpen ? _formName : null) || viewForm;

  const isDocumentStep = awaitDocuments || type === 'documentGeneration';
  const isExternalProcessStep = type === 'externalProcess';
  const isEmailForm = type === 'emailForm';
  const isFormStep = !isDocumentStep && !isExternalProcessStep;

  const [form, loadingISF, errorLoadingISF] = useResourceLoader(() => (isFormStep && formName ? (
        FormApi.getByName({ name: formName })
    ) : null), [formName]);

  return <div className={`uweentity-form ${hide ? 'hidden' : ''}`}>{isDocumentStep ? (
        <WaitForFiles currentEntity={currentEntity} page={formName} />
    ) : isExternalProcessStep ? (
        <WaitForExternalProcess type="externalProcess" currentEntity={currentEntity} slug={slug} viewForm={viewForm} />
    ) : (isEmailForm ? (
        <WaitForEmailForm currentEntity={currentEntity} page={formName} />
    ) : (formName ? (
        loadingISF ? (
            <Loader />
        ) : (errorLoadingISF ? (
            <Notification error={errorLoadingISF} />
        ) : (form ? (
            <UWEEntityUWEFormComponent
              slug={slug}
              form={form}
              currentEntity={currentEntity}
            />
        ) : (
            <Notification color="warning">Form not fully implemented yet</Notification>
        )))
    ) : (
        <Notification color="warning">The entity does not have any forms that you can view.</Notification>
    )))}</div>;
}

export default CurrentUWEEntityForm;
