import React from 'react';
import CreatableSelect from 'react-select/creatable';


function WorkflowParamSidepanel({
  dispatchAction,
  paramSchema,
}) {
  return (
    <div className="container">
      {paramSchema && Object.keys(paramSchema).length ? (
        <table className="table table-sm">
          <tbody>
            {Object.entries(paramSchema || {}).map((item, idx) => (
              <tr key={idx}>
                <td style={{ width: '90%' }}>{item[1].title}</td>
                <td>
                  <button
                    className="btn btn-sm"
                    onClick={() =>
                      dispatchAction.setManageParamSchema({
                        key: item[0],
                        active: false,
                      })
                    }
                  >
                    <i className="fa fa-trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="container text-center" style={{ paddingTop: '10px' }}>
          add some param schema
        </div>
      )}
      <label className="title">Add Parameter</label>
      <CreatableSelect
        noOptionsMessage={() => null}
        placeholder='new param'
        onChange={a => console.log(a)}
        onCreateOption={e =>
          dispatchAction.setManageParamSchema({
            value: e,
          })
        }
        isClearable
      />
    </div>
  );
}


export default WorkflowParamSidepanel;
