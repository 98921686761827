import React from 'react';
import { MsalProvider } from "@azure/msal-react";
import AppContext from './context/AppContext';
import routes from './routes';
import AuthVerification from './components/AuthVerification';
import UWEContext from './context/UWEContext';
import RouteSwitch from './components/RouteSwitch';
import initAppConfiguration from './hooks/initAppConfiguration';
import { msalInstance } from './msalConfig';
import useMSALAuthRedirectDetection from './hooks/useMSALAuthRedirectDetection';
import { AD_CLIENT_ID, BACKEND } from './constants';


const ConfigurationProvider = initAppConfiguration({
  LOGO_SRC: `${BACKEND}/template-files/logo.png`, // "uwe_logo.svg",
  APP_NAME: 'UWE',
  AD_CLIENT_ID
});

function App() {
  const {isMSALAuthRedirect} = useMSALAuthRedirectDetection();

  let app = (
    <ConfigurationProvider>
      <AppContext.Component>
        <UWEContext.Component>
          <AuthVerification />
          <RouteSwitch routes={routes} />
        </UWEContext.Component>
      </AppContext.Component>
    </ConfigurationProvider>
  );

  if (msalInstance) {
    app = <MsalProvider instance={msalInstance}>{app}</MsalProvider>;
  }

  return app;
}

export default App;
