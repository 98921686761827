import { useContext } from 'react';
import { useParams } from 'react-router';
import RouteSwitch from '../../components/RouteSwitch';
import AppContext from '../../context/AppContext';
import routes from './routes';


function AnonymousAccessView(){
  const { tenant } = useParams();
  const { isAnonymous } = useContext(AppContext);

  return <>
    <AppContext.SetIsAnonymous value />
    <AppContext.SetTenant value={tenant} />
    {isAnonymous ? <RouteSwitch routes={routes} /> : null}
  </>;
}


export default AnonymousAccessView;