import fetchApi from '../util/fetchApi';
import checkErrors from './checkErrors';

export const WorkpageApi = {
  async list({ forMe, all, offset, size, sortBy }) {
    return (
      await checkErrors(
        await fetchApi.api.get('workpages', {
          query: {
            forMe,
            all,
            offset,
            size,
            sortBy,
          },
        })
      )
    ).data;
  },

  async get({ id }) {
    return (await checkErrors(await fetchApi.api.get(`workpages/${id}`))).data;
  },

  async update(id, schema) {
    return (await checkErrors(await fetchApi.api.put(`manage/workpages/${id}`, { data: schema }))).data;
  },

  async create(schema) {
    return (await checkErrors(await fetchApi.api.post('manage/workpages/', { data: schema }))).data;
  },
};

export default WorkpageApi;
