import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap';
import useResourceLoader from '../../util/useResourceLoader';
import UWEEntityApi from '../../api/UWEEntityApi';

const PARAMS = ['formData'];

function SendBackModalFinalApproval({
  formData,
  formContext,
  onConfirm: onClose,
  isOpen,
  scope: {
    props: { currentEntity },
  },
}) {
  const {
    setFormDataValues,
  } = formContext;

  const [error, setError] = useState('');

  const id = currentEntity?.id;

  const [assignmentByRoleIdMap, loadingAssignments, errorRole, setAssignmentByRoleIdMap] = useResourceLoader(
    async () => (await UWEEntityApi.getUWEEntityAssignments({ id }))
      .assignments.reduce((_, x) => {
        _[x.roleId] = x;

        return _;
      }, {}),
    [id],
    []
  );

  const [rejectReason, setRejectReason] = useState('');
  const [rejectRole, setRejectRole] = useState('');

  function onChange({ target: { value } }) {
    setError('');
    setRejectReason(value);
  }

  function onAccept(e) {
    e.stopPropagation();

    if (!rejectRole) {
      setError('Debe seleccionar un rol');
    } else if (!rejectReason) {
      setError('Debe ingresar un motivo de devolución');
    } else {
      formData.sendBackReason = rejectReason;
      formData.sendBackTo = rejectRole;
      onClose(true);
    }
  }

  function onChangeRole({ target }) {
    const { value } = target;
    setError('');
    setRejectRole(value);
  }


  return (
    <Modal isOpen={isOpen}
      toggle={() => onClose()}
      className="modal-primary"
      size='xl'
      backdrop
    >
      <ModalHeader
        toggle={() => onClose()}
      >
                Devolver Requisición Final
      </ModalHeader>
      <ModalBody>
        <>
          <div className="row">
            <div className="col-lg-12">
              <span className="text-danger">{error}</span>
              <div className="form-group">
                <label className="title">Devolver a:</label>
                <select value={rejectRole} className="form-control" onChange={e => onChangeRole(e)}>
                  <option value="" disabled>
                    ----
                  </option>
                  {Object.values(assignmentByRoleIdMap).map((item, idx) => (
                    <option key={idx} value={item.role.name}>
                      {item.user.name} ({item.role.name})
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="title">Motivo:</label>
                <textarea rows={4} value={rejectReason || ''} className="form-control" name="description" onChange={e => onChange(e)} />
              </div>
            </div>
          </div>
        </>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onAccept} color="primary">
          Confirmar
        </Button>
        <Button onClick={() => onClose()} color="secondary">
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

SendBackModalFinalApproval.PARAMS = PARAMS;

export default SendBackModalFinalApproval;
