import React from 'react';
import useSearchParams from '../../../util/useSearchParams';
import EditWorkpage from './EditWorkpage';
import ListWorkpage from './ListWorkpage';

function Workpages() {
  const [[workpage, setWorkpage]] = useSearchParams({
    workpage: undefined,
  });

  return workpage ? <EditWorkpage workpageId={workpage} setWorkpage={setWorkpage} /> : <ListWorkpage setWorkpage={setWorkpage} />;
}

export default Workpages;
