import React, { useMemo, useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap';
import { Trans } from '@lingui/macro';

import useResourceLoader from '../../util/useResourceLoader';
import UWEEntityApi from '../../api/UWEEntityApi';
import { STATUS } from '../../api/checkDocumentResponse';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import PdfViewer from '../../components/PdfViewer';

const PARAMS = ['isPreview'];


function PdfPreviewModal({
  getCurrentFormObject,
  onConfirm: onClose,
  isOpen,
  isPreview = false
}) {
  const [updateCt, setUpdateCt] = useState();
  const formObject = useMemo(() => getCurrentFormObject && getCurrentFormObject(), []);
  const [referenceId, loadingPreviewReq, errorRequestiongPreview] = useResourceLoader(
    () => formObject ? UWEEntityApi.generatePreviewFor({
      id: formObject.id,
      data: {...formObject, isPreview}
    }): null,
    [formObject]
  );

  const [response, loadingResponse, errorLoadingResponse] = useResourceLoader(
    () => (referenceId ? UWEEntityApi.verifyPreviewGeneration({ referenceId }) : null),
    [referenceId, updateCt]
  );

  const [url, setUrl] = useState();
  useEffect(() => {
    if (!response) return undefined;

    if (response.status === STATUS.PROCESSING) {
      const timeout = setTimeout(() => setUpdateCt(new Date().getTime()), 1000);

      return () => {
        clearTimeout(timeout);
      };
    } else if (response.status === STATUS.AVAILABLE && response.items.length) {
      setUrl(response.items[0].uri);
    }

    return undefined;
  }, [response]);

  const error = errorRequestiongPreview || errorLoadingResponse;
  const loading = loadingPreviewReq || loadingResponse || (!url && !error);

  return (
    <Modal isOpen={isOpen}
      toggle={onClose}
      className={`modal-primary ${url ? 'maximized' : ''}`}
      size='xl'
      backdrop
    >
      <ModalHeader toggle={onClose} >
                Vista previa de Forma SC-1001
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <Loader>
            <Trans>Generating PDF...</Trans>
          </Loader>
        ) : error ? (
          <Notification error={error} />
        ) : (
          <PdfViewer url={url} />
        )}
      </ModalBody>
      <ModalFooter>
        <a className="btn btn-primary" href={url} target="_blank" rel="noreferrer">
          Descargar
        </a>
        <Button onClick={onClose} color="secondary">
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

PdfPreviewModal.PARAMS = PARAMS;

export default PdfPreviewModal;
