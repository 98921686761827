import { useMemo } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';


function SimpleDropdown({
  value: propValue,
  options,
  onSelect
}) {
  const current = useMemo(() => options.find(({ value }) => (
    value === propValue
  )), [options, propValue]);

  return (<UncontrolledDropdown>
    <DropdownToggle caret>
      {current?.title ?? ''}
    </DropdownToggle>
    <DropdownMenu>
      {options?.map(({ title, value }) => (
        <DropdownItem key={value} onClick={() => onSelect(value)}>
          {title}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </UncontrolledDropdown>);
}


export default SimpleDropdown;