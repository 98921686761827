import React, { useContext,useMemo, useState, useEffect } from 'react';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import { AuthContext } from '../context/AuthContext';
import UWEEntityAssignments from './UWEEntityAssignments';
import UWEEntityHistory from './UWEEntityHistory';
import UWEEntityNote from './UWEEntityNote';
import UWEEntityProgress from './UWEEntityProgress';

const SIDEBAR_COMPONENTS = {
  assignments: UWEEntityAssignments,
  notes: UWEEntityNote,
  text: NewlineText,
  history: UWEEntityHistory,
  progress: UWEEntityProgress
};

function SidebarComponents(props) {
  const { children, title, currentEntity, readOnly } = props;
  const propsComponents = props.onComponents || [];

  const auth = useContext(AuthContext);

  const [_mainContent, setMainContent] = useState();
  const mainContent = _mainContent || 'form';

  const [components, setComponents] = useState({});
  useMemo(() => {
    propsComponents.map((obj, key) => {
      return setComponents({
        ...components,
        [obj.mainContent]: {
          open: false,
          position: obj.position
        }
      });
    });
  }, []);

  const setComponent = (mainContent, action, position) => {
    setComponents(prevState => ({
      ...prevState,
      [mainContent]: {
        open: action,
        position
      }
    }));
  };

  const [showRight, setShowRight] = useState(0);
  const [showLeft, setShowLeft] = useState(0);

  useEffect(() => {
    setShowRight(Object.values(components).filter(x => x.position === 'right' && x.open).length);
    setShowLeft(Object.values(components).filter(x => x.position === 'left' && x.open).length);
  }, [components]);

  return children ? (
    <div className="form-container">
      <div className={`sidepanel sidepanel-left ${showLeft ? 'expanded' : ''}`}>
        {propsComponents
          .filter(x => x.position === 'left')
          .map((obj, idx) => {
            const Component = SIDEBAR_COMPONENTS[obj.type];

            return mainContent !== obj.mainContent && currentEntity ? (
              <ExpandableTabSidePanel
                key={idx}
                title={obj.title}
                expanded={components[obj.mainContent]?.open | 0}
                def={obj}
                setExpanded={() => setComponent(obj.mainContent, !components[obj.mainContent]?.open, obj.position)}
                onMaximize={() => setMainContent(obj.mainContent)}
              >
                <Component
                  auth={auth}
                  text={obj.content}
                  def={obj}
                  entity={currentEntity}
                  totalExpanded={mainContent === obj.mainContent}
                  readOnly={readOnly}
                />
              </ExpandableTabSidePanel>
            ) : null;
          })}
      </div>
      <div className={`sidepanel sidepanel-right ${showRight ? 'expanded' : ''}`}>
        {propsComponents
          .filter(x => x.position === 'right')
          .map((obj, idx) => {
            const Component = SIDEBAR_COMPONENTS[obj.type];

            return mainContent !== obj.mainContent && currentEntity ? (
              <ExpandableTabSidePanel
                key={idx}
                title={obj.title}
                expanded={components[obj.mainContent]?.open | 0}
                def={obj}
                setExpanded={() => setComponent(obj.mainContent, !components[obj.mainContent]?.open, obj.position)}
                onMaximize={() => setMainContent(obj.mainContent)}
              >
                <Component
                  auth={auth}
                  text={obj.content}
                  def={obj}
                  entity={currentEntity}
                  totalExpanded={mainContent === obj.mainContent}
                  readOnly={readOnly}
                />
              </ExpandableTabSidePanel>
            ) : null;
          })}
      </div>
      <div className="form-component uweentity-form">
        <div className="uweentity-title">
          {title}
        </div>
        {mainContent === 'form' ? children : null}
        {propsComponents.map((obj, idx) => {
          const Component = SIDEBAR_COMPONENTS[obj.type];

          return mainContent === obj.mainContent && currentEntity ? (
            <FullExpandedPanel
              key={idx}
              title={obj.title}
              def={obj}
              onClose={() => {
                setComponent(obj.mainContent, false, obj.position);
                setMainContent();
              }}
              onMinimize={() => setMainContent()}
            >
              <Component
                auth={auth}
                text={obj.content}
                def={obj}
                entity={currentEntity}
                totalExpanded={mainContent === obj.mainContent}
                readOnly={readOnly}
              />
            </FullExpandedPanel>
          ) : null;
        })}
      </div>
    </div>
  ) : null;
}

function ExpandableTabSidePanel({ title, children, expanded, setExpanded, onMaximize }) {
  return expanded ? (
    <Card className="expandable-tab-side-panel-panel">
      <CardHeader>
        {title}
        <div className="btn-bar">
          {onMaximize ? (
            <Button onClick={onMaximize} title={`Expandir ${title}`}>
              <i className="fa fa-expand" />
            </Button>
          ) : null}
          <Button
            onClick={() => {
              setExpanded(0);
            }}
            title={`Cerrar ${title}`}
          >
            <i className="fa fa-times" />
          </Button>
        </div>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  ) : (
    <button
      className="expandable-tab-side-panel-tab"
      onClick={() => {
        setExpanded(1);
      }}
      title={`Cerrar ${title}`}
    >
      {title}
    </button>
  );
}

function FullExpandedPanel({
  title,
  onClose,
  onMinimize,
  children
}) {
  return (<Card className="uweentity-form-full-expanded-sidepanel">
      <CardHeader className="card-header">
        {title}
        <div className="btn-bar">
          {onMinimize ? (
            <Button onClick={onMinimize} title={`Minimizar ${title}`}>
              <i className="fa fa-compress" />
            </Button>
          ) : null}
          {onClose ? (
            <Button onClick={onClose} title={`Cerrar ${title}`}>
              <i className="fa fa-times" />
            </Button>
          ) : null}
        </div>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
}

function NewlineText({ text }) {
  return text.split('\n').map((str, idx) => <p key={idx}>{str}</p>);
}

export default SidebarComponents;
