import { useCallback } from 'react';
import ComparisonTableHeaders from './ComparisonTableHeaders';
import ComparisonTableRow from './ComparisonTableRow';


function ComparisonTable({
  formIds,
  onRemoveFormId,
  state,
  dispatchAction,
}){
  const {
    forms,
    unrolledForms = {},
    loading,
    errorLoading,
    fieldsList = [],
    collapsedFields = {},
  } = state;

  const toggleFieldCollapsed = useCallback((field) => {
    dispatchAction.toggleFieldCollapsed({ field });
  }, [dispatchAction]);

  return (<table>
    <thead>
      <ComparisonTableHeaders
        formIds={formIds}
        forms={forms}
        loading={loading}
        errorLoading={errorLoading}
        onRemoveFormId={onRemoveFormId}
      />
    </thead>
    <tbody>{fieldsList.map(({name: field, props: fieldProps}) => <ComparisonTableRow
      key={field}
      field={field}
      fieldProps={fieldProps}
      formIds={formIds}
      unrolledForms={unrolledForms}
      collapsed={collapsedFields[field]}
      toggleFieldCollapsed={toggleFieldCollapsed}
    />)}</tbody>
  </table>);
}


export default ComparisonTable;