import { useState, useMemo } from 'react';

/** Hook for managing loading/error states in fetching/requesting something.
 */
function useLoader(initialiState){
  const [loading, setLoading] = useState(initialiState);
  const [error, setError] = useState();

  const loadFn = useMemo(() => async (asyncAction) => {
    setLoading(true);
    setError();

    try {
      await asyncAction();
      setLoading(false);
    } catch(e){
      setError(e.message);
      setLoading(false);
    }
  }, []);
    
  return [loading, error, loadFn];
}

export default useLoader;
