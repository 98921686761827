import {useEffect, useMemo, useState} from 'react';


export function useSideChannel(...initialPublication){
  const channel = useMemo(() => {
    const subscribers = [];
    let lastPublication = initialPublication;

    function unsubscribe(fn){
      const idx = subscribers.indexOf(fn);

      if (idx > -1){
        subscribers.splice(idx, 1);
      }
    }

    function subscribe(fn){
      subscribers.push(fn);
      fn(...(lastPublication || []));

      return () => unsubscribe(fn);
    }

    function publish(...data){
      lastPublication = data;
      subscribers.forEach(fn => fn(...data));
    }

    return ({
      getLastPublication(){return lastPublication;},
      subscribe,
      unsubscribe,
      publish,
    });
  }, []);

  return channel;
}


export function useSideChannelSubscription(sidechannel, indices=0){
  const [data, setData] = useState();
  indices = Array.isArray(indices) ? indices : [indices];

  useEffect(() => sidechannel?.subscribe((...data) => {
    data = indices.map(i => data[i]);

    if (data.length === 1){
      data = data[0];
    }

    setData(data);
  }), [sidechannel, indices.join(',')]);

  return data;
}
