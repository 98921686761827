import { useMemo, useState } from 'react';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap';
import useResourceLoader from '../../util/useResourceLoader';
import { getObject } from '../../util/mapObject';
import CaspioApi from '../../api/CaspioApi';
import Loader from '../../components/Loader';
import useSearchParams from '../../util/useSearchParams';


const PARAMS = ['contextPath', 'isReadonly', 'isAgainstContract'];

function ItemDetails({
  contextPath,
  formData: rootFormData,
  formContext,
  onConfirm: onClose,
  isOpen,
  isReadonly,
  isAgainstContract
}) {
  const {
    setFormDataValues,
  } = formContext;

  const itemPath = contextPath;

  const itemData = useMemo(() => {
    return getObject(rootFormData, itemPath);
  }, [rootFormData, itemPath]);

  const defRejectReason = useMemo(() => getObject(rootFormData, itemPath), [rootFormData, itemPath]);
  const [rejectReason, setRejectReason] = useState(defRejectReason);
  const [validationErrors, setValidationErrors] = useState();

  const [token, loadingToken, errorToken, setToken] = useResourceLoader(
    () => !itemData?.fromCatalog && isAgainstContract && !isReadonly ? CaspioApi.getToken() : null,
    [],
    []
  );

  const { access_Token } = token || {};

  const [macroCategories, loadingMacroCategories, errorMacroCategories, setMacroCategories] = useResourceLoader(
    () => access_Token ? CaspioApi.getMacroCategories({ token: access_Token }) : [],
    [access_Token],
    []
  );

  const [categories, loadingCategories, errorCategories, setCategories] = useResourceLoader(
    () => access_Token ? CaspioApi.getCategories({ token: access_Token }) : [],
    [access_Token],
    []
  );

  function onChange({ target: { name, valueAsNumber, value } }) {

    setRejectReason(prevState => ({
      ...prevState,
      [name]: !valueAsNumber || Number.isNaN(valueAsNumber) ? value : valueAsNumber,
    }));

  }

  function onChangeCategories({ target }) {
    const { name, value, selectedIndex } = target;
    let categoryName = '';

    if (selectedIndex) {
      categoryName = target[selectedIndex].text;
    }

    setRejectReason(prevState => ({
      ...prevState,
      [name]: categoryName,
      [`${name}Id`]: value,
    }));

  }

  function onAccept(e) {
    e.stopPropagation();

    if (isValid()) {
      setFormDataValues({ [itemPath]: rejectReason });
      onClose();
    }
  }

  function isValid() {
    const errorlist = [];
    ['supplier', 'supplierId'].forEach((field) => {
      if (!rejectReason[field]) errorlist.push([field, 'es requerido']);
    });

    const errors = errorlist.length ? errorlist.reduce((_, [field, err]) => {
      (_[field] || (_[field] = [])).push(err);

      return _;
    }, {}): null;

    if (errors) {
      setValidationErrors(errors);
    }

    return !errors;
  }

  return (
    <Modal isOpen={isOpen}
      toggle={onClose}
      className="modal-primary"
      size="xl"
      backdrop
    >
      <ModalHeader
        toggle={onClose}
      >
        {rejectReason?.name}
      </ModalHeader>
      <ModalBody>
        {loadingToken || loadingMacroCategories || loadingCategories ? <Loader fullscreen /> : null}
        <>
          <div className="item-details row">
            {isAgainstContract ? (
              <>
                <div className="col-lg-3">
                  {itemData?.fromCatalog && itemData?.contract ? (
                    <>
                      <label className="title">Contrato:</label>
                      <span>{itemData?.contract}</span>
                    </>
                  ) : (
                    <>
                      <label className="title">Contrato</label>
                      <input
                        type="text"
                        readOnly={isReadonly}
                        className="form-control"
                        value={rejectReason?.contract || ''}
                        name="contract"
                        onChange={e => onChange(e)}
                      />
                    </>
                  )}
                </div>
                <div className="col-lg-3">
                  {itemData?.fromCatalog && itemData?.contract ? (
                    <>
                      <label className="title">Macro Categoría:</label>
                      <span>{itemData?.category}</span>
                    </>
                  ) : (
                    <>
                      <label className="title">Macro Categoría</label>
                      {isReadonly ? (
                        <input
                          type="text"
                          readOnly={isReadonly}
                          className="form-control"
                          value={itemData?.category || ''}
                          name="category"
                          onChange={e => onChange(e)}
                        />
                      ) : (
                        <select
                          readOnly={isReadonly}
                          className="form-control"
                          name="category"
                          onChange={e => {
                            onChangeCategories(e);
                            onChangeCategories({ target: { name: 'subcategory1', value: '', selectedIndex: 0 } });
                          }}
                          value={rejectReason?.categoryId}
                        >
                          <option>Seleccione una opción</option>
                          {macroCategories?.Result?.map(({ ID, Category }, idx) => {
                            return (
                              <option key={idx} value={ID}>
                                {Category}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </>
                  )}
                </div>
                <div className="col-lg-3">
                  {itemData?.fromCatalog && itemData?.contract ? (
                    <>
                      <label className="title">Categoría:</label>
                      <span>{itemData?.subcategory1}</span>
                    </>
                  ) : (
                    <>
                      <label className="title">Categoría</label>
                      {isReadonly ? (
                        <input
                          type="text"
                          readOnly={isReadonly}
                          className="form-control"
                          value={itemData?.subcategory1 || ''}
                          name="subcategory1"
                          onChange={e => onChange(e)}
                        />
                      ) : (
                        <select
                          readOnly={isReadonly}
                          className="form-control"
                          name="subcategory1"
                          onChange={e => onChangeCategories(e)}
                          value={rejectReason?.subcategory1Id}
                        >
                          <option>Seleccione una opción</option>
                          {categories?.Result?.filter(item => item.CategoryID === Number(rejectReason?.categoryId)).map(
                            ({ ID, SubCategory_1 }, idx) => {
                              return (
                                <option key={idx} value={ID}>
                                  {SubCategory_1}
                                </option>
                              );
                            }
                          )}
                        </select>
                      )}
                    </>
                  )}
                </div>
                <div className="col-lg-3">
                  {itemData?.fromCatalog && itemData?.contract ? (
                    <>
                      <label className="title">Nombre:</label>
                      <span>{itemData?.name}</span>
                    </>
                  ) : (
                    <>
                      <label className="title">Nombre</label>
                      <input
                        readOnly={isReadonly}
                        type="text"
                        className="form-control"
                        value={rejectReason?.name || ''}
                        name="name"
                        onChange={e => onChange(e)}
                      />
                    </>
                  )}
                </div>
                {itemData?.fromCatalog && itemData?.supplier ? (
                  <div className="col-lg-3">
                    <label className="title">Suplidor:</label>
                    <span>{`${itemData?.supplier} (ID: ${itemData?.supplierId})`}</span>
                  </div>
                ) : (
                  <>
                    <div className="col-lg-3">
                      <label className="title">Suplidor*</label>
                      <input
                        readOnly={isReadonly}
                        type="text"
                        className="form-control"
                        value={rejectReason?.supplier || ''}
                        name="supplier"
                        onChange={e => onChange(e)}
                      />
                      {validationErrors?.supplier ? <ValidationErrorList errors={validationErrors?.supplier} /> : null}
                    </div>
                    <div className="col-lg-3">
                      <label className="title">Identificación Suplidor*</label>
                      <input
                        readOnly={isReadonly}
                        type="text"
                        className="form-control"
                        value={rejectReason?.supplierId || ''}
                        name="supplierId"
                        onChange={e => onChange(e)}
                      />
                      {validationErrors?.supplierId ? <ValidationErrorList errors={validationErrors?.supplierId} /> : null}
                    </div>
                  </>
                )}
              </>
            ) : null}

            <div className="col-lg-3">
              <label className="title">Marca</label>
              <input
                readOnly={isReadonly}
                type="text"
                className="form-control"
                value={rejectReason?.brand || ''}
                name="brand"
                onChange={e => onChange(e)}
              />
            </div>
            <div className="col-lg-3">
              <label className="title">Modelo</label>
              <input
                readOnly={isReadonly}
                type="text"
                className="form-control"
                value={rejectReason?.model || ''}
                name="model"
                onChange={e => onChange(e)}
              />
            </div>
            <div className="col-lg-3">
              <label className="title">Garantía</label>
              <input
                readOnly={isReadonly}
                type="text"
                className="form-control"
                value={rejectReason?.warranty || ''}
                name="warranty"
                onChange={e => onChange(e)}
              />
            </div>
            {isAgainstContract ? (
              <>
                <div className="col-lg-3">
                  <label className="title">Partida</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.startingNumber || ''}
                    name="startingNumber"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Renglón</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.row || ''}
                    name="row"
                    onChange={e => onChange(e)}
                  />
                </div>
              </>
            ) : null}
            <div className="col-lg-3">
              <label className="title">Cantidad</label>
              <input
                readOnly={isReadonly}
                type="number"
                className="form-control"
                value={rejectReason?.quantity || ''}
                name="quantity"
                onChange={e => onChange(e)}
              />
            </div>

            <div className="col-lg-3">
              <label className="title">Entrega</label>
              <input
                readOnly={isReadonly}
                type="text"
                className="form-control"
                value={rejectReason?.delivery || ''}
                name="delivery"
                onChange={e => onChange(e)}
              />
            </div>
            <div className="col-lg-3">
              <label className="title">Tipo</label>
              <input
                readOnly={isReadonly}
                type="text"
                className="form-control"
                value={rejectReason?.type || ''}
                name="type"
                onChange={e => onChange(e)}
              />
            </div>
            <div className="col-lg-3">
              <label className="title">Precio</label>
              <input
                readOnly={isReadonly}
                type="number"
                className="form-control"
                value={rejectReason?.estCost || ''}
                name="estCost"
                onChange={e => onChange(e)}
              />
            </div>
            <div className="col-lg-3">
              <label className="title">Unidad</label>
              <input
                readOnly={isReadonly}
                type="text"
                className="form-control"
                value={rejectReason?.unit || ''}
                name="unit"
                onChange={e => onChange(e)}
              />
            </div>
            {isAgainstContract && itemData?.fromCatalog && itemData?.quantityDetail ? (
              <>
                <div className="col-lg-3">
                  <label className="title">Cantidad Incluida</label>
                  <input readOnly="readonly" type="text" className="form-control" value={itemData.quantityDetail} name="quantityDetail" />
                </div>
              </>
            ) : null}
            {isAgainstContract ? (
              <>
                <div className="col-lg-3">
                  <label className="title">Equipo Servicio</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.teamService || ''}
                    name="teamService"
                    onChange={e => onChange(e)}
                  />
                </div>

                <div className="col-lg-3">
                  <label className="title">Zona</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.zone || ''}
                    name="zone"
                    onChange={e => onChange(e)}
                  />
                </div>

                <div className="col-lg-3">
                  <label className="title">Especificación</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.specification || ''}
                    name="specification"
                    onChange={e => onChange(e)}
                  />
                </div>

                <div className="col-lg-3">
                  <label className="title">Especificación Federal</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.federalSpecification || ''}
                    name="federalSpecification"
                    onChange={e => onChange(e)}
                  />
                </div>
              </>
            ) : null}

            <div className="col-lg-3">
              <label className="title">Manufacturero</label>
              <input
                readOnly={isReadonly}
                type="text"
                className="form-control"
                value={rejectReason?.manufacturer || ''}
                name="manufacturer"
                onChange={e => onChange(e)}
              />
            </div>

            <div className="col-lg-3">
              <label className="title">Procedencia</label>
              <input
                readOnly={isReadonly}
                type="text"
                className="form-control"
                value={rejectReason?.origin || ''}
                name="origin"
                onChange={e => onChange(e)}
              />
            </div>
            {isAgainstContract ? (
              <>
                <div className="col-lg-3">
                  <label className="title">Ley PCT Preferencia</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.lawPctPreference || ''}
                    name="lawPctPreference"
                    onChange={e => onChange(e)}
                  />
                </div>

                <div className="col-lg-3">
                  <label className="title">Instalación</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.installed || ''}
                    name="installed"
                    onChange={e => onChange(e)}
                  />
                </div>
              </>
            ) : null}

            <div className="col-lg-3">
              <label className="title">Dimensiones</label>
              <input
                readOnly={isReadonly}
                type="text"
                className="form-control"
                value={rejectReason?.dimensions || ''}
                name="dimensions"
                onChange={e => onChange(e)}
              />
            </div>

            <hr />

            {/* Facilidades & Construcción */}
            {rejectReason?.categoryId === '1' ? (
              <>
                <div className="col-lg-12">
                  <div className="sectionTitle">
                    <h1>Materiales</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">Tipo de Uso</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Materiales_TipoUso || ''}
                    name="Materiales_TipoUso"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">HP Bomba</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Materiales_HP_Bomba || ''}
                    name="Materiales_HP_Bomba"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Gal TPresuriz</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Materiales_Gal_TPresuriz || ''}
                    name="Materiales_Gal_TPresuriz"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-12">
                  <div className="sectionTitle">
                    <h1>Poz</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">Pueblos Region</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.POZ_Pueblos_Region || ''}
                    name="POZ_Pueblos_Region"
                    onChange={e => onChange(e)}
                  />
                </div>
              </>
            ) : null}

            {/* Administracion de oficinas */}
            {rejectReason?.categoryId === '6' ? (
              <>
                <div className="col-lg-12 text-center">
                  <div className="sectionTitle">
                    <h1>Docs</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">Pueblos en Zona</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.DOCS_Pueblos_en_Zona || ''}
                    name="DOCS_Pueblos_en_Zona"
                    onChange={e => onChange(e)}
                  />
                </div>
              </>
            ) : null}

            {rejectReason?.categoryId === '3' ? (
              <>
                <div className="col-lg-12 text-center">
                  <div className="sectionTitle">
                    <h1>Aire Acondicionado</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">Precio Instalación</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.priceInstallation || ''}
                    name="priceInstallation"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Voltios</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.AC_Voltios || ''}
                    name="AC_Voltios"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Modelo Evaporador</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.AC_Modelo_Evaporador || ''}
                    name="AC_Modelo_Evaporador"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Precio Unitario (Inverter)</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.AC_Precio_Unitario_Inverter || ''}
                    name="AC_Precio_Unitario_Inverter"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Toneladas</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.AC_Toneladas || ''}
                    name="AC_Toneladas"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Remocion Recl Equipos</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.AC_Remocion_Recl_Equipos || ''}
                    name="AC_Remocion_Recl_Equipos"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Modelo Condensador</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.AC_Modelo_Condensador || ''}
                    name="AC_Modelo_Condensador"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Garantia Compresor</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.AC_Garantia_Compresor || ''}
                    name="AC_Garantia_Compresor"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">BTU</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.AC_BTU || ''}
                    name="AC_BTU"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Pie Add tuberia</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.AC_Pie_Add_tuberia || ''}
                    name="AC_Pie_Add_tuberia"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">EER</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.AC_EER || ''}
                    name="AC_EER"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Región</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.AC_Region || ''}
                    name="AC_Region"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-12 text-center">
                  <div className="sectionTitle">
                    <h1>Catering</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">Comidas</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Catering_Comidas || ''}
                    name="Catering_Comidas"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Plato Principal</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Catering_Plato_Ppal || ''}
                    name="Catering_Plato_Ppal"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Acompañante</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Catering_Acompanante || ''}
                    name="Catering_Acompanante"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-12 text-center">
                  <div className="sectionTitle">
                    <h1>Seguridad</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">Security PrecioU HRXtra</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Security_PrecioU_HRXtra || ''}
                    name="Security_PrecioU_HRXtra"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-12 text-center">
                  <div className="sectionTitle">
                    <h1>Alimentos</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">Categoría</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Almiento_Categoria || ''}
                    name="Almiento_Categoria"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Numeración</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Alimento_Numeracion || ''}
                    name="Alimento_Numeracion"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Vida útil</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Alimento_VidaUtil || ''}
                    name="Alimento_VidaUtil"
                    onChange={e => onChange(e)}
                  />
                </div>

                <div className="col-lg-12 text-center">
                  <div className="sectionTitle">
                    <h1>Extintores</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">Propósito</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Extintores_Proposito || ''}
                    name="Extintores_Proposito"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Utilidad</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Extintores_Utilidad || ''}
                    name="Extintores_Utilidad"
                    onChange={e => onChange(e)}
                  />
                </div>

                <div className="col-lg-12 text-center">
                  <div className="sectionTitle">
                    <h1>Reciclaje</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">Costo Transporte</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Reciclaje_CostoTransp || ''}
                    name="Reciclaje_CostoTransp"
                    onChange={e => onChange(e)}
                  />
                </div>

                <div className="col-lg-12 text-center">
                  <div className="sectionTitle">
                    <h1>Gas</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">Pueblos en Zona</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.Gas_PueblosenZona || ''}
                    name="Gas_PueblosenZona"
                    onChange={e => onChange(e)}
                  />
                </div>
              </>
            ) : null}

            {/* Tecnología (IT) */}
            {rejectReason?.categoryId === '4' ? (
              <>
                <div className="col-lg-12 text-center">
                  <div className="sectionTitle">
                    <h1>Tecnología (IT)</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">Categoría</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.IT_Category || ''}
                    name="IT_Category"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Subcategoría</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.IT_Subcategory || ''}
                    name="IT_Subcategory"
                    onChange={e => onChange(e)}
                  />
                </div>
              </>
            ) : null}

            {/* Transporte & Logística */}
            {rejectReason?.categoryId === '9' ? (
              <>
                <div className="col-lg-12 text-center">
                  <div className="sectionTitle">
                    <h1>Batería</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">Comentarios Amperes</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.BAT_Cometarios_Amperes || ''}
                    name="BAT_Cometarios_Amperes"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Tornillos</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.BAT_Tornillos || ''}
                    name="BAT_Tornillos"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-12 text-center">
                  <div className="sectionTitle">
                    <h1>Renta de automóvil</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">Tarifa Diaria</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.CarRent_TarifaDiaria || ''}
                    name="CarRent_TarifaDiaria"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">LDW</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.CarRent_LDW || ''}
                    name="CarRent_LDW"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Tarifa Semanal</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.CarRent_TarifaSemanal || ''}
                    name="CarRent_TarifaSemanal"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Deducible</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.CarRent_Deductible || ''}
                    name="CarRent_Deductible"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">Tarifa Mensual</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.CarRent_TarifaMensual || ''}
                    name="CarRent_TarifaMensual"
                    onChange={e => onChange(e)}
                  />
                </div>

                <div className="col-lg-12 text-center">
                  <div className="sectionTitle">
                    <h1>VP</h1>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="title">VP Año</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.VP_Ano || ''}
                    name="VP_Ano"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">VP Garantía Conversión Implemento</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.VP_Garantia_ConvercionInplemento || ''}
                    name="VP_Garantia_ConvercionInplemento"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">VP Garantia Bumper To Bumper</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.VP_Garantia_BumperToBumper || ''}
                    name="VP_Garantia_BumperToBumper"
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="title">VP Garantia PowerTrain</label>
                  <input
                    readOnly={isReadonly}
                    type="text"
                    className="form-control"
                    value={rejectReason?.VP_Garantia_PowerTrain || ''}
                    name="VP_Garantia_PowerTrain"
                    onChange={e => onChange(e)}
                  />
                </div>
              </>
            ) : null}

            <div className="col-lg-12">
              <div className="form-group">
                <label className="title">Descripción</label>
                <textarea
                  readOnly={isReadonly}
                  rows={4}
                  className="form-control"
                  value={rejectReason?.description || ''}
                  name="description"
                  onChange={e => onChange(e)}
                />
              </div>
            </div>
          </div>
        </>
      </ModalBody>
      <ModalFooter>
        {!isReadonly ? (
          <Button onClick={onAccept} color="primary">
            Aceptar
          </Button>
        ) : null}
        <Button onClick={onClose} color="secondary">
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ItemDetails.PARAMS = PARAMS;

function ValidationErrorList({ errors }) {
  return errors && errors.length ? (
    <ul className="error-detail bs-callout bs-callout-info inline-error-list">
      {errors.map((err, index) => (
        <li key={index} className="text-danger">
          {err}
        </li>
      ))}
    </ul>
  ) : null;
}

export default ItemDetails;
