import fetchApi from '../util/fetchApi';
import checkErrors from './checkErrors';


export const WorkflowApi = {
  
  async getCurrentWorkflow() {
    return checkErrors(await fetchApi.api.get('manage/workflows/current'));
  },

  async list({ offset, size, sortBy }) {
    return checkErrors(await fetchApi.api.get('manage/workflows', { query: { offset, size, sortBy } }));
  },

  async get({ workflowId }) {
    return checkErrors(await fetchApi.api.get(`manage/workflows/${workflowId}`));
  },

  async getByName({ name }) {
    return checkErrors(await fetchApi.api.get(`manage/workflows/named/${name}`));
  },

  async update(workflowId, workflow) {
    return checkErrors(await fetchApi.api.put(`manage/workflows/${workflowId}`,{data: workflow}));
  },

  async create(workflow) {
    return checkErrors(await fetchApi.api.post('manage/workflows/',{ data: workflow }));
  },

};

export default WorkflowApi;
