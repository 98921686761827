const compareFormActions = {
  setFormLoadingStatus({formId, loading, error}, state) {
    if (!formId) return;

    return {
      loading: {...state.loading, [formId]: loading},
      errorLoading: {...state.errorLoading, [formId]: error},
    };
  },

  addForm({ form }, { forms, unrolledForms, enabledProps }) {
    if (!form) return;
    const schema = getFormDefSchema(form);
    // const cleanSchema = getCleanedSchema(schema);
    const unrolled = unrollSchema(schema);

    forms = {...forms, [form.id]: form};
    unrolledForms = {...unrolledForms, [form.id]: unrolled};

    const {
      allPropertiesList,
      allFieldsList,
      propsList,
      fieldsList,
    } = computeAllFieldsPropsLists(unrolledForms, enabledProps);

    return { forms, unrolledForms, fieldsList, allFieldsList, propsList, allPropertiesList };
  },
  removeForm({ form, formId }, { forms, unrolledForms, enabledProps }) {
    formId = formId ?? form?.id;
    if (!formId) return;
    forms = {...forms};
    unrolledForms = {...unrolledForms};

    delete forms[form.id];
    delete unrolledForms[form.id];

    const {
      allPropertiesList,
      allFieldsList,
      propsList,
      fieldsList,
    } = computeAllFieldsPropsLists(unrolledForms, enabledProps);

    return { forms, unrolledForms, fieldsList, allFieldsList, propsList, allPropertiesList };
  },
  setPropEnabled({prop, enabled}, {allPropertiesList, allFieldsList, enabledProps: oldEnabledProps}) {
    const enabledProps = {...oldEnabledProps, [prop]: enabled};
    const {propsList, fieldsList} = computeFilteredFieldsPropsLists(allPropertiesList, allFieldsList, enabledProps);

    return {
      enabledProps,
      propsList,
      fieldsList
    };
  },

  toggleFieldCollapsed({field}, {collapsedFields: oldCollapsedFields}) {
    const collapsed = !(oldCollapsedFields?.[field]);
    console.log(field, collapsed);
    const collapsedFields = {...oldCollapsedFields, [field]: collapsed};

    return { collapsedFields };
  },

};


function computeAllFieldsPropsLists(unrolledForms, enabledProps) {
  enabledProps = enabledProps || {};
  const allFieldsList = Object.values(Object.values(unrolledForms).reduce((_, unrolledFields) => {
    return Object.entries(unrolledFields).reduce((_, [fieldPath, fieldDef]) => {
      if (!_[fieldPath]) {
        _[fieldPath] = { name: fieldPath, props: {} };
      }

      const props = _[fieldPath].props;
      Object.keys(fieldDef).forEach(k => {props[k] = 1;});

      return _;
    }, _);
  }, {}));

  allFieldsList.sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name ? -1 : 0));
  const propMap = {};
  allFieldsList.forEach(field => {
    field.props = Object.keys(field.props || {});
    field.props.forEach(prop => { propMap[prop] = 1; });
    field.props.sort(sortByPropertyNames);
  });

  const allPropertiesList = Object.keys(propMap);
  allPropertiesList.sort(sortByPropertyNames);

  const {propsList, fieldsList} = computeFilteredFieldsPropsLists(allPropertiesList, allFieldsList, enabledProps);

  return {
    allFieldsList,
    allPropertiesList,
    propsList,
    fieldsList
  };
}


const PROP_ORDERING = ['type', 'title', 'format'].reduce((_, prop, idx) => {
  _[prop] = idx + 1;

  return _;
}, {});
const PROP_ORDERING_OTHER = Object.keys(PROP_ORDERING).length + 1;

function sortByPropertyNames(propA, propB) {
  const aOrder = PROP_ORDERING[propA] || PROP_ORDERING_OTHER;
  const bOrder = PROP_ORDERING[propB] || PROP_ORDERING_OTHER;
  const delta = bOrder - aOrder;
  if (delta !== 0) return -delta;

  const aHasColon = propA.indexOf(':') === -1;
  const bHasColon = propB.indexOf(':') === -1;

  if (aHasColon && !bHasColon) {
    return -1;
  } else if(!aHasColon && bHasColon) {
    return 1;
  }

  return (propA < propB) ? -1 : (propA > propB ? 1 : 0);
}

function computeFilteredFieldsPropsLists(allPropertiesList, allFieldsList, enabledProps) {
  const propsList = allPropertiesList.filter(prop => enabledProps[prop] !== false);

  const fieldsList = allFieldsList.map(({name, props}) => ({
    name,
    props: props.filter(prop => enabledProps[prop] !== false),
  })).filter(({props}) => !!props.length);

  return {propsList, fieldsList};
}



function getFormDefSchema(formDef){
  return {type: 'object', properties: formDef.schema};
}

function unrollSchema(jsonSchema){
  const unrolledSchema = {};
  const stack = [['', jsonSchema]];

  while(stack.length){
    const [path, curSchema] = stack.shift();
    const pathPrefix = path ? `${path}.` : '';

    if (curSchema.type === 'object' && curSchema.properties){
      const copy = {...curSchema};
      delete copy.properties;
      unrolledSchema[path] = copy;

      stack.unshift(...Object.entries(curSchema.properties).map(([k, v]) => [`${pathPrefix}${k}`, v]));
    } else if (curSchema.type === 'array' && curSchema.items){
      const copy = {...curSchema};
      delete copy.items;
      unrolledSchema[path] = copy;

      stack.unshift([`${path}[]`, curSchema.items]);
    } else {
      unrolledSchema[path] = curSchema;
    }
  }

  return unrolledSchema;
}


export default compareFormActions;