import RJSFForm from '@rjsf/core/lib/components/Form';
import { deepEquals, retrieveSchema, toPathSchema, mergeObjects } from '@rjsf/core/lib/utils';
import validateFormData, { toErrorList } from '@rjsf/core/lib/validate';


export default class Form extends RJSFForm {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextState = this.getStateFromProps(nextProps, nextProps.formData);

    const nonFdStateChanged = !deepEquals({...nextState, formData:null}, {...this.state, formData:null});
    const propsFdChanged = !deepEquals(this.props.formData, nextProps.formData);

    if (!nonFdStateChanged && !propsFdChanged) return;

    const nextFdChanged = !deepEquals(nextState.formData, nextProps.formData);
    const stateFdChanged = !deepEquals(nextState.formData, this.state.formData);

    if (
      nextFdChanged &&
            stateFdChanged &&
            this.props.onChange
    ) {
      this.props.onChange(nextState);
    }

    this.setState(nextState);
  }

  validate(formData, schema, additionalMetaSchemas, customFormats) {
    console.log('validate(formData, schema, additionalMetaSchemas, customFormats)', {formData, schema, additionalMetaSchemas, customFormats});
    schema = schema ?? this.props.schema;
    additionalMetaSchemas = additionalMetaSchemas ?? this.props.additionalMetaSchemas;
    customFormats = customFormats ?? this.props.customFormats;
    const { validate, transformErrors } = this.props;

    const rootSchema = this.getRegistry().rootSchema;
    const resolvedSchema = retrieveSchema(schema, rootSchema, formData);

    formData = cleanFormData(rootSchema, formData);

    return validateFormData(formData, resolvedSchema, validate, transformErrors, additionalMetaSchemas, customFormats);
  }

  onSubmit(event) {
    event.preventDefault();

    if (event.target !== event.currentTarget) {
      return;
    }
    
    event.persist();
    let newFormData = this.state.formData;
    const rootSchema = this.getRegistry().rootSchema;
    newFormData = cleanFormData(rootSchema, newFormData);

    if (this.props.omitExtraData === true) {
      const retrievedSchema = retrieveSchema(
        this.state.schema,
        this.state.schema,
        newFormData
      );
      const pathSchema = toPathSchema(
        retrievedSchema,
        '',
        this.state.schema,
        newFormData
      );
        
      const fieldNames = this.getFieldNames(pathSchema, newFormData);
        
      newFormData = this.getUsedFormData(newFormData, fieldNames);
    }
    
    if (!this.props.noValidate) {
      const schemaValidation = this.validate(newFormData);
      let errors = schemaValidation.errors;
      let errorSchema = schemaValidation.errorSchema;
      const schemaValidationErrors = errors;
      const schemaValidationErrorSchema = errorSchema;

      if (Object.keys(errors).length > 0) {
        if (this.props.extraErrors) {
          errorSchema = mergeObjects(
            errorSchema,
            this.props.extraErrors,
            !!'concat arrays'
          );
          errors = toErrorList(errorSchema);
        }

        this.setState({
          errors,
          errorSchema,
          schemaValidationErrors,
          schemaValidationErrorSchema,
        }, () => {
          if (this.props.onError) {
            this.props.onError(errors);
          } else {
            console.error('Form validation failed', errors);
          }
        });

        return;
      }
    }
    
    // There are no errors generated through schema validation.
    // Check for user provided errors and update state accordingly.
    let errorSchema;
    let errors;

    if (this.props.extraErrors) {
      errorSchema = this.props.extraErrors;
      errors = toErrorList(errorSchema);
    } else {
      errorSchema = {};
      errors = [];
    }
    
    this.setState(
      {
        formData: newFormData,
        errors,
        errorSchema,
        schemaValidationErrors: [],
        schemaValidationErrorSchema: {},
      }, () => {
        if (this.props.onSubmit) {
          this.props.onSubmit(
            { ...this.state, formData: newFormData, status: 'submitted' },
            event
          );
        }
      }
    );
  };
    
};


function cleanFormData(rootSchema, formData){
  const cleaned = {};
  const stack = [[rootSchema, cleaned, 'root', formData]];

  while(stack.length) {
    const [curSchema, curParent, curName, curData] = stack.shift();
    let cleaned = curData;
    const {type, items, properties, additionalProperties} = curSchema;

    switch(type) {
      case 'array':
        if (Array.isArray(curData)) {
          cleaned = [];
          let lastNondUndefined = 0;
          curData.forEach((dataItem, index) => {
            if (dataItem !== undefined) lastNondUndefined = index + 1;
          });
          curData.slice(0, lastNondUndefined).forEach((dataItem, index) => {
            stack.unshift([items, cleaned, index, dataItem]);
          });
        }

        break;
      case 'object':
        if (typeof curData === 'object' && curData) {
          cleaned = {};
          Object.keys(curData).forEach((key) => {
            if (key in properties) {
              stack.unshift([properties[key], cleaned, key, curData[key]]);
            } else if(additionalProperties) {
              stack.unshift([additionalProperties, cleaned, key, curData[key]]);
            }
          });
        }

        break;
    }

    curParent[curName] = cleaned;
  }

  return cleaned.root;
}
