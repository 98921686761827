import { useCallback, useEffect, useMemo } from 'react';
import { FormGroup } from 'reactstrap';
import { getObject, mapObject, setObject } from '../../util/mapObject';
import { useJnx } from '../../util/jnx';
import Form from './Form';
import parseFormDefinition from '../../util/parseFormDefinition';
import getPathFromId from '../../util/getPathFromId';


function SubformField({
  registry,
  name,
  schema,
  onChange: propOnChange,
  disabled,
  idSchema: {$id},
  formData: propFormData
}) {
  const {
    ArrayFieldTemplate,
    FieldTemplate,
    ObjectFieldTemplate,
    widgets,
    fields,
    definitions,
    formContext: propFormContext,
  } = registry;
  const {
    title,
    schemaExpr,
    schema: schemaPropDef
  } = schema;

  const schemaJnx = useJnx(schemaExpr);
  const path = useMemo(() => getPathFromId($id), [$id]);

  const schemaDef = useMemo(() => {
    const schemaDef =
      schemaJnx &&
      schemaJnx.eval(propFormData, '', {
        schema,
      });

    return schemaDef || schemaPropDef;
  }, [propFormData, schemaJnx, schemaPropDef, schema]);

  const formDef = useMemo(
    () =>
      parseFormDefinition({
        rootSchema: {
          ...schemaDef,
          definitions: {
            ...definitions,
            ...schemaDef?.definitions,
          },
        },
      }),
    [JSON.stringify(schemaDef), definitions]
  );

  const sideChannel = useSubformSideChannel(propFormContext.sideChannel, path, formDef.invObjectMap, propFormContext);
  const formContext = useMemo(
    () => ({
      ...propFormContext,
      sideChannel,
    }),
    [propFormContext, sideChannel]
  );
  const formData = useMemo(() => (formDef.invObjectMap ? mapObject(propFormData, formDef.invObjectMap, null, formContext) : propFormData), [
    propFormData,
    formDef,
    formContext,
  ]);

  const onChange = useCallback(
    ({ formData }) => {
      const { objectMap } = formDef;

      if (objectMap) {
        formData = mapObject(formData, objectMap, null, formContext);
      }

      propOnChange(formData);
    },
    [propOnChange, formDef]
  );

  return (
    <FormGroup disabled>
      {title !== ' ' ? (
        <label className="control-label" htmlFor={$id}>
          {title}
        </label>
      ) : null}
      <Form
        tagName="div"
        disabled={disabled}
        idPrefix={$id}
        formData={formData}
        formContext={formContext}
        onChange={onChange}
        ArrayFieldTemplate={ArrayFieldTemplate}
        ObjectFieldTemplate={ObjectFieldTemplate}
        FieldTemplate={FieldTemplate}
        schema={formDef.schema}
        uiSchema={formDef.uiSchema}
        widgets={widgets}
        fields={fields}
      >
        <></>
      </Form>
    </FormGroup>
  );
}

function useSubformSideChannel(parentSidechannel, path, inverseMap, formContext) {
  const channel = useMemo(() => {
    const subscribers = [];
    let lastPublication = extractSubFormPublication(parentSidechannel?.getLastPublication());

    function extractSubFormPublication(publication) {
      if (!publication) return null;

      const rootFormData = publication[0];
      const formData = getObject(rootFormData, path);
      const subformData = mapObject(formData, inverseMap, null, formContext);
      const rootSubformData = {};
      setObject(rootSubformData, path, subformData);

      const subformPub = [rootSubformData, rootFormData];

      return subformPub;
    }

    function unsubscribe(fn) {
      const idx = subscribers.indexOf(fn);

      if (idx > -1) {
        subscribers.splice(idx, 1);
      }
    }

    function subscribe(fn) {
      subscribers.push(fn);
      fn(...(lastPublication || []));

      return () => unsubscribe(fn);
    }

    function publish(...data) {
      lastPublication = extractSubFormPublication(data);
      subscribers.forEach(fn => fn(...lastPublication));
    }

    return {
      getLastPublication() {
        return lastPublication;
      },
      subscribe,
      unsubscribe,
      publish,
    };
  }, [parentSidechannel, path, inverseMap, formContext]);

  useEffect(() => parentSidechannel.subscribe((...data) => channel.publish(...data)), [parentSidechannel, channel]);

  return channel;
}

export default SubformField;
