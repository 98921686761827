import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap';

const PARAMS = ['title', 'message'];

function NumberEntryModal({ title, message, onCancel, onConfirm, isOpen }) {
  const [value, setValue] = useState();

  return (
    <Modal isOpen={isOpen}
      toggle={onCancel}
      size='sm'
      backdrop='static'
    >
      <ModalHeader>
        {title}
      </ModalHeader>
      <ModalBody style={{ padding: 0 }}>
        <div className="col-lg-12">
          {message}
          <input type="text" className="form-control" onChange={(e) => setValue(e.target.value)} />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="col-lg-12">
          <Button className="float-left mr-3" onClick={onCancel} variant="secondary">
            Cerrar
          </Button>
          <Button
            className="float-right"
            onClick={() => {
              onConfirm(value);
            }}
            color="primary"
          >
            Confirmar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

NumberEntryModal.PARAMS = PARAMS;

export default NumberEntryModal;
