import React from 'react';
import Notification from '../../../Notification';


class CardsTableLayout {
  constructor({
    $id,
    hasToolbar,
    itemsAreObjectType,
    onEmptyMessage,
    positionButtons,
    rowClassNames
  }) {
    this.isTable = false;
    this.rowClassNames = rowClassNames;
    this.$id = $id;
    this.itemsAreObjectType = itemsAreObjectType;
    this.positionButtons = positionButtons;
    this.rowClassNames = rowClassNames;
    this.hasToolbar = hasToolbar;
    this.onEmptyMessage = onEmptyMessage;
  }

  wrapArray(children) {
    return <div className="array-cards">{children}</div>;
  }

  wrapBody(children) {
    return (
      <div className="array-item-list" key={`array-item-list-${this.$id}`}>
        {children}
      </div>
    );
  }

  wrapLoader(children) {
    return children;
  }

  wrapItem(
    id,
    props,
    onAddClick,
    rowIdx,
    rowCount,
    children,
    buttons,
    rowClassNames
  ) {
    return (
      <div
        id={id}
        className={`has-buttons-position-${this.positionButtons || 'normal'} ${
          props.className
        } ${this.itemsAreObjectType ? 'for-object-row' : ''} ${rowClassNames}`}
      >
        <div>{children}</div>
        {buttons ? (
          <div
            className={`array-item-buttons col-xs-3 array-item-toolbox buttons-position-${this.positionButtons || 'normal'}`}
            style={{ width: '100px' }}
          >
            <div
              className="btn-group"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {buttons}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  wrapItemField({ name, uiSchema }, children) {
    return (
      <div key={name} className={(uiSchema[name] || {})['ui:cellClassName']}>
        {children}
      </div>
    );
  }

  wrapItemPanelRow(_1, children) {
    return (
      <div className={`array-item panel-row ${this.rowClassNames}`}>
        {children}
      </div>
    );
  }

  wrapItemErrorList(children) {
    return <div>{children}</div>;
  }

  makeEmptyItemField() {
    return <td />;
  }

  makeSimpleItem(props) {
    return (
      <div className={this.hasToolbar ? 'col-xs-9' : 'col-xs-12'}>
        {props.children}
      </div>
    );
  }

  wrapAddButton(children) {
    return <div>{children}</div>;
  }

  makeEmptyMessage() {
    return (
      <div>
        <Notification color="warning">{this.onEmptyMessage}</Notification>
      </div>
    );
  }

  makeHeaders() {
    return null;
  }
}

export default CardsTableLayout;
