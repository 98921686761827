import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input } from 'reactstrap';


function WorkflowJson({
  state,
  dispatchAction,
}){
  const { steps } = state;
  const json = useMemo(() => JSON.stringify(steps, null, 4), [steps]);
  const onChange = useCallback(({target: { value }}) => {
    try {
      const definition = JSON.parse(value);
      dispatchAction.setWorkflow({ definition });
    } catch(e) {
      // // pass
    }
  }, [dispatchAction]);

  return (
    <Input type="textarea" value={json} onChange={onChange} rows={5}/>
  );
}


export default WorkflowJson;
