import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import AuthApi from '../api/AuthApi';
import { AuthContext } from '../context/AuthContext';
import FormComponent from '../components/FormComponent';
import InternalLayout from '../components/layouts/InternalLayout';
import { FORM_UPDATE_SUBMIT_BUTTON } from '../constants';
import Notification from '../components/Notification';
import { makeUserSchema } from './users/EditUser';


const MyProfile = FormComponent({
  displayName: 'MyProfile',
  withoutNavigation: true,
  schema: makeUserSchema({
    title: 'Mi Perfil',
    onlyEditUserData: true,
    hidePassword: true,
  }),
  parseProps: () => ({
    auth: useContext(AuthContext),
  }),
  loadData: () => AuthApi.getSelf(),
  async onSubmit({ formData }, { props: { auth } }) {
    const user = await AuthApi.updateSelf(formData);

    if (user) {
      auth.setCompletedProfile(user.data.hasCompletedProfile);
    }
  },
  renderFormSubmitted: () => (
    <Redirect path="/" />
  ),
  submitButtons: FORM_UPDATE_SUBMIT_BUTTON,
});

export default function() {
  const auth = useContext(AuthContext);

  return (
    <InternalLayout>
      {!auth?.user?.user?.hasCompletedProfile ? (
        <Notification color="warning">Su perfil se encuentra incompleto</Notification>
      ) : null}
      <MyProfile />
    </InternalLayout>
  );
}

