import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';


function AdjusterEdge({
  parentRef,
  visible,
  fromRight,
  percentage,
  position, setPosition,
}){
  const ref = useRef();
  const [mousePt, setMousePt] = useState();
  const onMouseDown = useCallback((e) => {
    e.preventDefault();
    const { left, top } = ref.current.getBoundingClientRect();
    setMousePt([left - e.clientX, top - e.clientY]);
  }, []);

  const [offset, scale] = useMemo(() => (fromRight ? [100, -1] : [0, 1]), [fromRight]);
  const unit = percentage ? '%' : 'px';

  useEffect(() => {
    if (mousePt) {
      console.log('mousePt', mousePt);
      const handlers = {
        mousemove({ clientX }) {
          const { left, width } = parentRef.current.getBoundingClientRect();
          const delta = Math.max(0, Math.min(clientX + mousePt[0] - left, width));
          const x = (((delta / width) * 100 - offset) / scale);
          setPosition(x);
        },
        mouseup() {
          setMousePt();
        },
      };
      Object.entries(handlers).forEach(([event, handler]) => document.body.addEventListener(event, handler));

      return () => {
        Object.entries(handlers).forEach(([event, handler]) => document.body.removeEventListener(event, handler));
      };
    }
  }, [mousePt, offset, scale, parentRef]);

  const style = useMemo(() => {
    const style = {};
    const value = offset + scale * position;
    style.left = `${value}${unit}`;

    return style;
  }, [unit, offset, scale, position]);

  return (
    <div
      ref={ref}
      className={`adjuster-edge vertical ${mousePt || visible ? 'active' : ''} ${percentage ? 'percentage' : ''}`}
      onMouseDown={onMouseDown}
      style={style}
      role="separator"
    />
  );
}


export default AdjusterEdge;
