import useSearchParams from '../../../util/useSearchParams';
import EditWorkflow from './EditWorkflow';
import ListWorkflows from './ListWorkflows';


function Workflows(){
  const [
    [workflow, setWorkflow]
  ] = useSearchParams({
    workflow: undefined
  });

  return workflow ? (
        <EditWorkflow workflowId={workflow} setWorkflow={setWorkflow} />
    ) : (
        <ListWorkflows setWorkflow={setWorkflow} />
    );
}

export default Workflows;