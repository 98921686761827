import fetchApi from '../util/fetchApi';
import checkErrors from './checkErrors';

const BulkUploaderApi = {
  async bulkInsert({ model, file }) {
    return checkErrors(await fetchApi.api.post(`bulkUserUploader/insert/${model}`, {
      formdata: { file }
    }));
  },
  async getModel({ model }) {
    return checkErrors(
      await fetchApi.api.get(`bulkUserUploader/template/${model}`)
    );
  },
};

export default BulkUploaderApi;
