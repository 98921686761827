import jsonata from 'jsonata';
import { useMemo } from 'react';
import jsonataFunctions from './jsonataFunctions';
import { concatenatePaths, getObject, parseRelPath, CUSTOM_PATHS } from './mapObject';


export const DEFAULT_SCOPE = '$.';

class Jnx {
  constructor(jnx){
    jnx = normalizeToExprObject(jnx);

    this.expression = jnx.expr;

    try {
      this.fn = jsonata(this.expression);
    } catch (e){
      console.error('parsing expression: ', this.expression);
      throw e;
    }

    this.scope = jnx.scope || DEFAULT_SCOPE;

    this.scope = parseRelPath(this.scope);
    this.debug = jnx.debug;

    this.debug = jnx.debug;
    this._fnBindings = {};

    this.bindFunctions(jsonataFunctions);
  }

  bindFunctions(functionBinds){
    Object.entries(functionBinds || {}).forEach(([name, fn]) => {
      this.fn.registerFunction(name, fn, fn.signature || undefined);
      this._fnBindings[name] = fn;
    });
  }

  eval(data, path='', bindings=null, promised=false){
    let context = data;
    const evalBindings = {...(bindings || { })};
    evalBindings.path = path;
    evalBindings.rootData = data;

    if(this.scope !== DEFAULT_SCOPE){
      const newPath = concatenatePaths(path, this.scope);
      context = getObject(data, newPath);
      evalBindings.path = newPath;
    }

    evalBindings.evalContext = context;

    if (this.debug) {
      console.debug(`jnx expression debug tag: ${this.debug}`);
      console.debug({expression: this.expression, context, bindings: evalBindings, path, scope: this.scope, fns: this._fnBindings});
    }

    if (promised) {
      return new Promise((resolve, reject) => {
        this.fn.evaluate(context, evalBindings, (error, result) => {
          if (error) {
            if (this.debug) console.debug('error: ', error);
            reject(error);
          } else {
            if (this.debug) console.debug('result: ', result);
            resolve(result);
          }
        });
      });
    } else {
      const result = this.fn.evaluate(context, evalBindings);
      if (this.debug) console.debug('result: ', result);

      return result;
    }
  }

  evalAsync(data, path='', bindings=null){
    return this.eval(data, path, bindings, true);
  }
}


export function normalizeToExprObject(jnx){
  if (Array.isArray(jnx) || typeof jnx === 'string' || typeof jnx === 'number' || typeof jnx === 'boolean'){
    jnx = { expr: jnx, scope: DEFAULT_SCOPE };
  } else if (jnx === undefined) {
    return {scope: DEFAULT_SCOPE};
  }

  jnx.expr = Array.isArray(jnx.expr) ? jnx.expr.join('\n') : `${jnx.expr}`;

  return jnx;
}

export function parseJnxExpr(jnxExpr, defaultScope=DEFAULT_SCOPE){
  let jnx = jnxExpr;

  if (Array.isArray(jnx) || typeof jnx === 'string' || typeof jnx === 'number' || typeof jnx === 'boolean'){
    jnx = { expr: jnx, scope: defaultScope };
  }

  if (jnx.scope === undefined) {
    jnx.scope = defaultScope;
  }

  jnx.expr = Array.isArray(jnx.expr) ? jnx.expr.join('\n') : `${jnx.expr}`;

  return jnx;
}

export function embedJnx(expr, ...wrapperFns) {
  const jnxObj = normalizeToExprObject(expr);
  wrapperFns.forEach(wrapperFn => {
    jnxObj.expr = wrapperFn(jnxObj.expr);
  });

  return jnxObj;
}


export function useJnx(expression, options, fndeps){
  const {
    fn,
    functionBinds
  } = options && (typeof(options) === 'function') ? {fn : options} : (options || {});

  const stableFn = useMemo(() => fn, [...(fndeps || [])]);

  return useMemo(() => {
    try {
      const jnx = expression ? new Jnx(expression) : null;

      if (jnx) {
        jnx.bindFunctions(functionBinds);
      }

      return stableFn && jnx ? (...args) => stableFn(args, jnx) : jnx;
    } catch (e){
      console.error(e);

      return null;
    }
  }, [expression, stableFn, functionBinds]);
}

export function useMultipleJnx(expressions, options, fndeps) {
  const {
    fn,
    functionBinds
  } = options && (typeof (options) === 'function') ? { fn: options } : (options || {});

  const stableFn = useMemo(() => fn, [...(fndeps || [])]);

  if (!expressions)
    return null;

  return useMemo(() => {
    try {
      const result = {};
      Object.entries(expressions).forEach(([key, expression]) => {
        if (!key.toString().startsWith('expr:'))
          return;
        const jnx = expression ? new Jnx(expression) : null;

        if (jnx) {
          jnx.bindFunctions(functionBinds);
        }

        result[key.replace(/^expr:/, '')] = stableFn && jnx ? (...args) => stableFn(args, jnx) : jnx;
      });

      return result;
    } catch (e) {
      console.error(e);

      return null;
    }
  }, [expressions, stableFn, functionBinds]);
}

CUSTOM_PATHS.expr = {
  parse(mapKey){
    return { type: 'expr', expr: new Jnx(mapKey.expr) };
  },
  resolve(pv, pk, object, initialObject, context){
    return pv.expr.eval(object, '', {
      pv,
      pk,
      context,
      initialObject
    });
  }
};


export default Jnx;