import React from 'react';
import { Redirect, useParams, useHistory } from 'react-router';
import InvoiceItemApi from '../../../api/InvoiceItemApi';
import FormComponent from '../../../components/FormComponent';
import InternalLayout from '../../../components/layouts/InternalLayout';
import { FORM_UPDATE_SUBMIT_BUTTON } from '../../../constants';


export const makeItemSchema = ({
  title = 'Item'
} = {}) => ({
  ':classNames': 'smallThreeRows',
  title,
  'ui:field': 'HideObjectField',
  type: 'object',
  properties: {
    code: { title: 'Code', type: 'string' },
    description: { title: 'Description', type: 'string' },
    price: { title: 'Price', type: 'number' },
  },
  required: [
    'code',
    'description',
    'price',
  ]
});

export const EditItemForm = FormComponent({
  displayName: 'EditItem',
  withoutNavigation: true,
  schema: makeItemSchema(),
  uiSchema: {},
  parseProps: () => ({
    params: useParams(),
    history: useHistory(),
  }),
  loadData: ({ params: { id } }) => InvoiceItemApi.getItem({ id }),
  async onSubmit(
    { formData },
    {
      props: {
        params: { id },
      },
    }
  ) {
    await InvoiceItemApi.editItem({ id, ...formData });
  },
  submitButtons: {
    ...FORM_UPDATE_SUBMIT_BUTTON,
  },
  renderFormSubmitted: () => (
    <Redirect to="../system-settings/invoice" />
  )
});

export default function() {
  return (
    <InternalLayout>
      <EditItemForm />
    </InternalLayout>
  );
}

