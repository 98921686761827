import React, { useState, useEffect } from 'react';
import { useSortable, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDroppable } from '@dnd-kit/core';
import { SchemaNodeItem } from './SchemaNodeItem';

const SchemaNode = React.memo(
  ({ item = [], onFieldUpdate, onRemove }) => {
    const { id, title, type } = item;

    const [isExpanded, setIsExpanded] = useState(false);
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id,
      data: {
        label: title,
        type,
      },
    });

    const { setNodeRef: containerRef, isOver: isOverContainer } = useDroppable({
      id: `${id}-container`,
    });
    const [inputValue, setInputValue] = useState(title);

    useEffect(() => {
      setInputValue(title);
    }, [title]);

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      userSelect: 'none',
      WebkitUserSelect: 'none',
    };
    const hasChildren = type === 'object' || type === 'array';

    const handleExpand = e => {
      e.stopPropagation();
      setIsExpanded(!isExpanded);
    };

    if (!type) return null;

    return (
      <div className="d-flex flex-column w-100">
        <SchemaNodeItem
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          id={id}
          type={type}
          title={inputValue}
          isExpanded={isExpanded}
          hasChildren={hasChildren}
          isArrayItem={item.isArraySubitem}
          isRelation={item.isRelation}
          onExpand={e => handleExpand(e)}
          onTitleChange={setInputValue}
          onFieldUpdate={onFieldUpdate}
          onRemove={() => onRemove(id)}
        />
        {hasChildren && isExpanded && (
        // Handle nested schema elements
          <div
            ref={containerRef}
            className={`ml-4 mt-2 pl-4 d-flex flex-column border-left rounded border pb-5`}
            style={{ minHeight: '120px', backgroundColor: isOverContainer ? '#f5f5f5' : '' }}
            role="list"
          >
            <SortableContext items={item.properties ? item.properties : item.items} strategy={verticalListSortingStrategy}>
              {!(item.properties ? item.properties : item.items)?.length ? (
                <div className="text-center text-muted py-2">
                  <i className="fa fa-plus-circle mr-2" />
                  Arrastra elementos aquí
                </div>
              ) : (
                <>
                  {(item.properties ? item.properties : item.items).map(item => (
                    <SchemaNode key={item?.id} {...item} item={item} onFieldUpdate={onFieldUpdate} onRemove={onRemove} className="mb-2" />
                  ))}
                </>
              )}
            </SortableContext>
          </div>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    const subItemsEqual = (prev, next) => {
      if (!prev || !next) return prev === next;
      if (prev.length !== next.length) return false;

      return prev.every((prevItem, index) => {
        const nextItem = next[index];
        // Comprehensive null checks:
        if (!prevItem || !nextItem) return prevItem === nextItem;

        return (
          prevItem.id === nextItem.id &&
          prevItem.title === nextItem.title &&
          prevItem.type === nextItem.type &&
          subItemsEqual(prevItem.properties ? prevItem.properties : prevItem.items, nextItem.properties ? nextItem.properties : nextItem.items)
        );
      });
    };

    return (
      prevProps.id === nextProps.id &&
      prevProps.title === nextProps.title &&
      prevProps.type === nextProps.type &&
      subItemsEqual(prevProps.item?.properties ?? prevProps.item?.items, nextProps.item?.properties ?? nextProps.item?.items)
    );
  }
);

export default SchemaNode;
