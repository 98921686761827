import { useRef, useEffect } from 'react';

const useElementSizeCheckHook = (sizeCheckFn, interval) => {
  const ref = useRef();

  function checkFn(){
    if(ref.current){
      sizeCheckFn(ref.current);
    }
  }

  useEffect(() => {
    checkFn();
    const timer = setInterval(checkFn, interval || 1000);

    return () => {
      clearInterval(timer);
    };
  });

  return ref;
};

export default useElementSizeCheckHook;
