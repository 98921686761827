const appProps = {
  welcomeShown: false,
  sidebarTab: undefined,
  isAnonymous: undefined,
  tenant: undefined,
  sidebarExpanded: true,
  navbarContent: undefined,
  newReqDomElement: undefined,
  modalContainer: undefined,
};


export default appProps;