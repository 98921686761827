import React, { useState, useEffect } from 'react';
import { FormGroup } from 'reactstrap';
import InputMask from 'react-input-mask';
import { useDebouncedEffect } from '../../hooks/useDebounceEffect';

export default function PhoneInput({
  id,
  value,
  required,
  disabled,
  readonly,
  autofocus,
  onChange: onChangeForm,
}) {
  const [current, setCurrent] = useState(value);

  useDebouncedEffect(
    () => {
      if (current !== value) {
        onChangeForm(current);
      }
    },
    [current],
    450
  );

  useEffect(() => setCurrent(value), [value]);

  return (
    <FormGroup>
      <InputMask
        mask='(999)999-9999'
        disabled={disabled}
        className="form-control"
        value={current || ''}
        required={required}
        onChange={(e) => setCurrent(e.target.value)}
        readOnly={readonly}
        autoFocus={autofocus}
        id={id}
      />
    </FormGroup>
  );
}
