import { useMemo } from 'react';
import { useOpenModal } from '../../FormModal';
import CardsLayout from './layouts/CardsLayout';
import TableLayout from './layouts/TableLayout';
import TabsLayout from './layouts/TabsLayout';


function useTableLayoutType({
  $id,
  arrayType,
  tabNameExpr,
  hasAddBtnInRow,
  hasToolbar,
  headers,
  itemsAreObjectType,
  itemsUiSchema,
  onEmptyMessage,
  positionButtons,
  props,
  rowClassNames,
}) {
  const openModal = useOpenModal();

  return useMemo(() => {
    if (arrayType === 'cards') {
      return new CardsLayout({
        $id,
        arrayType,
        hasAddBtnInRow,
        hasToolbar,
        headers,
        itemsAreObjectType,
        itemsUiSchema,
        onEmptyMessage,
        positionButtons,
        props,
        rowClassNames,
      });
    } else if (arrayType === 'tabs') {
      return new TabsLayout({
        $id,
        hasToolbar,
        tabNameExpr,
        itemsAreObjectType,
        onEmptyMessage,
        positionButtons,
        rowClassNames,
        itemsUiSchema,
        openModal,
        props,
        tabs: props.items,
      });
    } else {
      return new TableLayout({
        $id,
        hasAddBtnInRow,
        hasToolbar,
        headers,
        itemsAreObjectType,
        itemsUiSchema,
        onEmptyMessage,
        positionButtons,
        props,
        rowClassNames,
      });
    }
  }, [arrayType, $id, itemsUiSchema, hasToolbar, itemsAreObjectType, headers, openModal, onEmptyMessage]);
}

export default useTableLayoutType;
