import React, { useMemo, useRef } from 'react';
import { FormGroup, Input } from 'reactstrap';
import getPathFromId from '../../util/getPathFromId';
import { useJnx } from '../../util/jnx';
import { useSideChannelSubscription } from '../../util/useSideChannel';


function BoolYesNoField(props) {
  const {
    formData,
    formContext: {
      setFormDataValues,
      sideChannel,
    },
    disabled,
    readonly: propReadonly,
    schema: { title },
    idSchema: { $id },
    uiSchema: {
      'akc:requiredIfVisible': requiredIfVisible,
      'ui:readonly': uiReadonly,
      'ui:onSelect': onSelectExpr,
    },
    required: requiredProp,
    onChange: propOnChange,
  } = props;

  const readonly = uiReadonly || propReadonly;
  const required = !!requiredProp || !!requiredIfVisible;

  const map = { true: 1, false: 0 };

  const dataPath = useMemo(() => getPathFromId($id), [$id]);
  const functionBinds = useMemo(() => ({ set: setFormDataValues }), [setFormDataValues]);
  const onSelectJnx = useJnx(onSelectExpr, { functionBinds });
  const rootFormData = useSideChannelSubscription(sideChannel, 0);
  const rootFormDataRef = useRef();
  rootFormDataRef.current = rootFormData;

  function onChange({ target: { value } }) {
    const boolValue = value === '1';
    propOnChange(boolValue);

    if (onSelectJnx) {
      setTimeout(() => {
        onSelectJnx.eval(rootFormDataRef.current, dataPath, {
          rootFormData: rootFormDataRef.current,
        });
      }, 500);
    }
  }

  return (
    <FormGroup disabled={readonly || disabled}>
      {title.trim() ? (
        <label className="control-label" htmlFor={$id}>
          {title}
          {required ? <span className="required">*</span> : null}
        </label>
      ) : null}
      <Input
        id={$id}
        type="select"
        disabled={readonly || disabled}
        required={required}
        value={formData in map ? map[formData] : 2}
        onChange={onChange}
      >
        <option value="2" disabled>---</option>
        <option value="1">Sí</option>
        <option value="0">No</option>
      </Input>
    </FormGroup>
  );
}


export default BoolYesNoField;
