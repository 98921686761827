import React, { createContext, useContext } from 'react';

export const ConfigurationContext = createContext(null);

export const useConfiguration = () => {
  return useContext(ConfigurationContext);
};

export const ConfigurationProvider = ({ children, configuration }) => {
  return (
    <ConfigurationContext.Provider value={configuration}>
      {children}
    </ConfigurationContext.Provider>
  );
};
