const env = process.env;

export const DEV_MODE = !!env.REACT_APP_DEV_MODE;
export const ENDPOINT = env.REACT_APP_API_ENDPOINT;
const endpointUrl = new URL(ENDPOINT)
export const BACKEND = `${endpointUrl.protocol}//${endpointUrl.host}`;
export const AD_CLIENT_ID =  env.REACT_APP_AD_CLIENT_ID;
export const AD_AUTHORITY = env.REACT_APP_AD_AUTHORITY;
export const SENTRY_DSN = (env.REACT_APP_SENTRY_DSN || '').trim();

if (DEV_MODE) {
  console.log('DEV_MODE');
}

export const WaitingForDocumentState = {
  AVAILABLE: 0,
  NOT_FOUND: 1,
  PROCESSING: 2,
  ERROR: 3,
};

export const LOAD_SEARCH_PARAMS =
  document?.location?.search
    ?.split('?')
    ?.pop()
    ?.split('&')
    ?.reduce((_, x) => {
      const [k, ...v] = x.split('=');
      _[k] = v.join('=');

      return _;
    }, {}) || {};

export const TIMEZONE_DEFAULT = '\'SA Western Standard Time\'';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'h:mm a';
export const DATETIME_FORMAT = 'DD/MM/YYYY h:mm A';
export const DEFAULT_TZ = 'America/Puerto_Rico'; // AST

export const FORM_SUBMIT_BUTTONS = {
  submit: { text: 'Someter', className: 'btn-primary' },
};

export const FORM_BUTTONS_GENERATE_DOCS = {
  generateDocs: {
    text: 'Generar Documentos',
    className: 'btn-secondary',
    onProcessSubmit(args) {
      (args.object || {}).onlySave = true;
      (args.object || {}).generateDocs = args.generateDocuments;
    },
    ignoreValidation: true,
  },
};

export const FORM_ONLY_SUBMIT_BUTTON = {
  submit: { text: 'Someter', className: 'btn-primary' },
};

export const FORM_UPDATE_SUBMIT_BUTTON = {
  submit: { text: 'Actualizar', className: 'btn-primary' },
};

export const NO_FORM = '!!!NO_FORM!!!';

export const DD_SENT_BACK_MAP = {
  RETURNED: { id: 2, title: 'Devuelto' },
  RETURNED_ADDITIONAL_REQUIREMENTS: { id: 6, title: 'Requerimiento de información adicional' },
};

export const SLA_COLORS = ['success', 'warning', 'danger', ''];

export const SLACOLOR = (lapsedTime, sla) => {
  sla = sla || [10, 20];

  if (lapsedTime < sla[0]) {
    return SLA_COLORS[0];
  } else if (lapsedTime < sla[1]) {
    return SLA_COLORS[1];
  }

  return SLA_COLORS[2];
};
