const faIcons = {
  description: 'FontAwesome Icons',
  image: {icon: 'fa fa-home'},
  listPromise: null,
  getList() {
    if (!faIcons.listPromise) {
      faIcons.listPromise = fetch('/icons/fa-icons.json').then(r => (
        r.json()
      )).then(list => {
        faIcons.items = list.map(ic => ({icon: `fa fa-${ic}`}));

        return faIcons.items;
      });
    }

    return faIcons.listPromise;
  }
};

export default faIcons;