function determineUniquePropName(name, object) {
  let candidate = name;
  let i=1;

  while (candidate in object) {
    candidate = `${name}_${i}`;
    i += 1;
  }

  return candidate;
}


export default determineUniquePropName;