import React, { useCallback, useMemo, useState } from 'react';
import { Tooltip } from 'reactstrap';

function Help({ children }) {
  // eslint-disable-next-line no-plusplus
  const id = useMemo(() => `help-${Help.__ct__++}`, []);
  const [isOpen, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen(!isOpen), [isOpen]);

  return (
    <>
      <i id={id} className="fa fa-info-circle" style={{ color: '#33ccff' }} />
      <Tooltip target={id} toggle={toggle} isOpen={isOpen}>
        {children}
      </Tooltip>
    </>
  );
}

Help.__ct__ = 0;

export default Help;
