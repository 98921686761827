import React, { useState, useContext, useEffect, useMemo } from 'react';
import {
  DropdownToggle, DropdownMenu, DropdownItem, Dropdown
} from 'reactstrap';

import { AuthContext } from '../../context/AuthContext';

import NotificationsApi from '../../api/NotificationsApi';
import usePolledResource from '../../util/usePolledResource';
import { DATETIME_FORMAT } from '../../constants';
import { toDrDateTime } from '../../util/dates';

function NavNotificationPanel() {
  const auth = useContext(AuthContext);
  const user = auth?.user?.user;

  const [isOpen, setOpen] = useState();

  const [notifications] = usePolledResource({
    resourceFn: () => NotificationsApi.list(),
    dependencies: [user],
    interval: 30000,
    initialValue: [],
  });
  const newNotifications = useMemo(() => notifications.reduce((_, { cleared }) => _ + (!cleared ? 1 : 0), 0), [notifications]);

  useEffect(() => {
    if (isOpen) {
      notifications.forEach(({ id, cleared }) => {
        if (!cleared) {
          NotificationsApi.markAsRead({ id });
        }
      });
    }
  }, [isOpen, notifications]);

  return (
    <Dropdown className="user-nav-dropdown" isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
      <DropdownToggle nav className="badged-icon nav-icon">
        <i className="fa fa-bell nav-icon" />
        {newNotifications ? <span className="icon-badge bg-danger">{newNotifications}</span> : null}
      </DropdownToggle>
      <DropdownMenu className="medium-panel">
        {notifications.length ? (
          (notifications || []).map(notification => <NotificationItem key={notification.id} notification={notification} />)
        ) : (
          <DropdownItem>No Notifications to show</DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

function NotificationItem(notification) {
  const {
    id,
    message,
    createdDate,
    cleared,
    link
  } = notification.notification;

  return (
    <DropdownItem tag="a" href={link} className="badged-icon">
      {!cleared ? <i className="large-red-badge fa fa-circle" /> : null}
      <p style={{ whiteSpace: 'normal' }}>{message}</p>
      <div>{toDrDateTime(createdDate).format(DATETIME_FORMAT)}</div>
    </DropdownItem>
  );
}

export default NavNotificationPanel;
