import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import DropZoneTargets from '../DropZoneTargets';


const NODE_TYPES = {
  object: {
    canHaveChildren: true,
    childSuffix: 'properties',
    getChildren(node) {
      return node?.properties;
    },
  },
  array: {
    hasSingularChild: true,
    childSuffix: 'items',
    getChild(node) {
      return node?.items;
    },
  },
  default: {},
};


function JsonSchemaTreeNode({
  fixedNode,
  node,
  path, selectedPath, setSelectedPath,
  addChild,
  onDeletePath,
  dndProps
}) {
  const name = useMemo(() => path.split('.').pop(), [path]);

  const [isInPath, isSelected] = useMemo(() => [
    `${selectedPath}.`.startsWith(`${path}.`),
    selectedPath === path,
  ], [selectedPath, path]);

  const [expanded, setExpanded] = useState(isInPath);
  useEffect(() => {
    if (isInPath && !expanded) setExpanded(true);
  }, [isInPath, expanded]);

  if (node.type === 'null') {
    node.type = 'string';
  }

  const {
    dragging,
    activeClass,
    dragStart,
    onZoneEnter,
    onZoneLeave,
    onZoneDrop,
  } = useDragAndDropForTreeItems({fixedNode, path, dndProps});

  const {
    childSuffix,
    canHaveChildren, getChildren,
    hasSingularChild,
  } = useMemo(() => NODE_TYPES[node.type] || NODE_TYPES.default, [node.type]);

  const children = getChildren ? Object.entries(getChildren(node) || {}) : null;
  const hasChildren = children?.length || hasSingularChild;

  const buttons = useMemo(() => isInPath ? [
    ...(canHaveChildren ? ([
      {title:'Add Child', icon:'fa fa-plus', onClick: () => addChild(path, childSuffix)},
    ]): []),
        fixedNode ? null : (
            {title:'Delete'   , icon:'fa fa-trash', onClick: () => onDeletePath(path)}
        ),
  ].filter(x => !!x) : [], [
    isSelected, isInPath,
    path, childSuffix,
    canHaveChildren,
    addChild, setSelectedPath, onDeletePath
  ]);

  const ref = useRef();

  useEffect(() => {
    if (isSelected) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }, [isSelected]);

  return (
    <li className={`tree-view ${activeClass || ''} ${isSelected ? 'selected' : ''}`}>
      <div
        className="title"
        onDragStart={dragStart}
        draggable={!fixedNode}
        tabIndex="0"
        ref={ref}
        role="treeitem"
        onClick={({ detail }) => {
          if ((canHaveChildren || hasChildren) && detail > 1) {
            setExpanded(!expanded);
          } else {
            setSelectedPath(path);
          }
        }}
      >
        <div className="title-label">
          <div>
            {hasChildren ? (
              <i
                className={`expand-icon text-center ${expanded ? 'fa fa-caret-down' : 'fa fa-caret-right'} `}
                role="button"
                tabIndex="-1"
                onClick={e => {
                  e.stopPropagation();
                  setExpanded(!expanded);
                }}
              />
            ) : null}
            <i className={`icon icon-json-${node.type}`} aria-hidden="true" />
            <span className="title-text">{name}</span>
          </div>
        </div>
        {buttons.length ? (
          <div className="buttons">
            {buttons.map(({ onClick, title, icon }, idx) => (
              <Button
                key={idx}
                color="clear"
                onClick={e => {
                  e.stopPropagation();
                  onClick();
                }}
                aria-label={title}
                title={title}
              >
                <i className={icon} />
              </Button>
            ))}
          </div>
        ) : null}
        {dragging ? <DropZoneTargets top bottom onZoneEnter={onZoneEnter} onZoneLeave={onZoneLeave} onZoneDrop={onZoneDrop} /> : null}
      </div>
      {expanded ? (
        <div className="children">
          {hasSingularChild ? (
            <ul key={childSuffix}>
              <JsonSchemaTreeNode
                fixedNode
                node={node[childSuffix]}
                path={`${path}.${childSuffix}`}
                selectedPath={selectedPath}
                setSelectedPath={setSelectedPath}
                addChild={addChild}
                onDeletePath={onDeletePath}
                dndProps={dndProps}
              />
            </ul>
          ) : null}
          {children ? ((children || []).map(([childName, childNode]) => (
            <ul key={childName}><JsonSchemaTreeNode
              node={childNode}
              path={`${path}.${childSuffix}.${childName}`}
              selectedPath={selectedPath}
              setSelectedPath={setSelectedPath}
              addChild={addChild}
              onDeletePath={onDeletePath}
              dndProps={dndProps}
            /></ul>
          ))) : null}
        </div>
      ) : null}
    </li>
  );
}


function useDragAndDropForTreeItems({
  fixedNode,
  path, dndProps: { dragItem, setDragItem, onDrop }
}){
  const [activeClass, setActiveClass] = useState();

  const dragStart = useCallback(
    e => {
      e.stopPropagation();
      e.dataTransfer.effectAllowed = 'move';
      setDragItem(path);
    },
    [path]
  );

  const onZoneEnter = useCallback((side) => setActiveClass(
    side === 'top' ? 'droptarget up': 'droptarget down'
  ), []);
  const onZoneLeave = useCallback(() => setActiveClass(), []);
  const onZoneDrop = useCallback(
    side =>
      onDrop({
        path,
        side: side === 'top' ? 'up' : 'down',
      }),
    [onDrop]
  );

  const dragging = useMemo(() => (
    dragItem && path && dragItem !== path.substring(0, dragItem.length)
  ), [dragItem, path]);
    

  return fixedNode ? {} : {
    dragging,
    activeClass: dragging && activeClass,
    dragStart, onZoneEnter, onZoneLeave, onZoneDrop
  };
}


export default JsonSchemaTreeNode;
