import React from 'react';

export const SchemaNodeItem = React.forwardRef(
  ({ title, type, id, isExpanded, onExpand, onTitleChange, onFieldUpdate, onRemove, hasChildren, isArrayItem, isRelation, ...props }, ref) => {
    const handleExpand = e => {
      onExpand(e);
    };

    return (
      <div className="d-flex align-items-center p-3" ref={ref} {...props}>
        <div className="w-75 d-flex align-items-center">
          <div className="cursor-move text-muted mr-3">
            <i className="fa fa-grip-vertical" />
          </div>
          {hasChildren && (
            <button
              type="button"
              className="btn btn-link px-3"
              onClick={handleExpand}
              onMouseDown={e => {
                // stop DND kit buble up kit
                e.stopPropagation();
              }}
              aria-expanded={isExpanded}
            >
              <i className={`fa fa-chevron-${isExpanded ? 'down' : 'right'}`} aria-hidden="true" />
            </button>
          )}
          {isArrayItem && !isRelation ? (
            <span className="mr-3">items</span>
          ) : (
            <input
              type="text"
              value={title}
              onChange={e => onTitleChange(e.target.value)}
              onBlur={() => onFieldUpdate(id, title)}
              onMouseDown={e => {
                // stop DND kit buble up kit
                e.stopPropagation();
              }}
              className="form-control mr-3"
            />
          )}

          <small className="text-muted ml-2 mr-5">({type})</small>
          <button
            type="button"
            className="btn btn-link px-4 ml-auto text-danger opacity-75"
            onClick={e => {
              e.stopPropagation();
              onRemove();
            }}
            onMouseDown={e => {
              // stop DND kit buble up kit
              e.stopPropagation();
            }}
            aria-label={`Eliminar ${title}`}
          >
            <i className="fa fa-times" aria-hidden="true" />
          </button>
        </div>
      </div>
    );
  }
);
