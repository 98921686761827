import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { AuthApi } from '../api/AuthApi';
import FormComponent from '../components/FormComponent';
import ExternalLayout from '../components/layouts/ExternalLayout';
import MessageView from '../components/MessageView';


const ForgotPassword = FormComponent({
  displayName: 'ForgotPassword',
  schema: {
    title: 'Contraseña olvidada',
    type: 'object',
    properties: {
      email: { title: 'Email', type: 'string' },
    },
    required: ['email'],
  },
  uiSchema: {
    email: { 'ui:widget': 'email' },
  },
  async onSubmit({ formData: { email } }) {
    await AuthApi.recoverPassword({ email });
  },
  renderFormChildren: () => (
    <Row>
      <Col>
        <Button color="primary" type="submit">
          Send Reset Link
        </Button>
      </Col>
    </Row>
  ),
  renderFormSubmitted: () => (
    <MessageView title="Contraseña olvidada">
      <div>Se ha enviado un email para restablecer su contraseña.</div>
      <br />
      <Link component={Button} color="primary" to="/">
        {' '}
        Regresar a Pantalla de Login
      </Link>
    </MessageView>
  ),
});

export default function() {
  return (
    <ExternalLayout>
      <ForgotPassword />
    </ExternalLayout>
  );
}

