import React from 'react';
import { getStraightPath } from '@xyflow/react';

function CustomConnectionLine(props) {
  const {
    fromNode: { width },
    fromX, 
    fromY, 
    toX, 
    toY, 
    connectionLineStyle
  } = props;

  const [edgePath] = getStraightPath({
    sourceX: fromX + (width/2),
    sourceY: fromY,
    targetX: toX,
    targetY: toY,
  });

  return (
    <g>
      <path style={connectionLineStyle} fill="none" d={edgePath} />
    </g>
  );
}

export default CustomConnectionLine;
