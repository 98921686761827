import { t } from '@lingui/macro';
import { getLabel } from '../../LanguageConstant';

export const STRUCTURE = [];


const CLASS_OPTIONS = {
  grid: [
    { value: 'grid-1-columns', label: 'One Colums' },
    { value: 'grid-2-columns', label: 'Two Colums' },
    { value: 'grid-3-columns', label: 'Three Colums' },
    { value: 'grid-4-columns', label: 'Four Columns' },
    { value: 'items-centered', label: 'Centered Items' },
  ],
  column: [
    { value: 'from-col-1-size-1', label: 'Column 1 Size 1' },
    { value: 'from-col-1-size-2', label: 'Column 1 Size 2' },
    { value: 'from-col-1-size-3', label: 'Column 1 Size 3' },
    { value: 'from-col-1-size-4', label: 'Column 1 Size 4' },
    { value: 'from-col-2-size-1', label: 'Column 2 Size 1' },
    { value: 'from-col-2-size-2', label: 'Column 2 Size 2' },
    { value: 'from-col-2-size-3', label: 'Column 2 Size 3' },
    { value: 'from-col-3-size-1', label: 'Column 3 Size 1' },
    { value: 'from-col-3-size-2', label: 'Column 3 Size 2' },
    { value: 'from-col-4-size-1', label: 'Column 4 Size 1' },
  ],
  row: [
    { value: 'from-row-1-size-1', label: 'Row 1 Size 1' },
    { value: 'from-row-2-size-1', label: 'Row 2 Size 1' },
    { value: 'from-row-3-size-1', label: 'Row 3 Size 1' },
    { value: 'from-row-4-size-1', label: 'Row 4 Size 1' },
    { value: 'from-row-1-size-2', label: 'Row 1 Size 2' },
    { value: 'from-row-2-size-2', label: 'Row 2 Size 2' },
    { value: 'from-row-3-size-2', label: 'Row 3 Size 2' },
    { value: 'from-row-1-size-3', label: 'Row 1 Size 3' },
    { value: 'from-row-2-size-3', label: 'Row 2 Size 3' },
    { value: 'from-row-1-size-4', label: 'Row 1 Size 4' },
  ],
  flex: [
    { value: 'flex-row', label: 'flex-row' },
  ],
  misc: [
    { value: 'no-title', label: 'no-title' },
    { value: 'bordered', label: 'bordered' },
  ]
};


const DEFAULT_PROPS = {
  'title': { order: -1, label: getLabel().title, type: 'string', class: 'col-lg-12',
    jnx: `(
            $scope.schemaChain[[-3..-2]].type = ["array", "object"]
            and $coalesce($scope.schemaChain[-3].\`ui:arrayType\`, 'table') = 'table'
        ) ? {
            "format": "textarea"
        }`
  },
  'structure': { order: 0, label: getLabel().type, type: 'select', options: STRUCTURE },
  'ui:field': { order: 2, label: 'Tipo de Campo', type: 'select', optionsFromScope: 'objectCustomFields', options: true },
  'map:field': { order: 3, label: 'Campo de Entidad', type: 'select', optionsFromScope: 'objectSchemaFields', options: true,
    showValueIfNotInOptions: true,
    valueTextFn: (value) => (typeof value === 'string' ? (
            value === '' ? '(base object)' : value
        ) : JSON.stringify(value)),
    showIf: { expr: '$not($scope.isArrayItems)' }
  },
  'jsonata': {
    order: 2.1,
    label: t`Jsonata Expression`,
    type: 'jnx',
    showIf: { expr: '`ui:field` = \'ComputedField\''}
  },
  ':classNames': { order: 4, label: getLabel().classNames, type: 'select', multiple: true, options: [
    ...CLASS_OPTIONS.column,
    ...CLASS_OPTIONS.row,
    ...CLASS_OPTIONS.misc,
  ] },
  'ui:cellClassName': { order: 4.1, type: 'select', label: t`Cell ClassName`, options: [
    { value: 'w-sm', label: 'Small Width' },
    { value: 'w-m', label: 'Medium Width' },
    { value: 'w-l', label: 'Large Width' },
  ], showIf: { expr: '$scope.schemaChain[[-3..-2]].type = ["array", "object"]' }},
  'ui:readonly': { order: 5, label: getLabel().uiReadonly, type: 'boolean' },
  'akc:requiredIfVisible': { order: 6, label: getLabel().akcRequiredIfVisible, type: 'boolean' },
};

export const PROPERTIES = {
  'Object': {
    ...DEFAULT_PROPS,
    ':classNames': { ...DEFAULT_PROPS[':classNames'], options: [
      ...CLASS_OPTIONS.grid,
      ...CLASS_OPTIONS.column,
      ...CLASS_OPTIONS.row,
      ...CLASS_OPTIONS.misc,
    ]},
    'ui:sectionType': { order: 2.5, label: getLabel().uiSectionType, type: 'select', options: [
      { value: null, label: getLabel().section },
      { value: 'subsection', label: getLabel().subsection },
      { value: 'subsubsection', label: getLabel().subsubsection },
      { value: 'label', label: getLabel().label },
    ] },
    'ui:newSection': { order: 7, label: getLabel().uiNewSection, type: 'boolean' },
    'ui:expandable': { order: 8, label: getLabel().uiExpandable, type: 'boolean' },
  },

  'Dropdown': {
    ...DEFAULT_PROPS,
    ':classNames': { ...DEFAULT_PROPS[':classNames'], options: [
      ...CLASS_OPTIONS.column,
      ...CLASS_OPTIONS.row,
    ] },
    'enum': { order: 4.1, label: getLabel().enum, type: 'array' },
  },

  'Radio': {
    ...DEFAULT_PROPS,
    'enum': { order: 4.1, label: getLabel().enum, type: 'array' },
  },

  'Button': {
    ...DEFAULT_PROPS,
    ':classNames': { ...DEFAULT_PROPS[':classNames'], options: [
      ...CLASS_OPTIONS.column,
      ...CLASS_OPTIONS.row,
    ] },
    'enum': { order: 4.1, label: getLabel().enum, type: 'array' },
    'ui:onClick' : { label: t`On Click`, type: 'jnx' },
    'ui:simulateLabel': { order: 4.2, label: getLabel().uiSimulateLabel, type: 'boolean' },
  },

  'Signature': {
    ...DEFAULT_PROPS,
    ':classNames': { ...DEFAULT_PROPS[':classNames'], options: [
      ...CLASS_OPTIONS.column,
      ...CLASS_OPTIONS.row,
      ...CLASS_OPTIONS.misc,
    ]},
  },

  'Text': {
    ...DEFAULT_PROPS,
    ':classNames': { ...DEFAULT_PROPS[':classNames'], options: [
      ...CLASS_OPTIONS.column,
      ...CLASS_OPTIONS.row,
    ] },
    'format': { order: 4.1, label: getLabel().format, class: 'col-lg-6', type: 'select', options: [
      { value: 'textarea', label: 'Area de Texto' },
      { value: 'currency-$', label: 'Moneda' },
      { value: 'date', label: 'Fecha' },
      { value: 'email', label: 'Email' },
      { value: 'phone', label: 'Phone' },
    ] },
    'mask': { order: 4.2, label: getLabel().mask, class: 'col-lg-6', type: 'select', options: [
      { value: '(999) 999-9999', label: '(999) 999-9999' },
      { value: '99(999) 999-9999', label: '99(999) 999-9999' },
      { value: '999-99-9999', label: '999-99-9999' },
    ] },
    'ui:placeHolder': { order: 4.3, label: getLabel().uiPlaceHolder, class: 'col-lg-12', type: 'string' },
    'minLength': { order: 4.4, label: getLabel().minLength, type: 'string', class: 'col-lg-3', isNumber: true },
    'maxLength': { order: 4.5, label: getLabel().maxLength, type: 'string', class: 'col-lg-3', isNumber: true },
    'ui:prefix': { order: 4.6, label: getLabel().uiPrefix, type: 'string', class: 'col-lg-3' },
    'ui:sufix' : { order: 4.7, label: getLabel().uiSufix, type: 'string', class: 'col-lg-3' },
    'lookup' :   { order: 4.8, label: getLabel().lookup, type: 'json', class: 'col-lg-12', showIf: { expr: '$.\'ui:field\' = \'LookupFormField\'' } },
  },

  'Number': {
    ...DEFAULT_PROPS,
    ':classNames': { ...DEFAULT_PROPS[':classNames'], options: [
      ...CLASS_OPTIONS.column,
      ...CLASS_OPTIONS.row,
    ] },
    'ui:placeHolder': { order: 4.3, label: getLabel().uiPlaceHolder, class: 'col-lg-12', type: 'string' },
    'minLength': { order: 4.4, label: getLabel().minLength, type: 'string', class: 'col-lg-3', isNumber: true },
    'maxLength': { order: 4.5, label: getLabel().maxLength, type: 'string', class: 'col-lg-3', isNumber: true },
    'ui:prefix': { order: 4.6, label: getLabel().uiPrefix, type: 'string', class: 'col-lg-3' },
    'ui:sufix' : { order: 4.7, label: getLabel().uiSufix, type: 'string', class: 'col-lg-3' },
    'lookup' :   { order: 4.8, label: getLabel().lookup, type: 'json', class: 'col-lg-12', showIf: { expr: '$.\'ui:field\' = \'LookupFormField\'' } },
  },

  'Boolean': {
    ...DEFAULT_PROPS,
    'ui:widget': {order: 2.1, label: 'Widget', type: 'string' },
    ':classNames': { ...DEFAULT_PROPS[':classNames'], options: [
      ...CLASS_OPTIONS.column,
      ...CLASS_OPTIONS.row,
    ] },
  },

  'Array': {
    ...DEFAULT_PROPS,
    ':classNames': { ...DEFAULT_PROPS[':classNames'], options: [
      ...CLASS_OPTIONS.grid,
      ...CLASS_OPTIONS.column,
      ...CLASS_OPTIONS.row,
      ...CLASS_OPTIONS.misc,
    ] },
    'ui:sectionType': { order: 2.5, label: getLabel().uiSectionType, type: 'select', options: [
      { value: null, label: getLabel().section },
      { value: 'subsection', label: getLabel().subsection },
      { value: 'subsubsection', label: getLabel().subsubsection },
      { value: 'label', label: getLabel().label },
    ] },
    'map:array': { order: 2, label: 'Arreglo de Entidad', type: 'select', optionsFromScope: 'objectSchemaArrayFields', options: true},
    'ui:hideTitle'         : { order: 4.1, label: getLabel().uiHideTitle, type: 'boolean' },
    'ui:hideHeader'         : { order: 4.11, label: getLabel().uiHideHeader, type: 'boolean' },
    'ui:showheaderOnEmpty' : { order: 4.2, label: getLabel().uiShowheaderOnEmpty, type: 'boolean' },
    'ui:addable'           : { order: 4.3, label: getLabel().uiAddable, type: 'boolean', toChild: 'items' },
    'ui:addButtonText'  : { order: 4.6, label: getLabel().uiAddableLabelButton, type: 'string', toChild: 'items',
      showIf: { expr: '$scope.arrayItemIsAddable' }
    },
    'ui:removable'         : { order: 4.4, label: getLabel().uiRemovable, type: 'boolean', toChild: 'items' },
    'ui:removableConfirm'  : { order: 4.5, label: getLabel().uiRemovableConfirm, type: 'boolean', toChild: 'items',
      showIf: { expr: '$scope.arrayItemIsRemovable' }
    },
    'ui:removable-label'  : { order: 4.6, label: getLabel().uiRemovableLabelButton, type: 'string', toChild: 'items',
      showIf: { expr: '$scope.arrayItemIsRemovable' }
    },
    'ui:removableConfirmText'  : { order: 4.7, label: getLabel().uiRemovableConfirmText, type: 'string', toChild: 'items',
      showIf: { expr: '$scope.arrayItemIsRemovable' }
    },
    'ui:orderable'         : { order: 4.8, label: getLabel().uiOrderable, type: 'boolean', toChild: 'items' },
    'ui:arrayType'         : { order: 4.85, label: getLabel().uiArrayType, type: 'select', options: [
      { value: 'table', label: 'Table' },
      { value: 'cards', label: 'Cards' },
      { value: 'tabs', label: 'Tabs' },
    ]},
    'ui:tabNameExpr'  : { order: 4.86, label: t`Tab Names`, type: 'jnx', toChild: 'items',
      showIf: { expr: '`ui:arrayType` = \'tabs\'' }
    },
    'ui:positionButtons': { order: 4.9, label: getLabel().uiPositionButtons, type: 'select', toChild: 'items', options: [
      { value: 'top', label: 'Top' },
      { value: 'top-outside', label: 'Top (Outside Card)' },
      { value: 'bottom', label: 'Bottom' },
      { value: 'right', label: 'Right' },
      { value: 'left', label: 'Left' }
    ] },
    'ui:readonlyRowIf': {
      order: 5.5,
      label: t`Read Only Row If`,
      toChild: 'items', 
      type: 'jnx',
    },    
  },

  'File': {
    ...DEFAULT_PROPS,
    ':classNames': { ...DEFAULT_PROPS[':classNames'], options: [
      ...CLASS_OPTIONS.column,
      ...CLASS_OPTIONS.row,
    ] },
  },

  'QrRead': {
    ...DEFAULT_PROPS,
  },
};


Object.keys(PROPERTIES).forEach(k => STRUCTURE.push({value: k, label: k}));
