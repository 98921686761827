import React from 'react';
import { FormGroup } from 'reactstrap';
import JsonComponent from '../FormBuilder/JsonComponent';
import JsonTreeEditor from '../JsonTreeEditor';
import Jnx, { parseJnxExpr } from '../../util/jnx';

const REQUIRED_IF_JNX = (jnx) => {
  if (jnx) {
    jnx = parseJnxExpr(jnx);
    jnx.expr = `($isTruthy(${jnx.expr}) and $isEmpty($value)) ? true : false`;

    return new Jnx(jnx);
  }

  return false;
};

function JsonField(props) {
  const {
    formData,
    idSchema: { $id },
    schema: {
      title,
      showTree,
    },
    uiSchema: {
      'akc:requiredIfVisible': akcRequiredIfVisible,
      'ui:readonly': readonly,
      'akc:requiredIf': requiredIfExpr,
    },
    disabled,
    required: requiredProp,
    onChange,
  } = props;

  const required = requiredProp || akcRequiredIfVisible || REQUIRED_IF_JNX(requiredIfExpr);

  return (
    <FormGroup disabled={readonly || disabled}>
      {title !== ' ' ? (
        <label className="control-label" htmlFor={$id}>
          {title}
          {required ? <span className="required">*</span> : null}
        </label>
      ) : null}
      {showTree ? (
        <JsonTreeEditor mode="object" value={formData} onChange={onChange} />
      ) : (
        <JsonComponent value={formData} onChange={onChange} height={300} />
      )}
    </FormGroup>
  );
}

export default JsonField;
