import ApiDefinition from "./ApiDefinition";


const AIApi = new ApiDefinition({
    generateWorkflow: {
        url: 'ai/generateWorkflow/',
        method: 'post',
        body: data => data
    },
    generateForm: {
        url: 'ai/generateForm/',
        method: 'post',
        body: data => data
    }
})

export default AIApi;