import React from 'react';


function TextDisplay({ element }) {
  const {
    label=''
  } = element;

  return (
    <div className='col-lg-6 align-self-sm-center label'>
      <NewlineText text={label} />
    </div>
  );
}


TextDisplay.rootSchema = {
  'properties': {
    'label': {
      'title': 'Label',
      'type': 'string',
      'map:field': 'label'
    }
  }
};


function NewlineText(props) {
  return props.text.split('\n').map((str, idx) => <p key={idx}>{str}</p>);
}

export default TextDisplay;
