import React, { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import useResourceLoader from '../../util/useResourceLoader';
import UWEEntityApi from '../../api/UWEEntityApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';

import RequestReloadContext from '../../context/RequestReloadContext';

import FormApi from '../../api/FormApi';
import AppContext from '../../context/AppContext';
import ExternalLayout from '../../components/layouts/ExternalLayout';
import InternalLayout from '../../components/layouts/InternalLayout';
import useTitle from '../../util/useTitle';
import UWEEntityUWEFormComponent from '../../blc/UWEEntityUWEFormComponent';


export function NewUweEntityView() {
  const { isAnonymous, tenant } = useContext(AppContext);

  const {
    entityType: slug,
  } = useParams();

  const [reloadCount, setReloadCount] = useState(0);
  const currentEntity = useMemo(() => ({}), []);

  const [form, loading, errorLoading] = useResourceLoader(async () => {
    const workflowInfo = (isAnonymous ? (
      await UWEEntityApi.getWorkflowInfoForSlugForAnonymous({ slug, tenant })
    ) : (
      await UWEEntityApi.getWorkflowInfoForSlug({ slug })
    ));

    const initialStatus = workflowInfo.steps?.filter(
      st => !!st.page && st.isInitial
    )[0];

    console.log('initialStatus', workflowInfo, initialStatus);

    const form = (isAnonymous ? (
      await FormApi.getByNameForAnonymous({ name: initialStatus?.page, slug, tenant })
    ) : (
      await FormApi.getByName({ name: initialStatus?.page })
    ));

    return form;
  }, [slug, isAnonymous]);

  useTitle((form && !loading) ? form.title : 'Loading Form');

  const rrcState = RequestReloadContext.useHook(() => {
    setReloadCount(reloadCount + 1);
  }, [reloadCount]);

  const [showDebugControls, setShowDebugControls] = useState();

  const Layout = isAnonymous ? ExternalLayout : InternalLayout;

  return (
    <Layout>
      <AppContext.Prop prop="sidebarTab" value="newreq" />
      <AppContext.SetSidebarTab value="vehicleRegistration" />
      {loading ? (
        <Loader fullscreen />
      ) : errorLoading ? (
        <Notification error={errorLoading} />
      ) : form ? (
        <div>
          <RequestReloadContext.Provider value={rrcState}>
            <button
              style={{
                position: 'absolute',
                top: 60,
                right: 0,
                opacity: '1%',
              }}
              onClick={() => setShowDebugControls(!showDebugControls)}
            >
              {showDebugControls ? 'hide' : 'show'} debug
            </button>
            <UWEEntityUWEFormComponent
              slug={slug}
              form={form}
              currentEntity={currentEntity}
            />
          </RequestReloadContext.Provider>
        </div>
      ) : null}
    </Layout>
  );
}

export default NewUweEntityView;

