import React from 'react';

function StaticIFrame(props) {
  const {
    schema: { url },
  } = props;

  return (
    <iframe title="staticIframe" src={url} height={530} width='100%' style={{ border: 0 }} />
  );
}

export default StaticIFrame;
