import { Trans, t } from '@lingui/macro';
import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import ObjectSchemaApi from '../../../api/ObjectSchemaApi';
import Loader from '../../../components/Loader';
import Notification from '../../../components/Notification';
import useResourceLoader from '../../../util/useResourceLoader';
import useLoader from '../../../util/useLoader';
import JsonTreeEditor from '../../../components/JsonTreeEditor';
import useSearchParams from '../../../util/useSearchParams';
import ObjectSchemaEditor from './ObjectSchemaEditor';

import JsonEditor from '../../../components/JsonEditor';
import Item from './Item';

const SHOW_TABS = {
  entityType: t`Entity Type`,
  tree: t`Tree`,
  json: t`Json`,
};

function EditObjectSchema({
  objectSchemaId,
  setObjectSchema: setObjectSchemaId,
}) {
  const [resetCt, setResetCt] = useState();
  const [
    objectSchema,
    loadingObjectSchema,
    errorLoadingObjectSchema,
    setObjectSchema,
  ] = useResourceLoader(
    () =>
      objectSchemaId === 'new'
        ? {
            name: t`New Entity Type`,
            schema: {},
            assignmentSlots: {},
            metadataObjectMap: {},
            roleMap: {},
          }
        : ObjectSchemaApi.get({ objectSchemaId }),
    [resetCt]
  );
  const [[show, setShow]] = useSearchParams({
    show: 'entityType',
  });

  const [loadingSetObjectSchema, errorLoadingSetObjectSchema, loadFn] = useLoader();
  const loading = loadingObjectSchema || loadingSetObjectSchema;
  const error = errorLoadingObjectSchema || errorLoadingSetObjectSchema;
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const [newSchema, setNewSchema] = useState();
  const schemaEditorRef = useRef();
  const updateSchema = newSchemaSection => {
    setNewSchema(currentSchema => ({
      ...currentSchema,
      ...newSchemaSection,
    }));
  };

  function save() {
    let updatedSchema = null;
    if (show === 'entityType' && schemaEditorRef.current) {
      const latestItems = schemaEditorRef.current.getItems();
      updatedSchema = Item.toSchema(latestItems);
    }

    return loadFn(async () => {
      let schema = { ...newSchema };
      if (show === 'entityType') {
        schema = { ...newSchema, schema: updatedSchema };
      }
      delete schema.__test__;
      if (objectSchemaId === 'new') {
        const saveOS = await ObjectSchemaApi.create(schema);
        setObjectSchema(saveOS);
        setObjectSchemaId(saveOS.id);
      } else {
        const savedOS = await ObjectSchemaApi.update(objectSchemaId, schema);
        setObjectSchema(savedOS);
      }
      if (show === 'entityType') {
        setNewSchema(prev => ({
          ...prev,
          schema: updatedSchema,
        }));
      }
    });
  }

  useEffect(() => {
    if (objectSchema !== undefined) setNewSchema(objectSchema);
  }, [objectSchema]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return loading ? (
    <Loader />
  ) : (
    <>
      {error ? (
        <Notification error={error} />
      ) : null}
      {objectSchema ? (
        <div>
          <h3>
            {objectSchemaId === 'new' ? (
              <>
                <Trans>Creating Entity Type: {objectSchema.name}</Trans>
              </>
            ) : (
              <>
                <Trans>Editing Entity Type: {objectSchema.name}</Trans>
              </>
            )}
          </h3>
          <UncontrolledDropdown>
            <DropdownToggle caret>{SHOW_TABS[show]}</DropdownToggle>
            <DropdownMenu>
              {Object.entries(SHOW_TABS).map(([key, title]) => (
                <DropdownItem key={key} onClick={() => setShow(key)}>
                  {title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
          {show === 'tree' ? (
            <JsonTreeEditor
              mode="object"
              value={newSchema}
              onChange={setNewSchema}
            />
          ) : null}
          {show === 'entityType' ? (
            <div>
              <ObjectSchemaEditor
                objectSchemaState={newSchema}
                onObjectSchemaChange={updateSchema}
                ref={schemaEditorRef}
              />
            </div>
          ) : null}
          {show === 'json' ? (
            <JsonEditor
              value={newSchema}
              onChange={setNewSchema}
              onSaveForm={save}
            />
          ) : null}
          <Button color="success" disabled={!newSchema} onClick={save}>
            <Trans>Save</Trans>
          </Button>
        </div>
      ) : null}
    </>
  );
}

export default EditObjectSchema;
