import FormComponent from '../FormComponent';


export const FormDetailsEditor = FormComponent({
  displayName: 'FormDetailsEditor',
  withoutNavigation: true,
  schema: {
    ':formClassName': 'sidebar-panel',
    type: 'object',
    properties: {
      name: {
        'map:field': 'name',
        title: 'Name',
        type: 'string',
      },
      objectSchemaId: {
        'map:field': 'objectSchemaId',
        'type': 'number',
        'title': 'Entity Type',
        'ui:field': 'LookupFormField',
        'lookup': {
          'resource': 'Entity Types',
          'api': 'api:manage/objectschemas',
          'options': { useCache: true },
          'jnx': 'data.items',
          'id': 'id',
          'label': 'name'
        }
      },
      title: {
        'map:field': 'title',
        type: 'string',
        title: 'Title'
      },
      options: {
        title: ' ',
        type: 'object',
        properties: {
          alignButtons: {
            type: 'string',
            'map:field': 'options.alignButtons',
            enum: [
              'left',
              'right'
            ],
            title: 'Align Submit Buttons'
          },
          submitButtons: {
            title: 'Submit Buttons',
            ':classNames': 'fieldset',
            type: 'object',
            'map:field': 'options.submitButtons',
            properties: {},
            additionalProperties: {
              type: 'object',
              title: ' ',
              ':classNames': 'separator',
              properties: {
                'text': { title: 'Text', type: 'string' },
                'order': { title: 'Order', type: 'number' },
                'className': { title: 'Class Name', type: 'string' },
                'ui:showIf': { title: 'showIf', type: 'string' },
                'setProps': {
                  title: 'Set Props', type: 'object',
                  'ui:asPropertiesTable': true,
                  properties: {},
                  additionalProperties: {
                    type: 'string',
                    title: 'value'
                  }
                },
                'confirm': { type: 'array', items: { type: 'string' } }
              }
            }
          }
        }
      },
      sidebarComponents: {
        title: 'Sidebar Components',
        type: 'array',
        'map:field': 'sidebarComponents',
        items: {
          'type': 'object',
          'properties': {},
          'ui:field': 'JsonField'
        }
      },
      'objectMap': {
        'type': 'array',
        'title': 'Object Map',
        'map:array': 'objectMap',
        'ui:field': 'JsonField',
        'items': {}
      }
    },
    required: [],
  },
  uiSchema: {},
  parseProps: ({ form, onChange }) => ({
    formDetails: form,
    onFormDetailsChange: onChange,
  }),
  loadData: ({ formDetails }) => formDetails,
  onChange({
    formData,
    parsedProps: { onFormDetailsChange }
  }) {
    onFormDetailsChange(formData);
  },
  async onSubmit({ formData }) {
    console.log('onSubmit :: ', formData);
  },
  submitButtons: {},
});

export default FormDetailsEditor;
