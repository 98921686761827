import { useReducer, useEffect } from 'react';
import useResourceLoader from './useResourceLoader';

/** Hook for loading a resource.
 * Loads a resource as an effect whenever the dependencies change.
 *
 * @param {Function} resourceFn - async function returning the resource
 * @param {Array} dependencies - List of dependencies. The function is called whenever an item changes in this list.
 * @param {Object} initialValue - Initial resource value
 *
 */
function usePolledResource({
  resourceFn,
  dependencies,
  initialValue,
  interval = 1000,
  enabled = true
}) {
  const [
    {poll, load},
    dispatch,
  ] = useReducer(pollReducer, {});


  dependencies = (dependencies || []);
  dependencies.push(load);

  const [resource, loading, error] = useResourceLoader(
    async () => {
      try {
        const result = await resourceFn();
        dispatch({ action: 'set-poll' });

        return result;
      } catch (e) {
        dispatch({ action: 'set-poll' });
        throw e;
      }
    },
    dependencies,
    initialValue
  );

  useEffect(() => {
    if (enabled) {
      const timeout = setTimeout(() => dispatch({ action: 'set-load' }), interval);

      return () => {
        clearTimeout(timeout);
      };
    }

    return null;
  }, [interval, poll, enabled]);

  return [resource, loading, error];
}

function pollReducer(state, { action }) {
  switch (action) {
    case 'set-poll':
      return { ...state, poll: (state.poll | 0) + 1 };
    case 'set-load':
      return { ...state, load: (state.load | 0) + 1 };
    default:
      return state;
  }
}

export default usePolledResource;
