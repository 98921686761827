import React from 'react';
import { Button } from 'reactstrap';


function RadioButtonsWidget(props) {
  const {
    options: { enumOptions, enumDisabled },
    value,
    schema: {
      btnClassName,
      btnCheckedClassName,
      color = 'secondary',
      checkedColor = 'success',
      enumColors = [],
      enumCheckedColors = [],
      enumClassNames = [],
      enumCheckedClassNames = [],
    },
    disabled,
    readonly,
    autofocus,
    onChange,
    id,
  } = props;

  return (
    <div className="field-radio-group radio-group-buttons" id={id}>
      {enumOptions.map((option, idx) => {
        const checked = option.value === value;
        const btnColor = checked ? (enumCheckedColors[idx] ?? checkedColor ?? enumColors[idx] ?? color) : (enumColors[idx] ?? color);
        const itemDisabled = enumDisabled && enumDisabled.indexOf(option.value) !== -1;
        const cls = [
          disabled || itemDisabled || readonly ? 'disabled' : '',
          (checked ? btnCheckedClassName : btnClassName ),
          (checked ? enumCheckedClassNames[idx] : enumClassNames[idx]),
          checked ? 'active' : ''
        ].filter(x => !!x).join(' ');

        return (
          <Button
            key={idx}
            color={btnColor}
            className={cls}
            onClick={() => onChange(option.value)}
            autoFocus={autofocus && idx === 0}
            disabled={disabled || itemDisabled || readonly}
          >
            {option.label}
          </Button>
        );
      })}
    </div>
  );
}

export default RadioButtonsWidget;
