import './scss/style.scss';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { I18nProvider } from '@lingui/react';
import i18n from './i18n';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import fetchApi from './util/fetchApi';
import { AuthProvider, getToken } from './context/AuthContext';

import { ENDPOINT, SENTRY_DSN } from './constants';
import { useZohoDeskWidget } from './util/useZohoDeskWidget';
/* import { Util } from 'reactstrap';

Util.setGlobalCssModule({
  btn: 'hyperspeed-btn',
}); */

fetchApi.configure({
  defaults: { baseurl: ENDPOINT },
  verbs: ['get', 'post', 'put', 'delete'],
  helpers: {
    api: (url, options, fetchApi) =>
      fetchApi(
        url,
        {
          auth: { type: 'Bearer', token: getToken() },
          json: true,
          ...options,
        },
        fetchApi
      ),
    external: (url, options, fetchApi) =>
      fetchApi(
        url,
        {
          json: true,
          baseUrl: undefined,
          ...options,
        },
        fetchApi
      ),
    lookup: (url, options, fetchApi) =>
      fetchApi(
        `form-lookup/${url}`,
        {
          auth: { type: 'Bearer', token: getToken() },
          json: true,
          useCache(url, options) {
            return options.method === 'get';
          },
          ...options,
        },
        fetchApi
      ),
  },
});

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
  });
}

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('app-root');

function MainApp() {
  useZohoDeskWidget();

  return (
    <I18nProvider i18n={i18n}>
      <BrowserRouter basename={baseUrl}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </I18nProvider>
  );
}

const root = createRoot(rootElement);
root.render(<MainApp />);

registerServiceWorker();
