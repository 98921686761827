import { PublicClientApplication } from '@azure/msal-browser';
import { AD_CLIENT_ID, AD_AUTHORITY } from './constants';

const msalConfig = {
  auth: {
    clientId: AD_CLIENT_ID,
    authority: AD_AUTHORITY,
    redirectUri: document.location.origin,
  },
};

export const msalInstance = AD_CLIENT_ID ? new PublicClientApplication(msalConfig) : null;
