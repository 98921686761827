import { useCallback, useContext, useMemo, useState } from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
  NavbarText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { checkAccess } from '../util/useAccessCheck';
import ElementIcon from './ElementIcon';


function TabPanel({
  noText,
  tabs,
  selected,
  setSelected,
  showContent = true,
  contentWrapper,
}) {
  const auth = useContext(AuthContext);
  contentWrapper = contentWrapper || (x => x);
  const SelectedTabComponent = useMemo(() => tabs && Object.entries(tabs).reduce(
    (_, [k, v]) => selected === k ? v.component : _,
    null
  ), [tabs, selected]);

  const permissions = ((auth || {}).user || {}).permissions || {};
  const manageAnything = permissions.ManageUsersInUnit || permissions.ManageOrganizationalUnits;

  return (
    <>
      <Nav tabs>
        {tabs &&
          Object.entries(tabs).map(([key, tabDef]) => {
            const { title, require, noTab } = tabDef;

            return noTab ? null : (
              <NavItem id={key} key={key} title={title} onClick={() => setSelected(key)}>
                {!require || checkAccess(auth, require[0], require[1]) ? (
                  <NavLink active={selected === key} title={title}>
                    <ElementIcon element={tabDef} />
                    {noText ? <NavTooltip id={key} title={title || key} /> : <NavTooltip id={key} title={title || key} />}
                  </NavLink>
                ) : null}
              </NavItem>
            );
          })}
          {(manageAnything || auth.user) ?
            <div className="separator"></div> 
          : null}
          <div className="additional-nav-items">
            {manageAnything ? (
              <NavbarText className="show-when-mobile">
                <NavLink tag={Link} to="/system-settings">
                  <i className="fa fa-cog nav-icon" />
                </NavLink>
              </NavbarText>
            ) : null}
            {auth.user ? (
              <NavItem className="show-when-mobile">
                <UncontrolledDropdown className="user-nav-dropdown">
                  <DropdownToggle caret nav>
                    <i className="fa fa-user nav-icon" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="user-info d-flex align-items-center" tag={Link} to="/profile">
                      <UserDataPane icon={auth?.user?.actualUser ? 'user-secret' : ''} user={auth.user} />
                    </DropdownItem>
                    {auth?.user?.actualUser ? (
                      <>
                        <hr className="dropdown-divider" />
                        <DropdownItem className="user-info d-flex align-items-center">
                          <UserDataPane icon="user" user={auth.user.actualUser} />
                        </DropdownItem>
                        <hr className="dropdown-divider" />
                        <DropdownItem className="d-flex align-items-center" tag={Link} to="/" onClick={() => auth.stopImpersonation()}>
                          Dejar de Impersonar
                        </DropdownItem>
                      </>
                    ) : null}
                    <hr className="dropdown-divider" />
                    <DropdownItem className="d-flex align-items-center" tag={Link} to="/" onClick={() => auth.logout()}>
                      Log Out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem>
            ) : null}
        </div>
      </Nav>
      {showContent ? (
        <TabContent activeTab={selected} className={SelectedTabComponent ? 'showing' : 'empty'}>
          {SelectedTabComponent ? (
            <TabPane tabId={selected} className={selected}>
              {contentWrapper(<SelectedTabComponent />)}
            </TabPane>
          ) : null}
        </TabContent>
      ) : null}
    </>
  );
}

function NavTooltip(props) {
  const { id, title } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <span className="nav-text show-when-mobile">{title}</span>
      <Tooltip
        isOpen={tooltipOpen}
        target={id}
        toggle={toggle}
        placement="right"
        className="hide-when-mobile"
      >
        {title}
      </Tooltip>
    </>
  );
}


TabPanel.Uncontrolled = function ({
  selected: defaultSelected,
  toggle = true,
  ...props
}) {
  const [selected, _setSelected] = useState(defaultSelected);
  const setSelected = useCallback((newSelected) => _setSelected(
        (!toggle || (newSelected !== selected)) ? newSelected : null
  ), [
    toggle, selected
  ]);

  return <TabPanel selected={selected} setSelected={setSelected} {...props} />;
};

function UserDataPane({ user, icon }) {
  const {
    firstName,
    lastName,
    organizationalUnit,
    accessProfile,
    roles,
  } = user?.user || {};

  return (<div>
    {icon ? <i className={`fa fa-${icon}`} /> : null}
    <div className="user">
      {` ${firstName || ''}  ${lastName || ''}`}
    </div>
    {organizationalUnit ? (
            <div className="organization">{organizationalUnit?.code}</div>
        ) : null}
    {accessProfile ? (
            <div className="role">{accessProfile?.displayName || '----'}</div>
        ) : null}
    {(roles || []).map((ur, index) => (
      <div key={index} className="role">{ur?.role?.name}</div>
    ))}
  </div>);
}


export default TabPanel;
