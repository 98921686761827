import { useCallback, useState } from 'react';
import { Button, InputGroupAddon } from 'reactstrap';


function CollapsableInputGroup({
  title,
  icon,
  color,
  shouldBeOpen,
  onOpen,
  onClose,
  children
}) {
  const [_open, setOpen] = useState();
  const open = _open || shouldBeOpen;
  const toggle = useCallback(() => {
    const willBeOpen = !open;
    setOpen(willBeOpen);
    if (onClose && !willBeOpen) onClose();
    if (onOpen && willBeOpen) onOpen();
  }, [setOpen, onClose, onOpen, open]);

  return <>
    <InputGroupAddon addonType="prepend">
      <Button color={color} outline={!open} title={title} onClick={toggle}>{icon ? <i className={icon} /> : title}</Button>
    </InputGroupAddon>
    {open ? children : null}
  </>;
}

export default CollapsableInputGroup;