import React, { useContext, useMemo } from 'react';
import {
  FormGroup,
  Button
} from 'reactstrap';
import { AuthContext } from '../../context/AuthContext';
import useEvalFunction from '../../util/useEvalFunction';
import { useSideChannelSubscription } from '../../util/useSideChannel';


function SendEmail(props) {
  const {
    disabled,
    formContext,
    schema: {
      title,
      subject: subjectText,
      body: bodyText,
      buttonText,
    },
    idSchema: { '$id': fieldId },
  } = props;
  const auth = useContext(AuthContext);

  const { clientEmail, sideChannel } = formContext;
  const formData = useSideChannelSubscription(sideChannel) || {};

  const subjectFn = useEvalFunction(
    ['formData', 'formContext'],
    subjectText
  );
  const bodyFn = useEvalFunction(
    ['formData', 'formContext'],
    bodyText
  );

  const [subject, body] = useMemo(() => [
    subjectFn,
    bodyFn,
  ].map(fn => {
    try {
      return fn(formData, formContext);
    } catch (e) {
      return `${e}`;
    }
  }), [subjectFn, bodyFn, formData, formContext]);

  const uri = useMemo(() => {
    const parts = [
      ['subject', subject],
      ['body', body],
    ].filter(
      part => !!part[1]
    ).map(
      ([key, value]) => `${key}=${encodeURIComponent(value).replace(/%20/g, ' ')}`
    ).join('&');

    return `mailto:${clientEmail}${parts.length ? `?${parts}` : ''}`;
  }, [clientEmail, subject, body]);

  if (!auth.user.user.role.permissions.includes('sendEmailToClient')) {
    return false;
  }

  return (
    <FormGroup>
      <label className="control-label" htmlFor={fieldId}>
        {title}
      </label>
      <Button disabled={disabled} color="success" id={fieldId} tag="a" href={uri} target="_blank">
        {buttonText}
      </Button>
    </FormGroup>
  );
}


export default SendEmail;
