import { useEffect } from 'react';
import { useConfiguration } from '../context/ConfigurationContext';

function useTitle(title) {
  const { APP_NAME } = useConfiguration();
  useEffect(() => {
    document.title = title ? `${title} | ${APP_NAME}` : APP_NAME;
  }, [title]);
}

export default useTitle;
