import parseUISchemaFromSchema from './parseUISchemaFromSchema';
import parseObjectMapFromSchema from './parseObjectMapFromSchema';
import transformJsonSchema from './transformJsonSchema';
import { invertMap } from './mapObject';


function parseFormDefinition(formDefinition) {
  if (!formDefinition) return null;

  const schema = transformJsonSchema(formDefinition.rootSchema || {
    type: 'object',
    'properties': formDefinition.schema || {},
    ...(formDefinition.schemaProps || {})
  });

  const uiSchema = (Object.keys(formDefinition.uiSchema || {}).length ?
        formDefinition.uiSchema :
        parseUISchemaFromSchema(schema, { 'ui:field': 'HideObjectField' })
  );

  const objectMap = parseObjectMapFromSchema(schema, formDefinition.objectMap, {
    removeNullMappedFields: true
  });

  return {
    entityType: formDefinition.objectSchema || { id: formDefinition.objectSchemaId },
    page: formDefinition.page ?? formDefinition.name,
    // buildFormContext,
    // secondaryClientForm,
    // generateDocuments,
    // renderFormSubmitted: propRenderFormSubmitted,
    // noSubmit,
    // canAddClients,
    // hasSaveProgressButton = true,
    commentFieldsMap: formDefinition.commentFieldsMap,
    // ...props
    displayName: formDefinition.page ?? formDefinition.name,
    title: formDefinition.title,
    schema,
    uiSchema,
    // commentFieldsMap: formDefinition.commentFieldsMap,
    objectMap,
    invObjectMap: objectMap ? invertMap(objectMap) : null,
    submitButtons: formDefinition.options?.submitButtons,
    alignButtons: formDefinition.options?.alignButtons
  };
}

export default parseFormDefinition;