import React, { useState, useMemo, useRef } from 'react';
import {
  FormGroup,
} from 'reactstrap';
import { getObject } from '../../util/mapObject';
import { useSideChannelSubscription } from '../../util/useSideChannel';
import xor from '../../util/xor';
import Jnx from '../../util/jnx';
import getPathFromId from '../../util/getPathFromId';
import { TitleField } from './ObjectFieldTemplate';


function HideObjectField(props) {
  const {
    formContext,
    idPrefix,
    idSeparator,
    registry,
    disabled,
    readonly,
    hideError,
    formData,
    onBlur,
    onFocus,
    onChange,
    uiSchema,
    errorSchema,
    idSchema,
    schema,
  } = props;

  const { sideChannel } = formContext;
  const { title, properties: childFields } = schema;

  const {
    fields: { SchemaField },
  } = registry;

  const { $id } = idSchema;

  const {
    'ui:className': className,
    'ui:newSection': uiNewSection,
    'ui:sectionType': uiSectionType,
    'ui:expandable': expandable,
    'ui:expandedDefault': expandedDefault = true,
  } = uiSchema;

  const [wasPropertyKeyModified, setWasPropertyKeyModified] = useState();

  const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}];

  const formDataRef = useRef();
  formDataRef.current = formData;

  function onKeyChange(oldValue, value, errorSchema) {
    if (oldValue === value) {
      return;
    }

    value = getAvailableKey(value, formData);
    const _formData = formDataRef.current || {};
    const newKeys = { [oldValue]: value };
    const renamed = Object.keys(_formData).reduce((_, key) => {
      _[newKeys[key] || key] = _formData[key];

      return _;
    }, {});

    setWasPropertyKeyModified(true);

    onChange(
      renamed,
      (errorSchema && props.errorSchema) &&
      { ...props.errorSchema, [value]: errorSchema }
    );
  }

  function onDropPropertyClick(key) {
    return (event) => {
      event.preventDefault();
      const copiedFormData = { ...formDataRef.current };
      delete copiedFormData[key];
      onChange(copiedFormData);
    };
  }

  function onPropertyChange(name, value, propErrorSchema) {
    const newFormData = { ...formDataRef.current };
    newFormData[name] = value;
    onChange(
      newFormData,
      (propErrorSchema && errorSchema) &&
      { ...errorSchema, [name]: propErrorSchema }
    );
  }

  const route = useMemo(() => getPathFromId($id), [$id]);

  const bindings = useMemo(
    () => ({
      formContext,
      formObject,
      rootFormData,
    }),
    [formContext, formObject, rootFormData]
  );

  const [visibleCount, hide] = useMemo(() => {

    const {
      showCount,
      nonEmptyCount,
      emptyChecks,
      aloneChecks,
    } = Object.keys(childFields).reduce((_, name) => {
      const childUISchema = uiSchema[name] || {};

      if (childUISchema['ui:showIf']) {
        const response = evalShowIf(rootFormData, route, childUISchema['ui:showIf'], bindings);

        if (response) {
          _.showCount += 1;
        } else {
          _.emptyChecks[name] = 1;
        }
      } else {
        _.showCount += 1;
      }

      return _;
    },
    { showCount: 0, nonEmptyCount: 0, emptyChecks: {}, aloneChecks: [] }
  );

    const isEmpty = (nonEmptyCount === 0);

    return [
      showCount,
      {
        ...emptyChecks,
        ...aloneChecks.reduce((_, [c, inverLogic]) => {
          _[c] = xor(isEmpty, inverLogic);

          return _;
        }, {}),
      },
    ];
  }, [childFields, rootFormData, uiSchema, bindings]);

  const [expanded, setExpanded] = useState(expandedDefault);

  function toggleExpand(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setExpanded(!expanded);
  }

  return visibleCount === 0 ? null : (
    <FormGroup className={className}>
      <TitleField
        id={$id}
        isSection={uiNewSection}
        sectionType={uiSectionType}
        title={title}
        bindings={bindings}
        expandable={expandable}
        expanded={expanded}
        toggleExpand={toggleExpand}
      />
      {expanded || !expandable ? (
        <div className="prop-fields">
          {Object.entries(childFields).map(([name, fieldDef]) =>
            hide[name] ? null : (
              <SchemaField
                key={name}
                name={name}
                required={isRequired(name, schema, hide[name], uiSchema)}
                schema={fieldDef}
                uiSchema={uiSchema[name]}
                errorSchema={errorSchema[name]}
                idSchema={idSchema[name]}
                idPrefix={idPrefix}
                formContext={formContext}
                idSeparator={idSeparator}
                formData={(formData || {})[name]}
                wasPropertyKeyModified={wasPropertyKeyModified}
                onKeyChange={(value, errorSchema) => onKeyChange(name, value, errorSchema)}
                onChange={(value, errorSchema) => onPropertyChange(name, value, errorSchema)}
                onBlur={onBlur}
                onFocus={onFocus}
                registry={registry}
                disabled={disabled}
                readonly={readonly}
                hideError={hideError}
                onDropPropertyClick={onDropPropertyClick}
              />
            )
          )}
        </div>
      ) : null}
    </FormGroup>
  );
}

export function compileShowIf(objectShowIf) {
  return objectShowIf instanceof Jnx ? objectShowIf : new Jnx(objectShowIf);
}

export function evalShowIf(formData, route, objectShowIf, bindings) {
  try {
    return compileShowIf(objectShowIf).eval(formData, route, bindings);
  } catch {
    return false;
  }
}

export function getAvailableKey(preferredKey, formData) {
  let index = 0;
  let newKey = preferredKey;

  while (Object.prototype.hasOwnProperty.call(formData, newKey)) {
    index += 1;
    newKey = ''.concat(preferredKey, '-').concat(index);
  }

  return newKey;
}

export function isRequired(name, schema, hideField, uiSchema) {
  const childUISchema = uiSchema[name] || {};

  return (Array.isArray(schema.required) && schema.required.indexOf(name) !== -1 && !hideField) || (childUISchema['akc:required'] && !hideField);
}

export default HideObjectField;
