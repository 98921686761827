import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NodeShape(props) {
  const {
    className,
    shape,
    icon,
    width,
    height,
    pad = 10,
    selected,
    isDragging
  } = props;

  const bgColor = '#ffff'

  const {
    buildProps: propsFn = (x => x),
    Component: ShapeComponent
  } = SHAPE_TYPES[shape] ?? {};

  const shapeProps = useMemo(() => propsFn({
    top: -2, left: -2,
    width: width / 2,
    height: height / 2,
    svgWidth: width / 2 + 10,
    svgHeight: height / 2 + 15,
    shape: shape,
    bgColor: bgColor,
    icon: icon,
    pad: pad,
    selected: selected,
  }), [propsFn, width, height, shape, bgColor, icon, pad, selected]);

  return (
    <>
      {hasIcon(shape) && icon && 
        <FontAwesomeIcon 
          style={{
            position: 'absolute', 
            left: -20, 
            top: -20, 
            width: 30, 
            height: 30
          }}  
          icon={icon}
        />
      }
      <svg 
        version="1.1"
        width={shapeProps.svgWidth}
        height={shapeProps.svgHeight}
        style={{
          position: 'absolute',
          top: shapeProps.top,
          left: shapeProps.left,
          zIndex: -1
        }}
        fill="transparent"
      >
        <g 
          className={className}
          transform="translate(2, 2)"
          filter={isDragging ? 'url(#dropShadow)' : undefined}
        >
          {
            width && height && (
              <ShapeComponent {...shapeProps} />
            )
          }
        </g>
      </svg>
    </>
  );
}


const hasIcon = (shape) => shape === 'rect' || shape === 'rect-bars';

const SHAPE_TYPES = {
  'rect': {
    Component: ({ width, height, selected, bgColor }) => (
      <rect className="shape" fill={bgColor} width={width} height={height} stroke="black" strokeWidth={selected ? 3 : undefined} />
    )
  },
  'diamond': {
    buildProps: (props) => ({
      ...props,
      top: props.top - props.height * .5,
      left: props.left - props.pad,
      svgHeight: 3 * props.height + 3,
      svgWidth: props.width + 2 * props.pad + 3,
      squish: .7,
    }),
    Component: ({ width, height, pad, selected, squish, bgColor }) => {
      const x1 = width / 2 + pad;
      const x2 = width + pad * 2;
      const y1 = height * 3 / 2 * squish;
      const y2 = 3 * height * squish;

      return (
        <path
          className="shape"
          stroke="black"
          fill={bgColor}
          strokeWidth={selected ? 3 : undefined}
          d={`M 0 ${y1}  L ${x1} 0  L ${x2} ${y1}  L ${x1} ${y2}  Z`}
        />
      )
    }
  },
  'rect-bars': {
    buildProps: (props) => ({
      ...props,
      left: props.left - props.pad,
      svgWidth: props.width * (1 + 1 / 4) + 3,
    }),
    Component: ({ width, height, selected, bgColor }) => {
      const [w1, w2] = [width / 8, width];
      const [x1, x2] = [w1, w1 + w2];

      return (
        <g>
          <rect className="squaredCorners" fill={bgColor} width={w1} height={height} stroke="black" strokeWidth={selected ? 3 : undefined} />
          <rect className="squaredCorners" fill={bgColor} width={w2} height={height} stroke="black" strokeWidth={selected ? 3 : undefined} x={`${x1}`} />
          <rect className="squaredCorners" fill={bgColor} width={w1} height={height} stroke="black" strokeWidth={selected ? 3 : undefined} x={`${x2}`} />
        </g>
      )
    }
  },
  'document': {
    buildProps: (props) => ({
      ...props,
      left: props.left - props.pad,
      svgWidth: props.width * (1 + 1 / 4) + 3,
    }),
    Component: ({ width, height, selected, pad, bgColor }) => {
      const w = width + pad;
      const [x0, x1, x2, x3, x4] = [0, w * 1 / 4, w * 2 / 4, w * 3 / 4, w];
      const [y0, y1, y2, y3] = [0, height * 0.6, height * 1.4, height];

      return (
        <path
          className="shape"
          fill={bgColor}
          stroke="black" 
          strokeWidth={selected ? 3 : undefined}
          d={`M ${x0} ${y0} L ${x4} ${y0} L ${x4} ${y3} Q ${x3} ${y1} ${x2} ${y3} Q ${x1} ${y2} 0 ${y3} Z`}
        />
      )
    }
  },
  'oval': {
    Component: ({ 
      width, height, selected }) => {
      width *= .95;
      height *= 2;

      return (
        <path
          className="shape"
          stroke="black"
          strokeWidth={selected ? 3 : undefined}
          d={`M ${width*.26},0 
                      C ${width*.11},0 0,${height*.11} 0,${height*.26} 
                      c 0,${height*.15} ${width*.11},${height*.26} ${width*.26},${height*.26} 
                      l ${width*.53},0 
                      c ${width*.15},0 ${width*.26},-${height*.11} ${width*.26},-${height*.26} 
                      C ${width*1.05},${height*.11} ${width*0.95},0 ${width*.8},0 
                      L ${width*.26},0 z`}
        />
      );
    }
  },
  'default': {
    Component: ({ width, height, selected }) => (
      <rect className="shape" width={width} height={height} stroke="black" strokeWidth={selected ? 3 : undefined}/>
    )
  }, 
  'circle': {
    Component: ({ width, height, selected }) => (
      <circle className="shape" cx={width / 2} cy={height / 2} r={width / 2} stroke="black" strokeWidth={selected ? 3 : undefined}/>
    )
  },
  'message': {
    Component: ({ width, height, selected }) => (
      <path
        className="shape"
        stroke="black"
        strokeWidth={selected ? 3 : undefined}
        d={`M 0 0 L ${width} 0 L ${width} ${height} L ${width - width*0.4} ${height} L ${width - width*0.6} ${height + height*0.4} L ${width - width*0.6} ${height} L 0 ${height} Z`}
      />
    )
  },
  'page': {
    Component: ({ width, height, selected }) => (
      <path
        className="shape"
        stroke="black"
        strokeWidth={selected ? 3 : undefined}
        d={`M 10 0 L ${width+width*0.05} 0 L ${width+width*0.05} ${height} L ${width*0.9 - width} ${height} L ${width*0.9 - width} ${height - height*0.6} Z`}
      />
    )
  },
  'hexagon': {
    Component: ({ width, height, selected }) => (
      <path
        className="shape"
        stroke="black"
        strokeWidth={selected ? 3 : undefined}
        d={`M 0 0 L ${width+width*0.05} 0 L ${width+width*0.25} ${height/2} L ${width+width*0.05} ${height} L 0 ${height} L ${width*0.75-width} ${height/2} Z`}
      />
    )
  },
};

export default NodeShape;