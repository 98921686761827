import React, { useContext, useMemo } from 'react';
import { Button } from 'reactstrap';
import { Redirect } from 'react-router';
import { AuthContext } from '../context/AuthContext';
import FormComponent from '../components/FormComponent';
import ExternalLayout from '../components/layouts/ExternalLayout';
import { useConfiguration } from '../context/ConfigurationContext';
import MSALLoginForm from './MSALLoginForm';
import useSearchParams from '../util/useSearchParams';


const LoginForm = FormComponent({
  displayName: 'Login',
  schema: {
    title: 'Login',
    type: 'object',
    properties: {
      email: { title: 'Email', type: 'string' },
      password: { title: 'Password', type: 'string' },
    },
    required: ['email', 'password'],
  },
  uiSchema: {
    password: { 'ui:widget': 'password' },
  },

  parseProps: () => {
    const auth = useContext(AuthContext);

    return {
      auth,
    };
  },

  async onSubmit({
    formData: { email, password }
  }, {
    props: { auth }
  }) {
      await auth.login({ email, password });
  },
  renderFormChildren: ({ history }) => {
    const configuration = useConfiguration();

    function forgotPassword() {
      history.push('/forgot-password');
    }

    function signUp() {
      history.push('/anonymous/1/workflow/onboarding/new');
    }

    return (
      <>
        <div>
          <Button color="primary" type="submit">
            Acceder
          </Button>
          <Button color="link" onClick={forgotPassword} size="sm">
            Olvidé mi contraseña
          </Button>
        </div>
        <hr />
        <div className="text-center">
          <h5 className="text-bold">¿Necesitas crear una cuenta?</h5>
          {configuration.ENABLE_REGISTRATION_WORKFLOW ? (
            <Button color="primary" onClick={signUp} block>Registrarme</Button> 
          ) : (
            <p className="text-secondary">Ponte en contacto con tu administrador de sistemas</p>
          )}
        </div>
      </>
    );
  },
  renderFormSubmitted: () => {
    return <Redirect path="/" />;
  },
  withoutNavigation: true,
});


const LOGIN_FORMS = [
  {
    name: "SSO",
    component: MSALLoginForm,
    condition: ({ AD_CLIENT_ID }) => !!AD_CLIENT_ID,
  },
  {
    name: "Basic Login",
    component: LoginForm,
    condition: () => true,
  },
]

const Login = ({ history }) => {
  const { AD_CLIENT_ID } = useConfiguration()
  const forms = useMemo(() => (
    LOGIN_FORMS
    .filter(x => x.condition({ AD_CLIENT_ID }))
  ), [AD_CLIENT_ID]);

  const [[_formIdx, setFormIdx]] = useSearchParams({f: '0'});

  const formIdx = _formIdx | 0;
  const nextFormIdx = (formIdx + 1) % forms.length;
  const {
    name: formName,
    component: FormLoginComponent,
  } = forms[formIdx];
  const nextFormName = forms[nextFormIdx].name

  return (
    <ExternalLayout>
      <div className="col col-md-6 offset-md-3 div-login">
        {/* <LoginForm history={history} /> */}
        <FormLoginComponent history={history} />
        {formName !== nextFormName  && (
          <div style={{textAlign: 'center'}}>
            Cannot use {formName}? Try
            <Button
              color="secondary"
              onClick={() => setFormIdx(nextFormIdx)}
              style={{marginLeft: "1em"}}
            >{nextFormName}</Button>
          </div>
        )}
      </div>
    </ExternalLayout>
  );
};

export default Login;
