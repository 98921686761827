import { memo, useRef, useEffect } from 'react';
import cc from 'classcat';
import { shallow } from 'zustand/shallow';

import { useStore } from '@xyflow/react';

import useTextWrapping from '../../../../components/SVGDrawer/useTextWrapping';

const selector = (s) => ({ transform: s.transform, patternId: `pattern-${s.rfId}` });

const GAP_PAD = 10;

function Background(props) {
  const {
    id,
    gap = 150,
    size,
    offset = 2,
    color,
    roles,
    layout,
    height,
    dispatchAction,
    style,
    className,
  } = props;

  const ref = useRef(null);
  const { transform, patternId } = useStore(selector, shallow);

  const _patternId = `${patternId}${id ? id : ''}`;

  return (
    <svg
      className={cc(['workflow react-flow__background', className])}
      style={{
        ...style,
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
      }}
      ref={ref}
      data-testid="rf__background"
    >
      <g>
        {
          roles.map((role, idx) => (
            <RoleSVG 
              key={idx}
              idx={idx} 
              transform={transform}
              role={role} 
              layout={layout}
              dispatchAction={dispatchAction}
            />
          ))
        }
      </g>
      <rect x="0" y="0" width="100%" height="100%" fill={`url(#${_patternId})`} />
    </svg>
  );
}

Background.displayName = 'Background';

// Copied over from SwimmingLane.js
function RoleSVG({idx, transform, role, layout, dispatchAction}) {
  const ref = useRef();

  const scale = transform[2];

  // const x = -layout.swimmingLane.gap;
  const y = idx * layout.swimmingLane.height * scale;
  const gap = layout.swimmingLane.gap * scale;
  const width = layout.swimmingLane.width * scale;
  const height = layout.swimmingLane.height * scale;
  const minLines = layout.swimmingLane.maxLines;

  const lines = useTextWrapping({ content: role, ref, maxWidth: height * .83 });
  const _lineCount = lines?.length || 1;
  useEffect(() => {
    if (_lineCount) {
      dispatchAction.setSwimmingLaneLineCount({idx, lineCount: _lineCount});
    }
  }, [_lineCount]);

  const lineCount = minLines > _lineCount ? minLines : _lineCount;
  const gap_pad = GAP_PAD + (1 - _lineCount / lineCount) * gap / 2;

  return (
    <g>
      <g 
        transform={`translate(${transform[0]}, ${(y | 0) + transform[1]})`}
      >
        <rect 
          width={`${width - gap}px`} 
          height={`${height}px`}
          stroke="#000" 
          fillOpacity={0}
        />
      </g>
      <g 
        transform={`translate(${transform[0]}, ${(y | 0) + transform[1]})`} 
      >
        <rect 
          width={`${lineCount * gap}px`} 
          height={`${height}px`}
          stroke="#000" fill="#fff"
        />
        <g transform={`translate(${gap_pad}, ${(height / 2) | 0})`}>
          <text 
            ref={ref} 
            y=".9em" 
            alignmentBaseline="top" 
            textAnchor="middle" 
            transform={`scale(${scale}) rotate(-90)`}
          >
            {
              (lines || []).map((line, idx) => (
                <tspan 
                  key={idx} 
                  x="0" 
                  dy={idx ? '1.2em' : ''}
                >
                  {line}
                </tspan>
              ))
            }
          </text>
        </g>
      </g>
    </g>
  );
}

export default memo(Background);