import roleNameMatches from './roleNameMatches';


function canViewStatus(status, auth){
  const {
    user: {
      user: { roles },
      permissions: {OverrideWorkflow} = {},
    },
    isAdmin
  } = auth;

  return isAdmin || roles.some(({role: {name}}) => roleNameMatches(status.role, name)) || OverrideWorkflow;
}


export default canViewStatus;