import fetchApi from '../util/fetchApi';
import { getObject } from '../util/mapObject';
import checkErrors from './checkErrors';


class ApiDefinition {
  constructor(apiDef){
    Object.entries(apiDef).forEach(([apiName, definition]) => {
      this[apiName] = async function apiFunction(data) {
        const {
          checkErrors:optionsCheckErrors=true,
          method:_method,
          module='api',
          body,
          query,
          dataPath='data',
        } = definition;
        let { url } = definition;
        if (typeof url === 'function') url = url(data);
        const method = _method || (body ? 'post' : 'get');

        const options = {};

        if (method !== 'get' && body) options.data = body(data); 
        if (query) options.query = query(data); 

        let response = await fetchApi[module][method](url, options);
        if (optionsCheckErrors) response = await checkErrors(response);
        if (dataPath) response = getObject(response, dataPath);

        return response;
      };
    });
  }
}


export default ApiDefinition;
