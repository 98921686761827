import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';


function MessageView({
  title,
  children
}){
  return (<Card className="message-view">
    <CardHeader>
      {title}
    </CardHeader>
    <CardBody>
      {children}
    </CardBody>
  </Card>);
}


export default MessageView;
