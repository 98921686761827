import React from 'react';
import { Spinner } from 'reactstrap';


function Loader({ fullscreen, sm, centered, children }) {
  let component = <Spinner animation="border" size={sm ? 'sm' : undefined} />;

  if (children || centered) {
    component = (
      <div className={`loader-container ${sm ? 'sm' : ''} ${centered ? 'centered' : ''}`}>
        {component}
        <div className="children">{children}</div>
      </div>
    );
  }

  if (fullscreen) {
    return (
      <div className="loader fullscreen">
        <div>{component}</div>
      </div>
    );
  } else {
    return component;
  }
}

export default Loader;
