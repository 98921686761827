import dayjs from 'dayjs';
import { DATETIME_FORMAT, DATE_FORMAT } from '../../constants';
// import leftPad from "../leftPad";

class DateFormatter{
  static tryParse(format) {
    const m = /^date(-only)?$/.exec(format);

    return m ? new DateFormatter(!!m[1]) : null;
  }

  constructor(onlyDate) {
    this.onlyDate = onlyDate;
  }

  apply(value) {
    if (!value) return '';
    value = dayjs.utc(value).local().format(this.onlyDate ? DATE_FORMAT : DATETIME_FORMAT);

    return value;
  }

  parse(value) {
    const m = /date\((.+)\)/.exec(value);

    return m ? m[1]: 0;
  }

}


export default DateFormatter;