import ValueCell from './ValueCell';


function ComparisonTableRow({
  field = undefined,
  fieldProps = [],
  formIds = [],
  unrolledForms = {},
  collapsed,
  toggleFieldCollapsed,
}) {
  return (<>
    <tr className="field-row"><td colSpan={1 + formIds.length} onClick={() => toggleFieldCollapsed(field)}>
      <i className={`fa ${collapsed ? 'fa-chevron-right' : 'fa-chevron-down'}`} />
      {field}
    </td></tr>
    {collapsed ? null : (fieldProps.map(fieldProp => (<tr key={fieldProp}>
      <td className="field-prop-cell">{fieldProp}</td>
      {formIds.map(formId => <ValueCell
        key={formId}
        unrolledForms={unrolledForms}
        formId={formId}
        field={field}
        fieldProp={fieldProp}
      />)}
    </tr>)))}
  </>);
}

export default ComparisonTableRow;