import { useMemo, useState } from 'react';
import { useJnx } from '../../../util/jnx';
import { concatenatePaths, getObject } from '../../../util/mapObject';
import { useSideChannelSubscription } from '../../../util/useSideChannel';
import getPathFromId from '../../../util/getPathFromId';

function IconCheckFieldHeader(props){
  const {
    field,
    formData,
    formContext: {
      setFormDataValues,
      sideChannel
    },
    schema: {
      items: itemsSchema
    },
    idSchema: {$id: $arrayId},
    uiSchema: {
      items: itemsUiSchema = {}
    } = {}
  } = props;

  const rootFormData = useSideChannelSubscription(sideChannel, 0) || {};

  const {
    'ui:readonlyRowIf': readonlyRowIf
  } = itemsUiSchema;

  const {
    'ui:columnHeaderProps': columnHeaderProps
  } = itemsUiSchema[field] || {};
  const {
    icon,
    iconUncheck
  } = columnHeaderProps || itemsSchema.properties[field] || {};

  const {
    'ui:checkBoxClassName': className,
    'ui:readonly': readonly,
    'ui:path': uiValuePath,
    'ui:dontToggle': dontToggle,
    'ui:exclusiveCheckgroup': exclusiveCheckgroup = []
  } = columnHeaderProps || itemsUiSchema[field] || {};

  const readonlyRowIfJnx = useJnx(readonlyRowIf);

  const arrayPath = useMemo(() => getPathFromId($arrayId), [$arrayId]);
  const $headerId = useMemo(() => `${$arrayId}_${field}_header`, []);
  const valuePath = useMemo(() => uiValuePath ? concatenatePaths(arrayPath, uiValuePath) : undefined, [uiValuePath]);
  const value = useMemo(() => valuePath ? getObject(rootFormData, valuePath) : undefined, [rootFormData, valuePath]);
  const [checkFlag, setCheckFlag] = useState(false);

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let newValues = null;

    if (valuePath) {
      if (dontToggle) {
        newValues = {[valuePath]: true};
      } else {
        newValues = {[valuePath]: !value};
      }
    } else {
      newValues = (formData || []).reduce((_, item, index) => {
        const isReadonly = readonlyRowIfJnx && readonlyRowIfJnx.eval(item, '', { $root: rootFormData });
        console.log('isReadonly', [index, readonlyRowIfJnx, isReadonly]);

        if (!isReadonly) {
          const fieldPath = `${arrayPath}.${index}.${field}`;

          _[fieldPath] = !checkFlag;
          exclusiveCheckgroup.forEach(exFieldPath => {
            _[concatenatePaths(fieldPath, exFieldPath)] = false;
          });
        }

        return _;
      }, {});
    }

    setCheckFlag(!checkFlag);
    if (newValues) setFormDataValues(newValues);
  };

  return <button
    id={$headerId}
    className={`icon-check-field btn ${className || ''}`}
    color="secondary"
    disabled={readonly}
    onClick={onClick}
  >
    {checkFlag ? (
            <i className={icon} />
        ) : (
            <i className={iconUncheck} />
        )}
  </button>;
}


export default IconCheckFieldHeader;