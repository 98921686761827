import { useEffect, useMemo, useState } from 'react';
import { Input } from 'reactstrap';
import IconButton from '@rjsf/core/lib/components/IconButton';
import getPathFromId from '../../util/getPathFromId';
import ErrorBoundary from '../ErrorBoundary';
import reactTraverse from '../../util/reactTraverse';
import Jnx, { parseJnxExpr } from '../../util/jnx';
import useOptionsLookup from './hooks/useOptionsLookup';
import { useSideChannelSubscription } from '../../util/useSideChannel';
import { makeTreeNode, organizeTreeNodes, visitTrees } from '../CollapsableTreeSelect';
import Loader from '../Loader';

const REQUIRED_IF_JNX = jnx => {
  if (jnx) {
    jnx = parseJnxExpr(jnx);
    jnx.expr = `($isTruthy(${jnx.expr}) and $isEmpty($value)) ? true : false`;

    return new Jnx(jnx);
  }

  return false;
};

const ADDITIONAL_PROPERTY_FLAG = '__additional_property';
const REQUIRED_FIELD_SYMBOL = '*';

function SchemaFieldTemplate(props) {
  const {
    children,
    classNames,
    description,
    disabled,
    displayLabel,
    errors,
    formContext,
    help,
    rawErrors,
    hidden,
    id,
    label,
    onDropPropertyClick,
    readonly,
    required: propRequired,
    schema,
    uiSchema,
  } = props;

  const { hidden: schemaHidden } = schema;

  const { onLocateField } = formContext;

  useEffect(() => {
    const visibility = formContext?.formFields?.current;
    const path = getPathFromId(id);

    if (visibility && path) {
      visibility[path] = true;

      return () => {
        visibility[path] = false;
      };
    }
  }, [id, formContext]);

  const {
    'akc:requiredIfVisible': requiredIfVisible,
    'akc:requiredIf': requiredIfExpr,
    'ui:labelSuffix': labelSuffix,
    'ui:hideLabel': hideLabel,
    'ui:field': uiField,
  } = uiSchema;

  const required = requiredIfVisible || propRequired || REQUIRED_IF_JNX(requiredIfExpr);
  const shouldHide = uiField !== 'ComputedField';

  if (schemaHidden && shouldHide) {
    return null;
  }

  if (hidden) {
    return <div className="hidden">{children}</div>;
  }

  const field = (
    <ErrorBoundary>
      {displayLabel && !hideLabel && (
        <>
          <Label label={label} suffix={labelSuffix} required={required} id={id} />
          {help || null}
          {schema?.description ? description : null}
        </>
      )}
      {children}
      {rawErrors?.length ? errors : null}
    </ErrorBoundary>
  );

  const [isHighlighthed, setHighlighthed] = useState(0);
  const hasAdditional = !!schema[ADDITIONAL_PROPERTY_FLAG];

  const fieldLocator = useMemo(() => {
    return onLocateField
      ? {
          onMouseEnter(e) {
            setHighlighthed(h => h + 1);
          },
          onMouseLeave(e) {
            setHighlighthed(h => h - 1);
          },
          onClick(e) {
            const topmostSftNode = reactTraverse(e.target)
              .filter(fn => fn.elementType?.name === SchemaFieldTemplate.name)
              .pop();
            onLocateField(topmostSftNode.memoizedProps.id);
          },
        }
      : {};
  }, [onLocateField, setHighlighthed]);
  useEffect(() => {
    if (!onLocateField) setHighlighthed(0);
  }, [onLocateField]);
  const path = useMemo(() => getPathFromId(id), [id]);

  return (
    <div className={`${classNames || ''} ${isHighlighthed > 0 ? 'is-highligthed' : ''}`} {...fieldLocator}>
      {hasAdditional ? (
        <div className="row">
          <div className="col-lg-6">
            <FieldKeyLabel {...props} />
          </div>
          <div className="col-lg-4">{field}</div>
          <div className="col-lg-2">
            <IconButton
              type="danger"
              icon="remove"
              className="array-item-remove btn"
              tabIndex="-1"
              style={{ border: '0' }}
              disabled={disabled || readonly}
              onClick={onDropPropertyClick(path)}
            />
          </div>
        </div>
      ) : (
        field
      )}
    </div>
  );
}

function useParsedOptions(options, { parentId, id, label }) {
  const tsOptions = useMemo(() => {
    if (!options) return null;
    const tsOptions = options.map(item => makeTreeNode(item, id, label, parentId));

    if (parentId) {
      const rootNodes = organizeTreeNodes(tsOptions);
      visitTrees(rootNodes);
      tsOptions.sort((a, b) => a.visitIdx - b.visitIdx);
    }

    return tsOptions;
  }, [options, parentId]);

  return tsOptions;
}

function FieldKeyLabel(props) {
  const { id, onKeyChange, required, schema, formContext } = props;
  let { label } = props;

  const keyFromLookup = schema?.keyFromLookup?.lookup;
  const keyLabel = schema?.keyLabel ?? 'Key'; // i18n ?
  const keyId = `${id}-key`;

  const keyValue = useMemo(() => getPathFromId(id), [id]);

  if (label === ' ' && keyValue && keyFromLookup) {
    label = keyValue;
  }

  return (
    <div className="form-group">
      <Label label={keyLabel} required={required} id={keyId} />
      {!keyFromLookup ? (
        <LabelInput label={label} required={required} id={keyId} onChange={onKeyChange} />
      ) : (
        <LabelInputSelect formContext={formContext} required={required} lookup={keyFromLookup} id={id} label={label} onChange={onKeyChange} />
      )}
    </div>
  );
}

function LabelInputSelect({ lookup, id, label, onChange, formContext, required }) {
  const { sideChannel } = formContext;

  const noValue = '';

  const rootFormData = useSideChannelSubscription(sideChannel, 0);

  const { options: lookupOptions, loadingOptions } = useOptionsLookup({
    lookup,
    rootFormData,
  });

  console.log('lookup', lookupOptions, loadingOptions);

  const options = useParsedOptions(lookupOptions, lookup);

  return loadingOptions ? (
    <Loader />
  ) : (
    <Input type="select" required={required} id={id} value={label} onChange={e => onChange(e.target.value)}>
      <option value={undefined}>{noValue}</option>
      {(options || []).map((option, idx) => (
        <option key={idx} value={option.id}>
          {option.label}
        </option>
      ))}
    </Input>
  );
}

function Label({ label, suffix, required, id }) {
  return label ? (
    <label className="control-label" htmlFor={id}>
      {label}
      {suffix || null}
      {required && <span className="required">{REQUIRED_FIELD_SYMBOL}</span>}
    </label>
  ) : null;
}

function LabelInput({ id, label, onChange }) {
  return <input className="form-control" type="text" id={id} onBlur={event => onChange(event.target.value)} defaultValue={label} />;
}

export default SchemaFieldTemplate;
