import React from 'react';
import { useConfiguration } from '../../context/ConfigurationContext';

// export const Logo = ({ style, width = 72, height = 72 }) => {
//   const [logoUrl, setLogoUrl] = useState(null);

//   useEffect(async () => {
//     const {
//       success,
//       data: { logoFileId, faviconFileId },
//     } = await ThemeSchemaApi.getDefaultTheme();

//     if (success) {
//       // Handle logo URL
//       const logo = await FileApi.getFile({ fileId: logoFileId });

//       if (logo) {
//         setLogoUrl(logo.url);
//       }

//       // Handle ico URL
//       const ico = await FileApi.getFile({ fileId: faviconFileId });

//       if (ico) {
//         const link = document.createElement('link');
//         link.rel = 'shortcut icon';
//         link.href = ico.url;

//         document.head.appendChild(link);
//       }
//     }
//   }, []);

//   return logoUrl ? <img src={logoUrl} style={style} width={width} height={height} alt="logo" /> : <div style={{ width, height }} />;
// };



export const Logo = ({ style, width = 72, height = 72 }) => {
  const { LOGO_SRC } = useConfiguration();

  return (
    <img src={LOGO_SRC} style={style} width={width} height={height} alt="logo" />
  );
};

