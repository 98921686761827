import React, { useMemo } from 'react';
import {
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import useResourceLoader from '../../util/useResourceLoader';
import fetchApi from '../../util/fetchApi';
import useUID from '../../util/useUID';
import checkErrors from '../../api/checkErrors';
import Notification from '../Notification';
import Loader from '../Loader';
import { useSideChannelSubscription } from '../../util/useSideChannel';
import { getObject } from '../../util/mapObject';


function LookupMultiSelectCheckboxesFormField(props) {
  const {
    formData,
    formContext: { sideChannel },
    schema: {
      title,
      lookup,
      items: { type },
      readOnly,
    },
    required,
    onChange: propOnChange,
  } = props;

  const {
    resource = 'Options',
    api,
    params,
    id,
    label,
  } = lookup || {};
  const isNumber = type === 'number';

  const uid = useUID();

  const rootFormData = useSideChannelSubscription(sideChannel, 0);

  const paramsString = useMemo(() => (
    rootFormData && params ? Object.entries(params).map(([k, v]) => {
      if (v && v.field) v = getObject(rootFormData, v.field);

      return v ? `${k}=${encodeURIComponent(v)}` : null;
    }).filter(s => !!s).join('&') : ''
  ), [rootFormData, params]);

  const [options, loadingOptions, errorLoadingOptions] = useResourceLoader(
    () =>
      api
        ? fetchApi.lookup.get(`${api}${paramsString ? `?${paramsString}` : ''}`).then(checkErrors)
        : Promise.reject(new Error('Schema error: Missing lookup data')),
    [api, paramsString]
  );

  function isChecked(optionId) {
    if (isNumber) {
      optionId = optionId | 0;
    }

    return !!formData && Array.isArray(formData) && formData.indexOf(optionId) !== -1;
  }

  function setChecked(optionId, checked) {
    if (isNumber) {
      optionId = optionId | 0;
    }

    const newFormData = !!formData && Array.isArray(formData) ? formData.slice() : [];
    const dataIndex = newFormData.indexOf(optionId);

    if (checked && dataIndex === -1) {
      newFormData.push(optionId);
    } else if (!checked && dataIndex !== -1) {
      newFormData.splice(dataIndex, 1);
    }

    const options = newFormData.filter(element => element !== undefined);
    propOnChange(options.length > 0 ? options : null);
  }

  return (
    <div className="form-group field field-number">
      <label className="control-label" htmlFor="root_preferredBranchId">
        {title}
        {required ? <span className="required">*</span> : null}
      </label>
      {loadingOptions ? (
        <div>
          <Loader>Loading {resource}</Loader>
        </div>
      ) : (errorLoadingOptions ? (
        <Notification color="danger">
          <div>{errorLoadingOptions.message}</div>
        </Notification>
      ) : ((options || []).length > 0 ? (
        (options || []).map((option, idx) => (
          <FormGroup key={idx} check>
            <Input
              disabled={readOnly}
              id={`${uid}-${idx}`}
              type="checkbox"
              checked={isChecked(option[id])}
              onChange={({ target: { checked } }) => setChecked(option[id], checked)}
            />
            <Label check for={`${uid}-${idx}`}>
              {option[label]}
            </Label>
          </FormGroup>
        ))
      ) : (
        <Notification color="danger">
          <div>{resource} list is empty.</div>
        </Notification>
      )))}
    </div>
  );
}


export default LookupMultiSelectCheckboxesFormField;
