import { useEffect } from 'react';

export function bindEvent(element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);

    return () => element.removeEventListener(eventName, eventHandler);
  } else if (element.attachEvent) {
    const event = `on${eventName}`;
    element.attachEvent(event, eventHandler);

    return () => element.detachEvent(event, eventHandler);
  }
}


export default function useEventHandler(
  element,
  event,
  handler,
  dependencies
){
  useEffect(() => bindEvent(
    element,
    event,
    handler),
  [...dependencies]
  );
}