import React, { useCallback, useMemo } from 'react';
import { Button } from 'reactstrap';
import JsonTreeEditorNode from './JsonTreeEditorNode';


function JsonArrayEditor({ indent, path, node, setObject }) {
  const prefix = useMemo(() => (path ? `${path}.` : ''), [path]);

  const onRemove = useCallback(
    ({
      currentTarget: {
        attributes: { idx },
      },
    }) => {
      idx = idx.value | 0;
      setObject(
        path,
        node.filter((_, i) => i !== idx)
      );
    },
    [setObject, path, node]
  );

  const onAddItem = useCallback(() => {
    setObject(path, [...node, null]);
  }, [setObject, path, node]);

  return (
    <div className="json-tree-editor-node-array">
      {node.map((subnode, idx) => (
        <React.Fragment key={idx}>
          <div className="index">
            <div className="number">{idx + 1}</div>
            <Button className="delete-btn" color="light" idx={idx} onClick={onRemove}>
              <i className="fa fa-times" />
            </Button>
          </div>
          <JsonTreeEditorNode
            indent={indent + 1}
            path={`${prefix}${idx}`}
            node={subnode}
            setObject={setObject}
          />
        </React.Fragment>
      ))}
      <div className="lower-buttons">
        <Button color="primary" onClick={onAddItem}>
          + Add
        </Button>
      </div>
    </div>
  );
}

JsonArrayEditor.isContainer = true;


export default JsonArrayEditor;
