import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';


function Clock({
  date,
  className,
  is24, leadZero=false,
  seconds=false,
}){
  const format = useMemo(() => ({
    date,
    time: `${
      (is24 ? 'H': 'h').repeat(leadZero ? 2 : 1)
    }:mm${
      seconds ? ':ss' : ''
    }` 
  }), [date, is24, leadZero]);
  const [updateFlag, setUpdateFlag] = useState();
  const time = useMemo(() => {
    const now = dayjs();

    return {
      date: format.date ? now.format(format.date) : null,
      time: now.format(format.time),
      isPm: now.hour() >= 12,
    };
  }, [updateFlag, format]);

  useEffect(() => {
    const interval = setInterval(() => setUpdateFlag(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <span className={`clock ${className || ''}`}>
      {time.date ? <span className="date">{time.date}</span> : null}
      <span className="time">{time.time}</span>
      {!is24 ? (
        <span className={`ampm ${time.isPm ? 'is-pm': 'is-am'}`}>{
          time.isPm ? ' PM': ' AM'
        }</span>
      ) : null}
    </span>
  );
}

export default Clock;
