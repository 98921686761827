import React, { useRef, useState } from 'react';
import Notification from '../../../components/Notification';
import useEventHandler from '../../../util/useEventHandler';
import useSearchParams from '../../../util/useSearchParams';


export default function() {
  const [
    [url]
  ] = useSearchParams({
    url: null
  });

  const [submitted, setSubmitted] = useState();
  const [canceled, setCanceled] = useState();

  const iframe = useRef();

  useEventHandler(window, 'message', (e) => {
    const action = e?.data?.action;
    console.log('message from child : ', action, e?.data?.userData);

    switch(action) {
      case 'submit':
        setTimeout(() => {
          iframe.current.contentWindow.postMessage({ action: 'submitOk' });
        }, 2000);

        break;
      case 'submitted':
        setSubmitted(true);
        console.log('parent', 'finish');
        break;
      case 'cancel':
        setCanceled(true);
        console.log('parent', 'finish');
        break;
    }
  }, []);

  return (
    <div style={{ margin: '20px' }}>
      <Notification>iframe test. url={url}</Notification>
      {submitted ? <Notification>submitted</Notification> : null}
      {canceled ? <Notification>canceled</Notification> : null}
      {!(submitted || canceled) ? (
        url ? (
          <iframe ref={iframe} title="test" id="ifr" src={url} height={700} width="100%" />
        ) : (
          <Notification color="warning">no url parameter given</Notification>
        )
      ) : null}
    </div>
  );
}

