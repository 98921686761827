class IdentityFormatter {
  static tryParse() {
    return new IdentityFormatter();
  }

  apply(value) {
    return value;
  }

  parse(value) {
    return value;
  }
}

export default IdentityFormatter;
