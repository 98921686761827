import { useCallback, useMemo, useRef } from 'react';

function JsonStringEditor({
  path,
  node,
  setObject,
  allowMultiline=true,
  hasButtons,
}) {
  const isMultiline = useMemo(() => allowMultiline && node && node.indexOf('\n') > -1, [allowMultiline, node]);

  const ref = useRef();
  const txRef = useRef();

  function transferProps(newElement) {
    const oldElement = ref.current;

    if (oldElement && !newElement && !txRef.current) {
      txRef.current = {
        hasFocus: oldElement === document.activeElement,
        selectionStart: oldElement.selectionStart,
        selectionEnd: oldElement.selectionEnd,
      };
    } else if (newElement && txRef.current) {
      const tx = txRef.current;
      txRef.current = null;

      if (tx.hasFocus) {
        newElement.focus();
        newElement.selectionStart = tx.selectionStart;
        newElement.selectionEnd = tx.selectionEnd;
      }
    }

    ref.current = newElement;
  }

  const onKeyDown = useCallback((e) => {
    if (e.keyCode === 13 && !isMultiline && allowMultiline) {
      e.preventDefault();
      const target = e.target;
      const {value, selectionStart, selectionEnd} = e.target;
      const prefix = value.substring(0, selectionStart);
      const suffix = value.substring(selectionEnd);
      const newValue = `${prefix}\n${suffix}`;
      setObject(path, newValue);
      txRef.current = {
        hasFocus: target === document.activeElement,
        selectionStart: target.selectionStart + 1,
        selectionEnd: target.selectionEnd + 1,
      };
    }
  }, [isMultiline, allowMultiline, setObject, path]);

  return isMultiline ? (<textarea
    ref={transferProps}
    onKeyDown={onKeyDown}
    className={hasButtons ? 'has-buttons': 'no-buttons'}
    type="text"
    title={node}
    value={node}
    onChange={({target: {value}}) => setObject(path, value)}
  />) : (<input
    ref={transferProps}
    onKeyDown={onKeyDown}
    className={hasButtons ? 'has-buttons': 'no-buttons'}
    type="text"
    title={node}
    value={node}
    onChange={({target: {value}}) => setObject(path, value)}
  />);
}


export default JsonStringEditor;
