import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router';
import OrganizationalUnitApi from '../../../api/OrganizationalUnitApi';
import FormComponent from '../../../components/FormComponent';
import InternalLayout from '../../../components/layouts/InternalLayout';
import parseUISchemaFromSchema from '../../../util/parseUISchemaFromSchema';

export const CreateOrganizationalUnitForm = FormComponent({
  beforeRenderHook({ setFormDefinition, initialFormObject }) {
    useEffect(() => {
      if (initialFormObject) {
        initialFormObject.name = '';

        const schema = {
          ':classNames': 'smallTwoRows',
          title: 'Crear Unidad Organizacional',
          type: 'object',
          properties: {
            name: { title: 'Nombre', type: 'string' },
            type: {
              'type': 'string',
              'title': 'Tipo',
              'enum': ['Agency', 'Bureau', 'WorkDivision'],
              'enumNames': ['Agencia', 'Entidad Peticionaria', 'División de Trabajo']
            },
            code: { title: 'Código de Negociado', type: 'string' },
            address1: { title: 'Dirección Linea 1', type: 'string' },
            address2: { title: 'Dirección Linea 2', type: 'string' },
            postalCode: { title: 'Código Postal', type: 'string' },
            parentId: {
              'title': 'Unidad Organizacional Padre',
              'type': 'number',
              ':classNames': 'grid-3-columns',
              'ui:field': 'LookupFormField',
              'lookup': {
                'resource': 'Unidades Organizacionales',
                'api': 'organizationalunits',
                'params': {
                  'all': true
                },
                'parentId': 'parentId',
                'collapsable': true,
                'collapseLevel': 2,
                'id': 'id',
                'label': 'name',
                'sortBy': 'name'
              }
            },
            city: { title: 'Ciudad', type: 'string' },
            state: { title: 'Estado', type: 'string' },
            numericCode: { title: 'Código de Agencia', type: 'number' },
            dependencyFigure: { title: 'Cifra de Dependencia', type: 'number' },
          },
          required: [
            'name', 'type', 'code', 'numericCode', 'dependencyFigure',
          ],
        };
        setFormDefinition(schema, parseUISchemaFromSchema(schema));
      }
    }, [initialFormObject]);
  },
  displayName: 'CreateOrganizationalUnit',
  withoutNavigation: true,
  schema: {},
  uiSchema: {},
  validate(prop, errors) {
    return errors;
  },
  parseProps: () => ({
    params: useParams(),
  }),
  async onSubmit({ formData }) {
    await OrganizationalUnitApi.createOrganizationUnit(formData);
  },
  submitButtons: {
    submit: { text: 'Crear Unidad Organizacional', className: 'btn-primary' },
  },
  renderFormSubmitted: () => (
    <Redirect to="../system-settings/organizationalunits" />
  )
});

export default function() {
  return (
    <InternalLayout>
      <CreateOrganizationalUnitForm />
    </InternalLayout>
  );
}

