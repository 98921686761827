import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { getToken } from '../context/AuthContext';
import { ConfigurationContext } from '../context/ConfigurationContext';
import fetchApi from '../util/fetchApi';

export const initFns = {}

initFns.sentry = ({ SENTRY_DSN }) => {
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
};

initFns.fetchApi = ({
  ENDPOINT,
  defaults = {},
  verbs=['get', 'post', 'put', 'delete'],
  helpers = {},
} = {}) => {
  fetchApi.configure({
    defaults: { ...defaults, baseurl: ENDPOINT },
    verbs,
    helpers: {
      api: (url, options, fetchApi) =>
        fetchApi(
          url,
          {
            auth: { type: 'Bearer', token: getToken() },
            json: true,
            ...options,
          },
          fetchApi
        ),
      external: (url, options, fetchApi) =>
        fetchApi(
          url,
          {
            json: true,
            baseUrl: undefined,
            ...options,
          },
          fetchApi
        ),
      lookup: (url, options, fetchApi) =>
        fetchApi(
          `form-lookup/${url}`,
          {
            auth: { type: 'Bearer', token: getToken() },
            json: true,
            useCache(url, options) {
              return options.method === 'get';
            },
            ...options,
          },
          fetchApi
        ),
      ...helpers
    },
  });
};

function initAppConfiguration(configuration) {
  Object.entries(initFns).forEach(([key, fn]) => {
    if(!fn.initialized){
      fn(configuration[key] ?? {})
      fn.initialized = true;
    }
  })

  return ({ children }) => (
    <ConfigurationContext.Provider value={configuration}>
      {children}
    </ConfigurationContext.Provider>
  )
}

export default initAppConfiguration;
