import { t } from '@lingui/macro';
import React, { useMemo } from 'react';
import Workpage from '../../../components/Workpage/index';

export function IntegrationsTable() {
  const schema = useMemo(
    () => ({
      title: t`Integrations`,
      type: 'workpage',
      elements: [
        {
          title: t`Integrations`,
          type: 'worktray',
          ':className': 'col-lg-12',
          resource: 'manage/integrations',
          filters: [
            { title: t`Name`, key: 'name', type: 'string' },
            { title: t`Active`, key: 'enabled', type: 'string' },
          ],
          columns: [
            { title: t`Name`, sortkey: 'name', 'map:field': 'name' },
            { title: t`Active`, sortkey: 'enabled', 'map:field': { expr: "enabled ? 'Si' : 'No'" } },
            { title: t`Version`, sortkey: 'version', 'map:field': 'version' },
            { title: t`Last Updated`, sortkey: 'lastUpdated', 'map:field': 'lastUpdated', format: 'date' },
            {
              title: t`Actions`,
              actions: [
                {
                  label: t`Edit`,
                  route: '/integrations/$id',
                },
              ],
            },
          ],
        },
      ],
    }),
    []
  );

  return <Workpage schema={schema} />;
}

export default IntegrationsTable;
