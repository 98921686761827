import fetchApi from '../util/fetchApi';
import checkErrors from './checkErrors';
import checkDocumentResponse, {STATUS} from './checkDocumentResponse';
import IntegrationsApi from './IntegrationsApi';


const TenantApi = {
  async getExportLink() {
    return (await checkErrors(await fetchApi.api.post('manage/tenant/export/link'))).data;
  },
  async importObjects({
    file,
    // filters
  }) {
    return (await checkErrors(await fetchApi.api.post('manage/tenant/import', {
      formdata: {
        file
      }
    }))).data;
  },
};


export default TenantApi;
