import React from 'react';


function SortButton({
  tag = 'button',
  sortKey,
  sortBy,
  setSortBy,
  style,
  children
}) {
  const descSortKey = `-${sortKey}`;
  const Tag = tag;
  const sorting = sortBy === sortKey ? 'asc' : sortBy === descSortKey ? 'desc' : null;
  const chevron = { asc: 'glyphicon glyphicon-sort-by-alphabet', desc: 'glyphicon glyphicon-sort-by-alphabet-alt' }[sorting];

  return (
    <Tag
      className="sort-button"
      style={style}
      onClick={() => {
        setSortBy(sorting === 'asc' ? descSortKey : sortKey);
      }}
    >
      <span>{children}</span> {chevron ? <i className={chevron} /> : null}
    </Tag>
  );
}

export default SortButton;
