import { t } from '@lingui/macro';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { TabContent, TabPane, Button, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';

import SLASetting from './SLASetting';
import Forms from './Forms';
import ObjectSchemas from './ObjectSchema';
import Sequences from './Sequences';
import UserTable from '../users/UsersTable';
import OrganizationalUnitsTable from './OrganizationalUnit/OrganizationalUnitsTable';
import WorkflowIndex from './Workflow';
import Export from './Export';
import IntegrationsTable from './Integrations/IntegrationsTable';
import InternalLayout from '../../components/layouts/InternalLayout';
import { AuthContext } from '../../context/AuthContext';
import { useConfiguration } from '../../context/ConfigurationContext.js';
import useLoader from '../../util/useLoader';
import IntegrationsApi from '../../api/IntegrationsApi';
import Loader from '../../components/Loader';
import Jnx from '../../util/jnx';
import { getLabel } from '../../LanguageConstant';
import ListInvoiceItem from './Invoce/ListInvoiceItem';
import Workpages from './Workpages';
import Roles from './Roles';
import Jobs from './Jobs';
import Documents from './Documents';
import Faqs from './Faq/index';
import BulkUploader from './BulkUploader';
import ThemeEditor from './ThemeEditor';

const GET_TABS = ({ ENABLE_JOBS_SETTINGS, ENABLE_DOCUMENTS_SETTINGS }) => ({
  undefined: {
    noTab: true,
    component: ({ setActiveTab }) => (
      <SwitchBoard
        title={getLabel().selectComponent}
        buttons={GET_TABS({ ENABLE_JOBS_SETTINGS, ENABLE_DOCUMENTS_SETTINGS })}
        setActive={setActiveTab}
      />
    ),
  },
  'users-jedi': {
    icon: 'fa fa-user',
    title: 'Usuarios',
    permission: 'ManageUsersInUnit',
    jnx: 'user.user.jediId',
    component: () => <Loader />,
    onActivate({ loadFn }) {
      loadFn(async () => {
        const url = await IntegrationsApi.GetOperationUrl('jedi/jump-to-jedi/login');

        if (url) {
          window.open(url, '_blank');
        }
      });
    },
  },
  users: {
    icon: 'fa fa-user',
    title: getLabel().users,
    component: UserTable,
    permission: 'ManageUsersInUnit',
    jnx: '$not(user.user.jediId)',
  },
  roles: {
    icon: 'fa fa-user-astronaut',
    title: t`Roles`,
    component: Roles,
    permission: 'ManageRole',
  },
  organizationalunits: {
    icon: 'fa fa-building',
    title: getLabel().agencies,
    component: OrganizationalUnitsTable,
    permission: 'ManageOrganizationalUnits',
  },
  themeEditor: {
    icon: 'fa fa-pen',
    title: getLabel().themes,
    component: ThemeEditor,
    permission: 'ManageTheme',
  },
  objectSchema: {
    icon: 'fa fa-list',
    title: getLabel().objectTypes,
    component: ObjectSchemas,
    permission: 'ManageWorkflow',
  },
  workpage: {
    icon: 'fa fa-tachometer-alt',
    title: t`Workpages`,
    component: Workpages,
    permission: 'ManageWorkflow',
  },
  workflow: {
    icon: 'fa fa-network-wired',
    title: getLabel().workflow,
    component: WorkflowIndex,
    permission: 'ManageWorkflow',
  },
  forms: {
    icon: 'fa fa-clipboard-list',
    title: getLabel().forms,
    component: Forms,
    permission: 'ManageWorkflow',
  },
  sla: {
    icon: 'fa fa-traffic-light',
    title: getLabel().sla,
    component: SLASetting,
    permission: 'ManageWorkflow',
  },
  integrations: {
    icon: 'fa fa-link',
    title: getLabel().integrations,
    component: IntegrationsTable,
    permission: 'ManageIntegrations',
  },
  invoice: {
    icon: 'fa fa-money-bill',
    title: getLabel().invoiceItems,
    component: ListInvoiceItem,
    permission: 'ManageWorkflow',
  },
  sequences: {
    icon: 'fa fa-tag',
    title: t`Sequences`,
    component: Sequences,
    permission: 'ManageWorkflow',
  },
  faq: {
    icon: 'fa fa-question-circle',
    title: t`Faq`,
    component: Faqs,
    permission: 'ManageFaq',
  },
  ...(ENABLE_JOBS_SETTINGS && {
    jobs: {
      icon: 'fa fa-robot',
      title: t`Jobs`,
      component: Jobs,
      permission: 'ManageWorkflow',
    },
  }),
  ...(ENABLE_DOCUMENTS_SETTINGS && {
    documents: {
      icon: 'fa fa-file-pdf',
      title: t`Documents`,
      component: Documents,
      permission: 'ManageWorkflow',
    },
  }),
  export: {
    icon: 'fa fa-download',
    title: t`Export`,
    component: Export,
    permission: 'ManageWorkflow',
  },
  bulkUploader: {
    icon: 'fa fa-upload',
    title: t`Bulk Uploader`,
    component: BulkUploader,
    permission: 'ManageWorkflow',
  },
});

function SystemSettings() {
  const { ENABLE_JOBS_SETTINGS, ENABLE_DOCUMENTS_SETTINGS } = useConfiguration();

  const [TABS, DEFAULT_TAB] = useMemo(() => {
    const tabs = GET_TABS({
      ENABLE_JOBS_SETTINGS,
      ENABLE_DOCUMENTS_SETTINGS,
    });
    const default_tab = Object.keys(tabs)[0];

    return [tabs, default_tab];
  }, [ENABLE_JOBS_SETTINGS, ENABLE_DOCUMENTS_SETTINGS]);

  const history = useHistory();
  const { setting = DEFAULT_TAB } = useParams();
  const auth = useContext(AuthContext);
  const { permissions } = auth?.user || {};
  const [loading, , loadFn] = useLoader();

  const tabs = useMemo(
    () =>
      Object.entries(TABS).reduce((_, [tab, tabDef]) => {
        if (
          (tabDef.permission ? permissions && permissions[tabDef.permission] : true) &&
          (tabDef.jnx ? new Jnx(tabDef.jnx).eval(auth || {}, '') : true)
        ) {
          tabDef.name = tab;
          _[tab] = tabDef;
        }

        return _;
      }, {}),
    [permissions, auth]
  );

  const activeTab = tabs[setting];
  const { component: Component } = activeTab || {};

  function setActiveTab(tab) {
    history.push(`/system-settings/${tab}`);
  }

  const context = useRef();
  context.current = {
    prefix: '/system-settings/',
    auth,
    history,
    loadFn,
  };

  useEffect(() => {
    if (activeTab?.onActivate) {
      activeTab.onActivate(context.current);
    }
  }, [activeTab]);

  return (
    <InternalLayout tabs={tabs} activeTab={setting} setActiveTab={setActiveTab}>
      <div>
        {loading ? (
          <Loader />
        ) : (
          <TabContent activeTab={setting}>
            {Component ? (
              <TabPane tabId={setting}>
                <Component activeTab={setting} setActiveTab={setActiveTab} />
              </TabPane>
            ) : null}
          </TabContent>
        )}
      </div>
    </InternalLayout>
  );
}

function SwitchBoard({ title, buttons: propButtons, setActive }) {
  const auth = useContext(AuthContext);
  const { permissions } = auth?.user || {};

  const filteredButtons = useMemo(
    () =>
      Object.entries(propButtons).reduce((_, [tag, def]) => {
        if (
          !def.noTab &&
          (def.permission ? permissions && permissions[def.permission] : true) &&
          (def.jnx ? new Jnx(def.jnx).eval(auth || {}, '') : true)
        ) {
          _.push({ tag, def });
        }

        return _;
      }, []),
    [permissions, auth, propButtons]
  );

  return (
    <>
      <Card className="inbox-card primary title">
        <CardHeader>
          <div className="title">{t`Admin Workpage`}</div>
        </CardHeader>
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <div className="system-setting-buttons">
            {filteredButtons.map(({ tag, def: { icon, title } }, idx) => (
              <div key={idx} className="button-item">
                <Button onClick={() => setActive(tag)} color="primary" block>
                  <i className={icon} />
                  <br />
                  {title}
                </Button>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default SystemSettings;
