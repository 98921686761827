import fetchApi from '../util/fetchApi';
import checkErrors from './checkErrors';


export const ObjectSchemaApi = {
  async list({ offset, size, sortBy }) {
    return checkErrors(await fetchApi.api.get('manage/objectschemas', { query: { offset, size, sortBy } }));
  },

  async get({ objectSchemaId }) {
    return (await checkErrors(await fetchApi.api.get(`manage/objectschemas/${objectSchemaId}`))).data;
  },

  async getSchema({ objectSchemaId }) {
    return (await checkErrors(await fetchApi.api.get(`manage/objectschemas/${objectSchemaId}/schema`))).data;
  },

  async update(objectSchemaId, schema) {
    return (await checkErrors(await fetchApi.api.put(`manage/objectschemas/${objectSchemaId}`,{data: schema}))).data;
  },

  async create(schema) {
    return (await checkErrors(await fetchApi.api.post('manage/objectschemas/',{data: schema}))).data;
  },
};


export default ObjectSchemaApi;
