import { useState, useEffect } from 'react';
import { FormGroup } from 'reactstrap';
import { useDebouncedEffect } from '../../hooks/useDebounceEffect';
import InputColor from 'react-input-color';

export default function InputColorPicker(props) {
  const {
    id,
    formData,
    onChange,
    required,
    idSchema: { $id },
    schema: { title },
  } = props;
  const [current, setCurrent] = useState(formData);

  useDebouncedEffect(
    () => {
      if (current !== formData) {
        onChange(current);
      }
    },
    [current],
    450
  );

  useEffect(() => {
    setCurrent(formData);
  }, [formData]);

  return (
    <FormGroup>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <label className="control-label" htmlFor={$id}>
          {title}
          {required ? <span className="required">*</span> : null}
        </label>
        <InputColor
          id={id}
          initialValue={current || '#684645'}
          onChange={e => {
            setCurrent(e.hex);
          }}
          placement="right"
        />
      </div>
    </FormGroup>
  );
}
