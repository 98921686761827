import { useCallback, useMemo, useState } from 'react';
import { Button } from 'reactstrap';

import JsonTypeSelector from './JsonTypeSelector';
import { EDITORS, CAST_TO, determineJSONType } from './util';


function JsonTreeEditorNode({
  indent=0, path, node, setObject, index, buttons,
  componentOnly
}) {
  const [expanded, setExpanded] = useState(true);
  const jsonType = useMemo(() => determineJSONType(node), [node]);

  const onChangeType = useCallback(
    newType => {
      console.log(`new type: ${newType}`, CAST_TO[newType], CAST_TO);
      setObject(path, CAST_TO[newType](node));
    },
    [path, node, setObject]
  );

  const Editor = EDITORS[jsonType];

  const component = Editor ? (
    <Editor indent={indent} path={path} node={node} setObject={setObject} hasButtons={!!buttons} />
  ) : (
    `JsonTreeEditorNode for type ${jsonType}`
  );

  const isContainer = Editor?.isContainer;

  return componentOnly ? (
    component
  ) : (
    <>
      {index || null}
      <div className="type-selector">
        <JsonTypeSelector type={jsonType} onChangeType={onChangeType} />
      </div>
      {isContainer ? (
        <>
          <ExpandButton count={Object.keys(node).length} expanded={expanded} setExpanded={setExpanded} />
          {buttons || null}
          {expanded ? component : null}
        </>
      ) : (
        <>
          {component}
          {buttons || null}
        </>
      )}
    </>
  );
}

function ExpandButton({
  count, expanded, setExpanded
}){
  return <div className="buttons inplace">
    <Button onClick={() => setExpanded(!expanded)}>
      <i className={expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'} />
    </Button>
    <span className="item-count">{count} items</span>
  </div>;
}

export default JsonTreeEditorNode;
