import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { SchemaElement } from './SchemaElement';

function DraggableSchemaElement({ type }) {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: type.id,
    data: { ...type, isNew: true },
  });

  const style = transform
    ? {
      transform: CSS.Transform.toString(transform),
      zIndex: 1000,
      opacity: isDragging ? 0 : 1,
      }
    : undefined;

  return <SchemaElement ref={setNodeRef} style={style} {...attributes} {...listeners} icon={type.icon} label={type.label} />;
}

export default DraggableSchemaElement;
