import { i18n } from '@lingui/core';
import { en, es } from 'make-plural/plurals';
import { messages as enMessages } from './locales/en/messages';
import { messages as esMessages } from './locales/es/messages';
import { LOAD_SEARCH_PARAMS } from './constants';


export const LANGS = {
  en: {messages: enMessages, plurals: en},
  es: {messages: esMessages, plurals: es},
};

export const INIT_LANG = LOAD_SEARCH_PARAMS.lang in LANGS ? LOAD_SEARCH_PARAMS.lang : 'es';

Object.entries(LANGS).forEach(([lang, {messages, plurals}]) => {
  i18n.load(lang, messages);
  i18n.loadLocaleData(lang, { plurals });
});

i18n.activate(INIT_LANG);

export default i18n;