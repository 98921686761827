import React from 'react';
import { Table } from 'reactstrap';
import Notification from '../../../Notification';

class TableLayout {
  constructor({
    $id,
    hasAddBtnInRow,
    hasToolbar,
    headers,
    itemsAreObjectType,
    itemsUiSchema,
    positionButtons,
    onEmptyMessage,
    props,
    rowClassNames,
  }) {
    this.isTable = true;
    this.$id = $id;
    this.hasAddBtnInRow = hasAddBtnInRow;
    this.hasToolbar = hasToolbar;
    this.headers = headers;
    this.itemsAreObjectType = itemsAreObjectType;
    this.itemsUiSchema = itemsUiSchema;
    this.onEmptyMessage = onEmptyMessage;
    this.positionButtons = positionButtons;
    this.buttonsLeft = positionButtons === 'left';
    this.props = props;
    this.rowClassNames = rowClassNames;
  }

  wrapArray(children) {
    return <Table>{children}</Table>;
  }

  wrapBody(children) {
    return (
      <tbody className="array-item-list" key={`array-item-list-${this.$id}`}>
        {children}
      </tbody>
    );
  }

  wrapLoader(children) {
    return (
      <tr>
        <td>{children}</td>
      </tr>
    );
  }

  wrapItem(
    id,
    props,
    onAddClick,
    rowIdx,
    rowCount,
    children,
    buttons,
    rowClassNames,
  ) {
    const buttonsComp = buttons ? (
      <td className="col-xs-3 array-item-toolbox" style={{ width: '100px' }}>
        <div
          className="btn-group"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {buttons}
        </div>
      </td>
    ) : null;

    const content = this.buttonsLeft ? (
      <>
        {buttonsComp}
        {children}
      </>
    ) : (
      <>
        {children}
        {buttonsComp}
      </>
    );

    return (
      <tr
        id={id}
        className={`${props.className} ${
          this.itemsAreObjectType ? 'for-object-row' : ''
        } ${rowClassNames}`}
      >
        {content}
      </tr>
    );
  }

  wrapItemField({ name, uiSchema }, children) {
    return (
      <td key={name} className={(uiSchema[name] || {})['ui:cellClassName']}>
        {children}
      </td>
    );
  }

  wrapItemPanelRow({ columnCount }, children) {
    return (
      <tr className={`array-item panel-row ${this.rowClassNames}`}>
        {this.buttonsLeft ? <td /> : null}
        <td colSpan={columnCount}>{children}</td>
      </tr>
    );
  }

  wrapItemErrorList(children) {
    return (
      <tr>
        {this.buttonsLeft ? <td /> : null}
        <td colSpan={this.headers?.length || 1}>{children}</td>
      </tr>
    );
  }

  makeEmptyItemField() {
    return <td />;
  }

  makeSimpleItem(props) {
    return (
      <td className={this.hasToolbar ? 'col-xs-9' : 'col-xs-12'}>
        {props.children}
      </td>
    );
  }

  wrapAddButton(children) {
    return (
      <tr>
        <td colSpan={this.headers?.length || 1}>{children}</td>
      </tr>
    );
  }

  makeEmptyMessage() {
    return (
      <tr>
        <td colSpan={this.headers?.length || 1}>
          <Notification color="warning">{this.onEmptyMessage}</Notification>
        </td>
      </tr>
    );
  }

  makeHeaders() {
    return (
      <thead>
        <tr className={`${this.itemsAreObjectType ? 'for-object-row' : ''}`}>
          {this.hasAddBtnInRow ? <th /> : null}
          {this.buttonsLeft ? <th /> : null}
          {this.headers.map(({ field, Component, text }, idx) => (
            <th
              key={idx}
              className={(this.itemsUiSchema[field] || {})['ui:cellClassName']}
            >
              {Component ? <Component field={field} {...this.props} /> : text}
            </th>
          ))}
          {this.hasToolbar ? <th /> : null}
        </tr>
      </thead>
    );
  }
}

export default TableLayout;
