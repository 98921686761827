import { useMemo, useEffect } from 'react';
import { useSideChannelSubscription } from '../../util/useSideChannel';
import getPathFromId from '../../util/getPathFromId';
import { useJnx } from '../../util/jnx';


function TimerField({
  formContext,
  schema,
  idSchema: { $id } = {},
}) {
  const {
    setFormDataValues,
    sideChannel,
    openModal
  } = formContext;

  const {
    delay,
    periodic,
    showState,
    active,
    activeIf,
    onTimeout,
  } = schema;

  const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [];

  const bindings = useMemo(() => ({
    openModal,
    rootFormData,
    formObject,
    formContext
  }), [openModal, rootFormData, formObject, formContext]);

  const functionBinds = useMemo(() => ({
    set: setFormDataValues,
  }), [setFormDataValues]);

  const path = useMemo(() => getPathFromId($id), [$id]);
  const activeJnx = useJnx(activeIf, {functionBinds}, [functionBinds]);
  const onTimeoutJnx = useJnx(onTimeout, {functionBinds}, [functionBinds]);
  const isActive = useMemo(() => {
    return (active || activeJnx.eval(rootFormData, path, bindings));
  }, [
    active, activeJnx,
    rootFormData, path, bindings,
  ]);

  useEffect(() => {
    let timeout = null;

    async function timeoutFn() {
      await onTimeoutJnx.evalAsync(rootFormData, path, bindings).catch((e) => console.error(e));

      if (periodic) {
        timeout = setTimeout(timeoutFn, delay);
      }
    }

    if (isActive) {
      timeout = setTimeout(timeoutFn, delay);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [
    isActive,
    onTimeoutJnx,
    rootFormData, path, bindings,
  ]);

  return showState ? JSON.stringify({
    type: 'timer',
    isActive,
    activeJnx,
    onTimeoutJnx,
    delay
  }) : null;
}


export default TimerField;