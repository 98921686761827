import React, { useState, useMemo, useCallback } from 'react';
import {
  Button,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

import UWEFormComponent from '../../blc/UWEFormComponent';
import { getObject, splitPath } from '../../util/mapObject';
import FormFieldsEditor from './FormFieldsEditor';
import FormDetailsEditor from './FormDetailsEditor';
import JsonSchemaTreeNode from './JsonSchemaTreeNode';
import SidebarPanel from './SidebarPanel';
import EditorContainer from './EditorContainer';
import { getLabel } from '../../LanguageConstant';
import useSearchParams from '../../util/useSearchParams';
import clone from '../../util/clone';
import JsonTreeEditor from '../JsonTreeEditor';
import UWEEntityApi from '../../api/UWEEntityApi';
import useLoader from '../../util/useLoader';
import Loader from '../Loader';
import Notification from '../Notification';
import getPathFromId from '../../util/getPathFromId';
import jsonSchemas from '../../util/jsonSchemas';

import JsonEditor from '../JsonEditor';
import FormAI from './FormAI';


const TABS = {
  'form': 'Form',
  'sections': getLabel().sections,
  'data': 'Data',
  'ai': 'AI',
};

const COMPONENT_TABS = {
  'component': getLabel().modifyComponent,
  'json': 'Json Tree',
  'jsontext': 'Json Text',
};



function UWEFormEditor({
  form,
  onChange
}) {
  const currentStatus = null;
  const [currentEntity, setCurrentEntity] = useState(null);
  const [aiMessages, setAiMessages] = useState([]);

  const [
    [sidebarPanel, setSidebarPanel],
    [componentSidebarPanel, setComponentSidebarPanel],
    [selectedPath, setSelectedPath],
  ] = useSearchParams({
    sidebar: 'form',
    cview: 'component',
    path: null,
  });

  const selectedObject = useMemo(() => (selectedPath ? getObject(form?.schema, selectedPath) : null), [form, selectedPath]);

  const canRemove = useMemo(() => (selectedPath || '').split('.').length % 2 > 0, [selectedPath]);

  const setPathForSelectedObject = useCallback((newPath) => {
    if (selectedPath === newPath || !newPath) {
      return;
    }

    const [parent, selectedName] = splitPath(`schema.${selectedPath}`);
    const [parent2, newName] = splitPath(`schema.${newPath}`);

    if (parent !== parent2) return;
    const parentObj = getObject(form, parent);
    const newUniqueName = determineUniquePropName(newName, parentObj);
    const newUniquePath = `${parent}.${newUniqueName}`.substr(7);

    const newForm = clone.set(
      form, parent,
      obj => obj ? Object.entries(obj).reduce((_, [k, v]) => {
        _[k === selectedName ? newUniqueName : k] = v;

        return _;
      }, {}) : null
    );

    onChange(newForm);
    setSelectedPath(newUniquePath);
  }, [form, selectedPath, setSelectedPath, selectedObject]);

  const objectOnChange = useCallback(
    newObject => {
      const newForm = clone.set(form, `schema.${selectedPath}`, newObject || clone.DELETE_VALUE);
      onChange(newForm);
    },
    [form, onChange, selectedPath]
  );

  const onDeletePath = useCallback(
    path => {
      onChange(clone.set(form, `schema.${path}`, clone.DELETE_VALUE));
    },
    [onChange, form]
  );

  const addChild = (path, childSuffix) => {
    const pathWithSuffix = path ? `${path}.${childSuffix}` : '';
    const schemaPathWithSuffix = path ? `schema.${pathWithSuffix}` : 'schema';
    const name = determineUniquePropName('field', getObject(form, schemaPathWithSuffix));
    const childPath = `${pathWithSuffix}${pathWithSuffix ? '.' : ''}${name}`;
    const newForm = clone.set(form, `schema.${childPath}`, makeDefaultObject());

    onChange(newForm);
    setSelectedPath(childPath);
  };

  const overrideOnSubmit = useCallback((event) => {
    console.log('onSubmit', event);
  }, []);

  const [locateField, setLocateField] = useState();
  const onLocateField = useCallback((id) => {
    const path = getPathFromId(id);
    const arraySchemas = jsonSchemas.collectSchemaChainFor(path, {
      type: 'object',
      properties: form.schema,
    }).map(sc => sc.type === 'array');

    const schemaPath = path
      .split('.')
      .map((p, pidx) => (
        arraySchemas[pidx] ? 'items' : `${pidx ? 'properties.' : ''}${p}`
      ))
      .join('.');
    setSelectedPath(schemaPath);
    setSidebarPanel('sections');
    setLocateField();
  }, [setSelectedPath, form]);

  const moveItem = useCallback((sourcePath, targetPath, direction) => {
    console.log({ sourcePath, targetPath, direction });

    if (
      !sourcePath ||
      !targetPath ||
      sourcePath === targetPath || // no-ops
      targetPath.startsWith(sourcePath) // cannot move to a target path inside the source path
    ) {
      return;
    }

    const [sourceParentPath, sourceName] = splitPath(`schema.${sourcePath}`);
    const [targetParentPath, targetName] = splitPath(`schema.${targetPath}`);

    const placeBefore = direction === 'up';

    const sameParents = targetParentPath === sourceParentPath;

    let newForm = form;

    const newSourceParent = { ...getObject(form, sourceParentPath) };
    const source = newSourceParent[sourceName];
    delete newSourceParent[sourceName];

    newForm = clone.set(newForm, sourceParentPath, newSourceParent);

    const targetParent = getObject(newForm, targetParentPath);
    const newName = sameParents ? sourceName : determineUniquePropName(sourceName, targetParent);

    const newTargetParent = Object.entries(targetParent).reduce((_, [k, v]) => {
      if (k === targetName) {
        if (placeBefore) _[newName] = source;
        _[k] = v;
        if (!placeBefore) _[newName] = source;
      } else {
        _[k] = v;
      }

      return _;
    }, {});

    newForm = clone.set(newForm, targetParentPath, newTargetParent);
    console.log({ newForm });
    onChange(newForm);
  }, [form, onChange]);

  const [dragItem, setDragItem] = useState();
  const dndProps = useMemo(() => ({
    dragItem,
    setDragItem,
    onDrop({ path: targetPath, side }) {
      moveItem(dragItem, targetPath, side);
      setDragItem();
    },
  }), [dragItem, setDragItem, moveItem]);

  return form ? (
    <EditorContainer>
      <UWEFormComponent
        form={form}
        currentStatus={currentStatus}
        currentEntity={currentEntity}
        renderFormSubmitted={false}
        overrideOnSubmit={overrideOnSubmit}
        onLocateField={locateField ? onLocateField : undefined}
      />
      <SidebarPanel
        title={<>
          <UncontrolledDropdown>
            <DropdownToggle caret>{TABS[sidebarPanel]}</DropdownToggle>
            <DropdownMenu>
              {Object.entries(TABS).map(([key, title]) => (
                <DropdownItem key={key} onClick={() => setSidebarPanel(key)}>
                  {title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </>}
        collapsable
        adjustable
        maxHeight={200}
        buttons={<>
          <Button active={locateField} onClick={() => setLocateField(!locateField)}>
            <i className="fa fa-crosshairs" />
          </Button>
          <Button onClick={() => addChild()}>
            <i className="fa fa-plus" />
          </Button>
        </>}
      >
        {sidebarPanel === 'form' ? (<FormDetailsEditor form={form} onChange={onChange} />) : null}
            {sidebarPanel === "ai" ? (<FormAI
                form={form}
                onChange={onChange}
                aiMessages={aiMessages}
                setAiMessages={setAiMessages}
            />) : null}
        {sidebarPanel === 'sections' ? (
          <FormSchemaTree
            form={form}
            selectedPath={selectedPath}
            setSelectedPath={setSelectedPath}
            addChild={addChild}
            onDeletePath={onDeletePath}
            dndProps={dndProps}
          />
        ) : null}
        {sidebarPanel === 'data' ? (<FormDataLoader
          currentEntity={currentEntity}
          setCurrentEntity={setCurrentEntity}
        />) : null}
      </SidebarPanel>
      {(sidebarPanel === 'sections' && selectedObject) ? (
        <SidebarPanel
          title={<>
            <UncontrolledDropdown>
              <DropdownToggle caret>{COMPONENT_TABS[componentSidebarPanel]}</DropdownToggle>
              <DropdownMenu>
                {Object.entries(COMPONENT_TABS).map(([key, title]) => (
                  <DropdownItem key={key} onClick={() => setComponentSidebarPanel(key)}>
                    {title}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </>}
          buttons={<>
            <Button
              onClick={() => setSelectedPath()}
            >
              <i className="fa fa-times" />
            </Button>
          </>}
        >
          {componentSidebarPanel === 'component' ? (
            <FormFieldsEditor
              form={form}
              canRemove={canRemove}
              onChange={objectOnChange}
              setArray={setArray}
              path={selectedPath}
              setPath={setPathForSelectedObject}
              object={selectedObject}
            />
          ) : (componentSidebarPanel === 'jsontext' ? (
            <JsonEditor value={selectedObject} onChange={objectOnChange} />
          ) : (
            <JsonTreeEditor
              mode="object" value={selectedObject}  onChange={objectOnChange}
              hideRoot
            />
          ))}
        </SidebarPanel>
      ) : null}
    </EditorContainer>
  ) : null;
}

function FormSchemaTree({form, selectedPath, setSelectedPath, addChild, onDeletePath, dndProps}) {
  return (
    Object.entries(form?.schema || {}).map(([name, childNode]) => (<JsonSchemaTreeNode key={name}
      node={childNode}
      path={name}
      selectedPath={selectedPath}
      setSelectedPath={setSelectedPath}
      addChild={addChild}
      onDeletePath={onDeletePath}
      dndProps={dndProps}
    />))
  );
}

function FormDataLoader({ currentEntity, setCurrentEntity }) {
  const [[entityId, setEntityId]] = useSearchParams({ entity: null });
  const [loading, error, loadFn] = useLoader();
  const loadEntity = useCallback((e) => {
    e.preventDefault();
    loadFn(async () => {
      const id = entityId | 0;
      console.log({ entityId, id });

      if (id) {
        const entity = await UWEEntityApi.getUWEEntity({ entityId: id });
        setCurrentEntity(entity);
      }
    });
  }, [entityId]);

  return (
    <>
      <form onSubmit={loadEntity}>
        Entity Id:
        <input type="number" value={entityId | 0 || ''} onChange={({ target: { value } }) => setEntityId(value | 0)} />
        {loading ? <Loader /> : <Button>Load</Button>}
      </form>
      {error ? <Notification error={error} /> : null}
      <JsonTreeEditor mode="object" value={currentEntity} onChange={setCurrentEntity} />
    </>
  );
}

function determineUniquePropName(name, object) {
  let candidate = name;
  let i = 1;

  while (candidate in object) {
    candidate = `${name}_${i}`;
    i += 1;
  }

  return candidate;
}

function setArray() {
  return {
    type: 'object',
    'ui:removable': false,
    'ui:addable': false,
    'ui:orderable': false,
    properties: {},
  };
}

function makeDefaultObject() {
  return {
    ':classNames': 'grid-3-columns',
    'title': 'new field',
    'type': 'object',
    'properties': {},
  };
}

export default UWEFormEditor;
