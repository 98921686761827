import { t, Trans } from '@lingui/macro';
import React, { useCallback, useMemo, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import useElementSizeCheckHook from '../../../util/useElementSizeCheckHook';
import RolePermissionSwitch from './RolePermissionSwitch';
import { stepCanHavePermissions } from './utils';

function ViewRolesModal({
  state,
  dispatchAction,
  onClose
}) {
  const {
    details: { name },
    roles,
    steps: allSteps,
  } = state;

  const steps = useMemo(
    () =>
      Object.entries(allSteps)
        .filter(([, step]) => stepCanHavePermissions(step))
        .sort((a, b) => a[1].x - b[1].x),
    [allSteps]
  );

  const onChangePermission = useCallback(
    (stepName, role, permission, value) => {
      dispatchAction.setStepRoleActionPermission({
        step: stepName,
        role,
        permission,
        value,
      });
    },
    [dispatchAction]
  );

  return (
    <Modal
      isOpen
      className="modal-primary"
      size='xl'
      toggle={() => onClose()}
      backdrop
    >
      <ModalHeader toggle={() => onClose()}>
        <Trans>Role permissions for workflow {name}</Trans>
      </ModalHeader>
      <ModalBody style={{ padding: 0 }}>
        <div className="table-wrapper table-fixed-header table-fixed-col-1 bg-white">
          <table className="table table-bordered">
            <thead>
              <StickyHeaderRow
                renderCells={top => (
                  <>
                    <SitckyCell tag="th" left={0} top={top} rowSpan={2} zIndex={11} className="text-center align-middle">
                      Role
                    </SitckyCell>
                    {steps.map(([, stepDef], idx) => (
                      <SitckyCell tag="th" key={idx} top={top} colSpan={(stepDef?.transitions?.length || 0) + 1} className="text-center align-middle">
                        {stepDef.title}
                      </SitckyCell>
                    ))}
                  </>
                )}
              />
              <StickyHeaderRow
                renderCells={top =>
                  steps.map(([, stepDef], idx) => (
                    <React.Fragment key={idx}>
                      <SitckyCell tag="th" top={top} className="text-center">
                        <Trans>View</Trans>
                      </SitckyCell>
                      {(stepDef.transitions || []).map((tx, idx) => (
                        <SitckyCell tag="th" key={idx} top={top} className="text-center">
                          {tx.name || '---'}
                        </SitckyCell>
                      ))}
                    </React.Fragment>
                  ))
                }
              />
            </thead>
            <tbody>
              {roles.map((role, idx) => (
                <RolePermissionsRow key={idx} role={role} steps={steps} onChangePermission={onChangePermission} />
              ))}
            </tbody>
          </table>
        </div>
      </ModalBody>
    </Modal>
  );
}

function RolePermissionsRow({ role, steps, onChangePermission }){
  return (<tr>
    <SitckyCell left={0}>{role}</SitckyCell>
    {steps.map(([stepName, stepDef]) => (<RoleStepPermissionCells key={stepName}
      role={role}
      name={stepName} step={stepDef}
      onChangePermission={onChangePermission}
    />))}
  </tr>);
}

function RoleStepPermissionCells({ role, name, step, onChangePermission }) {
  return (
    <>
      <td>
        <RolePermissionSwitch
          actionName={t`View`}
          role={role}
          isStepRole={step.role === role}
          value={!!step.permissions?.[role]?.view}
          onChange={checked => onChangePermission(name, role, 'view', checked)}
        />
      </td>
      {(step.transitions || []).map((tx, idx) => (
        <td key={idx}>
          <RolePermissionSwitch
            actionName={tx.name}
            role={role}
            isStepRole={step.role === role}
            value={!!step.permissions?.[role]?.[tx.name]}
            onChange={checked => onChangePermission(name, role, tx.name, checked)}
          />
        </td>
      ))}
    </>
  );
}

function StickyHeaderRow({ renderCells }) {
  const [top, setTop] = useState();
  const ref = useElementSizeCheckHook(element => {
    const elTop = element.offsetTop;
    if (elTop !== top) setTop(elTop);
  });

  return <tr ref={ref}>{renderCells(top)}</tr>;
}

function SitckyCell({
  tag: Tag='td',
  top, left,
  style,
  className,
  zIndex=10,
  ...props
}){
  return <Tag
    {...props}
    className={`${className || ''} sticky-cell`}
    style={{
      backgroundColor: 'white',
      ...style,
      top: top !== undefined ? `${top}${Number.isFinite(top) ? 'px': ''}` : undefined,
      left: left !== undefined ? `${left}${Number.isFinite(left) ? 'px': ''}` : undefined,
      zIndex,
      position: 'sticky',
    }} 
  />;
}

export default ViewRolesModal;
