import TextInput from './customFields/TextInput';
import TextArea from './customFields/TextArea';
import PhoneInput from './customFields/PhoneInput';
import MoneyInput from './customFields/MoneyInput';
import CedulaInput from './customFields/CedulaInput';
import SelectWidget from './customFields/SelectWidget';
import RadioButtonsWidget from './customFields/RadioButtonsWidget';

export default {
  TextWidget: TextInput,
  TextareaWidget: TextArea,
  PhoneWidget: PhoneInput,
  MoneyWidget: MoneyInput,
  CedulaWidget: CedulaInput,
  RadioButtons: RadioButtonsWidget,
  SelectWidget,
};