import { useContext, useState } from 'react';
import { t } from '@lingui/macro';
import FormComponent from '../../../components/FormComponent';
import { AuthContext } from '../../../context/AuthContext';
import ThemeSchemaApi from '../../../api/ThemeSchemaApi';
import { useHistory } from 'react-router-dom';
import { Modal } from 'reactstrap';

function SelectThemeForm({ setThemeId, setModal }) {
  const history = useHistory();

  const ThemeForm = FormComponent({
    schema: {
      title: 'Themes',
      type: 'object',
      properties: {
        themes: {
          title: t`Themes`,
          type: 'number',
          lookup: {
            api: 'api:manage/themes/all',
            resource: t`Themes`,
            noValue: '(No Theme Selected)',
            id: 'id',
            label: 'name',
            jnx: 'data',
            setObjectAs: 'selectedThemeObject',
          },
          'ui:field': 'LookupFormField',
        },
      },
    },
    submitButtons: {
      new: {
        text: t`New`,
        className: 'btn-primary',
        onBeforeSubmit: submitEvent => {
          submitEvent.preventDefault();

          setThemeId('new');
        },
      },
      edit: {
        text: t`Edit`,
        className: 'btn-primary',
        'ui:disableIf': '$isFalsy(selectedThemeObject)',
        onBeforeSubmit: submitEvent => {
          submitEvent.preventDefault();

          const {
            formData: { themes },
          } = submitEvent;

          setThemeId(themes);
        },
      },
      delete: {
        text: t`Delete`,
        className: 'btn-warning',
        'ui:disableIf': '$isFalsy(selectedThemeObject)',
        onBeforeSubmit: async submitEvent => {
          submitEvent.preventDefault();
          setModal(true);

          const {
            formData: {
              themes,
              selectedThemeObject: { isDefault },
            },
          } = submitEvent;

          const { success } = await ThemeSchemaApi.delete(themes);

          if (success) {
            history.push(history.location.pathname);
            setModal(false);

            if (isDefault) {
              window.location.reload();
            }
          }
        },
      },
      duplicate: {
        text: t`Duplicate`,
        className: 'btn-success',
        'ui:disableIf': '$isFalsy(selectedThemeObject)',
        onBeforeSubmit: async submitEvent => {
          submitEvent.preventDefault();

          const {
            formData: { themes },
          } = submitEvent;

          const { success, data } = await ThemeSchemaApi.duplicate(themes);

          if (success) {
            setThemeId(parseInt(data.id));
          }
        },
      },
      setDefault: {
        text: t`Set Default`,
        className: 'btn-info',
        'ui:disableIf': '$isFalsy(selectedThemeObject)',
        onBeforeSubmit: async submitEvent => {
          submitEvent.preventDefault();

          const {
            formData: { themes },
          } = submitEvent;

          const { success } = await ThemeSchemaApi.setDefaultTheme(themes);

          if (success) {
            // Show popup confirmation
            window.location.reload();
          }
        },
      },
    },
    uiSchema: {
      themeName: {
        'ui:options': {
          orderable: false,
        },
      },
    },
    loadData: async () => {
      const { success, data } = await ThemeSchemaApi.getThemes();

      if (success) {
        const defaultThemeId = data.find(theme => theme.isDefault)?.id;

        return { themes: defaultThemeId };
      }
    },
    parseProps: ({ setThemeId }) => ({
      auth: useContext(AuthContext),
      setThemeId,
    }),
    async onSubmit({ formData: { themes } }) {},
  });

  return <ThemeForm />;
}

function SelectTheme({ setThemeId }) {
  const [modal, setModal] = useState(false);

  return (
    <>
      <SelectThemeForm setThemeId={setThemeId} setModal={setModal} />
      <Modal isOpen={modal} fullscreen></Modal>
    </>
  );
}

export default SelectTheme;
