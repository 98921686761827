import React, { useMemo } from 'react';
import { FormGroup } from 'reactstrap';
import OEFJnxField from '../_formEditor/ObjectEditorField/JnxField';

function JnxField(props) {
  const {
    formData,
    idSchema: { $id },
    schema: {
      title,
      hideDebug,
      hideScope,
      hideModal,
      test,
    },
    uiSchema: {
      'akc:requiredIfVisible': akcRequiredIfVisible,
      'ui:readonly': readonly,
    },
    disabled,
    required: requiredProp,
    onChange,
  } = props;
  const required = requiredProp || akcRequiredIfVisible;
  const fieldProps = useMemo(
    () => ({
      hideDebug,
      hideScope,
      hideModal,
    }),
    [hideDebug, hideScope, hideModal, test]
  );

  return (
    <FormGroup disabled={readonly || disabled}>
      {title !== ' ' ? (
        <label className="control-label" htmlFor={$id}>
          {title}
          {required ? <span className="required">*</span> : null}
        </label>
      ) : null}
      <OEFJnxField fieldProps={fieldProps} value={formData} object={null} onChange={onChange} />
    </FormGroup>
  );
}

export default JnxField;
