import React, { useEffect, useCallback, useContext, useState, useMemo } from 'react';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { Button, Alert, Spinner, Container } from 'reactstrap';
import { AuthContext } from '../context/AuthContext';
import useLoader from '../util/useLoader';
import { Redirect } from 'react-router';

const LOGIN_REQUEST = {
    scopes: ["User.Read"]
};

const LOGIN_STATES = {
    PERFORMING_SSO_AUTH: 'PERFORMING_SSO_AUTH',
    NO_SSO_ACCOUNT: 'NO_SSO_ACCOUNT',
    LOGGING_IN: 'LOGGING_IN',
    LOGGED_IN: 'LOGGED_IN',
    FAILED_LOGIN: 'FAILED_LOGIN',
};

function MSALLoginForm() {
    const auth = useContext(AuthContext);
    const [loadingMsalAuth, setLoadingMsalAuth] = useState(true);
    const [loadingLogin, errorInLogin, loginLoader] = useLoader();
    const { login: _login, result, error: ssoLoginError } = useMsalAuthentication(InteractionType.Redirect, LOGIN_REQUEST);
    const { instance: msalInstance, accounts } = useMsal();
    const ssoAccount = msalInstance.getActiveAccount() || (accounts.length === 1 && accounts[0]);

    const performSSOLogin = useCallback(() => {
        setLoadingMsalAuth(true);
        _login(InteractionType.Redirect, LOGIN_REQUEST);
    }, [_login]);

    const state = useMemo(() => determineLoginState(loadingMsalAuth, ssoAccount, loadingLogin, auth), [
        loadingMsalAuth,
        ssoAccount,
        loadingLogin,
        auth,
    ]);

    useEffect(() => {
        if (!ssoAccount && !result && !ssoLoginError) return;
        setLoadingMsalAuth(false);
    }, [result, ssoLoginError, ssoAccount]);

    const { action, render } = LOGIN_STATE_DEF[state] || {};

    useEffect(() => {
        if (action) action({
            auth,
            msalInstance,
            ssoAccount,
            loginLoader,
        });
    }, [action, auth, ssoAccount, loginLoader, msalInstance]);

    return (
        <Container className="text-center mt-5">
            {/* <img style={{ height: '150px', objectFit: 'contain' }} src={logo} alt="logo" /> */}
            {render && render({
                errorInLogin,
                ssoAccount,
                ssoLoginError,
                performSSOLogin
            })}
        </Container>
    );
}

const LOGIN_STATE_DEF = {
    [LOGIN_STATES.PERFORMING_SSO_AUTH]: {
        render: () => (
            <div className="text-center">
                <Spinner color="black" />
                <p>Verifying SSO Login status...</p>
            </div>
        ),
    },
    [LOGIN_STATES.NO_SSO_ACCOUNT]: {
        render: ({ performSSOLogin, ssoLoginError }) => (
            <>
                {ssoLoginError && (
                    <Alert color="danger" className="mt-3">
                        {ssoLoginError.message || "An error occurred while logging in."}
                    </Alert>
                )}
                <p>You are not logged in. Please click the following button to start the login process.</p>
                <Button color="blue" className="mt-3" onClick={performSSOLogin}>
                    Log In
                </Button>
            </>
        ),
    },
    [LOGIN_STATES.LOGGING_IN]: {
        render: () => (
            <div className="text-center">
                <Spinner color="black" />
                <p>Logging you into the app...</p>
            </div>
        ),
        action: ({ auth, ssoAccount, loginLoader, msalInstance }) => {
            loginLoader(() => auth.loginWithSSO({ msalInstance, ssoAccount }));
        },
    },
    [LOGIN_STATES.LOGGED_IN]: {
        render: () => (
            <p className="text-success">
                You are logged in.
                <Redirect path="/" />
            </p>
        ),
    },
    [LOGIN_STATES.FAILED_LOGIN]: {
        render: ({ errorInLogin }) => (
            <>
                {errorInLogin && (
                    <Alert color="danger" className="mt-3">
                        {errorInLogin.message || "Login attempt failed."}
                    </Alert>
                )}
                <p>
                    For some reason, your login attempt failed.
                    You might not have the correct authorization to use this app.
                    Please contact an administrator.
                </p>
            </>
        ),
    },
};

function determineLoginState(loadingMsalAuth, account, loadingLogin, auth) {
    if (auth?.user) {
        return LOGIN_STATES.LOGGED_IN;
    }
    if (loadingMsalAuth) return LOGIN_STATES.PERFORMING_SSO_AUTH;
    if (!account) return LOGIN_STATES.NO_SSO_ACCOUNT;
    if (loadingLogin === undefined || loadingLogin === true) return LOGIN_STATES.LOGGING_IN;

    return LOGIN_STATES.FAILED_LOGIN;
}

export default MSALLoginForm;
