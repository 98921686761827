export function formatPercent(x, options) {
  return formatNumber(+x * 100, { ...options, suffix: '%' });
}

export function formatUSD(x) {
  return formatNumber(x, { prefix: '$' });
}

export class Culture {
  constructor(name, defs) {
    defs = defs || {};
    this.name = name;

    const currency = defs.currency ?? {};
    const separators = defs.separators ?? {};

    this.separators = {
      thousands: separators.thousands ?? ',',
      decimal: separators.thousands ?? '.',
    };

    this.currency = {
      symbol: currency.symbol ?? '$',
      left: currency.left ?? true,
      precision: currency.precision ?? 2,
      negativeParens: true,
    };
  }
}

export const DEFAULT_PRECISIONS = {
  binaryDigits: 8,
  hexDigits: 2,
  numberDecimals: 16,
  percentDecimals: 2,
};

export const CULTURES = {
  'none': new Culture('en-US', {
    currency: { symbol: '@', left: true, precision: 2 },
    separators: { thousands: '' },
  }),
  'en-US': new Culture('en-US', {
    currency: { symbol: '$', left: true, precision: 2 },
  }),
};

export const DEFAULT_CULTURE = CULTURES['en-US'];

export const STANDARD_FORMAT_OPTION_FNS = {
  b: ({ precision }) => ({
    base: 2,
    digits: precision ?? DEFAULT_PRECISIONS.binaryDigits,
    thousandsSeparator: '',
    decimalPlaces: 0,
  }),
  c: ({ precision, culture: { currency, separators } }) => ({
    decimalPlaces: precision ?? currency.precision,
    thousandsSeparator: separators.thousands,
    decimalSeparator: separators.decimal,
    [currency.left ? 'prefix' : 'suffix']: currency.symbol,
    negativeParens: currency.negativeParens,
  }),
  d: ({ precision }) => ({
    digits: precision,
    decimalPlaces: 0,
    thousandsSeparator: '',
  }),
  e: ({ formatCode, precision, culture: { separators } }) => ({
    exponent: formatCode,
    decimalPlaces: precision ?? 6,
    decimalSeparator: separators.decimal,
  }),
  f: ({ precision, culture: { separators } }) => ({
    decimalPlaces: precision ?? DEFAULT_PRECISIONS.numberDecimals,
    decimalSeparator: separators.decimal,
  }),
  g: ({ formatCode, precision, culture: { separators } }) => ({
    exponent: formatCode === 'G' ? 'E' : 'e',
    exponentConditional: true,
    decimalPlaces: precision ?? DEFAULT_PRECISIONS.numberDecimals,
    decimalSeparator: separators.decimal,
  }),
  n: ({ precision, culture: { separators } }) => ({
    thousandsSeparator: separators.thousands,
    decimalSeparator: separators.decimal,
    decimalPlaces: precision ?? DEFAULT_PRECISIONS.numberDecimals,
  }),
  p: ({ precision, culture: { separators } }) => ({
    suffix: '%',
    scale: 100,
    thousandsSeparator: separators.thousands,
    decimalSeparator: separators.decimal,
    decimalPlaces: precision ?? DEFAULT_PRECISIONS.percentDecimals,
  }),
  r: () => ({
    thousandsSeparator: '',
    decimalSeparator: '.',
    decimalPlaces: DEFAULT_PRECISIONS.numberDecimals,
    ignoreZeroDecimals: true,
  }),
  h: ({ precision }) => ({
    base: 16,
    digits: precision ?? DEFAULT_PRECISIONS.hexDigits,
    thousandsSeparator: '',
    decimalPlaces: 0,
  }),
};

export class NumberFormatOptions {
  static parse(text) {
    const m = /^((([a-zA-Z])(\d+)?([a-zA-Z][a-zA-Z]-[a-zA-Z][a-zA-Z])?)|())$/.exec(text);
    console.log('parse', { text, m });
    if (!m) return new NumberFormatOptions();

    if (m[3]) {
      const stdFormat = NumberFormatOptions.standardFormat(m[3], m[4] ? m[4] | 0 : undefined, CULTURES[m[5]]);
      if (stdFormat) return stdFormat;
    }

    // const stdFormat = m[3];
    // const stdPrecision = m[3];

    return new NumberFormatOptions();
  }

  static standardFormat(formatCode, precision, culture) {
    const optionsFn = STANDARD_FORMAT_OPTION_FNS[formatCode.toLowerCase()];
    console.log('standardFormat', { formatCode, precision, culture });

    const format = new NumberFormatOptions(
      optionsFn({
        formatCode,
        precision,
        culture: culture ?? DEFAULT_CULTURE,
      })
    );

    return optionsFn ? format : null;
  }

  static normalize(options) {
    if (typeof options === 'string') return NumberFormatOptions.parse(options);
    if (options instanceof NumberFormatOptions) return options;

    return new NumberFormatOptions(options);
  }

  constructor(options) {
    options = options ?? {};
    this.base = options.base ?? 10;
    this.scale = options.scale ?? 1;
    this.digits = options.digits;
    this.exponent = options.exponent;
    this.exponentConditional = options.exponentConditional;
    this.negativeParens = options.negativeParens ?? true;
    this.prefix = options.prefix ?? '';
    this.suffix = options.suffix ?? '';
    this.nonFinitePlaceholder = options.nonFinitePlaceholder ?? undefined;
    this.decimalSeparator = options.decimalSeparator ?? '.';
    this.ignoreSign = options.ignoreSign ?? false;
    this.ignoreZeroDecimals = options.ignoreZeroDecimals ?? false;
    this.thousandsSeparator = options.thousandsSeparator ?? ',';
    this.thousandsGrouping = options.thousandsGrouping ?? 3;
    this.decimalPlaces = options.decimalPlaces ?? 0;
  }
}

export function formatNumber(x, options) {
  const {
    scale: valueScale,
    digits,
    negativeParens,
    prefix,
    suffix,
    nonFinitePlaceholder,
    decimalSeparator,
    ignoreSign,
    ignoreZeroDecimals,
    thousandsSeparator,
    thousandsGrouping,
    decimalPlaces,
  } = NumberFormatOptions.normalize(options);

  const value = (+x) * valueScale;
  const isNegative = value < 0;
  let absValue = Math.abs(x);

  if (Number.isFinite(absValue)) {
    const scale = 10 ** decimalPlaces;
    absValue = ((0.5 + absValue * scale) | 0) / scale;

    if (decimalPlaces <= 0) {
      const valInt = ((0.5 + absValue * scale) | 0) / scale;

      let valIntStr = `${valInt}`;

      if (Number.isFinite(digits)) {
        if (valIntStr.length <= digits) {
          valIntStr = leftPad(valIntStr, digits, '0');
        }
      }

      const integer = reverseSplitInChunks(valIntStr, 3).join(thousandsSeparator);
      absValue = integer;
    } else {
      const valInt = absValue | 0;
      const valFracFragment = (((absValue - valInt) * scale) + 0.5);
      const valFrac = (valFracFragment | 0);
      const integer = reverseSplitInChunks(`${valInt}`, thousandsGrouping).join(thousandsSeparator);
      let fractional = leftPad(`${valFrac}`, decimalPlaces, '0');
      if (ignoreZeroDecimals) fractional = fractional.replace(/0*$/, '');

      absValue = `${integer}${fractional.length ? decimalSeparator : ''}${fractional}`;
    }
  } else {
    absValue = nonFinitePlaceholder ?? absValue;
  }

  if (isNegative && !ignoreSign) {
    return negativeParens ? `(${prefix}${absValue}${suffix})` : `${prefix}-${absValue}${suffix}`;
  } else {
    return `${prefix}${absValue}${suffix}`;
  }
}

export function cleanNumber(x, options) {
  const {
    negativeParens,
    prefix,
    suffix,
    nonFinitePlaceholder,
    decimalSeparator,
    ignoreSign,
    thousandsSeparator,
  } = NumberFormatOptions.normalize(options);
  let text = x;
  let sign = 1;

  // console.log("cleanNumber", x, options);

  if (nonFinitePlaceholder && text === nonFinitePlaceholder) return undefined;

  if (negativeParens && text.startsWith('(') && text.endsWith(')')) {
    text = text.substr(1, text.length - 2);
    sign = -1;
  }
  // console.log("   -> ", text);

  if (text.startsWith(prefix)) text = text.substr(prefix.length);
  // console.log("   -> ", text);
  if (text.endsWith(suffix)) text = text.substr(0, text.length - suffix.length);
  // console.log("   -> ", text);

  text = text.replaceAll(thousandsSeparator, '');
  // console.log("   -> ", text);
  text = text.replaceAll(decimalSeparator, '.');
  // console.log("   -> ", text);

  let value = sign * text;
  // console.log(`   -> ${sign} * ${text}`, value);
  if (ignoreSign) value = Math.abs(value);
  // console.log("   -> ", value);

  return Number.isFinite(value) ? value : undefined;
}

function reverseSplitInChunks(text, chunkLen) {
  const N = text.length;
  const list = [];
  let to = N;
  let from = to - chunkLen;

  while (from > 0) {
    list.unshift(text.substring(from, to));
    to = from;
    from = to - chunkLen;
  }

  list.unshift(text.substring(0, to));

  return list;
}

function leftPad(text, n, filler = '0') {
  n = n | 0;
  text = `${text}`;

  if ((filler?.length | 0) === 0) filler = '0';

  const list = Array.from(new Array(Math.max(0, n - text.length)), () => filler);
  list.push(text);

  const result = list.join('');
  const N = result.length;

  return result.substr(N - n, n);
}
