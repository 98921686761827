import React, { useMemo } from 'react';

import { matchesPattern } from '../../../util/patternMatching';
import OE_FIELD_MAP from './types';
import ErrorBoundary from '../../ErrorBoundary';
import { useJnx } from '../../../util/jnx';

function ObjectEditorField({
  id, path, fieldProps: baseFieldProps, onChange, value, scope, object
}) {
  const fieldPropsJnx = useJnx(baseFieldProps.jnx);
  const fieldProps = useMemo(() => {
    let fieldProps = baseFieldProps;

    if (fieldPropsJnx) fieldProps = {...fieldProps, ...fieldPropsJnx.eval(
      object,
      fieldProps.property,
      { scope, fieldProps, object, path }
    )};

    return fieldProps;
  }, [baseFieldProps, fieldPropsJnx, object, path, scope]);

  const showIfJnx = useJnx(fieldProps.showIf);

  const show = useMemo(() => (
    showIfJnx ?
    showIfJnx.eval(
      object,
      fieldProps.property,
      { scope, fieldProps, object, path }
    ) :
    true
  ), [showIfJnx, object, path, scope]);

  const Component = OE_FIELD_MAP.filter(fdef => matchesPattern(fieldProps, fdef.pattern))[0]?.Component;

  return Component && show ? (
    <div className={fieldProps.class ?? 'col-lg-12'}>
      <ErrorBoundary>
        <Component id={id} path={path} object={object} fieldProps={fieldProps} scope={scope} value={value} onChange={onChange} />
      </ErrorBoundary>
    </div>
  ) : null;
}

export default ObjectEditorField;
