import React, { useCallback, useMemo } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import ObjectEditorField from './ObjectEditorField';

import { getLabel } from '../../../LanguageConstant';

const PATTERN = { type: 'array' };

function ArrayField({ path, fieldProps: prop, value: propValue2, onChange: arrayOnChange, scope, object }) {
  const isObjectArray = !!prop.items;
  const propValue = useMemo(() => propValue2 || [], [propValue2]);

  const onAddElement = useCallback(
    e => {
      e.preventDefault();
      const newValue = propValue.slice();
      newValue.push(isObjectArray ? {} : '');
      arrayOnChange(newValue);
    },
    [propValue, isObjectArray, arrayOnChange]
  );

  const onDeleteElement = useCallback(
    (e, idx) => {
      e.preventDefault();
      const newValue = propValue.slice();
      newValue.splice(idx, 1);
      arrayOnChange(newValue);
    },
    [propValue, arrayOnChange]
  );

  const onEditElement = useCallback(
    (e, idx, value) => {
      e.preventDefault();
      const newValue = propValue.slice();
      newValue[idx] = value;
      arrayOnChange(newValue);
    },
    [propValue, arrayOnChange]
  );

  const onChange = useCallback(
    (idx, value) => {
      const newValue = propValue.slice();
      newValue[idx] = value;
      arrayOnChange(newValue);
    },
    [prop, arrayOnChange, propValue]
  );

  function renderObjectArrayItem(item, idx) {
    return (
      <Card key={idx}>
        <CardBody>
          <Button
            onClick={e => onDeleteElement(e, idx)}
            title="cerrar"
            style={{ position: 'absolute', right: '-2px', top: '0', background: 'transparent', border: 'none', color: 'black' }}
          >
            <i className="fa fa-trash" />
          </Button>
          <div key={idx} className="row">
            <ObjectEditorField
              key={idx}
              id={idx}
              path={`${path}[${idx}]`}
              fieldProps={prop.items}
              object={object}
              scope={scope}
              value={item}
              onChange={value => onChange(idx, value)}
            />
          </div>
        </CardBody>
      </Card>
    );
  }

  function renderNonObjectArrayItem(item, idx) {
    return (
      <div key={idx} className="row">
        <div className="col-lg-12" style={{ display: 'inline-flex', alignItems: 'center' }}>
          <input type="string" className="form-control" value={item} name={prop.property} onChange={e => onEditElement(e, idx, e.target.value)} />
          <i onClick={e => onDeleteElement(e, idx)} className="fa fa-trash" aria-hidden="true" />
        </div>
      </div>
    );
  }

  return (
    <>
      <label className="title">{prop.label}</label>
      {propValue.map(isObjectArray ? renderObjectArrayItem : renderNonObjectArrayItem)}
      <span aria-hidden="true" className="lnkAddOption" onClick={onAddElement}>
        {getLabel().addOption} <i className="fa fa-plus" />
      </span>
    </>
  );
}

ArrayField.pattern = PATTERN;

export default ArrayField;
