import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Button, DropdownMenu, DropdownToggle, FormGroup, Dropdown } from 'reactstrap';
import Notification from '../Notification';
import ElementIcon from '../ElementIcon';
import Loader from '../Loader';
import useLoader from '../../util/useLoader';
import faIcons from '../../util/icons/faIcons';
import dimoIcons from '../../util/icons/dimoIcons';


const ICON_LISTS = [
  faIcons,
  dimoIcons
];

function SelectImageField(props) {
  const {
    formData,
    schema: { title },
    idSchema: { $id },
  } = props;

  const {
    lists,
    isOpen, toggle,
    onListClick,
    currentIdx,
    selectedIconList,
    onSelectIcon,
  } = useSelectImageFieldHooks(props);

  return (
    <FormGroup className="select-image-field">
      {title !== ' ' ? (
        <label className="control-label" htmlFor={$id}>
          {title}
        </label>
      ) : null}
      <div>
        <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggle caret>
            <ElementIcon element={formData} fontSize="2em" />
          </DropdownToggle>
          {isOpen ? (<DropdownMenu>
            <div className="list-tabs">
              {lists.map((iconList, idx) => (
                <Button key={idx} color={idx === currentIdx ? 'primary' : 'secondary'} list={idx} onClick={onListClick}>
                  <ElementIcon element={iconList.image} />
                </Button>
              ))}
            </div>
            <IconList
              selectedIcon={formData}
              iconList={selectedIconList}
              onSelectIcon={onSelectIcon}
            />
          </DropdownMenu>) : null}
        </Dropdown>
      </div>
    </FormGroup>
  );
}

function useSelectImageFieldHooks(props) {
  const { onChange } = props;

  const [isOpen, setOpen] = useState();
  const toggle = useCallback(() => setOpen(isOpen => !isOpen), []);

  const [currentIdx, setCurrentIdx] = useState(0);
  const selectedIconList = useMemo(() => ICON_LISTS[currentIdx] || {}, [currentIdx]);

  const onListClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentIdx(e.currentTarget.attributes.list?.value | 0);
  }, []);

  const onSelectIcon = useCallback(
    icon => {
      onChange(icon);
      setOpen();
    },
    [onChange]
  );

  return {
    lists: ICON_LISTS,
    isOpen,
    toggle,
    onListClick,
    currentIdx,
    selectedIconList,
    onSelectIcon,
  };
}


function IconList(props) {
  const {
    showIconIndex,
  } = props;

  const {
    list, loading, errorLoading,
    selectedIdx,
    onIconClick,
  } = useIconListHooks(props);

  return (loading ? (
    <Loader />
  ) : (errorLoading ? (
    <Notification error={errorLoading} />
  ) : (
    <div className='button-list' >{list.map((item, idx) => (
      <Button key={idx} icon={idx}
        color={idx === selectedIdx ? 'primary': 'secondary'}
        onClick={onIconClick}
      >
        <ElementIcon element={item} />
        {showIconIndex ? <span className='idx'>{idx + 2}</span> : null}
      </Button>
    ))}</div>
  )));
}


function useIconListHooks({
  selectedIcon,
  iconList, onSelectIcon
}){
  const [loading, errorLoading, loadFn] = useLoader();

  const [list, setList] = useState([]);

  useEffect(() => {
    if (!iconList) {
      setList([]);
    } else if (iconList.items) {
      setList(iconList.items);
    } else if (iconList.getList) {
      loadFn(async () => {
        setList(await iconList.getList());
      });
    } else {
      setList([]);
    }
  }, [iconList]);

  const onIconClick = useCallback((e) => {
    onSelectIcon(list[e.currentTarget.attributes.icon?.value | 0]);
  }, [list, onSelectIcon]);

  const currentMap = useMemo(() => {
    if (!iconList.map) iconList.map = list.reduce((_, item, idx) => {
      _[computeKey(item)] = idx;

      return _;
    }, {});

    return iconList.map;
  }, [list, iconList.map]);

  const selectedIdx = useMemo(() => (
    currentMap[computeKey(selectedIcon)]
  ), [currentMap, selectedIcon]);

  return {
    list,
    loading,
    errorLoading,
    selectedIdx,
    onIconClick,
  };
}

function computeKey(item) {
  if (!item) return 'none';
  const { icon, icons, image } = item;

  return (icon ? (
    `icon.${icon}`
  ) : (icons ? (
    `icons.${icons.base}`
  ) : (
    `image.${image}`
  )));
}

export default SelectImageField;
