import { t } from '@lingui/macro';
import Workpage from '../../../components/Workpage/index';

const ListRoles = Workpage.HOC({
  slug: '?role=',
  schema: {
    title: t`Roles`,
    type: 'role',
    elements: [
      {
        title: t`Roles`,
        type: 'worktray',
        ':className': 'col-lg-12',
        resource: 'manage/roles',
        tableActions: [
          {
            label: t`Create Role`,
            route: '${workpage.slug}new',
          },
        ],
        filters: [{ title: t`Name`, key: 'name', type: 'string' }],
        columns: [
          { title: t`Name`, sortkey: 'name', 'map:field': 'name' },
          { title: t`Workpages`, sortkey: 'name', 'map:field': { expr: "workpageList ~> $join(',\n ')" } },
          {
            title: t`Actions`,
            actions: [
              {
                label: t`Edit`,
                route: '${workpage.slug}$id',
              },
            ],
          },
        ],
      },
    ],
  },
});

export default ListRoles;
