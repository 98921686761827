import React, { useState, useCallback, useContext, useMemo } from 'react';
import { NavbarToggler } from 'reactstrap';
import { useHistory, useRouteMatch } from 'react-router';

import TabPanel from '../TabPanel';
import AppContext from '../../context/AppContext';
import UWEContext from '../../context/UWEContext';

function Sidebar(props) {
  const { sidebarTab, setSelected, tabs } = useSidebarHooks(props);

  const [collapsed, setCollapsed] = useState(() => {
    const vpwidth = window.innerWidth;

    if (window.sessionStorage.getItem('sidebar') == null) {
      window.sessionStorage.setItem('sidebar', vpwidth < 1000 ? 'closed' : 'open');

      return vpwidth < 1000;
    }

    return window.sessionStorage.getItem('sidebar') === 'closed';
  });

  function toggleNavbar() {
    window.sessionStorage.setItem('sidebar', !collapsed ? 'closed' : 'open');
    setCollapsed(!collapsed);
  }

  return (
    <>
      <div className="sidebar-hamburger">
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
      </div>
      <div className={`sidebar ${collapsed ? 'is-collapsed' : 'is-not-collapsed'}`}>
        <TabPanel noText selected={sidebarTab} setSelected={setSelected} tabs={tabs} showContent={false} />
      </div>
    </>
  );
}

function useSidebarHooks({ tabs: propTabs, activeTab: propActiveTab, setActiveTab: propSetActiveTab }) {
  const usePropTabs = propTabs && propSetActiveTab;

  const {
    params: { slug: sidebarTab },
  } = useRouteMatch();

  const { sidebarExpanded } = useContext(AppContext);
  const { workpages } = useContext(UWEContext);
  const history = useHistory();

  const workpageTabs = useMemo(
    () =>
      workpages?.reduce((_, db) => {
        _[db.slug] = { ...db.image, path: `/workflow/${db.slug}/tray`, name: db.name };

        return _;
      }, {}),
    [workpages]
  );

  const setSelected = useCallback(
    newSelected => {
      const obj = workpageTabs[newSelected];
      console.log('setSelected', newSelected, sidebarTab, newSelected === sidebarTab);

      if (obj && obj.path) {
        history.push(obj.path);
      }
    },
    [sidebarTab, sidebarExpanded, workpageTabs]
  );

  return usePropTabs
    ? {
        tabs: propTabs,
        sidebarTab: propActiveTab,
        setSelected: propSetActiveTab,
      }
    : {
        tabs: workpageTabs,
        sidebarTab,
        setSelected,
      };
}

export default Sidebar;
