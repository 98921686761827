const hookResult = {
    isMSALAuthRedirect: detectMsalAuthenticationHash(document.location?.hash)
};

function useMSALAuthRedirectDetection(){
    return hookResult;
}

function detectMsalAuthenticationHash(locationHash){
//    console.log("detectMsalAuthenticationHash :: ", locationHash);

    if (!locationHash || !locationHash.startsWith('#')) return false;

    const parsed = locationHash.substring(1).split('&').reduce((_, x) => {
        const l = x.split('=');
        _[l[0]] = l.slice(1).join('=');
        return _;
    }, {})

    const isMsalAuth = !!(
        parsed.code ||
        parsed.error_description ||
        parsed.error ||
        parsed.state
    );

//    console.log("isMsalAuth", isMsalAuth);

    return isMsalAuth;
}


export default useMSALAuthRedirectDetection;