// import { Trans } from '@lingui/react';
import React from 'react';
import { Redirect } from 'react-router';
import UserApi from '../../api/UserApi';
import FormComponent from '../../components/FormComponent';
import InternalLayout from '../../components/layouts/InternalLayout';
import { EditUserForm } from './EditUser';

const CreateNewUser = FormComponent({
  displayName: 'Nuevo Usuario',
  schema: {
    ...EditUserForm.formDefinition.schema,
    title: 'Nuevo Usuario',
  },
  uiSchema: EditUserForm.formDefinition.uiSchema,
  validate: EditUserForm.formDefinition.validate,
  submitButtons: {
    submit: { text: 'Crear Usuario', className: 'btn-primary' },
  },
  async onSubmit({ formData }) {
    await UserApi.createUser(formData);
  },
  renderFormSubmitted: () => (
    <Redirect to="/system-settings/users" />
  )
});

export default function() {
  return (
    <InternalLayout>
      <CreateNewUser />
    </InternalLayout>
  );
}

