

export function getScrollParent(element, includeHidden) {
  if (!element) return document.body;

  let style = getComputedStyle(element);
  const excludeStaticParent = style.position === 'absolute';
  const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

  if (style.position === 'fixed') return document.body;

  while (element) {
    style = getComputedStyle(element);

    if (
      !(excludeStaticParent && style.position === 'static') &&
            overflowRegex.test(style.overflow + style.overflowY + style.overflowX)
    ){
      return element;
    }

    element = element.parentElement;
  }

  return window;
}
