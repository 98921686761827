import React, { useState } from "react";
import _ from "lodash";
import AIApi from "../../../api/AIApi";
import { correctJson, PromptAndHistory } from "../../../util/aiPromptBox";

function WorkflowAI({
  dispatchAction,
  state,
}) {

  const [prompt, setPrompt] = useState("");
  const [loadingGenerateWorkflow, setLoadingGenerateWorkflow] = useState(false);

  const onGenerateWorkflow = () => {

    if (!prompt) return;

    const wfDefinition = JSON.stringify(state?.workflow?.definition || '');

    setLoadingGenerateWorkflow(true);
    AIApi.generateWorkflow({ content: prompt, workflow: wfDefinition, messages: state?.messages }).then(response => {
      if (response?.jsonResponse) {
        setLoadingGenerateWorkflow(false);
        const definition = JSON.parse(correctJson(response.jsonResponse));
        dispatchAction.setWorkflow({ definition });

        Object.keys(definition).map((key) => {
          if (!state?.workflow?.definition?.[key]) {
            dispatchAction.rearrangeRole({ name: key, data: definition[key] });
          } else {
            dispatchAction.rearrangeRole({ name: key, data: definition[key], exist: true });
          }
          return true;
        })
      }
      if (response?.messages) {
        dispatchAction.setMessages({ messages: response.messages })
      }

      setPrompt("");
    });
  }

  return (<div className="container mt-2">

    {loadingGenerateWorkflow ? <div className="text-center d-flex justify-content-center mt-2" style={{ height: 250, alignItems: 'center' }}><i style={{ fontSize: '27px' }} className="fa fa-magic pulse-effect" /></div> :
      <PromptAndHistory
        onConfirm={onGenerateWorkflow}
        messages={state?.messages}
        prompt={prompt}
        setPrompt={setPrompt}
      />
    }

  </div>);
}

export default WorkflowAI;
