import React, { useState, useMemo, useEffect, useRef, useCallback, useContext, forwardRef } from 'react';
import { useHistory } from 'react-router';
import { Card, CardBody } from 'reactstrap';
import _ from 'lodash';
import {
  clone,
  mergeFlatListToErrorSchema,
  errorSchemaToFlatList,
  useLoader,
  Jnx,
  useJnx,
  useJnxFormValidation,
  parseUISchemaFromSchema,
  useSideChannel,
  useSideChannelSubscription,
  useStateUpdateRequestPromise,
} from '@akcelita/uwe-core';
import { Form, Loader, Notification, baseCustomFields } from '@akcelita/uwe-components';
import allCustomFields from './customFields';
const customFields = {
  ...baseCustomFields,
  ...allCustomFields,
};

import widgets from './customWidgets';

import { invertMap, mapObject } from '../util/mapObject';
import useTitle from '../util/useTitle';
import ErrorListTemplate, { transformErrors } from './ErrorListTemplate';
import ArrayFieldTemplate from './customFields/ArrayFieldTemplate/ArrayFieldTemplate';
import SchemaFieldTemplate from './customFields/SchemaFieldTemplate';
import ObjectFieldTemplate from './customFields/ObjectFieldTemplate';
import Help from './Help';
import FormModal, { useOpenModal } from './FormModal';
import AppContext from '../context/AppContext';
import ModalContainer from './ModalContainer';
import { AuthContext } from '../context/AuthContext';
import RequestReloadContext from '../context/RequestReloadContext';

/** Higher level component for creating a form component customized by the given arguments.
 *  @param {Objec} formDefinition - definition object of the form component.
 *  @param {string} displayName - displayed component name in react component tree
 *  @param {object} schema - a schema used to render the dynamic form (@see rjsf )
 *  @param {object} uiSchema - a uiSchema used to render the dynamic form (@see rjsf )
 *  @param {function(object): object} parseProps - callback that parses component props into a flat property mapping.
 *                  This mapping gets passed to the other callbacks.
 *                  Can also call React hooks and pass then in the mapping.
 *  @param {function(object): Promise} onSubmit - callback that handles form submission. Receives rjsf's onSubmit argument
 *                  and the parsed props as arguments, should return a promise that resolves if the submission was successful.
 *  @param {string} validate - Optional callback that handles form validation. Receives rjsf's onSubmit argument
 *                  and the parsed props as arguments.
 *  @param {string} renderFormChildren - callback for rendering the form component's children. Receives a props object with
 *                  the component's render props merged with the following attributes:
 *                      {object} scope - the flat property mapping created in parseProps
 *                      {object} formDefinition - the definition object passed to this function
 *  @param {string} renderFormSubmitted - callback for rendering a post submission component. Receives a props object with
 *                  the component's render props merged with the following attributes:
 *                      {object} scope - the flat property mapping created in parseProps
 *                      {object} formDefinition - the definition object passed to this function
 *
 */
function FormComponent(formDefinition) {
  const {
    alignButtons,
    beforeRenderHook = () => {},
    buildFormContext = undefined,
    commentFieldsMap: fdCommentFieldsMap = null,
    customFormats = undefined,
    displayName = 'FormComponent',
    hideDataLoader,
    loadData = undefined,
    noHtml5Validate,
    objectMap: fdObjectMap,
    onChange: propOnChange,
    onSubmit: propOnSubmit,
    parseProps = () => ({}),
    renderFormChildren,
    renderFormDetails,
    renderFormSubmitted,
    renderNavigation,
    schema: fdSchema = {},
    submitButtons: constSubmitButtons = null,
    title,
    uiSchema: fdUiSchemaProp = {},
    validate: propValidate,
  } = formDefinition;

  const fdUiSchema = parseUISchemaFromSchema(fdSchema, fdUiSchemaProp);

  let { staticFormData } = formDefinition;

  const staticUnmappedFormData = staticFormData;

  if (staticFormData && fdObjectMap) {
    staticFormData = mapObject(staticFormData, invertMap(fdObjectMap));
  }

  const staticEffects = [];

  function Component(props) {
    const currentFormDef = useRef();
    const currentFormData = useRef();
    const navRef = useRef();
    const formRef = useRef();
    const formComponentRef = useRef();
    const submitState = useRef();
    const extraErrors = undefined;
    const setExtraErrors = useCallback(
      extraErrorSchema => {
        formComponentRef.current?.setState(st => ({
          errorSchema: mergeFlatListToErrorSchema([...errorSchemaToFlatList(st.errorSchema || {}), ...errorSchemaToFlatList(extraErrorSchema)]),
        }));
      },
      [formComponentRef]
    );

    const rrc = useContext(RequestReloadContext);

    const [loadingAction, loadingError, loadFn] = useLoader();
    const [formSubmitted, setFormSubmitted] = useState();
    const [formSubmitResult, setFormSubmitResult] = useState();
    const [errorLoadingInitialFormData, setErrorLoadingInitialFormData] = useState();
    const [loadingInitialFormData, setLoadingInitialFormData] = useState();
    const [initialFormObject, setInitialFormObject] = useState(staticUnmappedFormData || {});
    const [initialFormData, setInitialFormData] = useState(staticFormData);
    const loading = loadingAction || (loadingInitialFormData && !hideDataLoader);
    const error = loadingError || errorLoadingInitialFormData;
    const [_formDefinition, _setFormDefinition] = useState([fdSchema, fdUiSchema, fdObjectMap, fdCommentFieldsMap, 0]);
    const [schema, uiSchema, objectMap, commentFieldsMap, curClientIdx] = _formDefinition;

    const sideChannel = useSideChannel(initialFormData);

    currentFormDef.current = [schema, uiSchema, objectMap];
    const { onLocateField } = props;

    const parsedProps = { ...props, ...parseProps(props) };
    const propsSignature = JSON.stringify(parsedProps);

    const scope = useMemo(
      () => ({
        props: parsedProps,
        initialFormObject,
        initialFormData,
      }),
      [propsSignature, initialFormObject, initialFormData]
    );

    const setFormDataValues = useCallback(
      dataValues => {
        console.log('setFormDataValues', dataValues);
        const formC = formComponentRef.current;
        const newFormData = clone.set(formC.state.formData, dataValues);
        formC.setState({ formData: newFormData }, () => formC.props.onChange && formC.props.onChange(formC.state));
      },
      [formComponentRef]
    );

    const openModal = useOpenModal();
    const auth = useContext(AuthContext);

    const history = useHistory();

    const formContext = useMemo(
      () => ({
        sideChannel,
        openModal,
        auth,
        history,
        rrc,
        onLocateField,
        formDefinition: {
          schema,
          uiSchema,
          objectMap,
          commentFieldsMap,
          curClientIdx,
          invObjectMap: objectMap ? invertMap(objectMap) : undefined,
        },
        setFormDataValues,
        formFields: { current: {} },
        ...(buildFormContext ? buildFormContext(scope) : {}),
      }),
      [buildFormContext, rrc, scope, sideChannel, setFormDataValues, openModal, auth, _formDefinition, onLocateField, history]
    );

    const getCurrentFormObject = useCallback(() => {
      const objectMap = currentFormDef.current[2];
      const cfo = getFormObjectFromData(currentFormData.current, objectMap, initialFormObject, formContext);

      return cfo;
    }, [currentFormData, initialFormObject, formContext]);

    const setFormObject = useCallback(
      formObject => {
        const objectMap = currentFormDef.current[2];
        const formData = getFormDataFromObject(formObject, objectMap, formContext);
        setInitialFormData(formData);
        setInitialFormObject(formObject);
        sideChannel.publish(formData, formObject);
      },
      [sideChannel, formContext]
    );

    const saveCurrentFormObject = useStateUpdateRequestPromise(initialFormObject, setFormObject, getCurrentFormObject);

    const { readonly, submitButtons: propSubmitButtons } = parsedProps;

    const setFormDefinition = useCallback(
      (schema, uiSchema, objectMap, commentFieldsMap, clientIdx) => {
        schema = schema || fdSchema;
        uiSchema = uiSchema || fdUiSchema;
        objectMap = objectMap || fdObjectMap;
        commentFieldsMap = commentFieldsMap || fdCommentFieldsMap;
        _setFormDefinition([schema, uiSchema, objectMap, commentFieldsMap, clientIdx || 0]);

        if (initialFormObject) {
          const formData = getFormDataFromObject(initialFormObject, objectMap, formContext);
          setInitialFormData(formData);
          currentFormData.current = formData;
          sideChannel.publish(formData, initialFormObject);
        }
      },
      [fdSchema, formContext, initialFormObject, fdUiSchema, fdObjectMap]
    );

    staticEffects.forEach(fn => !!fn && fn());

    const renderScope = useMemo(
      () => ({
        ...scope,
        loading,
        error,
        loadFn,
      }),
      [scope, loading, error, loadFn]
    );

    const submitButtons = propSubmitButtons || constSubmitButtons;
    const sortedSubmitButtons = useMemo(() => {
      if (!submitButtons) return null;
      const list = Object.entries(submitButtons);
      list.sort((a, b) => a[1].order - b[1].order);

      return list;
    }, [submitButtons]);

    const onSubmit = useMemo(() => {
      const timestamp = new Date().getTime();

      const fn = readonly
        ? () => {}
        : async args => {
            const submitButton = (submitButtons || {})[submitState.current] || {};
            const { formData } = args;

            const submitAction = async () => {
              const formObject = getFormObjectFromData(formData, objectMap, initialFormObject, formContext);

              if (formObject) {
                args.object = formObject;
                args.clientIdx = curClientIdx;
                args.commentFieldsMap = commentFieldsMap;
                args.generateDocuments = parsedProps.generateDocuments;

                if (submitButton.onProcessSubmit) {
                  submitButton.onProcessSubmit(args, scope);
                } else if (submitButton.setProps && formObject) {
                  executeSetProps(submitButton.setProps, formObject, formData, {
                    formData,
                    formContext,
                    formObject,
                  });
                }
              }

              setInitialFormData(formData);
              setInitialFormObject(formObject);
              const result = await propOnSubmit(args, scope);
              setFormSubmitResult(result);
              setFormSubmitted(true);
            };

            if (submitButton.onBeforeSubmit) {
              const evt = {
                preventDefault() {
                  evt.cancel = true;
                },
                setExtraErrors,
                formData,
                formContext,
                scope,
              };

              await submitButton.onBeforeSubmit(evt);
              if (evt.cancel) return;
            }

            return (submitButton.modal
              ? openModal(...submitButton.modal, formData)
              : submitButton.confirm
              ? openModal('ConfirmationModal', submitButton.text, ...submitButton.confirm)
              : Promise.resolve(true)
            ).then(confirmation => {
              return confirmation ? loadFn(submitAction) : undefined;
            });
          };

      fn.timestamp = timestamp;

      return fn;
    }, [readonly, propOnSubmit, setFormSubmitted, scope, initialFormObject, setExtraErrors]);

    const jnxValidation = useJnxFormValidation(schema, uiSchema);

    const validate = useMemo(() => {
      function validate(formData, errors) {
        console.log('running validate');

        [jnxValidation, propValidate].reduce((errors, valFn) => {
          return valFn ? valFn(formData, errors, scope, formContext.formFields?.current) : errors;
        }, errors);

        return errors;
      }

      return propValidate || jnxValidation ? validate : undefined;
    }, [propValidate, jnxValidation, scope]);

    useEffect(() => {
      const flags = {};

      async function initialFormDataLoader(parsedProps) {
        setErrorLoadingInitialFormData();
        setLoadingInitialFormData(true);

        try {
          const formObject = await loadData(parsedProps);

          if (!flags.canceled) {
            setFormObject(formObject);
          }
        } catch (e) {
          if (!flags.canceled) setErrorLoadingInitialFormData(e.message);
        }

        setLoadingInitialFormData();
      }

      if (loadData) {
        initialFormDataLoader(parsedProps);

        return () => {
          // cancel load if component is unloaded or props change
          flags.canceled = true;
        };
      }

      return undefined;
    }, [loadData, propsSignature]);

    const propagateChangeRef = useRef();
    const onChange = useMemo(() => {
      const propagateChange = _.debounce(() => {
        // kill any old propagations
        if (propagateChangeRef.current !== propagateChange) {
          return;
        }

        const formData = currentFormData.current;
        const formObject = getCurrentFormObject();
        sideChannel.publish(formData, formObject);

        if (navRef && navRef.current) {
          navRef.current.updateNav();
        }
      }, 200);
      propagateChangeRef.current = propagateChange;

      return ({ formData }) => {
        currentFormData.current = formData;
        propagateChange();
      };
    }, [sideChannel, currentFormData, getCurrentFormObject, navRef]);

    useEffect(
      () =>
        propOnChange
          ? sideChannel?.subscribe((formData, formObject) => {
              propOnChange({ formData, formObject, parsedProps });
            })
          : undefined,
      [propOnChange, sideChannel, parsedProps]
    );

    useEffect(() => {
      if (navRef && navRef.current) {
        navRef.current.updateNav();
      }
    }, [initialFormData]);

    const onSubmitRef = useRef();
    onSubmitRef.current = onSubmit;

    function makeSubmitButtonHandler({ key, ignoreValidation }) {
      function submitButtonHandler(event) {
        submitState.current = key;

        const onSubmit = onSubmitRef.current;

        if (ignoreValidation) {
          if (event) event.preventDefault();
          onSubmit({ formData: currentFormData.current });
        }
      }

      return submitButtonHandler;
    }

    const brh = beforeRenderHook({
      parsedProps,
      sideChannel,
      formComponentRef,
      setFormDataValues,
      setFormDefinition,
      saveCurrentFormObject,
      setFormObject,
      setExtraErrors,
      navRef,
      onSubmitRef,
      getCurrentFormObject,
      initialFormObject,
      currentFormData,
      submitButtons,
    });

    const processed_uiSchema = useMemo(() => {
      const pschema = {};
      const stack = Object.entries(uiSchema).map(([k, v]) => [pschema, k, v]);

      while (stack.length) {
        const current = stack.pop();
        const [obj, k, v] = current;
        let vvalue = v;

        if (k === 'ui:help' && v) vvalue = <Help>{v}</Help>;

        obj[k] = vvalue;

        if (v && typeof v === 'object' && !Array.isArray(v)) {
          stack.push(...Object.entries(v).map(([k, v2]) => [v, k, v2]));
        }
      }

      return pschema;
    }, [uiSchema]);

    const transformErrorsCb = useCallback(errors => transformErrors(errors, schema), [schema]);

    useEffect(() => {
      currentFormData.current = formComponentRef?.current?.state?.formData;
    }, [formComponentRef?.current?.state?.formData]);

    const formClassName = processed_uiSchema?.formClassName || '';

    return (
      <>
        {loading ? <Loader fullscreen /> : null}
        {error ? (
          <Notification color="danger" show="true">
            {error}
          </Notification>
        ) : null}
        {formSubmitted && renderFormSubmitted ? (
          renderFormSubmitted({
            ...props,
            history,
            scope: renderScope,
            formDefinition,
            formSubmitResult,
          })
        ) : (
          <>
            {renderNavigation ? renderNavigation({ title, navRef, formRef }) : null}
            <div className={`form-component ${formClassName}`} ref={formRef}>
              {renderFormDetails
                ? renderFormDetails({
                    brh,
                    parsedProps,
                    setFormObject,
                    sideChannel,
                  })
                : null}
              <Card>
                <CardBody>
                  <ModalContainer
                    formContext={formContext}
                    currentFormData={currentFormData}
                    getCurrentFormObject={getCurrentFormObject}
                    scope={scope}
                  />
                  <Form
                    ArrayFieldTemplate={ArrayFieldTemplate}
                    customFormats={customFormats}
                    disabled={readonly}
                    ErrorList={ErrorListTemplate}
                    extraErrors={extraErrors}
                    fields={customFields}
                    FieldTemplate={SchemaFieldTemplate}
                    formContext={formContext}
                    formData={initialFormData}
                    noHtml5Validate={noHtml5Validate}
                    ObjectFieldTemplate={ObjectFieldTemplate}
                    onChange={loadingInitialFormData ? undefined : onChange}
                    onSubmit={onSubmit}
                    ref={formComponentRef}
                    schema={schema}
                    transformErrors={transformErrorsCb}
                    uiSchema={processed_uiSchema}
                    validate={validate}
                    widgets={widgets}
                  >
                    <>
                      {renderFormChildren
                        ? renderFormChildren({
                            ...props,
                            scope,
                            formDefinition,
                          })
                        : null}
                      {!readonly && sortedSubmitButtons ? (
                        <div className={`form-submit-buttons ${alignButtons ? `float-${alignButtons}` : ''}`}>
                          {sortedSubmitButtons.map(([key, btnDef]) => (
                            <SubmitButton
                              key={key}
                              onClick={makeSubmitButtonHandler({ key, ...btnDef })}
                              btnDef={btnDef}
                              initialFormObject={initialFormObject}
                              formContext={formContext}
                            />
                          ))}
                        </div>
                      ) : null}
                    </>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </>
        )}
      </>
    );
  }

  Component.displayName = displayName;
  Component.formDefinition = formDefinition;

  return Component;
}

function executeSetProps(setProps, formObject, formData, bindings) {
  Object.entries(setProps || {}).forEach(([attr, expr]) => {
    const value = new Jnx(expr).eval(formData, '', bindings);
    console.log('executeSetProps formObject[attr] = value;', attr, value);
    formObject[attr] = value;
  });
}

function SubmitButton({ onClick, btnDef, initialFormObject, formContext }) {
  const { text: propText, className, Component = 'button', 'ui:showIf': showIf, 'ui:disableIf': disableIf, ...props } = btnDef;

  const { sideChannel } = formContext;
  const rootFormData = useSideChannelSubscription(sideChannel, [0]);

  const showIfJnx = useJnx(showIf);
  const disableIfJnx = useJnx(disableIf);
  const textJnx = useMemo(() => (propText && propText.expr ? new Jnx(propText) : null), [propText]);
  const text = useMemo(() => `${textJnx ? textJnx.eval(rootFormData, '') : propText}` || 'Someter', [propText, textJnx, rootFormData]);

  const show = useMemo(() => {
    if (showIfJnx) {
      return showIfJnx.eval(rootFormData, '', {
        formData: rootFormData,
        initialFormObject,
        formContext,
      });
    }

    return true;
  }, [showIfJnx, rootFormData, initialFormObject, formContext]);

  const disabled = useMemo(() => {
    if (disableIfJnx) {
      return disableIfJnx.eval(rootFormData, '', {
        formData: rootFormData,
        initialFormObject,
        formContext,
      });
    }

    return false;
  }, [disableIfJnx, rootFormData, initialFormObject, formContext]);

  return show ? (
    <Component
      type="submit"
      onClick={onClick}
      disabled={disabled}
      {...(typeof Component === 'string' ? {} : { sideChannel })}
      className={`btn ${className || ''}`}
      {...(props || {})}
    >
      {text}
    </Component>
  ) : null;
}

function getFormObjectFromData(formData, objectMap, initialFormObject, context) {
  return objectMap ? mapObject(formData, objectMap, JSON.parse(JSON.stringify(initialFormObject || {})), context) : undefined;
}

function getFormDataFromObject(formObject, objectMap, context) {
  return objectMap ? mapObject(formObject, invertMap(objectMap), undefined, context) : formObject;
}

export const ExtendedForm = forwardRef((props, ref) => {
  return <Form {...props} ref={ref} fields={customFields} transformErrors={transformErrors} widgets={widgets} ErrorList={ErrorListTemplate} />;
});

export default FormComponent;
