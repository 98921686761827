import { t } from '@lingui/macro';

export function getLabel() {
  return {
    active: t`Active`,
    addOption: t`Add Option`,
    agencies: t`Agencies`,
    agencyRegister: t`Add Agency`,
    akcRequiredIfVisible: t`Required`,
    archived: t`Archived`,
    city: t`City`,
    classNames: t`Styles`,
    code: t`Code`,
    createForm: t`Create Form`,
    createObject: t`Create Object`,
    createSection: t`Create Section`,
    createWorkflow: t`Create Workflow`,
    edit: t`Edit`,
    enum: t`Options`,
    format: t`Format`,
    forms: t`Forms`,
    help: t`Help`,
    integrations: t`Integrations`,
    invoiceItems: t`Invoice Items`,
    label: t`Label`,
    lookup: t`Lookup`,
    mask: t`Mask`,
    maxLength: t`Max. Length`,
    minLength: t`Min. Length`,
    modifyComponent: t`Modify Component`,
    name: t`Name`,
    objectTypes: t`Entity Types`,
    organization: t`Organization`,
    position: t`Position`,
    postalCode: t`Postal Code`,
    reset: t`Reset`,
    save: t`Save`,
    section: t`Section`,
    sections: t`Sections`,
    selectComponent: t`Select a component of the system you will manage`,
    sla: t`SLA`,
    state: t`State`,
    subsection: t`Subsection`,
    subsubsection: t`Subsubsection`,
    subtype: t`Sub Type`,
    themes: t`Themes`,
    title: t`Title`,
    type: t`Type`,
    uiAddable: t`Addable`,
    uiAddableLabelButton: t`Add Button Label`,
    uiArrayType: t`Array Type`,
    uiCellClassName: t`Cell Class`,
    uiExpandable: t`Expandable`,
    uiHideHeader: t`Hide Header`,
    uiHideTitle: t`Hide Title`,
    uiNewSection: t`New Section`,
    uiOrderable: t`Orderable`,
    uiPlaceHolder: t`Placeholder`,
    uiPositionButtons: t`Button Positions`,
    uiPrefix: t`Prefix`,
    uiReadonly: t`Readonly`,
    uiRemovable: t`Removable`,
    uiRemovableConfirm: t`Confirm on Remove?`,
    uiRemovableConfirmText: t`Confirm on Remove Text`,
    uiRemovableLabelButton: t`Remove Button Label`,
    uiSectionType: t`Section Type`,
    uiShowheaderOnEmpty: t`Show Header On Empty`,
    uiSimulateLabel: t`Simulate Label`,
    uiSufix: t`Suffix`,
    userRegister: t`Add User`,
    users: t`User`,
    workflow: t`Workflow`,
    workTray: t`Work Tray`,
  };
}