import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { Redirect } from 'react-router';
import { t } from '@lingui/macro';
import { useHistory } from 'react-router-dom';
import FormComponent from '../../../components/FormComponent';
import Loader from '../../../components/Loader';
import Notification from '../../../components/Notification';
import ThemeSchemaApi from '../../../api/ThemeSchemaApi';
import useLoader from '../../../util/useLoader';
import parseFormDefinition from '../../../util/parseFormDefinition';

function ThemeEditor({ themeId, setThemeId }) {
  const [loadingTheme, errorLoadingTheme] = useLoader();
  const history = useHistory();

  const nameProperties = {
    name: { title: t`Name`, type: 'string', 'map:field': 'name' },
    isDefault: {
      title: t`Set as Default`,
      type: 'boolean',
      'map:field': 'isDefault',
    },
  };

  const uploadProperties = {
    logoFileId: {
      title: 'Logo',
      type: 'number',
      'ui:field': 'FileField',
      'map:field': 'logoFileId',
    },
    faviconFileId: {
      title: 'ICO Logo Icon',
      type: 'number',
      'ui:field': 'FileField',
      'map:field': 'faviconFileId',
    },
  };

  /*  TODO DO NOT REMOVE - complete additional features
  const fontProperties = {
    'fonts-url': {
      title: 'Google Fonts URL',
      type: 'string',
      'map:field': 'fontSettings.fonts-url',
    },
    'font-family': { title: 'font-family', type: 'string', 'map:field': 'fontSettings.font-family' },
    'font-optical-sizing': { title: 'font-optical-sizing', type: 'string', 'map:field': 'fontSettings.font-optical-sizing' },
    'font-weight': { title: 'font-weight', type: 'number', 'map:field': 'fontSettings.font-weight' },
    'font-style': { title: 'font-style', type: 'string', 'map:field': 'fontSettings.font-style' },
    'font-size': { title: 'font-size', type: 'string', 'map:field': 'fontSettings.font-size' },
  }; */

  const colorProperties = {
    primary: {
      title: 'primary',
      type: 'string',
      'map:field': 'colors.color_primary',
      'ui:field': 'InputColorPicker',
    },
    secondary: { title: 'secondary', type: 'string', 'map:field': 'colors.color_secondary', 'ui:field': 'InputColorPicker' },
    main: { title: 'main', type: 'string', 'map:field': 'colors.color_main', 'ui:field': 'InputColorPicker' },
    info: { title: 'info', type: 'string', 'map:field': 'colors.color_info', 'ui:field': 'InputColorPicker' },
    white: { title: 'white', type: 'string', 'map:field': 'colors.color_white', 'ui:field': 'InputColorPicker' },
    black: { title: 'black', type: 'string', 'map:field': 'colors.color_black', 'ui:field': 'InputColorPicker' },
    warning: { title: 'warning', type: 'string', 'map:field': 'colors.color_warning', 'ui:field': 'InputColorPicker' },
    success: { title: 'success', type: 'string', 'map:field': 'colors.color_success', 'ui:field': 'InputColorPicker' },
    danger: { title: 'danger', type: 'string', 'map:field': 'colors.color_danger', 'ui:field': 'InputColorPicker' },
    'gray-100': { title: 'gray-100', type: 'string', 'map:field': 'colors.color_gray-100', 'ui:field': 'InputColorPicker' },
    'gray-200': { title: 'gray-200', type: 'string', 'map:field': 'colors.color_gray-200', 'ui:field': 'InputColorPicker' },
    'gray-300': { title: 'gray-300', type: 'string', 'map:field': 'colors.color_gray-300', 'ui:field': 'InputColorPicker' },
    'gray-400': { title: 'gray-400', type: 'string', 'map:field': 'colors.color_gray-400', 'ui:field': 'InputColorPicker' },
    'gray-500': { title: 'gray-500', type: 'string', 'map:field': 'colors.color_gray-500', 'ui:field': 'InputColorPicker' },
    'gray-600': { title: 'gray-600', type: 'string', 'map:field': 'colors.color_gray-600', 'ui:field': 'InputColorPicker' },
    'gray-700': { title: 'gray-700', type: 'string', 'map:field': 'colors.color_gray-700', 'ui:field': 'InputColorPicker' },
    'gray-800': { title: 'gray-800', type: 'string', 'map:field': 'colors.color_gray-800', 'ui:field': 'InputColorPicker' },
    'gray-900': { title: 'gray-900', type: 'string', 'map:field': 'colors.color_gray-900', 'ui:field': 'InputColorPicker' },
  };

  const formSchema = {
    properties: {
      themeName: {
        title: t`Name`,
        type: 'object',
        properties: nameProperties,
        required: ['name'],
      },
      colors: {
        ':classNames': 'smallTwoRows',
        title: t`Colors`,
        type: 'object',
        properties: colorProperties,
        required: Object.keys(colorProperties),
      },
      upload: {
        ':classNames': 'smallTwoRows',
        title: t`Upload Logos`,
        type: 'object',
        properties: uploadProperties,
      },
      /* TODO DO NOT REMOVE - complete additional features
      font: {
        title: t`Font`,
        type: 'object',
        properties: fontProperties,
        required: Object.keys(fontProperties),
      }, */
    },
  };

  function renderFormSubmitted() {
    return <Redirect path="../system-settings/themeEditor" />;
  }

  function getEditForm() {
    const Form = FormComponent({
      ...parseFormDefinition({
        schemaProps: { type: 'object', required: formSchema.required },
        schema: formSchema.properties,
      }),
      submitButtons: {
        save: {
          text: t`Save`,
          className: 'btn-success',
          // onProcessSubmit: async ({ object }) => {
          //   const { success } = await ThemeSchemaApi.update(object);

          //   if (success) {
          //     history.push('/system-settings/themeEditor');

          //     // If Default Theme, force reload
          //   }
          // },
        },
        cancel: {
          text: t`Cancel`,
          className: 'btn-warning',
          ignoreValidation: true,
          onBeforeSubmit: submitEvent => {
            submitEvent.preventDefault();
            history.push('/system-settings/themeEditor');
          },
        },
      },
      async onSubmit({ object }, { props: { themeId }}) {
        if (themeId === "new") {
          await ThemeSchemaApi.create(object);
        } else {
          await ThemeSchemaApi.update(object);
        }

        if (object.isDefault) {
          window.location.reload();
        }

      },
      renderFormSubmitted: () => (
        <Redirect to="/system-settings/themeEditor" />
      ),
      loadData: async ({ themeId }) => {
        if (themeId === "new") {
          return JSON.parse(JSON.stringify(DEFAULT_THEME));
        }

        const { success, data } = await ThemeSchemaApi.getTheme(themeId);

        return success ? data : {};
      },
      parseProps: ({ themeId, setThemeId, history }) => ({
        auth: useContext(AuthContext),
        history,
        setThemeId,
        themeId,
      }),
      renderFormSubmitted,
    });

    return (
      <div>
        <Form themeId={themeId} setThemeId={setThemeId} history={history} />
      </div>
    );
  }

  return loadingTheme ? (
    <Loader />
  ) : (
    <>
      {errorLoadingTheme && <Notification error={errorLoadingTheme} />}
      {getEditForm()}
    </>
  );
}


const DEFAULT_THEME = {
  "fontSettings": {
      "fonts-url":"https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
      "font-family":"'Inter', sans-serif","font-optical-sizing":"auto",
      "font-weight":300,"font-style":"normal","font-size":"12pt"
  },
  "colors": {
      "color_info": "#3c8ef7", "color_primary": "#091f68", "color_secondary": "#9dcbef", "color_background": "#000000",
      "color_main": "#000000", "color_white": "#fff", "color_black": "#000", "color_warning": "#f8d161", "color_success": "#4caf50",
      "color_danger": "#f44336", "color_gray-100": "#f5f5f5", "color_gray-200": "#eeeeee", "color_gray-300": "#e0e0e0",
      "color_gray-400": "#bdbdbd", "color_gray-500": "#9e9e9e", "color_gray-600": "#757575", "color_gray-700": "#616161",
      "color_gray-800": "#424242", "color_gray-900": "#212121"
  },
  "isDefault": true,
  "logoFileId": null,
  "faviconFileId": null
};

export default ThemeEditor;
