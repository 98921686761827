import { WORKPAGE_COMPONENTS } from '../../../components/Workpage/index';
import FormComponent from '../../../components/FormComponent';
import parseFormDefinition from '../../../util/parseFormDefinition';

export const WorkpageDetailsEditor = FormComponent({
  ...parseFormDefinition({
    displayName: 'WorkpageDetailsEditor',
    title: 'Workpage Details',
    schemaProps: {
      title: 'Workpage ${imagename.name}',
      'ui:newSection': true,
      ':classNames': 'grid-2-columns',
      definitions: {
        elementType: {
          type: 'string',
          enum: Object.keys(WORKPAGE_COMPONENTS),
        },
      },
    },
    schema: {
      // type: "object",
      order: {
        title: 'Order',
        'map:field': 'order',
        type: 'number',
        structure: 'Number',
      },
      imagename: {
        ':classNames': 'from-col-1-size-1 flex-h',
        type: 'object',
        title: ' ',
        properties: {
          image: {
            title: 'Image',
            'map:field': 'image',
            'ui:field': 'SelectImageField',
            type: 'object',
            properties: {},
            structure: 'Object',
          },
          name: {
            title: 'Name',
            'map:field': 'name',
            type: 'string',
            structure: 'Text',
          },
        },
      },
      slug: {
        title: 'Slug',
        'map:field': 'slug',
        type: 'string',
        structure: 'Text',
      },
      elements: {
        ':classNames': 'from-col-1-size-2',
        title: 'Elements',
        'map:field': 'definition.elements',
        type: 'array',
        'ui:arrayType': 'cards',
        items: {
          type: 'object',
          properties: {},
          'ui:field': 'SubformField',
          schemaExpr: ['$deepMerge(', '    $schema.schema,', '    $coalesce($schema.byType ~> $lookup(type), $schema.byType.default)', ')'],
          byType: {
            ...Object.entries(WORKPAGE_COMPONENTS).reduce((_, [k, { rootSchema }]) => {
              if (rootSchema) _[k] = rootSchema;

              return _;
            }, {}),
            default: {
              type: 'object',
              properties: {
                json: {
                  type: 'object',
                  properties: {},
                  'map:field': '',
                  'ui:field': 'JsonField',
                },
              },
            },
          },
          schema: {
            type: 'object',
            title: 'Element # ${$index + 1} (${type} ${col} ${colSpan})',
            'ui:newSection': true,
            'ui:expandable': true,
            ':classNames': 'grid-3-columns',
            properties: {
              type: { $ref: '#/definitions/elementType', 'map:field': 'type' },
              col: {
                type: 'number',
                title: 'Column',
                'ui:placeholder': 'auto-assigned',
                'map:field': 'col',
              },
              colSpan: {
                type: 'number',
                title: 'Column Span',
                default: 4,
                min: 1,
                max: 4,
                'map:field': 'colSpan',
              },
            },
          },
        },
      },
    },
  }),
  withoutNavigation: true,
  parseProps: ({ workpage, onChange }) => ({
    workpage,
    onWorkpageChange: onChange,
  }),
  loadData: ({ workpage }) => workpage,
  hideDataLoader: true,
  onChange({ formObject, parsedProps: { onWorkpageChange } }) {
    if (!formObject) return;
    onWorkpageChange(formObject);
  },
  onSubmit: () => Promise.resolve(),
  submitButtons: {},
});

export default WorkpageDetailsEditor;
