import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'reactstrap';

function ToolbarButton(props) {
  const { className, iconClassName, title, tooltip, onClick, disabled } = props;
  const id = `Toolbar${title}Button`;

  const [hover, setHover] = useState(false);

  // Clear hover once button is disabled, prevents phantom tooltips
  useEffect(() => {
    if (disabled) {
      setHover(false);
    }
  }, [disabled]);

  // Only tracks hover on enabled buttons
  const toggleHover = () => {
    if (!disabled) {
      setHover(hover => !hover);
    }
  };

  return (
    <>
      <Button
        id={id}
        className={className}
        title={title}
        onClick={onClick}
        disabled={disabled}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        style={{
          minWidth: '28x',
          height: '32px',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <i
          className={iconClassName}
          style={{
            position: 'relative',
            fontSize: '1rem',
          }}
        />
      </Button>
      {
        tooltip ? (
          <Tooltip
            placement="bottom"
            isOpen={hover}
            target={id}
            toggle={toggleHover}
          >
            {tooltip}
          </Tooltip>
        ) : null
      }
    </>
  );
}

export default ToolbarButton;
