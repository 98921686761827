import React from 'react';
import { Input } from 'reactstrap';
import Buttons from './_buttons';


const PATTERN = { type: 'string' };


function StringField(props) {
  const { fieldProps, value, onChange, object, path, scope } = props;
  const {
    label,
    isNumber,
    property,
    format,
    rows,
    default: defaultValue,
    leftButtons,
    rightButtons
  } = fieldProps || props;

  return (
    <>
      {label ? <label className="title">{label}</label> : null}
      <div className="d-flex">
        {leftButtons ? <Buttons buttons={leftButtons} prop={fieldProps} object={object} path={path} value={value} scope={scope} /> : null}
        <Input
          type={isNumber ? 'number' : format === 'textarea' ? 'textarea' : 'string'}
          className="form-control"
          rows={rows}
          value={value || defaultValue || ''}
          name={property}
          onChange={({ target: { value } }) => onChange(isNumber ? parseInt(value, 10) : value)}
        />
        {rightButtons ? <Buttons buttons={rightButtons} prop={fieldProps} object={object} path={path} value={value} scope={scope} /> : null}
      </div>
    </>
  );
}

StringField.pattern = PATTERN;

export default StringField;
