import { useContext, useMemo } from 'react';
import AppContext from '../context/AppContext';
import MODALS from '../blc/modals';


function FormModal({
  modalDef,
  formContext,
  formData,
  getCurrentFormObject,
  scope,
}) {
  const { modal, args, resolve, reject } = modalDef;
  const { Component, params } = MODALS[modal] || {};
  const props = useMemo(
    () =>
      (params || []).reduce((_, propName, idx) => {
        _[propName] = args[idx];

        return _;
      }, {}),
    [args, params]
  );

  return Component && props ? (
    <Component
      isOpen
      onCancel={reject}
      onConfirm={resolve}
      formData={formData}
      formContext={formContext}
      getCurrentFormObject={getCurrentFormObject}
      scope={scope}
      {...props}
    />
  ) : null;
}

export default FormModal;

export function useOpenModal() {
  const { modalContainer } = useContext(AppContext);

  return modalContainer?.openModal;
}
