import { forwardRef } from 'react';
import { Button } from 'reactstrap';

export const SchemaElement = forwardRef(({ icon, label, ...props }, ref) => (
  <>
    <div ref={ref} {...props}>
      <Button
        className="w-100 mb-2 d-flex align-items-center schema-element"
        onClick={e => e.preventDefault()}
      >
        <span className="mr-2" role="img" aria-label={`${label} type`}>
          {icon}
        </span>
        <span>{label}</span>
      </Button>
    </div>
  </>
));
