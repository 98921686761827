const UID = `${Math.random()}`.split('.').pop();

function getUtilSVG(){
  const svgId = `UtilSvg_${UID}`;
  let svg = document.querySelector(`svg.util#${svgId}`);

  if (!svg) {
    svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.classList.add('util');
    svg.style.position='absolute';
    svg.setAttribute('width', 1);
    svg.setAttribute('height', 1);
    svg.style.top=0;
    svg.style.left='-10px';
    svg.id = svgId;
    document.body.appendChild(svg);
  }

  return svg;
}

export function measureTextWords(text, style) {
  const svg = getUtilSVG();
  const words = text ? text.split(/\s+/) : [];

  let textEl = svg.querySelector('text.textRuler');

  if (!textEl) {
    textEl = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    textEl.classList.add('textRuler');
    textEl.style.opacity = 0;
    svg.appendChild(textEl);
  }

  if (style) {
    Object.assign(textEl.style, style);
    textEl.style.opacity = 0;
  }

  function measure(text) {
    textEl.textContent = text;

    return textEl.getComputedTextLength();
  }

  return {
    words: words.map(word => ({ word, width: measure(word)})),
    space: measure('\u00A0')
  };
}

export function wrapTextContent(text, style, options) {
  const {
    maxWidth = 250
  } = options || {};

  const measurements = measureTextWords(text, style);

  return measurements.words.reduce((_, {word, width}) => {
    if (_.lines.length === 0 || _.currentLineWidth + width > maxWidth) {
      _.currentLine = [word];
      _.lines.push(_.currentLine);
      _.currentLineWidth = width;
    } else {
      _.currentLine.push(word);
      _.currentLineWidth += width + measurements.space;
    }

    return _;
  }, {
    lines: [],
    currentLineWidth: 0,
    currentLine: null
  }).lines.map(line => line.join(' '));
}
