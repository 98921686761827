import React, { useState } from "react";
import _ from "lodash";
import AIApi from "../../api/AIApi";
import { correctJson, PromptAndHistory } from "../../util/aiPromptBox";

function FormAI(props) {

    const {
        onChange,
        aiMessages,
        setAiMessages,
        form
    } = props;

    const [prompt, setPrompt] = useState("");
    const [loadingGenerateForm, setLoadingGenerateForm] = useState(false);

    const onGenerateForm = () => {

        if (!prompt) return;

        const newForm = { ...form };
        const formSchema = Object.keys(form?.schema || {}).length > 0 ? JSON.stringify({ schema: form?.schema || '' }) : undefined;

        setLoadingGenerateForm(true);
        AIApi.generateForm({ content: prompt, form: formSchema, messages: aiMessages }).then(response => {
            if (response?.jsonResponse) {
                setLoadingGenerateForm(false);
                const json = JSON.parse(correctJson(response.jsonResponse));
                newForm.schema = json.schema;
                if (!newForm?.options?.submitButtons || !Object.keys(newForm.options.submitButtons).length) {
                    newForm.options.submitButtons = {
                        submit: { "text": "Submit", "className": "btn-submit", "setProps": { "action": "'submit'" } }
                    }
                }
                onChange(newForm);

                if (response?.messages) {
                    setAiMessages(response.messages);
                }

                setPrompt("");
            }

        });

    };

    return (<div className="container mt-2">

        {loadingGenerateForm ? <div className="text-center d-flex justify-content-center mt-2" style={{ height: 250, alignItems: 'center' }}><i style={{ fontSize: '27px' }} className="fa fa-magic pulse-effect" /></div> :
            <PromptAndHistory
                onConfirm={onGenerateForm}
                messages={aiMessages}
                prompt={prompt}
                setPrompt={setPrompt}
            />
        }

    </div>);
}

export default FormAI;
