function roleNameMatches(testRole, roleName){
  if(Array.isArray(testRole)) {
    return testRole.some(tr => roleNameMatches(tr, roleName));
  }

  let result;

  if (/(.+)\*$/.test(testRole)) {
    testRole = /(.+)\*$/.exec(testRole)[1];
    result = roleName.substr(0, testRole.length) === testRole;
  } else {
    result = testRole === roleName;
  }

  return result;
}







export default roleNameMatches;