import { Label, Row, Col } from 'reactstrap';
import RelactionSection from './RelationSection';

const RelationLayout = ({ isLoading, fowardRelations, backwardRelations }) => {
  return (
    <>
      <h3>
        Relations
      </h3>
      <Row>
        <Col xs="6">
          <Label for="fowardRelations">
            Foward Relations
          </Label>
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          ) : (
            <RelactionSection relationData={fowardRelations} />
          )}
        </Col>
        <Col xs="6">
          <Label for="backRelations">
            Back Relations
          </Label>
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          ) : (
            <RelactionSection relationData={backwardRelations} isBackWardRelation />
          )}
        </Col>
      </Row>
    </>
  );
};

export default RelationLayout;
