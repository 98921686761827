import { t } from '@lingui/macro';
import Workpage from '../../../components/Workpage/index';

const ListFaqs = Workpage.HOC({
  slug: '?faq=',
  schema: {
    title: t`Frequently Asked Questions`,
    type: 'faq',
    elements: [
      {
        title: t`Frequently Asked Questions`,
        type: 'worktray',
        ':className': 'col-lg-12',
        resource: 'manage/faq/list',
        tableActions: [
          {
            label: t`Create FAQ`,
            route: '${workpage.slug}new',
          },
        ],
        filters: [],
        columns: [
          { title: t``, style: { width: '90%' }, 'map:field': 'content', isHTML: true },
          {
            title: t`Actions`,
            actions: [
              {
                label: t`Edit`,
                route: '${workpage.slug}$id',
              },
            ],
          },
        ],
      },
    ],
  },
});

export default ListFaqs;
