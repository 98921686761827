
const clone = {
  DELETE_VALUE: Symbol('DELETE_VALUE'),

  shallow(value) {
    return Array.isArray(value) ? [...value] : {...value};
  },

  set(object, ...args){
    if (args.length === 1 && (typeof args[0] === 'object')) {
      return clone.set(object, ...Object.entries(args[0]).reduce((_, [k, v]) => {
        _.push(k, v);

        return _;
      }, []));
    }

    const clonedObject = clone.shallow(object);

    while(args.length > 1){
      const selector = args.shift();
      let value = args.shift();
      const keys = selector.split('.');

      let currentClone = clonedObject;

      while (keys.length > 1){
        let key = keys.shift();

        if (Array.isArray(currentClone)) {
          key = key | 0;
        }
    
        const nextObject = currentClone[key];
        const nextClone = clone.shallow(nextObject);
    
        currentClone[key] = nextClone;
        currentClone = nextClone;
      }
    
      const key = keys.shift();
            
      if (typeof value === 'function') {
        value = value(currentClone[key]);
      }

      if (value === clone.DELETE_VALUE) {
        if (Array.isArray(currentClone)) {
          currentClone.splice(key, 1);
        } else {
          delete currentClone[key];
        }
      } else {
        currentClone[key] = value;
      }
    }
    
    return clonedObject;
  },

  splice(array, start, deleteCount=1, ...insert){
    const clone = array.slice();
    clone.splice(start, deleteCount, ...insert);

    return clone;
  },
};


export default clone;