import Workpage from './Workpage';
import DC from './fields';
import TextDisplay from './TextDisplay';
import WorkTray from './WorkTray';
import WorkTrayIcon from './WorkTrayIcon';
import EntityWidget from './EntityWidget';
import EntitySummary from './EntitySummary';

export const WORKPAGE_COMPONENTS = DC;
WORKPAGE_COMPONENTS.worktray = WorkTray;
WORKPAGE_COMPONENTS.entity = EntityWidget;
WORKPAGE_COMPONENTS.button = WorkTrayIcon;
WORKPAGE_COMPONENTS.text = TextDisplay;
WORKPAGE_COMPONENTS.summary = EntitySummary;

export default Workpage;
