import _ from 'lodash';
import FormComponent from '../../../components/FormComponent';
import parseFormDefinition from '../../../util/parseFormDefinition';

export const WorkflowDetailsSidepanel2 = FormComponent({
  ...parseFormDefinition({
    displayName: 'WorkflowDetailsSidepanel',
    title: 'Workflow Details',
    schemaProps: {
      ':formClassName': 'sidebar-panel',
      'ui:newSection': true
    },
    schema: {
      name: {
        title: 'Name',
        'map:field': 'name',
        type: 'string'
      },
      objectSchemaId: {
        title: 'Object Schema',
        type: 'number',
        'map:field': 'objectSchemaId',
        'ui:field': 'LookupFormField',
        lookup: {
          resource: 'Entity Types',
          api: 'api:manage/objectschemas',
          params: { all: true, size: 30 },
          options: { useCache: true },
          jnx: 'data.items',
          id: 'id',
          label: 'name'
        }
      },
      slug: {
        title: 'Slug',
        'map:field': 'slug',
        type: 'string',
        structure: 'Text'
      },
      redirect: {
        title: 'Redirect',
        'map:field': 'redirect',
        type: 'string',
        structure: 'Text'
      },
      viewForm: {
        title: 'View Form',
        type: 'object',
        ':classNames': 'flex-h',
        properties: {
          name: {
            title: ' ',
            'map:field': 'viewForm',
            type: 'string',
            'ui:field': 'LookupFormField',
            lookup: {
              resource: 'Forms',
              api: 'api:forms',
              params: {
                all: true,
                'expr:entityType': 'objectSchemaId'
              },
              options: { useCache: true },
              jnx: 'items',
              id: 'name',
              label: 'name'
            }
          },
          btn: {
            title: ' ',
            icon: 'fa fa-clipboard-list',
            tooltip: 'View Form',
            type: 'number',
            'map:field': 'viewFormId',
            'ui:field': 'Button',
            'ui:onClick': {
              expr: [
                '(',
                '    $url:=$makeUrl(\'/system-settings/forms/\', {\'form\': \'name-\' & viewForm.name});',
                '    $openWindow($url);',
                '    $url',
                ')'
              ],
              debug: 'btn onclick'
            }
          }
        }
      }
    }
  }),
  withoutNavigation: true,
  parseProps: ({ dispatchAction, state }) => ({ dispatchAction, state }),
  loadData: ({ state }) => state.details,
  hideDataLoader: true,
  onChange: _.debounce(
    ({ formObject: newDetails, parsedProps: { dispatchAction } }) => {
      console.log('formObject', newDetails);
      dispatchAction.setWFDetails({ details: newDetails });
    },
    200
  ),
  onSubmit: () => Promise.resolve(),
  submitButtons: {}
});

export default WorkflowDetailsSidepanel2;
